import { configureStore } from "@reduxjs/toolkit";
import { authSlice, patientAttentionSlice, uiSlice, workspaceSlice } from "./slices";
import { canAttentionEndSlice } from "./slices/canAttentionEnd";
import { categorySlice } from "./slices/category";
import { countersSlice } from "./slices/counters";
import { SurgerySlice } from "./slices/surgery";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        workspace: workspaceSlice.reducer,
        ui: uiSlice.reducer,
        patientAttention: patientAttentionSlice.reducer,
        counters: countersSlice.reducer,
        canAttentionEnd: canAttentionEndSlice.reducer,
        category: categorySlice.reducer,
        surgery: SurgerySlice.reducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;