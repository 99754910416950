import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { AneTypesResponse, AnesthesiaNotesParams, AnesthesiaNotesResponse, CurrentAnestesiologistResponse, OxygenTypesResponse, SectionsChanged } from "@/models/surgery/patientAttention/Anesthesiologist";
import { setHeaders } from "@/utils";

export async function getCurrentAnestesiologistService(): Promise<CurrentAnestesiologistResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CurrentAnestesiologistResponse>(
        "/medical/current_anestesiologist", axiosParams
    );
    return serviceData;
}

export async function getAneTypeService(): Promise<AneTypesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<AneTypesResponse>(
        "/medical/ane-types", axiosParams
    );
    return serviceData;
}

export async function getOxygenTypesService(): Promise<OxygenTypesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<OxygenTypesResponse>(
        "/medical/oxygen-types", axiosParams
    );
    return serviceData;
}

export async function getAnesthesiaNotesService(params: AnesthesiaNotesParams): Promise<AnesthesiaNotesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AnesthesiaNotesResponse>(
        "/medical/clinicHistory/anesthesiaNotes", axiosParams
    );
    return serviceData;
}

export async function UpdateSectionsService(payload: SectionsChanged): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/anesthesiaNotes", payload, axiosParams
    );
    return serviceData;
}
export async function FinishEventService(payload: SectionsChanged): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/anesthesiaNotesClosing", payload, axiosParams
    );
    return serviceData;
}