import axios from "axios";
//
import { statusCodes } from "@/constants/http";
import { LoginInputs, RecoverPayload } from "@/models/auth";
import * as authService from "@/services/auth.service";
//
import { AppDispatch } from "@/config/store";
import * as uiActions from "@/config/slices/ui";
import { clearAuth, createAuth } from "@/config/slices/auth";
import { IResponse } from "@/models/general";
import { fireErrorAlert, fireSuccessAlert, fireWarningAlert } from "@/utils/utils";

export function loginUser(payload: LoginInputs) {
    return async function (dispatch: AppDispatch): Promise<boolean> {
        try {
            dispatch(uiActions.startLoading());
            const response = await authService.authLogin(payload);

            localStorage.setItem("x_token", response.access_token as string);
            localStorage.setItem("user_data", JSON.stringify(response.user_data));

            dispatch(createAuth(response));
            return true;

        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}

export function logoutUser() {
    return async function (dispatch: AppDispatch): Promise<void> {
        localStorage.clear();
        dispatch(clearAuth());
    };
}

export function recoverPassword(payload: RecoverPayload) {
    return async function (dispatch: AppDispatch): Promise<void> {
        try {
            dispatch(uiActions.startLoading());
            const response = await authService.recoverPassword(payload);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            fireSuccessAlert("Se ha enviado un correo para la recuperación de contraseña.");

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireWarningAlert(error.response?.data.message as string, "Intenta de nuevo");
            }

        } finally {
            dispatch(uiActions.finishLoading());

        }
    };
}

export function checkAuthentication() {
    return async function (dispatch: AppDispatch): Promise<void> {
        try {
            // dispatch(uiActions.startLoading());
            const response = await authService.verifyToken();

            if (response.status !== statusCodes.OK) {
                dispatch(logoutUser());
                return;
            }
            const userData = JSON.parse(localStorage.getItem("user_data") as string);
            dispatch(createAuth({
                access_token: localStorage.getItem("x_token"),
                user_data: userData,
                is_auth: true,
            }));

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string, "Error", () => window.location.reload());
            }

        }
    };
}