
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    currentChecklist: "entry",
    requestStatus: "",
    activeTabAttention: "",
    currentSheet: "",
    allLoaded: false,
};

export const SurgerySlice = createSlice({
    name: "checklist",
    initialState,
    reducers: {
        setCurrentCheckList: (state, action: PayloadAction<"entry" | "exit" | "pause">) => {
            state.currentChecklist = action.payload;
        },
        setRequestStatus: (state, action: PayloadAction<string>) => {
            state.requestStatus = action.payload;
        },
        setActiveTabAttention: (state, action: PayloadAction<string>) => {
            state.activeTabAttention = action.payload;
        },
        setCurrentSheet: (state, action: PayloadAction<"nurseNotes" | "surgeon" | "anesthesiologist">) => {
            state.currentSheet = action.payload;
        },
        setAllLoaded: (state, action: PayloadAction<boolean>) => {
            state.allLoaded = action.payload;
        },
    }
});

export const { setCurrentCheckList, setRequestStatus, setActiveTabAttention, setCurrentSheet, setAllLoaded } = SurgerySlice.actions;