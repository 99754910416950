


export default function AnalysisAndPlanDetails({ data }: { data?: string; }) {
    const renderComponent = () => {
        if (data) {
            return (
                <div className="item py-4">

                    <h5>{data}</h5>
                </div>
            );
        } else {
            return <></>;
        }
    };


    return renderComponent();
}
