import { Button, Modal, Select } from "@/components";
import {
    CommonSelect,
    PrismaticDiopters,
    SightConfig,
    SightConfigKeys,
    SightPosition,
    Squints
} from "@/models/sheets/orthoptic";
import { getSquintsDegreeService, getSquintsService } from "@/services";
import { useEffect, useState } from "react";
import "./SightTest.scss";

interface SightTestProps {
    type: "vl" | "vp";
    isPrism?: boolean;
    currentData?: SightConfig;
    onGetResult: (result: { vl?: SightConfig; vp?: SightConfig; }) => void;
    readOnly?: boolean;
    isDisabledForm?: boolean;
    squintsData?: Squints[];
    degreesData?: CommonSelect[]
}

export default function SightTest({ type, onGetResult, isPrism, currentData, readOnly, isDisabledForm, degreesData, squintsData }: SightTestProps) {
   
    const [sightTest, setSightTest] = useState<SightConfig>({
        observations: ""
    });
    const [result, setResult] = useState<SightConfig>({
        observations: ""
    });
    const [modalConfig, setModalConfig] = useState<{ isOpen: boolean; tag?: SightConfigKeys; title: string; }>({
        isOpen: false,
        title: ""
    });
    const [squints, setSquints] = useState<Squints[]>([]);
    const [degrees, setSquintsDegree] = useState<CommonSelect[]>([]);

    useEffect(() => {
        if (currentData || modalConfig.isOpen) {
            setSightTest(currentData || {});
            setResult(currentData || {});
        }
    }, [currentData, modalConfig.isOpen]);

    useEffect(() => {
        async function fetchData() {
            if(squintsData){
                setSquints(squintsData);
            }else{
                const dataS = await getSquintsService(); 
                setSquints(dataS.results);
            }

            if(degreesData){
                setSquintsDegree(degreesData); 
            }else{
                const dataSD = await getSquintsDegreeService();
                setSquintsDegree(dataSD.results);
            }

        }
        fetchData();
    }, [degreesData, squintsData]);

    const onCloseConfigModal = () => {
        setModalConfig({
            isOpen: false,
            tag: undefined,
            title: ""
        });
    };

    const onOpenConfigModal = (segment: SightConfigKeys) => setModalConfig({
        isOpen: true,
        tag: segment,
        title: segment.toUpperCase()
    });

    const onSaveResult = () => {
        setResult({
            ...result,
            ...sightTest,
        });
        onGetResult({
            [type]: {
                ...result,
                ...sightTest,
            }
        });
        onCloseConfigModal();
    };

    const onChangeSegment = (
        tag: SightConfigKeys,
        label: keyof SightPosition,
        item: "a" | "b",
        value: number | string
    ) => {
        let options: SightConfig;
        if (label === "prismaticDiopters") {
            if (Number(value) >= 0 && Number(value) <= 99) {
                options = {
                    ...result,
                    [tag]: {
                        ...sightTest[tag] as SightPosition,
                        [label]: {
                            ...sightTest[tag]?.[label] as PrismaticDiopters,
                            [item]: value.toString().length > 0 ? value : undefined
                        }
                    }
                };
                setSightTest(options);
            }
        } else {
            options = {
                ...result,
                [tag]: {
                    ...sightTest[tag] as SightPosition,
                    [label]: {
                        ...sightTest[tag]?.[label] as PrismaticDiopters,
                        [item]: +value
                    }
                }
            };
            setSightTest(options);
        }
    };

    const onChangeObservation = (value: string) => {
        const options = {
            ...result, observations: value
        };
        setResult(options);
        onGetResult({
            [type]: options,

        });
    };

    const onClearFields = () => {
        setSightTest({
            ...sightTest,
            [modalConfig.tag as SightConfigKeys]: {
                squint: { a: null, b: null },
                prismaticDiopters: isPrism ? { a: null, b: null } : undefined,
                degree: !isPrism ? { a: null, b: null } : undefined
            }
        });
    };

    const renderModal = () => {
        const squintsFormatted = squints.map(item => ({ label: item?.abbreviation, value: item.id }));
        const squintsValueA = squintsFormatted.find(item => item.value === sightTest?.[modalConfig.tag as SightConfigKeys]?.squint?.a);
        const squintsValueB = squintsFormatted.find(item => item.value === sightTest?.[modalConfig.tag as SightConfigKeys]?.squint?.b);

        const degreeFormatted = degrees.map(item => ({ label: item.value, value: item.id }));
        const degreeValueA = degreeFormatted.find(item => item.value === sightTest?.[modalConfig.tag as SightConfigKeys]?.degree?.a);
        const degreeValueB = degreeFormatted.find(item => item.value === sightTest?.[modalConfig.tag as SightConfigKeys]?.degree?.b);

        const prismValueA = sightTest?.[modalConfig.tag as SightConfigKeys]?.prismaticDiopters?.a || "";
        const prismValueB = sightTest?.[modalConfig.tag as SightConfigKeys]?.prismaticDiopters?.b || "";

        return (
            <Modal
                isOpen={modalConfig.isOpen}
                onClose={onCloseConfigModal}
                positionModal="center"
                width={400}
            >
                <h3 className="text-secondary fw-bold">{modalConfig.title}</h3>
                <table className="mt-3">
                    <tbody>
                        <tr>
                            <td className="fs-5 text-secondary" align="center">Estrabismo</td>
                            <td className={`text-secondary ${isPrism ? "fs-3" : "fs-5"}`} align="center">{isPrism ? "Δ" : "Grados"}</td>
                        </tr>
                        <tr>
                            <td className="pe-2 pb-3">
                                <Select
                                    disabled={isDisabledForm}
                                    options={squintsFormatted}
                                    style={{ width: "100%" }}
                                    value={squintsValueA}
                                    onChange={({ option }) => onChangeSegment(modalConfig.tag as SightConfigKeys, "squint", "a", option.value)}
                                />
                            </td>
                            <td className="ps-2 pb-3">
                                {isPrism ? (
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={prismValueA}
                                        onChange={({ target }) => onChangeSegment(modalConfig.tag as SightConfigKeys, "prismaticDiopters", "a", target.value)}
                                        onKeyDown={(event) => (event.key === "." || event.key === "-" || event.key === "+" || event.key === ",") && event.preventDefault()}
                                    />
                                ) : (
                                    <Select
                                        disabled={isDisabledForm}
                                        options={degreeFormatted}
                                        style={{ width: "100%" }}
                                        value={degreeValueA}
                                        onChange={({ option }) => onChangeSegment(modalConfig.tag as SightConfigKeys, "degree", "a", option.value)}
                                    />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="pe-2">
                                <Select
                                    disabled={isDisabledForm}
                                    style={{ width: "100%" }}
                                    options={squintsFormatted}
                                    value={squintsValueB}
                                    onChange={({ option }) => onChangeSegment(modalConfig.tag as SightConfigKeys, "squint", "b", option.value)}
                                />
                            </td>
                            <td className="ps-2">
                                {isPrism ? (
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={prismValueB}
                                        onChange={({ target }) => onChangeSegment(modalConfig.tag as SightConfigKeys, "prismaticDiopters", "b", target.value)}
                                        onKeyDown={(event) => (event.key === "." || event.key === "-" || event.key === ",") && event.preventDefault()}
                                    />
                                ) : (
                                    <Select
                                        disabled={isDisabledForm}
                                        options={degreeFormatted}
                                        style={{ width: "100%" }}
                                        value={degreeValueB}
                                        onChange={({ option }) => onChangeSegment(modalConfig.tag as SightConfigKeys, "degree", "b", option.value)}
                                    />
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="align-self-end mt-3">
                    <Button
                        className="align-self-end me-3"
                        onClick={onClearFields}
                    >
                        Limpiar
                    </Button>
                    <Button
                        variant="primary"
                        className="align-self-end"
                        onClick={onSaveResult}
                    >
                        Aceptar
                    </Button>
                </div>
            </Modal>
        );
    };

    const getSquintValue = (segment: SightConfigKeys) => {
        const squintsLabelA = squints.find(item => item.id === result?.[segment]?.squint?.a);
        const squintsLabelB = squints.find(item => item.id === result?.[segment]?.squint?.b);

        const prismValueA = !isNaN(Number(result?.[segment]?.prismaticDiopters?.a ?? undefined))
            ? Number(result?.[segment]?.prismaticDiopters?.a) : undefined;
        const prismValueB = !isNaN(Number(result?.[segment]?.prismaticDiopters?.b ?? undefined))
            ? Number(result?.[segment]?.prismaticDiopters?.b) : undefined;

        const degreeValueA = degrees.find(item => item.id === result?.[segment]?.degree?.a);
        const degreeValueB = degrees.find(item => item.id === result?.[segment]?.degree?.b);

        return {
            squints: {
                labelA: squintsLabelA?.abbreviation,
                labelB: squintsLabelB?.abbreviation
            },
            values: {
                valueA: prismValueA,
                valueB: prismValueB,
                degreeValueA: degreeValueA?.value,
                degreeValueB: degreeValueB?.value,
            }
        };
    };

    const renderTable = () => {
        const dsup = getSquintValue("dsup");
        const sup = getSquintValue("sup");
        const lsup = getSquintValue("lsup");
        const dv = getSquintValue("dv");
        const ppm = getSquintValue("ppm");
        const lv = getSquintValue("lv");
        const dinf = getSquintValue("dinf");
        const inf = getSquintValue("inf");
        const linf = getSquintValue("linf");

        return (
            <table className="table m-0">
                <tbody>
                    <tr>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("dsup")}>
                            <div className="d-flex flex-column align-items-center nowrap">
                                <h6 className="text-secondary">DSUP</h6>
                                <h5 className="text-secondary fw-bold">
                                    {dsup.squints.labelA} {isPrism ?
                                        dsup.values.valueA !== undefined ? dsup.values.valueA + "Δ" : ""
                                        : dsup.values.degreeValueA ? dsup.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {dsup.squints.labelB} {isPrism ?
                                        dsup.values.valueB !== undefined ? dsup.values.valueB + "Δ" : ""
                                        : dsup.values.degreeValueB ? dsup.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("sup")}>
                            <div className="d-flex flex-column align-items-center nowrap">
                                <h6 className="text-secondary">SUP</h6>
                                <h5 className="text-secondary fw-bold">
                                    {sup.squints.labelA} {isPrism ?
                                        sup.values.valueA !== undefined ? sup.values.valueA + "Δ" : ""
                                        : sup.values.degreeValueA ? sup.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {sup.squints.labelB} {isPrism ?
                                        sup.values.valueB !== undefined ? sup.values.valueB + "Δ" : ""
                                        : sup.values.degreeValueB ? sup.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("lsup")}>
                            <div className="d-flex flex-column align-items-center nowrap">
                                <h6 className="text-secondary">LSUP</h6>
                                <h5 className="text-secondary fw-bold">
                                    {lsup.squints.labelA} {isPrism ?
                                        lsup.values.valueA !== undefined ? lsup.values.valueA + "Δ" : ""
                                        : lsup.values.degreeValueA ? lsup.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {lsup.squints.labelB} {isPrism ?
                                        lsup.values.valueB !== undefined ? lsup.values.valueB + "Δ" : ""
                                        : lsup.values.degreeValueB ? lsup.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("dv")}>
                            <div className="d-flex flex-column align-items-center">
                                <h6 className="text-secondary">DV</h6>
                                <h5 className="text-secondary fw-bold">
                                    {dv.squints.labelA} {isPrism ?
                                        dv.values.valueA !== undefined ? dv.values.valueA + "Δ" : ""
                                        : dv.values.degreeValueA ? dv.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {dv.squints.labelB} {isPrism ?
                                        dv.values.valueB !== undefined ? dv.values.valueB + "Δ" : ""
                                        : dv.values.degreeValueB ? dv.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("ppm")}>
                            <div className="d-flex flex-column align-items-center">
                                <h6 className="text-secondary">PPM</h6>
                                <h5 className="text-secondary fw-bold">
                                    {ppm.squints.labelA} {isPrism ?
                                        ppm.values.valueA !== undefined ? ppm.values.valueA + "Δ" : ""
                                        : ppm.values.degreeValueA ? ppm.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {ppm.squints.labelB} {isPrism ?
                                        ppm.values.valueB !== undefined ? ppm.values.valueB + "Δ" : ""
                                        : ppm.values.degreeValueB ? ppm.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("lv")}>
                            <div className="d-flex flex-column align-items-center">
                                <h6 className="text-secondary">LV</h6>
                                <h5 className="text-secondary fw-bold">
                                    {lv.squints.labelA} {isPrism ?
                                        lv.values.valueA !== undefined ? lv.values.valueA + "Δ" : ""
                                        : lv.values.degreeValueA ? lv.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {lv.squints.labelB} {isPrism ?
                                        lv.values.valueB !== undefined ? lv.values.valueB + "Δ" : ""
                                        : lv.values.degreeValueB ? lv.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("dinf")}>
                            <div className="d-flex flex-column align-items-center">
                                <h6 className="text-secondary">DINF</h6>
                                <h5 className="text-secondary fw-bold">
                                    {dinf.squints.labelA} {isPrism ?
                                        dinf.values.valueA !== undefined ? dinf.values.valueA + "Δ" : ""
                                        : dinf.values.degreeValueA ? dinf.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {dinf.squints.labelB} {isPrism ?
                                        dinf.values.valueB !== undefined ? dinf.values.valueB + "Δ" : ""
                                        : dinf.values.degreeValueB ? dinf.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("inf")}>
                            <div className="d-flex flex-column align-items-center">
                                <h6 className="text-secondary">INF</h6>
                                <h5 className="text-secondary fw-bold">
                                    {inf.squints.labelA} {isPrism ?
                                        inf.values.valueA !== undefined ? inf.values.valueA + "Δ" : ""
                                        : inf.values.degreeValueA ? inf.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {inf.squints.labelB} {isPrism ?
                                        inf.values.valueB !== undefined ? inf.values.valueB + "Δ" : ""
                                        : inf.values.degreeValueB ? inf.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                        <td style={{ width: "33%", height: "80px", pointerEvents: readOnly ? "none" : "inherit" }} onClick={() => !isDisabledForm && onOpenConfigModal("linf")}>
                            <div className="d-flex flex-column align-items-center">
                                <h6 className="text-secondary">LINF</h6>
                                <h5 className="text-secondary fw-bold">
                                    {linf.squints.labelA} {isPrism ?
                                        linf.values.valueA !== undefined ? linf.values.valueA + "Δ" : ""
                                        : linf.values.degreeValueA ? linf.values.degreeValueA : ""}
                                </h5>
                                <h5 className="text-secondary fw-bold">
                                    {linf.squints.labelB} {isPrism ?
                                        linf.values.valueB !== undefined ? linf.values.valueB + "Δ" : ""
                                        : linf.values.degreeValueB ? linf.values.degreeValueB : ""}
                                </h5>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const render = () => {
        return (
            <div className="w-100">
                {type === "vl" && (
                    <h5 className="text-center text-secondary">Visión Lejana</h5>
                )}
                {type === "vp" && (
                    <h5 className="text-center text-secondary">Visión Cercana</h5>
                )}
                <div className={`${readOnly ? "sight-table-readonly" : "sight-table"}`}>
                    {renderTable()}
                </div>
                <textarea
                    disabled={isDisabledForm}
                    rows={3}
                    className="form-control no-resize mt-3"
                    placeholder="Observaciones..."
                    onChange={({ target }) => onChangeObservation(target.value)}
                    value={result.observations}
                    readOnly={readOnly}
                />
                {renderModal()}
            </div>
        );
    };

    return render();
}
