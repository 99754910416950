import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import "./AudioRecorder.scss";
import { Button } from "../Button";
import { IconDeviceFloppy, IconMicrophone, IconPlayerStopFilled, IconTrash } from "@tabler/icons-react";
import del from "../../assets/delete.svg";
import pause from "../../assets/pause.svg";
import AudioPlayerComponent from "./AudioPlayer/AudioPlayer";
import { IconAttach } from "@/assets/icons";

interface AudioRecorderProps {
    onRecordingComplete?: (blob: Blob) => void;
    downloadOnSavePress?: boolean;
    downloadFileExtension?: string;
    onSubmitAudio: (base64: string, ext?: string) => void;
    urlAudio: string;
    setIsAudioSaved: (saved: boolean) => void;
    isAudioSaved: boolean;
}

const AudioRecorderComponent: FunctionComponent<AudioRecorderProps> = ({ onSubmitAudio, urlAudio, isAudioSaved, setIsAudioSaved }) => {
    const fileInputRef = useRef(null);

    const [isRecording, setIsRecording] = useState(false);
    const [base64Data, setBase64Data] = useState<string>("");
    const [saveRecording, setSaveRecording] = useState<boolean>(false);
    const [showPlayer, setShowPlayer] = useState<{ show: boolean, audio: string, ext?: string; }>({
        show: false,
        audio: ""
    });

    useEffect(() => {
        if (urlAudio) {
            setShowPlayer(
                {
                    show: true,
                    audio: urlAudio
                }
            );
        }
    }, [urlAudio]);


    useEffect(() => {
        const imgSave = document.querySelector("[data-testid='ar_mic']") as HTMLImageElement;
        if (imgSave) {
            imgSave.className = "hidden ";
        }
    }, [showPlayer.show]);

    useEffect(() => {
        const imgDiscard = document.querySelector("[data-testid='ar_cancel']") as HTMLImageElement;
        if (imgDiscard) {
            imgDiscard.src = del;
            imgDiscard.classList.add("blueFilter");
        }

    }, [isRecording, showPlayer.show]);

    useEffect(() => {
        const imgDiscard = document.querySelector("[data-testid='ar_pause']") as HTMLImageElement;
        if (imgDiscard) {
            imgDiscard.src = pause;
            imgDiscard.classList.add("blueFilter");
        }

    }, [isRecording, showPlayer.show]);


    const recorderControls = useAudioRecorder();

    const addAudioElement = (blob: Blob) => {
        setIsRecording(false);
        if (saveRecording) {
            const url = URL.createObjectURL(blob);
            convertAudioToBase64(blob);
            setShowPlayer({ audio: url, show: true });
        }
    };


    const convertAudioToBase64 = (blob: Blob) => {
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result as ArrayBuffer;
            const base64 = arrayBufferToBase64(arrayBuffer);
            setBase64Data(base64);
        };
        reader.readAsArrayBuffer(blob);
    };

    const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
        const binary = Array.from(new Uint8Array(buffer)).map(byte => String.fromCharCode(byte)).join("");
        return btoa(binary);
    };

    const setting = () => {
        setBase64Data("");
        setShowPlayer({ audio: "", show: false });
        setSaveRecording(false);

    };


    const handleAudioUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;

        if (fileInput?.files?.length) {
            convertAudioToBase64(fileInput?.files[0]);
            setShowPlayer({ audio: URL.createObjectURL(fileInput?.files[0]), show: true, ext: fileInput?.files[0].type });
        }
    };


    function isURL(str: string) {
        const regex = /^(ftp|http|https):\/\/[^ "]+$/;
        return regex.test(str);
    }



    return (
        showPlayer.show ?
            <div className={"d-flex  w-100 align-items-center justify-content-between"}>
                <AudioPlayerComponent audio={showPlayer.audio} />
                <div className="d-flex">
                    <button className=" buttonRecords mx-2" onClick={() => {
                        setting();
                        setIsAudioSaved(false);
                    }}> <IconTrash className="blueFilter" />
                    </button>
                    {(!isAudioSaved && !isURL(showPlayer.audio)) &&
                        <button className="buttonRecords"
                            onClick={() => {
                                onSubmitAudio(base64Data);
                            }
                            }
                        > <IconDeviceFloppy className="blueFilter " /></button>
                    }

                </div>
            </div>
            :
            <div className={`d-flex g-2 w-100 align-items-center ${isRecording ? " justify-content-center" : "justify-content-between"} `}>
                <div className="d-flex">
                    <div className="d-flex align-items-center justify-content-between justify-content-center">
                        <Button className="buttonRecords" onClick={() => {
                            if (isRecording) {
                                recorderControls.stopRecording();
                                setIsRecording(false);
                                setSaveRecording(true);

                            } else {
                                recorderControls.startRecording();
                                setIsRecording(true);
                                setSaveRecording(false);
                            }
                        }}>  {isRecording ? <IconPlayerStopFilled /> : <IconMicrophone />} </Button>
                    </div>
                    <div className="recorder"
                    >
                        <AudioRecorder
                            recorderControls={recorderControls}
                            onRecordingComplete={addAudioElement}
                            audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                                sampleRate: 16000
                            }}
                        />

                    </div>
                </div>
                <div>
                    {!isRecording &&
                        <div className="cursor">
                            <label htmlFor="audioInput" className="d-flex align-items-center">
                                <IconAttach className="blueFilter" />
                                <span className="px-2 text-muted">Adjuntar audio</span>
                            </label>
                            <input
                                type="file"
                                accept="audio/*"
                                id="audioInput"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleAudioUpload}
                            />
                        </div>
                    }
                </div>
            </div>

    );
};

export default AudioRecorderComponent;