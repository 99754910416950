import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import {
    DeletePreviousSurgery,
    PreoperativeParams,
    PreoperativePayload,
    PreoperativeResponse
} from "@/models/sheets/preoperative";
import { setHeaders } from "@/utils";

export async function getPreoperativeEvaluationService(params: PreoperativeParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PreoperativeResponse>(
        "/medical/clinicHistory/preoperativeEvaluation/", axiosParams
    );
    return serviceData;
}

export async function savePreoperativeEvaluationService(payload: PreoperativePayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<PreoperativeResponse>(
        "/medical/clinicHistory/preoperativeEvaluation/", payload, axiosParams
    );
    return serviceData;
}

export async function deleteSurgeryService(payload: DeletePreviousSurgery) {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };

    const { data: serviceData } = await axiosInstance.delete<IResponse>(
        "/medical/clinicHistory/preoperativeEvaluation/", axiosParams
    );
    return serviceData;
}

export async function getDentalProthesisService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<IResponse>(
        "/medical/generals/dentalProthesis/", axiosParams
    );
    return serviceData;
}