import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { LowVisionParams, LowVisionPayload, LowVisionResponse } from "@/models/sheets/lowVision";
import { setHeaders } from "@/utils";

export async function getLowVisionService(params: LowVisionParams): Promise<LowVisionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<LowVisionResponse>(
        "/medical/clinicHistory/lowVision/", axiosParams
    );
    return serviceData;
}

export async function updateLowVisionService(payload: LowVisionPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/lowVision/", payload, axiosParams
    );
    return serviceData;
}
