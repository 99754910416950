import { axiosInstance } from "@/config/axios";
import { setHeaders } from "@/utils";
import {
    DilatationParams,
    DilatationPayload,
    DilatationResponse
} from "@/models/pre-consultation/dilatation";
import { IResponse } from "@/models";

export async function getDilatationsService(params: DilatationParams): Promise<DilatationResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DilatationResponse>(
        "/medical/clinicHistory/dilation/", axiosParams
    );
    return serviceData;
}


export async function updateDilatationService(payload: DilatationPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/dilation/", payload, axiosParams
    );
    return serviceData;
}