import { generateId } from "@/utils";
import "./Checkbox.scss";
import { ICheckbox } from "./checkbox";

export function Checkbox({
    className = "",
    label,
    labelPosition = "right",
    id = generateId(),
    withShadow,
    checked,
    ...rest
}: ICheckbox) {

    return (
        <div className={`checkbox ${className} ${(withShadow && checked) ? "with-shadow" : ""}`}>

            <label >
                {labelPosition === "left" && (
                    <span className="form-check-label me-2">{label}</span>
                )}
                <input className="form-check-input " type="checkbox" id={id} checked={checked} {...rest} />
                {labelPosition === "right" && (
                    <span className="ms-2 pointer">{label}</span>
                )}
            </label>
        </div>
    );
}