import { Button, RadioButton, SectionCard, Select } from "@/components";
import { setAttentionEnd } from "@/config/slices/canAttentionEnd";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { PreoperativeSegment } from "@/models/sheets/preoperative";
import { IconFiles } from "@tabler/icons-react";
import { useEffect } from "react";
import { getCanAttentionEnd } from "../../consultation.actions";

export default function Approval({ preoperativePayload, setPreoperativePayload, onSubmit, isDisabledForm }: PreoperativeSegment) {
    const signature = useAppSelector(state => state.auth.user_data.signature);
    const dispatch = useAppDispatch();
    const medicalFormId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);

    const riskOptions = [
        { label: "Bajo", value: 1 },
        { label: "Normal", value: 2 },
        { label: "Alto", value: 3 },
    ];

    useEffect(() => {
        async function fetchData() {
            if(!isDisabledForm){
                const data = await dispatch(getCanAttentionEnd(medicalFormId));
                dispatch(setAttentionEnd(data.results ?? false)); 
            }
        }
        fetchData();
    }, [dispatch, medicalFormId, isDisabledForm]);
    const onChangeAnestheticRisk = (value: 1 | 0) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                suitable: value
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeJustification = (value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                justification: value
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeRiskValue = (value: number) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                anestheticRisk: value
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const onChangeSigned = (value?: boolean) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            suitableForSurgery: {
                ...preoperativePayload.suitableForSurgery,
                signed: !value
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const render = () => {
        const riskValue = riskOptions.find(item => item.value === preoperativePayload.suitableForSurgery?.anestheticRisk);

        return (
            <SectionCard className="align-items-start align-self-center">
                <h5 className="fw-bold text-secondary">
                    Apto para cirugía
                </h5>
                <hr className="text-primary mt-2" />
                <div className="select-option-item">
                    <span>El paciente se encuentra apto para cirugía</span>
                    <div className="flex-grow-1"></div>
                    <RadioButton
                        disabled={isDisabledForm}
                        className="me-5"
                        label="Sí"
                        name="option-1"
                        id="yes"
                        checked={preoperativePayload.suitableForSurgery?.suitable === 1}
                        onChange={() => onChangeAnestheticRisk(1)}
                    />
                    <RadioButton
                        disabled={isDisabledForm}
                        label="No"
                        name="option-1"
                        id="no"
                        checked={preoperativePayload.suitableForSurgery?.suitable === 0}
                        onChange={() => onChangeAnestheticRisk(0)}
                    />
                </div>
                <textarea
                    className="form-control mt-3 no-resize"
                    disabled={preoperativePayload.suitableForSurgery?.suitable === null || isDisabledForm}
                    value={preoperativePayload.suitableForSurgery?.justification}
                    onChange={({ target }) => onChangeJustification(target.value)}
                />
                <p className="mt-3">Clasificación riesgo anestésico</p>
                <hr className="w-100 mt-0" />
                <Select
                    value={riskValue}
                    options={riskOptions}
                    onChange={({ option }) => onChangeRiskValue(option.value)}
                    disabled={preoperativePayload.suitableForSurgery?.suitable === null || isDisabledForm}
                />
                <Button
                    className="mt-3"
                    variant="text"
                    startIcon={<IconFiles />}
                    onClick={() => onChangeSigned(preoperativePayload.suitableForSurgery?.signed)}
                    disabled={preoperativePayload.suitableForSurgery?.suitable === null || isDisabledForm}
                >
                    {preoperativePayload.suitableForSurgery?.signed ? "Quitar firma" : "Agregar firma"}
                </Button>
                {preoperativePayload.suitableForSurgery?.signed === true &&
                    <img src={signature} alt="doc signature" style={{ maxWidth: 150 }} />}
            </SectionCard>
        );
    };

    return render();
}