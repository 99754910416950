import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks";

import {
    getFarVisionList,
    getLensAdd,
    getLensType,
    getNearVisionList
} from "@/services";

import { Button, Modal, Select } from "@/components";

import { Vision } from "@/models/select/vision";
import { LentsInUse, LentsOdClass, PurpleOd } from "@/models/sheets/lowVision";
import { useDebouncedCallback } from "use-debounce";
import { IconDownload } from "@tabler/icons-react";
import { getFinalRefraction } from "../oftalmology.actions";
import { fireCautionAlert } from "@/utils";

interface IRefractionModal {
    isOpen: boolean;
    isDisabledForm?: boolean;
    finalRefraction?: Partial<LentsInUse>;
    changeToInput?: boolean;
    onClose: () => void;
    onChange: (payload: Partial<LentsInUse>) => void;
}

export default function RefractionModal(props: IRefractionModal) {
    const dispatch = useAppDispatch();
    const patientId = useAppSelector(state => state.patientAttention.appointmentSelected.patientId);

    const [payload, setPayload] = useState<Partial<LentsInUse>>();

    const [farVision, setFarVision] = useState<Vision[]>([]);
    const [nearVision, setNearVision] = useState<Vision[]>([]);
    const [lensAdd, setLensAdd] = useState<Vision[]>([]);
    const [lensType, setLensType] = useState<Vision[]>([]);

    useEffect(() => {
        if (props.isOpen && props.finalRefraction) {
            setPayload(props.finalRefraction);
        }
    }, [props.isOpen, props.finalRefraction]);

    useEffect(() => {
        async function fetch() {
            if (props.isOpen) {
                const farVisionResponse = await getFarVisionList();
                const nearVisionResponse = await getNearVisionList();
                const lensAddResponse = await getLensAdd();
                const lensTypeResponse = await getLensType();

                setFarVision(farVisionResponse.results);
                setNearVision(nearVisionResponse.results);
                setLensAdd(lensAddResponse.results);
                setLensType(lensTypeResponse.results);
            }
        }
        fetch();
    }, [props.isOpen]);

    const onGetFinalRefraction = async () => {
        fireCautionAlert("Se traerá la información de la refracción desde la optometría.", undefined, async () => {
            const result = await dispatch(getFinalRefraction({ patientId: patientId }));
            if (result) {
                setPayload(result);
                props.onChange(result);
            }
        });
    };

    const onChangeDebounced = useDebouncedCallback(options => {
        props.onChange(options);
    }, 1000);

    const handleChangeInputNumber = (
        event: ChangeEvent<HTMLInputElement>,
        lentsType: "lentsOd" | "lentsOi",
        lentsItem: keyof LentsOdClass,
    ) => {
        const regex = /^(^(\+|-)[0-9]{1,2}\.[0-9]{2}|)$/;

        if (event.target.value.length > 0 && !(regex).test(event.target.value)) {
            event.target.style.borderColor = "red";
            event.preventDefault();
        } else {
            event.target.style.borderColor = "#ced4da";
            const options = {
                ...payload,
                [lentsType]: {
                    ...payload?.[lentsType],
                    [lentsItem]: event.target.value
                }
            };
            setPayload(options);
            props.onChange(options);
        }
    };

    const handleChangeLensesInputs = (
        lentsType: "lentsOd" | "lentsOi",
        lentsItem: keyof LentsOdClass,
        value: string
    ) => {
        const options = {
            ...payload,
            [lentsType]: {
                ...payload?.[lentsType],
                [lentsItem]: value
            }
        };
        setPayload(options);
        onChangeDebounced(options);
    };

    const handleChangeEyeVision = (
        eye: "od" | "oi",
        vision: keyof PurpleOd,
        value: string
    ) => {
        const options = {
            ...payload,
            [eye]: {
                ...payload?.[eye],
                [vision]: value
            }
        };
        setPayload(options);
        props.onChange(options);
    };

    const handleObservation = (
        value: string
    ) => {
        const options = {
            ...payload,
            observations: value
        };
        setPayload(options);
        onChangeDebounced(options);
    };

    const handleOnCloseModal = () => {
        props.onClose();
    };

    const farVisionFormatted = farVision?.map(item => ({ label: item.value, value: item.id }));
    const nearVisionFormatted = nearVision?.map(item => ({ label: item.value, value: item.id }));

    const odFarValueInput = payload?.od?.far;
    const odFarValue = farVisionFormatted.find(fv => fv.value === odFarValueInput);

    const odNearValueInput = payload?.od?.near;
    const odNearValue = nearVisionFormatted.find(fv => fv.value === odNearValueInput);

    const oiFarValueInput = payload?.oi?.far;
    const oiFarValue = farVisionFormatted.find(fv => fv.value === oiFarValueInput);

    const oiNearValueInput = payload?.oi?.near;
    const oiNearValue = nearVisionFormatted.find(fv => fv.value === oiNearValueInput);

    const lensAddFormatted = lensAdd?.map(item => ({ label: item.value, value: item.id }));
    const lensTypeFormatted = lensType?.map(item => ({ label: item.value, value: item.id }));

    const odAddValueInput = payload?.lentsOd?.add;
    const odAddValue = lensAddFormatted.find(laf => laf.value === odAddValueInput);

    const odLensTypeValueInput = payload?.lentsOd?.len;
    const odLensTypeValue = lensTypeFormatted.find(laf => laf.value === odLensTypeValueInput);

    const oiAddValueInput = payload?.lentsOi?.add;
    const oiAddValue = lensAddFormatted.find(laf => laf.value === oiAddValueInput);

    const oiLensTypeValueInput = payload?.lentsOi?.len;
    const oiLensTypeValue = lensTypeFormatted.find(laf => laf.value === oiLensTypeValueInput);

    const renderComponent = () => {
        return (
            <Modal width={1000} positionModal="center" isOpen={props.isOpen} onClose={handleOnCloseModal}>
                <h3 className="text-secondary fw-bold">Refracción final</h3>
                <hr className="text-primary mt-2" />
                <Button
                    variant="primary"
                    className="align-self-end"
                    endIcon={<IconDownload />}
                    onClick={() => void onGetFinalRefraction()}
                >
                    Cargar información previa
                </Button>
                <div className="row gx-1">
                    <div className="col">
                        <h4 className="text-secondary fw-bold">Lente</h4>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td className="p-2"></td>
                                    <td align="center" className="text-secondary">Esfera</td>
                                    <td align="center" className="text-secondary">Cilindro</td>
                                    <td align="center" className="text-secondary">Eje°</td>
                                    <td align="center" className="text-secondary">ADD</td>
                                    <td align="center" className="text-secondary">Tipo de lente</td>
                                    <td align="center" className="text-secondary">Marca</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="ps-2 py-1 bg-od nowrap text-secondary" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                                    <td className="p-2 bg-od" width={120}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={(event) => handleChangeInputNumber(event, "lentsOd", "sphere")}
                                            defaultValue={payload?.lentsOd?.sphere}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" width={120}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={(event) => handleChangeInputNumber(event, "lentsOd", "cylinder")}
                                            defaultValue={payload?.lentsOd?.cylinder}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" width={120}>
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={({ target }) => {
                                                handleChangeLensesInputs("lentsOd", "axis", target.value);
                                            }}
                                            value={payload?.lentsOd?.axis}
                                            onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" width={150}>
                                        <Select
                                            options={lensAddFormatted}
                                            changeToInput={props.changeToInput}
                                            style={{ width: "100%" }}
                                            disabled={props.isDisabledForm}
                                            inputValue={odAddValue?.label}
                                            onChange={({ option }) => handleChangeLensesInputs(
                                                "lentsOd",
                                                "add",
                                                option.value
                                            )}
                                            value={odAddValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" width={150}>
                                        <Select
                                            options={lensTypeFormatted}
                                            changeToInput={props.changeToInput}
                                            style={{ width: "100%" }}
                                            disabled={props.isDisabledForm}
                                            inputValue={odLensTypeValue?.label}
                                            onChange={({ option }) => handleChangeLensesInputs(
                                                "lentsOd",
                                                "len",
                                                option.value
                                            )}
                                            value={odLensTypeValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={({ target }) => {
                                                handleChangeLensesInputs("lentsOd", "brand", target.value);
                                            }}
                                            value={payload?.lentsOd?.brand}
                                        />
                                    </td>

                                </tr>
                                <tr>
                                    <td className="ps-2 py-1 bg-oi nowrap text-secondary" style={{ borderBottomLeftRadius: "0.5rem" }} width={30}>OI</td>
                                    <td className="p-2 bg-oi">
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={(event) => handleChangeInputNumber(event, "lentsOi", "sphere")}
                                            defaultValue={payload?.lentsOi?.sphere}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={(event) => handleChangeInputNumber(event, "lentsOi", "cylinder")}
                                            defaultValue={payload?.lentsOi?.cylinder}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={({ target }) => {
                                                handleChangeLensesInputs("lentsOi", "axis", target.value);
                                            }}
                                            value={payload?.lentsOi?.axis}
                                            onKeyDown={(event) => event.key === "." && event.preventDefault()}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <Select
                                            options={lensAddFormatted}
                                            changeToInput={props.changeToInput}
                                            style={{ width: "100%" }}
                                            disabled={props.isDisabledForm}
                                            inputValue={oiAddValue?.label}
                                            onChange={({ option }) => handleChangeLensesInputs(
                                                "lentsOi",
                                                "add",
                                                option.value
                                            )}
                                            value={oiAddValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi">
                                        <Select
                                            options={lensTypeFormatted}
                                            changeToInput={props.changeToInput}
                                            disabled={props.isDisabledForm}
                                            inputValue={oiLensTypeValue?.label}
                                            style={{ width: "100%" }}
                                            onChange={({ option }) => handleChangeLensesInputs(
                                                "lentsOi",
                                                "len",
                                                option.value
                                            )}
                                            value={oiLensTypeValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={props.isDisabledForm}
                                            onChange={({ target }) => {
                                                handleChangeLensesInputs("lentsOi", "brand", target.value);
                                            }}
                                            value={payload?.lentsOi?.brand}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-12">
                        <h4 className="text-secondary fw-bold mt-4">AV</h4>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td className="p-2"></td>
                                    <td align="center" className="text-secondary">Visión Lejana</td>
                                    <td align="center" className="text-secondary">Visión Cercana</td>
                                    <td align="center" className="text-secondary"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width={25} className="ps-2 py-1 bg-od text-secondary" style={{ borderTopLeftRadius: "0.5rem" }}>OD</td>
                                    <td width={100} className="p-2 bg-od">
                                        <Select
                                            options={farVisionFormatted}
                                            changeToInput={props.changeToInput}
                                            style={{ width: "100%" }}
                                            disabled={props.isDisabledForm}
                                            inputValue={odFarValue?.label}
                                            onChange={({ option }) => handleChangeEyeVision(
                                                "od",
                                                "far",
                                                option.value
                                            )}
                                            value={odFarValue}
                                        />
                                    </td>
                                    <td width={100} className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }}>
                                        <Select
                                            options={nearVisionFormatted}
                                            changeToInput={props.changeToInput}
                                            disabled={props.isDisabledForm}
                                            inputValue={odNearValue?.label}
                                            onChange={({ option }) => handleChangeEyeVision(
                                                "od",
                                                "near",
                                                option.value
                                            )}
                                            value={odNearValue}
                                        />
                                    </td>
                                    <td className="px-2" rowSpan={2}>
                                        <textarea
                                            className="form-control no-resize"
                                            style={{ height: 80 }}
                                            placeholder="Observaciones..."
                                            disabled={props.isDisabledForm}
                                            onChange={({ target }) => {
                                                handleObservation(target.value);
                                            }}
                                            value={payload?.observations}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ps-2 bg-oi py-1 text-secondary" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                                    <td className="p-2 bg-oi">
                                        <Select
                                            options={farVisionFormatted}
                                            changeToInput={props.changeToInput}
                                            disabled={props.isDisabledForm}
                                            inputValue={oiFarValue?.label}
                                            onChange={({ option }) => handleChangeEyeVision(
                                                "oi",
                                                "far",
                                                option.value
                                            )}
                                            value={oiFarValue}
                                        />
                                    </td>
                                    <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }}>
                                        <Select
                                            options={nearVisionFormatted}
                                            changeToInput={props.changeToInput}
                                            disabled={props.isDisabledForm}
                                            inputValue={oiFarValue?.label}
                                            onChange={({ option }) => handleChangeEyeVision(
                                                "oi",
                                                "near",
                                                option.value
                                            )}
                                            value={oiNearValue}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        );
    };

    return renderComponent();
}
