import { axiosInstance } from "@/config/axios";
import { Account, NewTokenParams, NewTokenResponse } from "@/models/account";
import { IResponse } from "@/models/general";
import { setHeaders } from "@/utils/utils";

interface AccountResponse extends IResponse {
    accounts: Account[];
}

export async function getAccountsByUser(userId: string): Promise<AccountResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const { data: serviceData } = await axiosInstance.get(
        `/composite/selectaccount/${userId}`, axiosParams
    );

    return serviceData as AccountResponse;
} 

export async function getNewTokenService(payload: NewTokenParams): Promise<NewTokenResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<NewTokenResponse>(
        "/security/reassignToken/", payload, axiosParams
    );
    return serviceData;
}