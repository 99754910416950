import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthInitialState, AuthUserData } from "../../models/auth";

const CURRENT_AUTH = {
    id: null,
    first_name: "",
    second_name: "",
    first_surname: "",
    second_surname: "",
    doc_type: "",
    doc_num: "",
    doc_city: "",
    email: "",
    cellphone: "",
    image: "",
    active: false,
    have_password: false,
    signature: "",
    medicalRecord: null,
};

const initialState: AuthInitialState = {
    user_data: JSON.parse(localStorage.getItem("user_data") as string)
        ? JSON.parse(localStorage.getItem("user_data") as string) as AuthUserData
        : CURRENT_AUTH,
    access_token: localStorage.getItem("x_token"),
    is_auth: localStorage.getItem("x_token") ? true : false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        createAuth: (state, action: PayloadAction<AuthInitialState>) => {
            state.user_data = action.payload.user_data;
            state.access_token = action.payload.access_token;
            state.is_auth = action.payload.is_auth;
        },
        clearAuth: (state) => {
            state.user_data = CURRENT_AUTH;
            state.access_token = null;
            state.is_auth = false;
        }
    }
});

export const { createAuth, clearAuth } = authSlice.actions;