import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { useAppDispatch } from "@/hooks";
import { getAHAnesthesiaNotesDetail } from "../../../../history.actions";

import { SectionCard } from "@/components";

import { AnesthesiaNotesDetail as IAnesthesiaNotesDetail } from "@/models/historyDetails/anesthesiaNotes";
import RecordChartComponent from "@/features/Workspace/PatientAttention/Surgery/components/RecordChart";
import { RecordChart } from "@/models/surgery/patientAttention/patientAttention";


export default function AnesthesiaNotesDetail({ appId }: { appId: number; }) {
    const dispatch = useAppDispatch();

    const [anesthesiaDetail, setAnesthesiaDetail] = useState<IAnesthesiaNotesDetail | null>(null);
    const [dataRecord, setDataRecord] = useState<RecordChart>(
        {
            reload: 0,
            appId: appId,
            endOfSurgery: "",
            startOfAnesthesia: "",
            startOfSurgery: "",
            vitalSigns: {
                fc: [],
                o2: [],
                pad: [],
                pas: [],
            },
        }
    );

    useEffect(() => {
        void (async function () {
            const response = await dispatch(getAHAnesthesiaNotesDetail({ prefix: "anesthesiaNotes", appId: appId }));

            if (response) {
                setAnesthesiaDetail(response);
            }
        })();
    }, [appId, dispatch]);

    const formatTimeToAmPm = (time: string): string => {
        if (time) {
            return dayjs("1/1/1 " + time).format("hh:mm a");
        } else return "-";
    };

    function calcTime(time1: string, time2: string) {
        const difSeconds = Date.parse(time1) - Date.parse(time2);

        const hours = Math.floor(difSeconds / (3600 * 1000));
        const min = Math.round((difSeconds % (3600 * 1000)) / (60 * 1000));

        const formato = `${hours ? hours + "h " : ""} ${min}m`;
        return formato;
    }

    const renderAnesthesiaInfo = () => {
        return (
            <SectionCard className='m-4'>
                <h5 className="fw-bold text-secondary">Información de anestesia</h5>
                <div className="d-flex align-items-center text-muted">
                    <div className='me-4'>
                        <span className='me-2'>Anestesiólogo:</span>
                        <span className="fw-bold">{anesthesiaDetail?.anesthesiaNotes.anesthesiologistName ?? "-"}</span>
                    </div>
                    <div className='me-4'>
                        <span className='me-2'>Tipo de anestesia:</span>
                        <span className="fw-bold">{anesthesiaDetail?.anesthesiaNotes.aneName ?? "-"}</span>
                    </div>
                    <div className='me-4'>
                        <span className='me-2'>Oxígeno:</span>
                        <span className="fw-bold">{anesthesiaDetail?.anesthesiaNotes.oxiName ?? "-"}</span>
                    </div>
                </div>
            </SectionCard>
        );
    };

    const renderContent = () => {
        return (
            <div className="card-columns px-4" style={{ flex: 0 }}>
                <div className="item">
                    <SectionCard>
                        <h5 className="fw-bold text-secondary">Drogas para la inducción</h5>
                        <hr className="lineCards" />
                        <div className="fw-bold text-muted">Medicamentos registrados</div>
                        {anesthesiaDetail?.anesthesiaNotes.drugs_for_induction && anesthesiaDetail.anesthesiaNotes.drugs_for_induction?.length > 0
                            ? anesthesiaDetail.anesthesiaNotes.drugs_for_induction?.map(drug => (
                                <div key={drug} className='mt-2' style={{ textTransform: "capitalize" }}>{drug}</div>
                            ))
                            : (
                                <div className='mt-2'>No se registraron medicamentos</div>
                            )}
                    </SectionCard>
                </div>
                <div className="item">
                    <SectionCard>
                        <h5 className="fw-bold text-secondary">Líquidos y drogas - anestesia</h5>
                        <hr className="lineCards" />
                        <div className="fw-bold text-muted">Medicamentos registrados</div>
                        {anesthesiaDetail?.anesthesiaNotes.liquids_and_drugs && anesthesiaDetail.anesthesiaNotes.liquids_and_drugs?.length > 0
                            ? anesthesiaDetail.anesthesiaNotes.liquids_and_drugs?.map(drug => (
                                <div key={drug} className='mt-2' style={{ textTransform: "capitalize" }}>{drug}</div>
                            ))
                            : (
                                <div className='mt-2'>No se registraron medicamentos</div>
                            )}
                    </SectionCard>
                </div>
                <div className="item">
                    <SectionCard>
                        <h5 className="fw-bold text-secondary">Notas generales de anestesia</h5>
                        <hr className="lineCards" />
                        <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            defaultValue={anesthesiaDetail?.anesthesiaNotes.ansGeneralAnesthesiaNotes}
                            disabled
                        />
                    </SectionCard>
                </div>
                <div className="item">
                    <SectionCard>
                        <h5 className="fw-bold text-secondary">Notas de egreso de anestesia</h5>
                        <hr className="lineCards" />
                        <textarea
                            className="form-control"
                            style={{ minHeight: 80 }}
                            defaultValue={anesthesiaDetail?.anesthesiaNotes.ansDischargeAnesthesiaNotes}
                            disabled
                        />
                    </SectionCard>
                </div>
            </div>
        );
    };

    const renderComponent = () => {
        return (
            <>
                <div className="bodyHeight surgeryBody h-100">
                    {renderAnesthesiaInfo()}
                    {renderContent()}
                    <SectionCard className='mx-4 mb-4'>
                        <h5 className="fw-bold text-secondary">Notas de egreso de anestesia</h5>
                        <hr className="lineCards" />

                        <div className="row align-items-center">
                            <div className="col-lg-10">
                                <RecordChartComponent
                                    appId={appId}
                                    setDataRecord={setDataRecord}
                                    heightProp={350}
                                    widthProp="100%"
                                />
                            </div>

                            <div className="col-lg-2">
                                <div className="pb-2">
                                    <b className="text-secondary">Inicio anestesia:</b> <span className="text-muted"> {formatTimeToAmPm(dataRecord?.startOfAnesthesia?.split(" ")[1])}</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">Inicio cirugía:</b> <span className="text-muted"> {formatTimeToAmPm(dataRecord?.startOfSurgery?.split(" ")[1])}</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">Fin cirugía:</b> <span className="text-muted"> {formatTimeToAmPm(dataRecord?.endOfSurgery?.split(" ")[1])}</span>
                                </div >
                                <div className="pb-2">
                                    <b className="text-secondary">Duración cirugía:</b> <span className="text-muted"> {
                                        dataRecord.endOfSurgery ?
                                            calcTime(dataRecord?.endOfSurgery, dataRecord?.startOfSurgery) : "-"}</span>
                                </div>
                            </div>
                        </div>
                    </SectionCard>
                </div>
            </>
        );
    };

    return renderComponent();
}