import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { DiagnosticReadingParams, DiagnosticReadingUpdatePayload } from "@/models/diagnosticAidReadings/diagnosticAidReadings";
import { getStatusServices, getDiagnosticReadingService, updateDiagnosticReadingService } from "@/services/diagnosticAidReadings/diagnosticAidReadings";
import { fireSuccessAlert, fireWarningAlert } from "@/utils";

export function getDiagnosticReading(params: DiagnosticReadingParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDiagnosticReadingService(params);
            return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateDiagnosticReading(payload: DiagnosticReadingUpdatePayload, doAfterSuccess?: () => void, alert?: boolean) {
    return async function (dispatch: AppDispatch) {
        dispatch(startLoading());
        const response = await updateDiagnosticReadingService(payload);
        if (response.success) {
            doAfterSuccess &&
                doAfterSuccess();
            alert && fireSuccessAlert(response.message, response.title);

        } else{
            alert && fireWarningAlert(response.message, response.title);
        }
        dispatch(finishLoading());
        return response.success;
    };
}

export function getStatus(params: {eaccount: number, view: string }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getStatusServices(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}


