import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { SurgicalNotesVariables, getDoctorInformation, getSurgeonNotes, updateSurgeonNotes } from "./surgeon.action";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { getSurgeons } from "../../../patientAttentionSurgery.action";

import { SectionCard, Select, TextField } from "@/components";
import TextConfigModal from "@/features/Workspace/PatientAttention/Consultation/AttentionOrigin/TextConfig/TextConfigNoIns";
import DiagnosticImpression from "@/features/Workspace/PatientAttention/Consultation/DiagnosticImpression/DiagnosticImpression";
import TemplateBodyDoctor from "../TemplateBodyDoctors";

import { DoctorsInformation, SurgeonNotes, SurgicalNotesVariablesList, Variable, VariableList } from "@/models/surgery/patientAttention/surgeon";

const Surgeon = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const appId = location.state.data.appId;
    const sunId = location.state.startAttentionInfo.sunId || location.state.megaId;
    const userId = useAppSelector(state => state.auth.user_data.id);
    const eaccount = useAppSelector(state => state.workspace.id);
    const workspace = useAppSelector(state => state.workspace);
    const userRol = workspace.profile?.prefijo.toLowerCase();
    const isDisabledFields = userRol !== "medical";
    const [surgeonNotes, setSurgeonNotes] = useState<SurgeonNotes>({
        appId: null,
        sunDescription: "",
        sunId: null,
        sunRotatorId: null,
        sunSupportNurseId: null,
        sunSupportSurgeonId: null,
        variables: [],
    });
    const [doctorsInformation, setDoctorsInformation] = useState<DoctorsInformation>();
    const [surgeonModal, setSurgeonModal] = useState<{ isOpen: boolean; current: string; }>({
        isOpen: false,
        current: "surgicalDescription"
    });
    const [nurseList, setNurseList] = useState<{ label: string, value: number; }[]>([]);
    const [doctorsList, setDoctorsList] = useState<{ label: string, value: number; }[]>([]);
    const [variablesList, setVariablesList] = useState<SurgicalNotesVariablesList[]>([]);


    const [dataTemplate, setDataTemplate] = useState<{ clhId: number | undefined; cluId: number | undefined; }>({
        clhId: undefined,
        cluId: undefined
    });

    const getSurgeonNotesFunction = async () => {
        const data = await dispatch(getSurgeonNotes({
            appId,
            sunId
        }));
        setSurgeonNotes(data);
    };

    useEffect(() => {
        async function fetchData() {
            getSurgeonNotesFunction();

            const dataListNurse = await dispatch(getSurgeons({
                profilePrefix: "nurse"
            }));
            if (dataListNurse.length > 0) {
                const optionsNurse = dataListNurse.map(x => ({ label: x.name, value: x.id }));
                setNurseList(optionsNurse);
            }
            const dataListDoctors = await dispatch(getSurgeons({
                profilePrefix: "medical"
            }));

            if (dataListDoctors.length > 0) {
                const optionsDoctor = dataListDoctors.map(x => ({ label: x.name, value: x.id }));
                setDoctorsList(optionsDoctor);
            }
        }
        fetchData();
    }, [dispatch]);

    useEffect(() => {

        async function fetchData() {
            const data = await dispatch(getDoctorInformation(
                appId,
            ));
            setDoctorsInformation(data);
            const dataVariables = await dispatch(SurgicalNotesVariables(
                { appId }
            ));
            setVariablesList(dataVariables);
            setSurgeonNotes(state => ({
                ...state,
                sunSupportSurgeonId: data.supportSurgeon.id
            }));
        }
        fetchData();
    }, [appId, doctorsList, dispatch]);



    const handleAttatchText = (text: string) => {
        if (surgeonNotes) {
            const data = surgeonNotes?.sunDescription?.length
                ? `${surgeonNotes.sunDescription}\n${text}`
                : text;

            handleChangeSubmitNotes(data, "sunDescription");
        }
        handleCloseNoInsTextModal();
    };


    const handleChangeSubmitNotes = (value: string | number | Variable[], name: string) => {
        if (surgeonNotes) {
            const payload = {
                ...surgeonNotes,
                [name]: value
            };
            setSurgeonNotes(payload);
            updateFunction(payload);
        }
    };

    const updateFunction = useDebouncedCallback(async (payload: SurgeonNotes) => {
        const data = await dispatch(updateSurgeonNotes(payload));
        if (data.success) {
            getSurgeonNotesFunction();
        }
    }, 500);




    const handleCloseNoInsTextModal = (): void => setSurgeonModal({
        current: "surgicalDescription", isOpen: false
    });

    const handleOpenNoInsTextModal = (name: string): void => setSurgeonModal({
        current: name, isOpen: true
    });

    return (
        <TemplateBodyDoctor sheet="surgery" setDataTemplate={setDataTemplate}
            sectionsChanged={{ ...surgeonNotes }}
        >
            <div className="card-columns">
                <div className="item">
                    <SectionCard cardAidStyle >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary">Equipo quirúrgico</h5>
                        </div>
                        <hr className="lineCards" />
                        <div className="row align-content-center text-muted">
                            <div className="col-4 align-content-center">Cirujano principal</div>
                            <div className="col-8 py-2"> {doctorsInformation?.surgeon?.fullName}</div>
                        </div>
                        <div className="row align-content-center text-muted">
                            <div className="col-4 align-content-center">Cirujano ayudante</div>
                            <div className="col-8">
                                <Select
                                    className="textGray100 mb-1"
                                    isSearchable
                                    readOnly={isDisabledFields}
                                    variant="plain"
                                    options={doctorsList}
                                    value={doctorsList?.find(x => x.value === surgeonNotes?.sunSupportSurgeonId)}
                                    onChange={({ option }) => {
                                        handleChangeSubmitNotes(option.value || null, "sunSupportSurgeonId");
                                    }}
                                /></div>

                        </div>
                        <div className="row align-content-center text-muted">
                            <div className="col-4 align-content-center">Instrumentador</div>
                            <div className="col-8">
                                <Select
                                    className="textGray100 mb-1"
                                    isSearchable
                                    variant="plain"
                                    readOnly={isDisabledFields}
                                    options={nurseList}
                                    value={nurseList?.find(x => x.value === surgeonNotes?.sunSupportNurseId)}
                                    onChange={({ option }) => {
                                        handleChangeSubmitNotes(option.value || null, "sunSupportNurseId");
                                    }}
                                /></div>

                        </div>
                        <div className="row align-content-center text-muted">
                            <div className="col-4 align-content-center">Rotador</div>
                            <div className="col-8">
                                <Select
                                    className="textGray100 mb-1"
                                    isSearchable
                                    variant="plain"
                                    readOnly={isDisabledFields}
                                    options={nurseList}
                                    value={nurseList?.find(x => x.value === surgeonNotes?.sunRotatorId)}
                                    onChange={({ option }) => {
                                        handleChangeSubmitNotes(option.value || null, "sunRotatorId");
                                    }}
                                /></div>

                        </div>
                        <div className="row align-content-center text-muted">
                            <div className="col-4 align-content-center">Anestesiólogo</div>
                            <div className="col-8 py-2"> {doctorsInformation?.anesthesiologist?.fullName ?? "-"}</div>


                        </div>
                    </SectionCard>
                </div>
                <div className="item">
                    <SectionCard cardAidStyle >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary required">Descripción</h5>
                        </div>
                        <hr className="lineCards" />
                        <textarea
                            className="form-control w-100"
                            style={{ minHeight: "20rem" }}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                handleOpenNoInsTextModal("surgicalDescription");
                            }}
                            onChange={({ target }) => {
                                handleChangeSubmitNotes(target.value, "sunDescription");
                            }}
                            readOnly={isDisabledFields}
                            value={surgeonNotes?.sunDescription}
                        />
                    </SectionCard>
                </div>
                {variablesList.length > 0 && variablesList.some(variable => variable.variables.length > 0)
                    &&
                    <div className="item">
                        <SectionCard className="h-100" cardAidStyle >
                            <h5 className="fw-bold text-secondary required">Variables específicas cx</h5>
                            <hr className="lineCards" />
                            {variablesList.length > 0 ?
                                variablesList.map(service => {
                                    return (
                                        <div className="row">
                                            {service.variables.length > 0 &&
                                                service.variables.map(variable => {
                                                    return <div className="col-6 mb-2">
                                                        <RenderVariable variable={variable} variablesList={surgeonNotes?.variables}
                                                            onSubmit={handleChangeSubmitNotes} isDisabledFields={isDisabledFields} />
                                                    </div>;
                                                })
                                            }
                                        </div>
                                    );
                                })
                                : <div className="text-muted">Los servicios asociados a la cirugía no tienen variable parametrizadas, comuníquese con un administrador.</div>
                            }
                        </SectionCard>

                    </div>}

                <div className="item">
                    {dataTemplate.clhId &&
                        <DiagnosticImpression
                            isDisabledForm={isDisabledFields}
                            isSurgery={true}
                            data={{
                                appId,
                                cluId: dataTemplate.cluId,
                                userId: userId ?? 0,
                                eaccount,
                                clhId: dataTemplate.clhId,
                                sheet: "surgery"
                            }}
                        />
                    }

                </div>
            </div>
            {!isDisabledFields &&
                <TextConfigModal
                    title="Textos predefinidos no institucionales"
                    sheet="surgicalNotes"
                    isOpen={surgeonModal.isOpen}
                    segment={surgeonModal.current}
                    current={surgeonModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    positionModal="center"
                    onSelectItem={(_, item) => handleAttatchText(item)}
                />
            }
        </TemplateBodyDoctor>
    );
};



const RenderVariable = ({ variable, variablesList, onSubmit, isDisabledFields }: {
    variable: VariableList; variablesList?: Variable[];
    onSubmit: (value: string | number | Variable[], name: string) => void;
    isDisabledFields: boolean;

}) => {

    const [options, setOptions] = useState<{ label: string, value: number; }[]>([]);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const location = useLocation();
    const sunId = location.state.startAttentionInfo.sunId;
    const variableData = variablesList?.find(x => variable.id === x.varId);

    useEffect(() => {
        if (variable && variable.options) {
            const activeOptions = variable?.options?.length > 0 ? variable.options?.filter(x => x.active === 1) : [];
            const optionsSelect = activeOptions?.length > 0 ? activeOptions?.map((option => ({ label: option.label, value: option.id }))) : [];

            setOptions(optionsSelect);
        }
    }, []);



    const formattedDataChange = (dataType: Variable) => {
        if (variableData) {
            const dataModified = variablesList?.map(x => x.varId === variable.id ? (
                { ...x, ...dataType })
                : ({ ...x })
            );
            return dataModified;
        } else {
            if (variablesList && variablesList?.length > 0) {
                const newVariable = [...variablesList, { ...dataType }];
                return newVariable;
            } else {
                const newVariable = [{ ...dataType }];
                return newVariable;

            }

        }
    };


    const handleChange = (value: string | number, type: "fieldtext" | "dropdownList",) => {

        const data = {
            createdBy: userId,
            name: variableData?.name ?? variable?.name,
            relId: variableData?.relId ?? null,
            sunId,
            value: null,
            vaoId: null,
            varId: variableData?.varId ?? variable?.id
        };
        if (type === "dropdownList") {
            const dataType = { ...data, value: null, vaoId: value === "" ? null : value };
            const payload = formattedDataChange(dataType);
            if (payload) {
                onSubmit(payload, "variables");
            }

        } else {
            const dataType = { ...data, value, vaoId: null };
            const payload = formattedDataChange(dataType);
            if (payload) {
                onSubmit(payload, "variables");
            }



        }

    };



    return <div className="row align-items-end">
        <div className="col-5 text-muted">{variable.name} {variable.units ? `(${variable.units})` : ""}</div>
        <div className="col-7 px-2">
            {variable.type === "fieldtext" ?
                <TextField
                    variant="plain"
                    placeholder=""
                    defaultValue={variableData?.value ?? ""}
                    onChange={({ target }) => handleChange(target.value, "fieldtext")}
                    type="text"
                    min={0}
                    readOnly={isDisabledFields}
                />
                :
                <Select
                    className="textGray100"
                    isSearchable
                    variant="plain"
                    options={options}
                    value={options?.find(x => x.value === variableData?.vaoId)}
                    onChange={({ option }) => {
                        handleChange(option.value, "dropdownList");
                    }}
                    readOnly={isDisabledFields}
                />
            }
        </div>
    </div>;
};

export default Surgeon;