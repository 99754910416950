import { Modal, TextField, } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ConsumptionCenters, Lot } from "@/models/surgery/patientAttention/patientAttention";
import { IconChevronRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { addLotArticle } from "../patientAttentionSurgery.action";


const BatchRecord = ({ stage, isOpen, onClose, appId, data }: {
    stage?: string; isOpen: boolean, onClose: (update: boolean) => void, appId?: number | null; data: { medicineName: string, data: ConsumptionCenters; };
}) => {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const eaccount = useAppSelector(state => state.workspace.id);
    const [lotsList, setLotsList] = useState<Lot[]>([]);
    useEffect(() => {
        setLotsList(data?.data?.lots);
    }, [data]);

    const handleCloseModal = (update: boolean) => {
        onClose(update);
        setLotsList(data?.data?.lots);
    };

    const searchItemsFunction = (value: string) => {
        if (value !== "") {
            const tempArr = data?.data?.lots.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setLotsList(tempArr);
        } else {
            setLotsList(data?.data?.lots);
        }
    };

    const handleAddArticle = async (lotId: number) => {
        if (appId && stage !== undefined && userId) {
            const response = await dispatch(addLotArticle(
                {
                    appId,
                    quantity: 1,
                    stgPrefix: stage,
                    supId: data.data.supplieId,
                    artId: data.data.article.id,
                    lotId: lotId,
                    createdBy: userId,
                    eaccount,
                }
            ));
            if (response.success) {
                handleCloseModal(true);
            }

        }
    };

    const render = () => {

        return (
            <Modal
                positionModal="center"
                width={500}
                isOpen={isOpen}
                onClose={() => handleCloseModal(false)}
            >
                <h1 className="text-secondary fw-bold text-start">Seleccionar lote</h1>
                <div className="d-flex flex-column">
                    <div>
                        <b className="text-primary">Articulo seleccionado</b>
                    </div>
                    <div>
                        <span className="text-muted">{data.medicineName}</span>

                    </div>
                </div>
                <div className="py-2">
                    <TextField
                        type="text"
                        onChange={({ target }) => { searchItemsFunction(target.value); }}
                        name="appDate"
                        placeholder="Buscar lote..."

                        style={{ color: "#999" }}
                    />
                </div>
                <div className=" px-2 py-2" key={(lotsList.length)}>
                    {lotsList?.length > 0 ? lotsList.map(lot => {
                        return (
                            <div className="row cardLotsSection mb-2 " key={lot.id}>
                                <div className="col-10">
                                    <div className="row">
                                        <div className="col-6 fw-bold text-primary">Lote </div>
                                        <div className="col-6 fw-bold text-primary">Fecha de expedición</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 text-secondary fw-bold"> {lot.name}</div>
                                        <div className="col-6 text-secondary fw-bold">{lot.expirationDate}</div>
                                    </div>

                                </div>
                                <div className="col-2 align-self-center text-end pointer"
                                    onClick={() => handleAddArticle(lot.id)}
                                > <IconChevronRight
                                    /> </div>

                            </div>
                        );
                    }) :
                        <div className="text-muted text-center"> No hay lotes relacionados</div>
                    }
                </div>
            </Modal>
        );
    };

    return render();
};

export default BatchRecord;