import { axiosInstance } from "@/config/axios";
import { SurgeryHistoryDownloadParams, SurgeryHistoryDownloadResponse, SurgeryStagesResponse } from "@/models/surgery/surgeryStages";
import { setHeaders } from "@/utils";

export async function getSurgeryStagesListService(params: { toDownload?: 1 | 0; appId: number; }) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<SurgeryStagesResponse>(
        "/medical/clinicHistory/attentionHistoryPrefixes/", axiosParams
    );
    return serviceData;
}

export async function getSurgeryDownloadDetailsService(params: SurgeryHistoryDownloadParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<SurgeryHistoryDownloadResponse>(
        "/medical/clinicHistory/attentionHistoryDownload/", axiosParams
    );
    return serviceData;
}