import { Button, SectionCard } from "@/components";
import { setCurrentCheckList } from "@/config/slices/surgery";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { CreateVitalSingsPayload, RecordChart, SurgeryNotes, VitalSign } from "@/models/surgery/patientAttention/patientAttention";
import { IconChecks, IconPhotoUp, IconPlayerPlayFilled, IconPlayerStopFilled } from "@tabler/icons-react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useEffect, useState } from "react";
import TextConfigModal from "../../Consultation/AttentionOrigin/TextConfig/TextConfigNoIns";
import CheckList from "../components/CheckList";
import RecordChartComponent from "../components/RecordChart";
import VitalSignsModal from "../components/VitalSingsModalComponent";
import EvidenceModal from "../consumptionSheet/EvidenceModal";
import { createVitalSingsSurgery, getAnesthesiaTime, getCheckList, startSurgerySegment } from "../patientAttentionSurgery.action";
import { Timer } from "../../Consultation/Header/Timer";
dayjs.extend(duration);

const Surgery = ({ data, onSubmitNotes, appId, numId, statusSurgery, readOnly }: {
    numId: number;
    appId: number;
    data?: SurgeryNotes,
    onSubmitNotes: (section: string, payload: SurgeryNotes) => void, statusSurgery: string;
    readOnly?: boolean;
}) => {

    const dispatch = useAppDispatch();
    const eaccount = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const [time, setTime] = useState(0);
    const [originalTime, setOriginalTime] = useState(0);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [SurgeryNotes, setSurgeryNotes] = useState<SurgeryNotes>();
    const [checkListModal, setCheckListModal] = useState({
        isOpen: false
    });
    const [imagesModal, setImagesModal] = useState<{ isOpen: boolean, data: { url: string; }[]; }>({
        isOpen: false,
        data: []
    });
    const [stageSurgery, setStageSurgery] = useState<{ stage: "toStart" | "start" | "pause" | "end"; }>(() => {
        const savedStage = localStorage.getItem(`surgeryStage-${appId}`);
        return savedStage ? { stage: JSON.parse(savedStage) } : { stage: "toStart" };
    });
    const [vitalSingsData, setVitalSingsData] = useState<CreateVitalSingsPayload>({
        isOpen: false,
        stgPrefix: "surgery",
        appId,
        visRegistrationDate: "",
        vitalSigns: [],
        userId

    });
    const [dataRecord, setDataRecord] = useState<RecordChart>(
        {
            reload: 0,
            appId: null,
            endOfSurgery: "",
            startOfAnesthesia: "",
            startOfSurgery: "",
            vitalSigns: {
                fc: [],
                o2: [],
                pad: [],
                pas: [],
            },
        }
    );


    const [surgeryNotesModal, setSurgeryNotesModal] = useState<{ isOpen: boolean; current: string | null; }>({
        isOpen: false,
        current: ""
    });
    const handleOpenNoInsTextModal = (name: string): void => setSurgeryNotesModal({
        current: name, isOpen: true
    });

    const handleCloseNoInsTextModal = (): void => setSurgeryNotesModal({
        current: "", isOpen: false
    });

    useEffect(() => {
        async function fetchData() {
            if (numId) {
                const data = await dispatch(getCheckList({
                    numId,
                }));
                data.checklists.forEach(obj => {
                    if (
                        (obj.prefix === "entry" && stageSurgery.stage === "toStart") ||
                        (obj.prefix === "pause" && (stageSurgery.stage === "start" || stageSurgery.stage === "pause")) ||
                        (obj.prefix === "exit" && stageSurgery.stage === "end")
                    ) {

                        obj.categories.forEach(category => {
                            const closedQuestions = category.questions.filter(question => question.type === "closed");
                            const multipleChoiceQuestions = category.questions.filter(question => question.type === "multipleChoice");

                            closedQuestions.forEach(question => {
                                question.answersCheckListArray.forEach(arr => {
                                    if (arr.description === "false" && arr.selected === true) {
                                        setCheckListModal({ isOpen: true });
                                    }
                                });
                            });

                            multipleChoiceQuestions.forEach(question => {
                                if (question.answersCheckListArray.every(arr => arr.selected === false)) {
                                    setCheckListModal({ isOpen: true });
                                }
                            });
                        });
                    }
                });
            }
        }
        fetchData();
    }, [numId, stageSurgery.stage, dispatch]);

    const handleChange = (value: string, name: "numReviewsUsedMedicalDevices" | "numSurgeryObservation") => {
        if (SurgeryNotes) {
            const payload = {
                ...SurgeryNotes,
                numId: SurgeryNotes.numId,
                [name]: value
            };
            setSurgeryNotes(payload);
            onSubmitNotes("surgery", payload);
        }
    };

    const handleAttatchText = (text: string) => {
        const label = surgeryNotesModal.current === "reviewsUsedMedicalDevices" ? "numReviewsUsedMedicalDevices" : "numSurgeryObservation";
        if (SurgeryNotes) {
            const payload = {
                ...SurgeryNotes,
                numId: SurgeryNotes.numId,
                [label]: SurgeryNotes?.[label]?.length
                    ? `${SurgeryNotes?.[label]}\n${text}`
                    : text
            };
            setSurgeryNotes(payload);
            onSubmitNotes("surgery", payload);
        }
        handleCloseNoInsTextModal();
    };

    const handleOpenCheckListModal = () => {
        setCheckListModal((state) => ({ ...state, isOpen: true }));
    };
    const handleCloseCheckListModal = () => {
        setCheckListModal((state) => ({ ...state, isOpen: false }));
    };

    useEffect(() => {
        async function fetchData() {
            if (numId) {
                const data = await dispatch(getCheckList({
                    numId,
                }));
                data.checklists.forEach(obj => {
                    if (
                        (obj.prefix === "entry" && stageSurgery.stage === "toStart") ||
                        (obj.prefix === "pause" && (stageSurgery.stage === "start" || stageSurgery.stage === "pause")) ||
                        (obj.prefix === "exit" && stageSurgery.stage === "end")
                    ) {

                        obj.categories.forEach(category => {
                            const closedQuestions = category.questions.filter(question => question.type === "closed");
                            const multipleChoiceQuestions = category.questions.filter(question => question.type === "multipleChoice");

                            closedQuestions.forEach(question => {
                                question.answersCheckListArray.forEach(arr => {
                                    if (arr.description === "false" && arr.selected === true) {
                                        setCheckListModal({ isOpen: true });
                                    }
                                });
                            });

                            multipleChoiceQuestions.forEach(question => {
                                if (question.answersCheckListArray.every(arr => arr.selected === false)) {
                                    setCheckListModal({ isOpen: true });
                                }
                            });
                        });
                    }
                });
            }
        }
        fetchData();
    }, [numId, stageSurgery.stage, dispatch]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getAnesthesiaTime({
                careTag: "surgery",
                configTag: "anesthesia_data_entry_time",
                eaccount
            }));
            setOriginalTime((data.timeValue * data.value));
            setTime(data.timeValue * data.value);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            setSurgeryNotes(data);
        }
    }, [data]);

    useEffect(() => {
        if (isRunning && stageSurgery.stage !== "end") {
            setStageSurgery((data) => ({ ...data, stage: "start" }));

        }
    }, [isRunning, stageSurgery]);

    useEffect(() => {
        if (stageSurgery.stage === "toStart") {
            dispatch(setCurrentCheckList("entry"));
        } else if (stageSurgery.stage === "start" || stageSurgery.stage === "pause") {
            dispatch(setCurrentCheckList("pause"));
        } else if (stageSurgery.stage === "end") {
            dispatch(setCurrentCheckList("exit"));
        }
    }, [stageSurgery.stage, dispatch]);

    useEffect(() => {
        if (!isRunning || time <= 0 || statusSurgery === "recovery") return;
        const timerId = setInterval(() => {
            setTime(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timerId);
                    setIsRunning(false);
                    setVitalSingsData((state) => ({ ...state, isOpen: true }));
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timerId);
    }, [isRunning, time, statusSurgery]);

    useEffect(() => {
        if (dataRecord.endOfSurgery && dataRecord.startOfSurgery) {
            setStageSurgery((data) => ({ ...data, stage: "end" }));
        } else if (dataRecord.startOfSurgery) {
            setStageSurgery((data) => ({ ...data, stage: "start" }));
            setIsRunning(true);
        } else {
            setStageSurgery((x) => ({ ...x, stage: "toStart" }));
        }
    }, [dataRecord.startOfSurgery, dataRecord.endOfSurgery]);



    const handleCloseVitalSings = () => {
        setVitalSingsData((data) => ({ ...data, isOpen: false }));
        setDataRecord((state) => ({ ...state, reload: (state.reload + 1) }));
        setTime(originalTime);
        setIsRunning(true);
    };



    const handleStageSurgery = async (stage: "toStart" | "start" | "pause" | "end",) => {
        if (numId && (stage === "end" || stage === "start")) {
            const data = await dispatch(startSurgerySegment({ numId, action: stage }));
            if (data.success) {
                setStageSurgery((data) => ({ ...data, stage }));
                setIsRunning(true);
                setDataRecord((state) => ({ ...state, reload: state.reload + 1 }));
                if (stage === "end") {
                    dispatch(setCurrentCheckList("exit"));
                } else {
                    dispatch(setCurrentCheckList("pause"));
                }
                handleOpenCheckListModal();
            }
        } else {
            setStageSurgery((data) => ({ ...data, stage }));
        }

    };


    const handleSubmitVitalSings = async (data: VitalSign[]) => {
        const fullData = {
            stgPrefix: "surgery",
            appId,
            userId,
            vitalSigns: data,
            visRegistrationDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
        };
        const res = await dispatch(createVitalSingsSurgery(fullData));
        if (res.success) {
            handleCloseVitalSings();
        }
    };

    function calcTime(time1: string, time2: string) {
        const difSeconds = Date.parse(time1) - Date.parse(time2);

        const hours = Math.floor(difSeconds / (3600 * 1000));
        const min = Math.round((difSeconds % (3600 * 1000)) / (60 * 1000));

        const formato = `${hours ? hours + "h " : ""} ${min}m`;
        return formato;
    }

    const formatTimeToAmPm = (time: string): string => {
        if (time) {
            return dayjs("1/1/1 " + time).format("hh:mm a");
        } else return "-";
    };

    return (
        <>
            <CheckList onClose={handleCloseCheckListModal} numId={numId} isOpen={checkListModal.isOpen}

            />
            <EvidenceModal
                details
                isDataModified
                dataModified={imagesModal.data}
                isOpen={imagesModal.isOpen}
                onClose={() => {
                    setImagesModal((state) => ({ ...state, isOpen: false }));
                }} />
            <VitalSignsModal
                handleGenericFunction={handleSubmitVitalSings}
                isOpen={vitalSingsData.isOpen}
                onClose={handleCloseVitalSings}
                setVitalSingsData={setVitalSingsData}
                appId={appId}
            />
            {SurgeryNotes?.userInfo &&
                <SectionCard className=" cardAidStyle mx-4 mt-3"  >
                    <span className="text-muted"><b className="text-secondary">Último cambio realizado por: </b>
                        {typeof SurgeryNotes.userInfo === "string" ? SurgeryNotes.userInfo : SurgeryNotes.userInfo.fullName}
                    </span>
                </SectionCard>}
            <div className="card-columns px-4">
                <div className="item">
                    <SectionCard cardAidStyle >
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h5 className="fw-bold text-secondary mb-0 required">Record anestésico</h5>
                            {!readOnly && (
                                <div className="text-primary d-flex align-items-start mb-1">
                                    {dataRecord.startOfSurgery !== null && dataRecord.endOfSurgery === null
                                        &&
                                        <div className="px-4" key={dataRecord.startOfSurgery}>
                                            <Timer
                                                startTime={dataRecord.startOfSurgery}
                                                endTime={dataRecord.endOfSurgery}
                                            />
                                        </div>
                                    }
                                    {stageSurgery.stage === "toStart" ?
                                        <div className="d-flex text-primary pointer"
                                            onClick={() => handleStageSurgery("start")}
                                        >
                                            <IconPlayerPlayFilled width={20} />
                                            <span className="align-self-center m-0 mx-2">Iniciar acto quirúrgico</span>
                                        </div>
                                        : stageSurgery.stage === "start" ?
                                            <div className="d-flex text-danger pointer"
                                                onClick={() => handleStageSurgery("end")}
                                            >
                                                <IconPlayerStopFilled width={20} />
                                                <h5 className="align-self-center m-0 mx-2">Finalizar acto quirúrgico</h5>
                                            </div> :
                                            <div className="d-flex text-muted pointer">
                                                <IconChecks width={20} />
                                                <h5 className="align-self-center m-0 mx-2">Acto quirúrgico finalizado</h5>
                                            </div>
                                    }
                                </div>
                            )}
                        </div>

                        <hr className="lineCards" />
                        <div className="row">
                            <div className="col-8">
                                <RecordChartComponent appId={appId} setDataRecord={setDataRecord} reload={dataRecord.reload} heightProp={290} />
                            </div>
                            <div className="d-flex flex-column px-4 pt-2 col-4 align-self-center">
                                <div className="pb-2">
                                    <b className="text-secondary">Inicio anestesia:</b> <span className="text-muted"> {formatTimeToAmPm(dataRecord?.startOfAnesthesia?.split(" ")[1])}</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">Inicio cirugía:</b> <span className="text-muted"> {formatTimeToAmPm(dataRecord?.startOfSurgery?.split(" ")[1])}</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">Fin cirugía:</b> <span className="text-muted"> {formatTimeToAmPm(dataRecord?.endOfSurgery?.split(" ")[1])}</span>
                                </div >
                                <div className="pb-2">
                                    <b className="text-secondary">Duración cirugía:</b> <span className="text-muted"> {
                                        dataRecord.endOfSurgery ?
                                            calcTime(dataRecord?.endOfSurgery, dataRecord?.startOfSurgery) : "-"}</span>
                                </div >
                                <div className="pb-2">
                                    <b className="text-secondary">PAS:</b> <span className="text-muted">{dataRecord?.vitalSigns?.pas?.length > 0 ? dataRecord?.vitalSigns?.pas[dataRecord?.vitalSigns?.pas.length - 1]?.value : "0"}  (mm/hg)</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">PAD:</b> <span className="text-muted"> {dataRecord?.vitalSigns?.pad?.length > 0 ? dataRecord?.vitalSigns?.pad[dataRecord?.vitalSigns?.pad.length - 1]?.value : "0"}  (mm/hg)</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">FC:</b> <span className="text-muted"> {dataRecord?.vitalSigns?.fc?.length > 0 ? dataRecord?.vitalSigns?.fc[dataRecord?.vitalSigns?.fc.length - 1]?.value : "0"} (lpm)</span>
                                </div>
                                <div className="pb-2">
                                    <b className="text-secondary">O2:</b> <span className="text-muted"> {dataRecord?.vitalSigns?.o2?.length > 0 ? dataRecord?.vitalSigns?.o2[dataRecord?.vitalSigns?.o2.length - 1]?.value : "0"}  (%)</span>
                                </div>

                                {!readOnly && (
                                    <Button variant="outline-danger" onClick={() => {
                                        setIsRunning(false);
                                        setVitalSingsData((state) => ({ ...state, isOpen: true }));
                                    }}>
                                        Alertar
                                    </Button>
                                )}
                            </div>
                        </div>


                    </SectionCard>
                </div>
                <div className="item">
                    <SectionCard cardAidStyle >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary">Insumos especiales</h5>
                        </div>
                        <hr className="lineCards" />
                        <b className="text-muted">Insumos registrados</b>
                        <div className="d-flex flex-column">
                            {(SurgeryNotes?.special_supplies && SurgeryNotes?.special_supplies.length > 0) ? SurgeryNotes?.special_supplies?.map((drug) => (
                                <div key={drug?.name} className="text-muted align-items-center d-flex justify-content-between">
                                    <div>{drug?.name}</div>
                                    {drug?.images?.length > 0 &&
                                        <div className=" text-primary pointer"
                                            onClick={() => {
                                                if (drug?.images?.length > 0) {
                                                    const fullData = drug?.images.map(img => ({ url: img }));
                                                    setImagesModal({ ...imagesModal, isOpen: true, data: fullData });
                                                } else {
                                                    setImagesModal({ ...imagesModal, isOpen: true });
                                                }
                                            }}
                                        >
                                            <IconPhotoUp />
                                        </div>
                                    }
                                </div>
                            ))
                                : <div className="text-muted">No se han agregado insumos</div>
                            }
                        </div>
                    </SectionCard>
                </div>

                <div className="item">
                    <SectionCard cardAidStyle >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary required">Comentarios sobre dispositivos médicos usados</h5>
                        </div>
                        <hr className="lineCards" />
                        <textarea
                            className="form-control no-resize w-100"
                            style={{ height: 100 }}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                handleOpenNoInsTextModal("reviewsUsedMedicalDevices");
                            }}
                            onChange={({ target }) => handleChange(target.value, "numReviewsUsedMedicalDevices")}
                            value={SurgeryNotes?.numReviewsUsedMedicalDevices}
                            readOnly={readOnly}
                        />
                    </SectionCard>
                </div>

                <div className="item">
                    <SectionCard cardAidStyle >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="fw-bold text-secondary required">Observación sala de cirugía</h5>
                        </div>
                        <hr className="lineCards" />
                        <textarea
                            className="form-control no-resize w-100"
                            style={{ height: 100 }}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                handleOpenNoInsTextModal("surgeryObservation");
                            }}
                            onChange={({ target }) => handleChange(target.value, "numSurgeryObservation")}
                            value={SurgeryNotes?.numSurgeryObservation}
                            readOnly={readOnly}
                        />
                    </SectionCard>
                </div>

                <div className="item"><SectionCard cardAidStyle >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Líquidos y drogas </h5>
                    </div>
                    <hr className="lineCards" />
                    <b className="text-muted">Medicamentos registrados</b>
                    <div className="d-flex flex-column">
                        {(SurgeryNotes?.liquids_and_drugs && SurgeryNotes?.liquids_and_drugs.length > 0) ?
                            SurgeryNotes?.liquids_and_drugs.map((drug) => <div key={drug} className="text-muted">
                                {drug}
                            </div>)
                            : <div className="text-muted">No se han agregado medicamentos</div>
                        }
                    </div>
                </SectionCard>
                </div>
                {!readOnly && (
                    <TextConfigModal
                        title="Textos predefinidos no institucionales"
                        isOpen={surgeryNotesModal.isOpen}
                        current=""
                        sheet="nurseryNotes"
                        segment={surgeryNotesModal.current as string}
                        onClose={handleCloseNoInsTextModal}
                        width={800}
                        positionModal="center"
                        onSelectItem={(_, item) => handleAttatchText(item)}
                    />
                )}
            </div>

        </>
    );
};

export default Surgery;