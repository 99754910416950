import { useEffect, useState } from "react";
//
import { Button, Modal, Select } from "@/components";
import { IModal } from "@/components/Modal/modal";
//
import { Anesthesia } from "@/models/sheets/orthoptic";
import { Surgery } from "@/models/sheets/preoperative";
import { getAnesthesiaList } from "@/services";
import { fireWarningAlert } from "@/utils";

export default function PreviousSurgery({ data, onClose, onSuccess, isDisabledForm, isLoading, ...rest }: IModal<Surgery>) {
    const [surgery, setSurgery] = useState<Surgery>({});
    const [typeOfAnesthesia, setTypeOfAnesthesia] = useState<Anesthesia[]>([]);

    useEffect(() => {
        async function fetchData() {
            const response = await getAnesthesiaList();
            if (response.success) setTypeOfAnesthesia(response.results);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (data) setSurgery(data);
    }, [data]);

    const onCloseModal = () => {
        onClose();
        setSurgery({});
    };

    const onSubmit = async () => {
        if (
            surgery.anesthesia &&
            surgery.surgery !== "" &&
            surgery.year !== ""
        ) {
            await onSuccess?.(surgery);
            onCloseModal();

        } else {
            fireWarningAlert("Asegúrese de completar todos los campos", "Intenta de nuevo");
        }
    };

    const render = () => {
        const formattedAnesthesiaList = typeOfAnesthesia.map(item => ({ label: item.name, value: item.id }));

        return (
            <Modal onClose={onCloseModal} {...rest}>
                <h2 className="fw-bold text-secondary">
                    {data?.id ? "Editar" : "Añadir"} cirugía
                </h2>
                <div className="row g-2 mt-2">
                    <div className="col-4">
                        <label className="text-secondary ms-1">Año</label>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            onKeyDown={(e) => (e.key === "." || e.key === "," || e.key === "-" || e.key === "+") && e.preventDefault()}
                            value={surgery.year}
                            onChange={(e) => {
                                if (e.target.value.length > 4) {
                                    e.preventDefault();
                                } else {
                                    setSurgery({ ...surgery, year: e.target.value });
                                }
                            }}
                            placeholder="Escriba el año"
                        />
                    </div>
                    <div className="col">
                        <label className="text-secondary ms-1">Cirugía practicada</label>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={surgery.surgery}
                            onChange={({ target }) => setSurgery({ ...surgery, surgery: target.value })}
                            placeholder="Escriba el nombre de la cirugía"
                        />
                    </div>
                    <div className="col-12">
                        <label className="text-secondary ms-1">Anestesia</label>
                        <Select
                            disabled={isDisabledForm}
                            onChange={({ option }) => setSurgery({ ...surgery, anesthesia: option.value, anesthesia_description: option.label })}
                            value={formattedAnesthesiaList.find(item => item.value === surgery.anesthesia)}
                            options={formattedAnesthesiaList}
                        />
                    </div>
                </div>
                <div className="align-self-end mt-3">
                    <Button
                        disabled={isDisabledForm}
                        variant="outline"
                        className="me-3"
                        onClick={onCloseModal}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        disabled={isDisabledForm}
                        onClick={onSubmit}
                        isLoading={isLoading}
                    >
                        Aceptar
                    </Button>
                </div>
            </Modal>
        );
    };

    return render();
}