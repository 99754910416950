import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { CommonSelectResponse, OrthopticParams, OrthopticPayload, OrthopticResponse } from "@/models/sheets/orthoptic";
import { setHeaders } from "@/utils";

export async function getOrthopticService(params: OrthopticParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OrthopticResponse>(
        "/medical/clinicHistory/orthoptic/", axiosParams
    );
    return serviceData;
}

export async function saveOrthopticService(payload: OrthopticPayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/orthoptic/", payload, axiosParams
    );
    return serviceData;
}

export async function getStereopsisListService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<CommonSelectResponse>(
        "/medical/clinicHistory/orthopticStereopsisTest/", axiosParams
    );
    return serviceData;
}