import { SectionCard } from "@/components";
import { LaboratoryExams, PhysicalExams, PreoperativeSegment } from "@/models/sheets/preoperative";

export default function MedicalExams({ preoperativePayload, setPreoperativePayload, onSubmit, isDisabledForm }: PreoperativeSegment) {

    const oncChangeLaboratoryExams = (field: keyof LaboratoryExams, value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            laboratoryExams: {
                ...preoperativePayload.laboratoryExams,
                [field]: value
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const oncChangePhysicalExams = (field: keyof PhysicalExams, value: string) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            physicalExam: {
                ...preoperativePayload.physicalExam,
                [field]: value
            }
        };
        setPreoperativePayload(options);
        onSubmit();
    };

    const renderLabsExams = () => {
        return (
            <SectionCard>
                <h5 className="fw-bold text-secondary">
                    Exámenes de laboratorio
                </h5>
                <hr className="text-primary mt-2" />
                <div className="row g-3">
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Hemoglobina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.hemoglobin}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("hemoglobin", event.target.value);
                                }
                            }}
                            onKeyDown={e => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Hematocrito</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.hematocrit}
                            onChange={(event) => {
                                if (Number(event.target.value) > 100) {
                                    event.preventDefault();
                                    return;
                                }
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("hematocrit", event.target.value);
                                }
                            }}
                            onKeyDown={e => (e.key === "+" || e.key === "-") && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Leucocitos</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.leukocytes}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("leukocytes", event.target.value);
                                }
                            }}
                            onKeyDown={e => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Plaquetas</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.platelets}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("platelets", event.target.value);
                                }
                            }}
                            onKeyDown={e => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">T. Tromboplastina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.thromboplastin}
                            onChange={(event) => oncChangeLaboratoryExams("thromboplastin", event.target.value)}
                            onKeyDown={e => (e.key === "+" || e.key === "." || e.key === "-") && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">T. Protrombina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.prothrombin}
                            onChange={(event) => oncChangeLaboratoryExams("prothrombin", event.target.value)}
                            onKeyDown={e => (e.key === "+" || e.key === "." || e.key === "-") && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Glicemia</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.glycemia}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("glycemia", event.target.value);
                                }
                            }}
                            onKeyDown={e => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Creatinina</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.creatinine}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("creatinine", event.target.value);
                                }
                            }}
                            onKeyDown={e => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">TSH</div>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.tsh}
                            onChange={(event) => {
                                const values = event.target.value.split(".")[1];
                                if (values && values.length > 2) {
                                    event.preventDefault();
                                } else {
                                    oncChangeLaboratoryExams("tsh", event.target.value);
                                }
                            }}
                            onKeyDown={e => e.key === "+" && e.preventDefault()}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Tiroides</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.thyroid}
                            onChange={({ target }) => oncChangeLaboratoryExams("thyroid", target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">EKG</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.ekg}
                            onChange={({ target }) => oncChangeLaboratoryExams("ekg", target.value)}
                        />
                    </div>
                    <div className="col">
                        <div className="text-center text-secondary">Otros</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.other}
                            onChange={({ target }) => {
                                if (!target.value.length) {
                                    oncChangeLaboratoryExams("value", "");
                                }
                                oncChangeLaboratoryExams("other", target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="w-25 d-flex justify-content-end align-self-end mt-3">
                    <div className="d-flex flex-column align-items-center">
                        <div className="text-secondary">Valor</div>
                        <input
                            type="text"
                            className="form-control"
                            value={preoperativePayload.laboratoryExams?.value}
                            disabled={!preoperativePayload.laboratoryExams?.other?.length || isDisabledForm}
                            onChange={({ target }) => oncChangeLaboratoryExams("value", target.value)}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    const renderPhysicalExams = () => {
        return (
            <SectionCard className="mt-4">
                <h5 className="fw-bold text-secondary">
                    Examen físico
                </h5>
                <hr className="text-primary mt-2" />
                <div className="row g-3">
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Cardiovascular</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.physicalExam?.cardiovascular}
                            onChange={({ target }) => oncChangePhysicalExams("cardiovascular", target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Pulmonar</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.physicalExam?.pulmonary}
                            onChange={({ target }) => oncChangePhysicalExams("pulmonary", target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Extremidades</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.physicalExam?.extremities}
                            onChange={({ target }) => oncChangePhysicalExams("extremities", target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div className="text-center text-secondary">Sistema nervioso central</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.physicalExam?.centralNervousSystem}
                            onChange={({ target }) => oncChangePhysicalExams("centralNervousSystem", target.value)}
                        />
                    </div>
                    <div className="col-lg-12">
                        <div className="text-center text-secondary">Otros</div>
                        <input
                            disabled={isDisabledForm}
                            type="text"
                            className="form-control"
                            value={preoperativePayload.physicalExam?.other}
                            onChange={({ target }) => oncChangePhysicalExams("other", target.value)}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    const render = () => {
        return (
            <>
                <h4 className="fw-bold text-muted text-center">
                    Complete los resultados exámenes médicos del paciente
                </h4>
                {renderLabsExams()}
                {renderPhysicalExams()}
            </>
        );
    };

    return render();
}