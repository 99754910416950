import { Modal } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { IconDeviceFloppy, IconRepeat } from "@tabler/icons-react";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

export default function SignatureComponent({ firm, handleChangeCompanion, setFirmModal, ...rest }: IModal & {
    appId?: number,
    firm?: string;
    handleChangeCompanion: (value: string | number, name: string) => void;
    setFirmModal: (state: { isOpen: boolean, data: string; }) => void;

}) {
    const sigCanvas = useRef<SignatureCanvas>(null);
    const clear = () => {
        sigCanvas.current?.clear();
    };

    const onCloseModal = () => {
        rest.onClose();
    };

    const save = () => {
        const dataUrl = sigCanvas.current?.getTrimmedCanvas().toDataURL("image/png");
        if (dataUrl) {
            setFirmModal({ isOpen: false, data: dataUrl.replace(/^data:image\/[a-z]+;base64,/, "") });
            handleChangeCompanion(dataUrl.replace(/^data:image\/[a-z]+;base64,/, ""), "base64");
            onCloseModal();
        }
    };

    return (
        <Modal {...rest} width={400} positionModal="center" onClose={onCloseModal}>
            <div>


                <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                />
                <div className="d-flex justify-content-center">
                    <div className="pointer text-muted" onClick={clear}> <IconRepeat /></div>
                    <div className="pointer text-primary mx-2" onClick={save}>
                        <IconDeviceFloppy />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

