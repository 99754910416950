import { listHistorySegments } from "@/constants";
import { DiagnosticImpression } from "@/models/historyDetails/diagnosticImpression";
//
import { Table } from "@/components";

export default function DiagnosticImpressionDetails({ diDetail, forDownload }: { diDetail: DiagnosticImpression; forDownload?: boolean; }) {

    const render = () => {
        return (
            <div>
                <div className={`${!forDownload ? "overflow-auto" : ""} ps-1 pt-1 pe-3 mt-3`} style={{ maxHeight: !forDownload ? "55vh" : undefined }}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-end">Ojo</th>
                                <th className="text-start">Médico</th>
                                <th>Origen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={diDetail.id} className="pointer">
                                <td>{diDetail.date}</td>
                                <td style={{ maxWidth: 300, wordWrap: "break-word" }}>
                                    {diDetail.descriptionAmp &&
                                        <>
                                            <span>
                                                <b>AMP / </b>{diDetail.descriptionAmp}
                                            </span> <br />
                                        </>
                                    }
                                    <span><b>{diDetail.cie10} / </b>{diDetail.descriptionCie10}</span>

                                </td>
                                <td>{diDetail.eye}</td>
                                <td>{diDetail.medName}</td>
                                <td>{listHistorySegments.find(item => item.value === diDetail.sheet)?.label}</td>
                            </tr>
                            <tr>
                                <td colSpan={6}>
                                    <table className="w-100">
                                        <thead>
                                            <th>Estado</th>
                                            <th>Médico</th>
                                            <th>Fecha</th>
                                        </thead>
                                        <tbody>
                                            {diDetail.AllStatus?.map(el => (
                                                <tr key={el.id}>
                                                    <td>{el.status}</td>
                                                    <td>{el.medName}</td>
                                                    <td>{el.date}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td>{diDetail.status}</td>
                                                <td>{diDetail.medName}</td>
                                                <td>{diDetail.date}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    return render();
}