import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { AnesthesiaNotesParams, SectionsChanged } from "@/models/surgery/patientAttention/Anesthesiologist";
import { FinishEventService, UpdateSectionsService, getAneTypeService, getAnesthesiaNotesService, getCurrentAnestesiologistService, getOxygenTypesService } from "@/services/surgery/Anesthesiologist";
import { fireSuccessAlert, fireWarningAlert } from "@/utils";

export function getCurrentAnestesiologist() {
    return async function (dispatch: AppDispatch) {
        const response = await getCurrentAnestesiologistService();
        return response.results;
    };
}
export function getAneType() {
    return async function (dispatch: AppDispatch) {
        const response = await getAneTypeService();
        return response;
    };
}
export function getOxygenTypes() {
    return async function (dispatch: AppDispatch) {
        const response = await getOxygenTypesService();
        return response;
    };
}
export function getAnesthesiaNotes(params: AnesthesiaNotesParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAnesthesiaNotesService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateSections(payload: SectionsChanged) {
    return async function (dispatch: AppDispatch) {
        const response = await UpdateSectionsService(payload);
        return response.results;
    };
}
export function finishEvent(payload: SectionsChanged, doAfterSuccess?: () => void, alert?: boolean) {
    return async function (dispatch: AppDispatch) {
        const response = await FinishEventService(payload);
        if (response.success) {
            fireSuccessAlert(response.message, "Cerrado con éxito");

        } else {
            fireWarningAlert(response.message, "Cerrado con éxito");
        }
        return response;
    };
}