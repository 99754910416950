import { axiosInstance } from "@/config/axios";
import { FindingsParams, FindingsResponse } from "@/models/select/findings";
import { setHeaders } from "@/utils";

export async function getFindingsService(params: FindingsParams): Promise<FindingsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<FindingsResponse>(
        "/medical/clinicHistory/findings/", axiosParams
    );
    return serviceData;
}