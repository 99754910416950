import axios from "axios";
//
import { statusCodes } from "@/constants/http";
import { IResponse } from "@/models/general";
import { AttentionAppointmentsParams, AttentionCounterParams, DayServicesParams } from "@/models/appointment";
import { fireErrorAlert } from "@/utils/utils";
//
import { AppDispatch } from "@/config/store";
import * as uiActions from "@/config/slices/ui";
import * as consultationService from "@/services/consultation.service";
import { getDoctorsService, getFinishAppointmentService, getSitesService } from "@/services";
import { SitesParams } from "@/models";

export function getDoctors(accountId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const response = await getDoctorsService(accountId);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(uiActions.finishLoading());

        }
    };
}

export function getSites(params: SitesParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const response = await getSitesService(params);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(uiActions.finishLoading());

        }
    };
}

export function getAttentionAppointments(params: AttentionAppointmentsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());

            const filters = { ...params };
            const response = await consultationService.getAttentionAppointments(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}

export function getDayServices(params: DayServicesParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());

            const filters = { ...params };
            const response = await consultationService.getDayServices(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error(error);

        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}

export function getAttentionAppointmentsCounters(params: AttentionCounterParams) {
    return async function () {
        const response = await consultationService.getAttentionAppointmentsCounters(params);
        return response.results;
    };
}

export function getCanAttentionEnd(mcfId: number) {
    return async function () {
        const response = await getFinishAppointmentService(mcfId);
        return response;
    };
}