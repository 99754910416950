import { AppDispatch } from "@/config/store";
import { SurgeonNotes } from "@/models/surgery/patientAttention/surgeon";
import { FinishSurgeryEventService, GetDoctorInformationService, GetSurgeonNotesService, SurgicalNotesVariablesService, UpdateSurgeonNotesService, surgicalDiagnosisService } from "@/services/surgery/surgeon";

export function getSurgeonNotes(params: { appId: string; sunId: string; }) {
    return async function (dispatch: AppDispatch) {
        const response = await GetSurgeonNotesService(params);
        return response.results;

    };
}


export function updateSurgeonNotes(payload: SurgeonNotes) {
    return async function (dispatch: AppDispatch) {
        const response = await UpdateSurgeonNotesService(payload);
        return response;
    };
}


export function surgicalDiagnosis(params: { appId: number; clhId: number; }) {
    return async function (dispatch: AppDispatch) {
        const response = await surgicalDiagnosisService(params);
        return response.results;
    };
}
export function getDoctorInformation(appId: number) {
    return async function (dispatch: AppDispatch) {
        const response = await GetDoctorInformationService(appId);
        return response.results;
    };
}

export function SurgicalNotesVariables(params: { appId: number; }) {
    return async function (dispatch: AppDispatch) {
        const response = await SurgicalNotesVariablesService(params);
        return response.results;
    };
}

export function finishSurgeryEvent(payload: SurgeonNotes) {
    return async function (dispatch: AppDispatch) {
        const response = await FinishSurgeryEventService(payload);
        return response;
    };
}