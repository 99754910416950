import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { OptometryParams, OptometryPayload, OptometryResponse } from "@/models/sheets/optometry";
import { setHeaders } from "@/utils";

export async function getOptometryService(params: OptometryParams): Promise<OptometryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OptometryResponse>(
        "/medical/clinicHistory/optometry/", axiosParams
    );
    return serviceData;
}

export async function updateVisualAcuityService(payload: OptometryPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/optometry/", payload, axiosParams
    );
    return serviceData;
}
