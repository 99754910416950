import { useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { getAHBackgroundDetail } from "../../../../history.actions";

import { BackgroundDetail as IBackgroundDetail } from "@/models/historyDetails/background";

interface PreconsultationOptions {
    checked: boolean;
    label: string;
    value?: string;
}

export default function BackgroundDetail({ appId, data, download }: { appId?: number; data?: IBackgroundDetail; download?: boolean; }) {
    const [backgroundDetail, setBackgroundDetail] = useState<IBackgroundDetail>((download && data) ? data : {});
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetch() {
            if (data) {
                setBackgroundDetail(data);
            } else {
                const response = await dispatch(getAHBackgroundDetail({
                    prefix: "background",
                    appId: appId
                }));
                if (response) setBackgroundDetail(response);
            }
        }
        fetch();

    }, [appId, dispatch, data]);

    const renderCheckbox = (item: PreconsultationOptions) => {
        return (
            <li className="text-muted py-1"><span className="fw-bold">{item.label}</span>{(item.checked && item.value) && `: ${item.value}`}</li>
        );
    };

    const renderGeneralOptions = () => {
        if (backgroundDetail && backgroundDetail.generals) {
            return (
                <>
                    <h5 className="fw-bold text-primary">Generales</h5>
                    <ul>
                        {(backgroundDetail && backgroundDetail.generals.arterialHypertension) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.arterialHypertension) ? true : false,
                                label: "Hipertensión arterial",
                                value: backgroundDetail && backgroundDetail.generals.arterialHypertensionNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.diabetes) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.diabetes) ? true : false,
                                label: "Diabetes",
                                value: backgroundDetail && backgroundDetail.generals.diabetesGeneralNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.asthma) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.asthma) ? true : false,
                                label: "Asma",
                                value: backgroundDetail && backgroundDetail.generals.asthmaNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.cancer) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.cancer) ? true : false,
                                label: "Cáncer",
                                value: backgroundDetail && backgroundDetail.generals.cancerNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.coronaryHeartDisease) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.coronaryHeartDisease) ? true : false,
                                label: "Enfermedad coronaria",
                                value: backgroundDetail && backgroundDetail.generals.coronaryHeartDiseaseNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.tuberculosis) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.tuberculosis) ? true : false,
                                label: "Tuberculosis",
                                value: backgroundDetail && backgroundDetail.generals.tuberculosisNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.rheumatoidArthritis) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.rheumatoidArthritis) ? true : false,
                                label: "Artritis Reumatoidea",
                                value: backgroundDetail && backgroundDetail.generals.rheumatoidArthritisNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.COPD) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.COPD) ? true : false,
                                label: "EPOC",
                                value: backgroundDetail && backgroundDetail.generals.COPDNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.useMedications) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.useMedications) ? true : false,
                                label: "¿Usa medicamentos?",
                                value: backgroundDetail && backgroundDetail.generals.useMedicationsNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.previousSurgeriesGeneral) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.previousSurgeriesGeneral) ? true : false,
                                label: "Cirugías previas",
                                value: backgroundDetail && backgroundDetail.generals.previousSurgeriesGeneralNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.allergies) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.allergies) ? true : false,
                                label: "Alergias",
                                value: backgroundDetail && backgroundDetail.generals.allergiesNotes
                            })
                        )}
                        {(backgroundDetail && backgroundDetail.generals.others) && (
                            renderCheckbox({
                                checked: Number(backgroundDetail && backgroundDetail.generals.others) ? true : false,
                                label: "Otros",
                                value: backgroundDetail && backgroundDetail.generals.othersNotes
                            })
                        )}
                    </ul>
                    {backgroundDetail && backgroundDetail.generals.notes ? (
                        <div className="text-muted">
                            <span className='fw-bold'>Nota</span>{backgroundDetail ? `: ${backgroundDetail.generals.notes}` : ""}
                        </div>
                    ) : ""}
                </>
            );
        }
    };

    const renderOcularOptions = () => {
        if (backgroundDetail && backgroundDetail.ocular) {
            return (
                <>
                    <h5 className="fw-bold text-primary">Oculares</h5>
                    <ul>
                        {backgroundDetail && backgroundDetail.ocular.glaucoma && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.glaucoma) ? true : false,
                            label: "Glaucoma",
                            value: backgroundDetail && backgroundDetail.ocular.glaucomaNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.ROP && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.ROP) ? true : false,
                            label: "ROP",
                            value: backgroundDetail && backgroundDetail.ocular.ROPNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.DMRE && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.DMRE) ? true : false,
                            label: "DMRE",
                            value: backgroundDetail && backgroundDetail.ocular.DMRENotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.glasses && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.glasses) ? true : false,
                            label: "Gafas",
                            value: backgroundDetail && backgroundDetail.ocular.glassesNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.dryEye && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.dryEye) ? true : false,
                            label: "Ojo seco",
                            value: backgroundDetail && backgroundDetail.ocular.dryEyeNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.diabetic_retinopathy && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.diabetic_retinopathy) ? true : false,
                            label: "Retinopatía diabética",
                            value: backgroundDetail && backgroundDetail.ocular.diabetic_retinopathyNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.uveitis && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.uveitis) ? true : false,
                            label: "Uveitis",
                            value: backgroundDetail && backgroundDetail.ocular.uveitisNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.contactLenses && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.contactLenses) ? true : false,
                            label: "Lentes de contacto",
                            value: backgroundDetail && backgroundDetail.ocular.contactLensesNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.traumas && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.traumas) ? true : false,
                            label: "Traumas",
                            value: backgroundDetail && backgroundDetail.ocular.traumasNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.surgeries && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.surgeries) ? true : false,
                            label: "Cirugía",
                            value: backgroundDetail && backgroundDetail.ocular.surgeriesNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.alerts && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.alerts) ? true : false,
                            label: "Alertas",
                            value: backgroundDetail && backgroundDetail.ocular.alertsNotes
                        })}
                        {backgroundDetail && backgroundDetail.ocular.others && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.ocular.others) ? true : false,
                            label: "Otros",
                            value: backgroundDetail && backgroundDetail.ocular.othersNotes
                        })}
                    </ul>
                    {backgroundDetail && backgroundDetail.ocular.eyeNotesOcular ? (
                        <div className="text-muted">
                            <span className='fw-bold'>Nota</span>{backgroundDetail ? `: ${backgroundDetail.ocular.eyeNotesOcular}` : ""}
                        </div>
                    ) : ""}
                </>
            );
        }
    };

    const renderFamiliarOptions = () => {
        if (backgroundDetail && backgroundDetail.familiar) {
            return (
                <>
                    <h5 className="fw-bold text-primary">Familiares</h5>
                    <ul>
                        {backgroundDetail && backgroundDetail.familiar.AHT && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.AHT) ? true : false,
                            label: "HTA",
                            value: backgroundDetail && backgroundDetail.familiar.AHTNotes
                        })}
                        {backgroundDetail && backgroundDetail.familiar.diabetes && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.diabetes) ? true : false,
                            label: "Diabetes",
                            value: backgroundDetail && backgroundDetail.familiar.diabetesNotes
                        })}
                        {backgroundDetail && backgroundDetail.familiar.asthma && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.asthma) ? true : false,
                            label: "Asma",
                            value: backgroundDetail && backgroundDetail.familiar.asthmaNotes
                        })}
                        {backgroundDetail && backgroundDetail.familiar.coronaryHeartDisease && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.coronaryHeartDisease) ? true : false,
                            label: "Enfermedad coronaria",
                            value: backgroundDetail && backgroundDetail.familiar.coronaryHeartDiseaseNotes
                        })}
                        {backgroundDetail && backgroundDetail.familiar.collagenDisease && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.collagenDisease) ? true : false,
                            label: "Enfermedad colágeno",
                            value: backgroundDetail && backgroundDetail.familiar.collagenDiseaseNotes
                        })}
                        {backgroundDetail && backgroundDetail.familiar.glaucoma && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.glaucoma) ? true : false,
                            label: "Glaucoma",
                            value: backgroundDetail && backgroundDetail.familiar.glaucomaNotes
                        })}
                        {backgroundDetail && backgroundDetail.familiar.keratoconus && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.keratoconus) ? true : false,
                            label: "Queratocono",
                            value: backgroundDetail && backgroundDetail.familiar.keratoconusNotes
                        })}
                        {backgroundDetail && backgroundDetail.familiar.others && renderCheckbox({
                            checked: Number(backgroundDetail && backgroundDetail.familiar.others) ? true : false,
                            label: "Otros",
                            value: backgroundDetail && backgroundDetail.familiar.othersNotes
                        })}
                    </ul>
                    {backgroundDetail && backgroundDetail.familiar.family_notes ? (
                        <div className="text-muted">
                            <span className='fw-bold'>Nota</span>{backgroundDetail ? `: ${backgroundDetail.familiar.family_notes}` : ""}
                        </div>
                    ) : ""}
                </>
            );
        }
    };

    const renderComponent = () => {
        return (
            <div className="d-flex flex-column w-100">
                <h4 className={`${data ? "p-0" : "p-4"} fw-bold text-secondary mb-2 py-4`}>Antecedentes</h4>
                <div className={`${data ? "p-0  h-100  overflow-auto w-100 " : "p-4 h-100  overflow-auto w-100"} row g-0`}>
                    <div className={"mb-3 col-4"}>
                        {backgroundDetail?.generals &&
                        !Object.values(backgroundDetail.generals)
                            .every(opt => opt === null) && renderGeneralOptions()}
                    </div>
                    <div className={"mb-3 col-4"}>
                        {backgroundDetail.ocular &&
                        !Object.values(backgroundDetail.ocular)
                            .every(opt => opt === null) && renderOcularOptions()}
                    </div>
                    <div className={"mb-3 col-4"}>
                        {backgroundDetail.familiar &&
                        !Object.values(backgroundDetail.familiar)
                            .every(opt => opt === null) && renderFamiliarOptions()}
                    </div>
                </div>
            </div>
        );
    };

    return renderComponent();
}