import { generateId } from "@/utils";
import "./Radio.scss";
import { IRadio } from "./radio";

export function RadioButton({
    className = "",
    label,
    labelPosition = "right",
    id = generateId(),
    ...rest
}: IRadio) {

    return (
        <div className={`radio ${className}`}>
            <label className="pointer">
                {labelPosition === "left" && (
                    <span className="form-check-label me-3">{label}</span>
                )}
                <input className="form-check-input border-primary" style={{ borderWidth: 2 }} type="radio" id={id} {...rest} />
                {labelPosition === "right" && (
                    <span className="ms-2">{label}</span>
                )}

            </label>
        </div>
    );
}