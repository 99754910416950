import { Button, Modal, Select } from "@/components";
import { IModal } from "@/components/Modal/modal";
import routes from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { IntervenedPayload } from "@/models/surgery/patientAttention/patientAttention";
import { fireWarningAlert } from "@/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { attentionIntervened, cancellationList, justificationMotivesList } from "../patientAttentionSurgery.action";



export default function Intervened({ appId, cluId, ...rest }: IModal & {
    appId?: number;
    cluId?: number;
}) {

    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const [optionsList, setOptionsList] = useState<{ label: string, value: number; }[]>([]);
    const [data, setData] = useState({
        motive: undefined,
        justification: ""
    });
    const navigate = useNavigate();


    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen) {
                const data = await dispatch(cancellationList());
                if (data) {
                    const idMotive = data.find(x => x.cancellationReasonTag === "reintervention");
                    if (idMotive) {
                        const dataRes = await dispatch(justificationMotivesList({ cancellationReasonId: idMotive.cancellationReasonId }));
                        if (dataRes) {
                            const options = dataRes.map(x => ({ label: x.canDescription, value: x.canId }));
                            setOptionsList(options);
                        }
                    }
                }
            }
        }
        fetchData();
    }, [rest.isOpen]);




    const onCloseModal = () => {

        rest.onClose();
    };

    const onSubmit = async () => {
        if (data.motive !== undefined && data.motive !== "") {
            if (appId && cluId && userId) {
                const fulldata: IntervenedPayload = {
                    appId,
                    cluId,
                    userId,
                    "canId": data.motive,
                    "aslComment": data.justification,
                    stgPrefix: "surgery",
                };
                const response = await dispatch(attentionIntervened(fulldata));
                if (response.success) {
                    navigate(routes.workspaceSurgery);
                    onCloseModal();
                } else {
                    fireWarningAlert(response.message, "Intenta de nuevo");
                }
            }

        } else {
            fireWarningAlert("El motivo es obligatorio", "Intenta de nuevo");
        }




    };






    return (
        <Modal {...rest} width={500} positionModal="center" onClose={onCloseModal}>
            <div className="p-4">
                <h1 className="text-primary fw-bold text-center ">Justificación</h1>
                <div className="p-3 " >
                    <b className=" text-secondary align-self-end">Motivo <span className="text-danger">*</span></b>
                    <div className="">
                        <Select
                            className="textGray100"
                            isSearchable
                            showDefaultOption={false}
                            options={optionsList}
                            value={optionsList.find(x => x.value === data.motive)}
                            onChange={({ option }) => {
                                setData((state) => ({ ...state, motive: option.value }));
                            }}
                        />
                    </div>

                </div>
                <div className="p-3  " >
                    <b className="text-secondary align-self-end">Justificación</b>
                    <div className="">
                        <textarea
                            name="comment"
                            className="form-control no-resize w-100"
                            onChange={({ target }) => {
                                setData((state) => ({ ...state, justification: target.value }));
                            }}
                            style={{ height: "7rem" }}
                        />

                    </div>

                </div>


                <div className="pt-4">
                    <div className="d-flex justify-content-center mt-3">
                        <Button variant="primary" size="large" className="py-2"
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}




