import axios from "axios";
import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { IResponse } from "@/models";
import {
    DeleteEvidencePayload,
    OftalmologySheet,
    PIOCorrectionParams,
    PhysicalExamsParams,
    ProvocativeEvidenceParams,
    ProvocativeEvidencePayload,
    TonometryParams,
    TonometryPayload
} from "@/models/sheets/oftalmology";
import {
    deleteProvocativeEvidenceService,
    getFinalRefractionService,
    getOftalmologySheetService,
    getPachymetryCorrectionService,
    getPhysicalExamsService,
    getPreviousPhysicalExamService,
    getProvocativeEvidenceGraphService,
    getProvocativeEvidenceService,
    savePhysicalExamsService,
    saveProvocativeEvidenceService,
    setPhysicalExamNormalService,
    updateOftalmologySheetService
} from "@/services/sheets/oftalmology";
import { fireErrorAlert } from "@/utils";
import { ExamConfig, PhysicalExamNormalPayload, PhysicalExamsPayload } from "@/models/physicalExam";
import { getTonometryService } from "@/services/sheets/oftalmology";
import { saveTonometryService } from "@/services/sheets/oftalmology";
import { getCorrectedPIOService } from "@/services/sheets/oftalmology";
import { getPIOMeasurementsMethodsService } from "@/services";

interface PhysicalExamsReduced {
    external?: ExamConfig;
    ppl?: ExamConfig;
    screra_conjunctiva?: ExamConfig;
    cornea?: ExamConfig;
    previous_chamber?: ExamConfig;
    iris?: ExamConfig;
    crystalline?: ExamConfig;
    retina_vitreous?: ExamConfig;
    optic_nerve?: ExamConfig;
}

export function getPhysicalExams(params: PhysicalExamsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getPhysicalExamsService(params);

            const formatData = response.results.physicalExam.reduce<PhysicalExamsReduced>((acc, el) => {
                return { ...acc, [el.prefix]: el };
            }, {});

            return formatData;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function savePhysicalExams(payload: PhysicalExamsPayload) {
    return async function () {
        const response = await savePhysicalExamsService(payload);
        if (response.success) return "ok";
    };
}
export function getOftalmologySheet(params: PhysicalExamsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getOftalmologySheetService(params);
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function updateOftalmologySheet(payload: OftalmologySheet) {
    return async function () {
        const response = await updateOftalmologySheetService(payload);
        if (response.success) return "ok";
    };
}


export function getProvocativeEvidence(params: ProvocativeEvidenceParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getProvocativeEvidenceService(params);
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function saveProvocativeEvidence(payload: ProvocativeEvidencePayload) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await saveProvocativeEvidenceService(payload);
            return response.success;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function deleteProvocativeEvidence(payload: DeleteEvidencePayload) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await deleteProvocativeEvidenceService(payload);
            return response.success;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function getTonometry(params: TonometryParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getTonometryService(params);
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function saveTonometry(payload: TonometryPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await saveTonometryService(payload);
            if (response.success) return response.results;
            return {};

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function getPachymetryCorrection(thickness: number) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getPachymetryCorrectionService(thickness);
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function getPIOCorrection(params: PIOCorrectionParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getCorrectedPIOService(params);
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function getMeasurementMethods() {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getPIOMeasurementsMethodsService();
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getProvocativeEvidenceGraph(params: ProvocativeEvidenceParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getProvocativeEvidenceGraphService(params);
            return response.results;

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function setPhysicalExamNormal(payload: PhysicalExamNormalPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await setPhysicalExamNormalService(payload);
            return response.success;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getFinalRefraction(params: { patientId: number; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getFinalRefractionService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getPreviousPhysicalExam(params: { appId: number; patientId: number; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getPreviousPhysicalExamService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}