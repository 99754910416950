import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { LowVisionParams, LowVisionPayload } from "@/models/sheets/lowVision";
import { getLowVisionService, updateLowVisionService } from "@/services/sheets/lowVision";

export function getLowVision(params: LowVisionParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getLowVisionService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateLowVision(payload: LowVisionPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await updateLowVisionService(payload);
            return response.success;

        } finally {
            // dispatch(finishLoading());
        }
    };
}