import ProfilePlaceholder from "@/assets/auth/profile_placeholder.jpg";
import { Button, Modal } from "@/components";
import { AuthUserData } from "@/models/auth";
//
import "../Header.scss";

interface IProfile {
    userData: AuthUserData;
    isOpen: boolean;
    onClose: () => void;
}

export default function ProfileModal({ userData, isOpen, onClose }: IProfile) {

    const render = () => {
        return (
            <Modal isOpen={isOpen} onClose={onClose} width={500}>
                <div className="header-profile">
                    <h2 className="text-primary fw-bold text-center">Mi perfil</h2>
                    <hr className="mt-2" />
                    <div className="row gx-0">
                        <div className="col-3 me-3">
                            <img
                                className="img-fluid rounded"
                                src={userData.image ? userData.image : ProfilePlaceholder}
                                alt=""
                            />
                        </div>
                        <div className="col">
                            <div className="header-profile__info">
                                <span className="fs-5 text-primary fw-bold">Información General</span>
                                <div className="d-flex align-items-center mb-2">
                                    <div className="me-3 flex-fill">
                                        <span className="text-label">Nombre</span>
                                        <input
                                            type="text"
                                            defaultValue={userData.first_name}
                                            className="form-control form-control-sm"
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex-fill">
                                        <span className="text-label">Apellido</span>
                                        <input
                                            type="text"
                                            defaultValue={userData.first_surname}
                                            className="form-control form-control-sm"
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-label">Correo institucional</span>
                                    <input
                                        type="text"
                                        defaultValue={userData.email}
                                        className="form-control form-control-sm w-100"
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="fs-6 text-primary fw-bold mt-3">Información General</span>
                    <div className="d-flex w-100 align-items-center">
                        <div className="me-3 flex-grow-1">
                            <span className="text-label">Contraseña anterior</span>
                            <input type="password" placeholder="Escribe..." className="form-control form-control-sm" />
                        </div>
                        <div className="me-3 flex-grow-1">
                            <span className="text-label">Nueva contraseña</span>
                            <input type="password" placeholder="Escribe..." className="form-control form-control-sm" />
                        </div>
                        <div className="flex-grow-1">
                            <span className="text-label">Verificar contraseña</span>
                            <input type="password" placeholder="Escribe..." className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="mt-4 align-self-center">
                        <Button
                            variant="outline"
                            className="me-3"
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                        <Button variant="primary">Guardar</Button>
                    </div>
                </div>
            </Modal>
        );
    };

    return render();
}