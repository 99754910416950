import { Button, Modal, Select, TextField } from "@/components";
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { BodyCenterList, ConsumptionCenters, ConsumptionCentersListByInsurance, deleteSupplyParams } from "@/models/surgery/patientAttention/patientAttention";
import { fireCautionAlert } from "@/utils";
import { IconChecklist, IconCircleX, IconEditCircle, IconMessage, IconPhotoUp, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { changeQuantity, deleteSupply, getConsumptionCenters, getConsumptionCentersSheet } from "../patientAttentionSurgery.action";
import BatchRecord from "./BatchRecord";
import EvidenceModal from "./EvidenceModal";

const ConsumptionSheet = ({ stage, isOpen, onClose, appId, setReloadDataNotes }: {
    stage?: { label: string, value: string; }; isOpen: boolean, onClose: () => void, appId?: number | null; setReloadDataNotes?: (data: number) => void;
}) => {

    const eaccount = useAppSelector(state => state.workspace).id;
    const dispatch = useAppDispatch();
    const [consumptionList, setConsumptionList] = useState<ConsumptionCenters[]>([]);
    const [trigger, setTrigger] = useState(0);
    const [consumptionListSheet, setConsumptionListSheet] = useState<ConsumptionCentersListByInsurance[]>([]);
    const [lotsList, setLotsList] = useState<{ medicineName: string; data: ConsumptionCenters; }>(
        {
            medicineName: "",
            data: {
                article: {
                    barCode: "",
                    id: NaN,
                    name: "",
                },
                inventoryFamily: "",
                lots: [],
                supplieId: NaN,
                supplieName: ""
            },
        }
    );

    const [lotsModal, setLotsModal] = useState<boolean>(false);
    const [valueArticle, setValueArticle] = useState<string | number>("");
    const [reloadDataEvidence, setReloadDataEvidence] = useState(0);

    const handleOpenLotsModal = () => setLotsModal(true);
    const handleCloseLotsModal = (update: boolean) => {
        setLotsModal(false);
        setValueArticle("");
        if (update) {
            setTrigger(trigger + 1);
            if (setReloadDataNotes) {
                setReloadDataNotes(Math.floor(Math.random() * 90000) + 10000);
            }

        }
    };
    const handleCloseModal = () => {
        onClose();
    };


    const renderTable = () => {
        return (

            <ScrollableTable maxHeight={600} key={trigger}>
                <ScrollableTable.Head>
                    <ScrollableTable.Cell className="fs-6" col={2}>
                        Código
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6 nowrap" col={2}>
                        Articulo
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6 nowrap text-ellipsis overflow-hidden" col={1}>
                        Lote
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell align="center" className="fs-6 text-ellipsis overflow-hidden" col={2}>
                        Fecha de vencimiento
                    </ScrollableTable.Cell>
                    {stage?.value === "surgery" &&
                        <ScrollableTable.Cell className="fs-6 text-start" >
                            Quirófano
                        </ScrollableTable.Cell>
                    }
                    <ScrollableTable.Cell align="center" className="fs-6" col={1}>
                        Cant. teórica
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" col={1}>
                        Cant. corregida
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" col={1}>
                        Cant. consumida
                    </ScrollableTable.Cell>
                    <ScrollableTable.Cell className="fs-6" col={1}>
                       
                    </ScrollableTable.Cell>
                </ScrollableTable.Head>

                <ScrollableTable.Body>
                    {consumptionListSheet?.length > 0 ? consumptionListSheet?.map((article, ind) => {
                        return (
                            <div key={ind}>
                                <ScrollableTable.Row >
                                    <ScrollableTable.Cell col={12} align="center" className="subTitleTable fw-bold">
                                        {article.supName}
                                    </ScrollableTable.Cell>
                                </ScrollableTable.Row>
                                {article?.articles.length > 0 && article?.articles.map((supply, index) => {
                                    return (
                                        <RenderRow
                                            reloadDataEvidence={reloadDataEvidence}
                                            setReloadDataEvidence={setReloadDataEvidence}
                                            setReloadDataNotes={setReloadDataNotes}
                                            key={index} row={supply}
                                            appId={appId}
                                            deleteSupplyFunction={deleteSupplyFunction}
                                            setTrigger={setTrigger}
                                            trigger={trigger}
                                            showImage={article.supPrefix === "specials"}
                                            stage={stage?.value}
                                        />
                                    );
                                })
                                }
                            </div>
                        );
                    }) :
                        <ScrollableTable.Row >
                            <ScrollableTable.Cell col={12} align="center" className=" justify-content-center fw-bold py-4">
                                <span>
                                    No hay insumos disponibles
                                </span>
                            </ScrollableTable.Cell>
                        </ScrollableTable.Row>
                    }
                </ScrollableTable.Body>
            </ScrollableTable>

        );
    };

    const deleteSupplyFunction = async (params: deleteSupplyParams) => {
        fireCautionAlert("Se eliminará de la hoja de consumo", "¿Está seguro?", async () => {
            const data = await dispatch(deleteSupply(params));
            if (data.success) {
                setTrigger(trigger + 1);
                if (setReloadDataNotes) {
                    setReloadDataNotes(Math.floor(Math.random() * 90000) + 10000);
                }
            }
        });

    };

    useEffect(() => {
        async function fetchData() {
            if (appId && stage?.value) {
                const dataArticles = await dispatch(getConsumptionCenters({ eaccount, appId, stagePrefix: stage?.value }));
                const dataSheet = await dispatch(getConsumptionCentersSheet({ appId, stagePrefix: stage?.value, eaccount }));
                if (dataSheet) {
                    setConsumptionListSheet(dataSheet);
                }
                if (dataArticles) {
                    setConsumptionList(dataArticles);
                }
            }
        }
        fetchData();
    }, [appId, eaccount, dispatch, trigger, stage?.value, reloadDataEvidence]);


    const renderModalLots = (fullData: ConsumptionCenters, name: string) => {
        setLotsList({ medicineName: name, data: fullData });
        handleOpenLotsModal();
    };

    const render = () => {
        const options = consumptionList.map(center => ({
            value: center.article.id,
            label: `${center.article.barCode} - ${center.article.name}`,
            extraData: center,
        }));

        const handleInputChange = (value: string) => {
            const valueFound = options.find(option =>
                option.label.includes(value)
            );
            if (valueFound !== undefined) {
                setValueArticle(valueFound?.value);
                renderModalLots(valueFound.extraData, valueFound.label);
            }


        };



        return (
            <>
                <BatchRecord stage={stage?.value} isOpen={lotsModal} onClose={handleCloseLotsModal} data={{ medicineName: lotsList.medicineName, data: lotsList.data }} appId={appId} />
                <Modal
                    positionModal="center"
                    width={1400}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                >
                    <h1 className="text-secondary fw-bold text-center">Hoja de consumo - {stage?.label}</h1>
                    <div className="row d-flex pb-4">
                        <div>
                            <b className="text-muted text-center align-self-center"> Buscar</b>
                        </div>
                        <div className="">
                            <Select
                                className="textGray100 w-100"
                                isSearchable
                                placeholder="Código o nombre del articulo..."
                                variant="plain"
                                autoFocus
                                showDefaultOption={false}
                                options={options}
                                value={options.find(x => x.value === valueArticle)}
                                style={{ width: "100%" }}
                                clearOnSelect={false}
                                onChange={(e) => {
                                    setValueArticle(e.option.value);
                                    if (!e.option?.inputText?.includes("-")) {
                                        handleInputChange(e.option.inputText);
                                    }
                                    if (e.option.extraData) {
                                        renderModalLots(e.option.extraData, e.option.label);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {renderTable()}
                </Modal>
            </>
        );
    };

    return render();
};

export default ConsumptionSheet;


const RenderRow = ({ stage, row, appId, showImage, trigger, deleteSupplyFunction, setTrigger, setReloadDataNotes, setReloadDataEvidence, reloadDataEvidence }:
    {
        stage?: string;
        row: BodyCenterList, showImage?: boolean,
        reloadDataEvidence: number,
        setReloadDataEvidence: (data: number) => void;
        appId?: number | null;
        setReloadDataNotes?: (data: number) => void;
        trigger: number, setTrigger: (data: number) => void, deleteSupplyFunction: (payload: deleteSupplyParams) => void;
    }) => {
    const eaccount = useAppSelector(state => state.workspace.id);
    const dispatch = useAppDispatch();
    const evidenceMandatory = (row.lotId && row.evidence === 1 && (row?.files?.length === 0 || row.files === null)) ? "#ffa73320" : "";
    const justificationMandatory = (row.lotId && row.expectedAmount < row.quantity ?  "#ffa73320" : "");
    const [isEditing, setIsEditing] = useState(false);
    const [quantityValue, setQuantityValue] = useState<string | number>("");
    const [justificationModal, setJustificationModal] = useState<{ isOpen: boolean, justification: string; consultingRoomId: string | number; }>({
        isOpen: false,
        justification: "",
        consultingRoomId: "",
    });
    const [lotsModal, setLotsModal] = useState<boolean>(false);
    const [consumptionList, setConsumptionList] = useState<ConsumptionCenters>();
    let triggerValue = 0;
    const handleChangedQuantity = async () => {
        const data = await dispatch(changeQuantity({
            "artId": row.articleId,
            "quantity": quantityValue !== "" ? Number(quantityValue) : row.quantity,
            "cssId": row.cssId,
        }));
        if (data.success) {
            setIsEditing(false);
            setTrigger(trigger + 1);
        }
    };
    const handleChangeJustification = async () => {
        const data = await dispatch(changeQuantity({
            "artId": row.articleId,
            justification: justificationModal.justification,
            "cssId": row.cssId,
        }));
        if (data.success) {
            setIsEditing(false);
            setTrigger(trigger + 1);
        }
    };
    const cancelQuantity = () => {
        setIsEditing(false);
    };

    const handleCloseLotsModal = (update: boolean) => {
        setLotsModal(false);
        if (update) {
            setTrigger(trigger + 1);
            if (setReloadDataNotes) {
                setReloadDataNotes(Math.floor(Math.random() * 90000) + 10000);
            }

        }
    };

    const [imagesModal, setImagesModal] = useState<{ isOpen: boolean, elem?: BodyCenterList; }>({
        isOpen: false
    });

    const handleCloseJustificationModal = () => {
        setJustificationModal({ ...justificationModal, isOpen: false, justification: "" });
    };

    const justificationModalComponent = () => {
        return (
            <Modal isOpen={justificationModal.isOpen} width={500} positionModal="center"
                onClose={handleCloseJustificationModal}>
                <div className="p-4">
                    <h1 className="text-primary fw-bold text-center ">Justificación</h1>
                    <h4 className="text-muted text-center py-3">Por favor, justifique la cantidad consumida</h4>
                    <div className="px-3 d-flex flex-column ">
                        <div>
                            <textarea
                                name="comment"
                                defaultValue={justificationModal.justification}
                                className="form-control no-resize w-100"
                                onChange={({ target }) => {
                                    setJustificationModal((state) => ({ ...state, justification: target.value }));
                                }}
                                style={{ height: "7rem" }}
                            /> 
                        </div> 
                    </div>
    
                    <div className="pt-4"> 
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="outline" size="large" className="py-2 me-3" onClick={handleCloseJustificationModal}
                            >
                                Cancelar
                            </Button>
                            <Button variant="primary" size="large" className="py-2" 
                                onClick={handleChangeJustification}
                            >
                                Guardar
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };





    return (
        <>
            {justificationModalComponent()}
            {(consumptionList && appId) && (
                <BatchRecord
                    stage={stage}
                    isOpen={lotsModal}
                    onClose={handleCloseLotsModal}
                    data={{ medicineName: row.articleName, data: consumptionList }}
                    appId={appId}
                />
            )}
            <EvidenceModal
                data={row}
                isOpen={imagesModal.isOpen}
                reloadDataEvidence={reloadDataEvidence} setReloadDataEvidence={setReloadDataEvidence}
                onClose={() => {
                    if (setReloadDataNotes) {
                        setReloadDataNotes(Math.floor(Math.random() * 90000) + 10000);
                    }
                    setImagesModal((state) => ({ ...state, isOpen: false }));
                }} />
            <ScrollableTable.Row key={row.articleId + trigger + row.cssId}
                style={{ backgroundColor: evidenceMandatory || justificationMandatory }}
            >
                <ScrollableTable.Cell col={2}
                > <span className="text-muted" >{row.articleBarCode ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2}><span className="text-muted">{row.articleName ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={1}><span className="text-muted">{row.lotName ?? "-"}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={2} className="justify-content-center"><span className="text-muted">{row.expirationDate ?? "-"}</span></ScrollableTable.Cell>
                {stage === "surgery" &&
                    <ScrollableTable.Cell className="justify-content-center"><span className="text-muted">{row.qx ?? "-"}</span></ScrollableTable.Cell>
                }
                <ScrollableTable.Cell col={1} className="justify-content-center"><span className="text-muted ">{row.expectedAmount}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={1} className="justify-content-center"><span className="text-muted ">{(row.expectedAmount - row.quantity ).toFixed(2)}</span></ScrollableTable.Cell>
                <ScrollableTable.Cell col={stage === "surgery" ? 2 : 3} key={triggerValue} className="d-flex">
                    <div className="d-flex justify-content-between w-100" key={triggerValue}>
                        <div style={{ width: 80 }} className="text-center align-self-center">
                            {isEditing ?
                                <TextField
                                    type="number"
                                    defaultValue={row.quantity}
                                    onChange={(e) => { setQuantityValue(e.target.value); }}
                                    name="appDate"
                                    style={{ color: "#999", width: "80px" }}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            handleChangedQuantity();
                                        }
                                    }}
                                />
                                :
                                <span className="text-muted text-center">{row.quantity}</span>
                            }
                        </div>
                        <div className="d-flex ">
                            {!isEditing &&
                                <div className="px-2 align-self-center pointer" onClick={async () => {
                                    if (!row.lotName && appId) {
                                        const dataArticles = await dispatch(getConsumptionCenters({
                                            eaccount: eaccount,
                                            appId: appId,
                                            artId: row.articleId,
                                            stagePrefix: ""
                                        }));
                                        setConsumptionList(dataArticles[0]);
                                        setLotsModal(true);
                                    }
                                    else {
                                        setIsEditing(true);
                                        triggerValue = triggerValue + 1;
                                    }
                                }}>
                                    <IconEditCircle width={20} />
                                </div>
                            }
                            {
                                isEditing &&
                                <>
                                    <div className="pointer px-2 text-success align-self-center" onClick={() => {
                                        handleChangedQuantity();
                                    }} >
                                        <IconChecklist width={20} />
                                    </div>
                                    <div className="px-2 text-danger pointer align-self-center"
                                        onClick={() => cancelQuantity()}
                                    >
                                        <IconCircleX width={20} />
                                    </div>
                                </>

                            }
                            {(!isEditing && row.lotId) &&
                            <>
                                <div className="pointer text-primary align-self-center"
                                    onClick={() => {
                                        deleteSupplyFunction({
                                            artId: row.articleId,
                                            cssId: row.cssId,
                                            enabled: 0
                                        });
                                    }}
                                >
                                    <IconTrash />
                                </div> 
                                {showImage &&
                                <div id="evidence" 
                                    onClick={() => {
                                        setImagesModal((state) => ({ ...state, isOpen: true }));
                                    }} 
                                    className="pointer px-2">
                                    <span className=" text-primary"> <IconPhotoUp /></span>
                                </div>
                                }
                                {(row.expectedAmount < row.quantity) &&
                                
                                <div id="justification" onClick={() => {
                                    setJustificationModal({ ...justificationModal, isOpen: true, justification: row.justification });

                                }} className="pointer px-2">
                                    <span className=" text-primary"><IconMessage /></span>
                                </div>
                                
                                }
                            </>

                            }

                        </div>

                    </div>
                </ScrollableTable.Cell>


            </ScrollableTable.Row >
        </>
    );
};

