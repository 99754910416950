type allowedFormats = "jpg" | "jpeg" | "png" | "svg" | "pdf"

// interface IConvertFileBase64 {
//     files: FileList | null,
//     allowedFormats: allowedFormats[]
// }

// export interface IResultConvertFileBase64 {
//     success: boolean
//     message: string
//     data: {
//         filename: string,
//         base64: string,
//         src: string,
//     }
// }
interface IConvertFileBase64 {
    files: FileList | null;
    allowedFormats: allowedFormats[];
}

interface IResultConvertFileBase64 {
    success: boolean;
    message: string;
    data: {
        filename: string;
        base64: string;
        src: string;
    };
}

export const convertMultipleFilesBase64 = ({ files, allowedFormats }: IConvertFileBase64): Promise<IResultConvertFileBase64[]> => {
    const results: IResultConvertFileBase64[] = [];

    if (files?.length) {
        return new Promise<IResultConvertFileBase64[]>((resolve, reject) => {
            const invalidFiles: string[] = [];

            Array.from(files).forEach(file => {
                const type = file.type.split("/")[1];

                if (!allowedFormats.includes(type as allowedFormats)) {
                    invalidFiles.push(file.name);
                } else {
                    const reader = new FileReader();

                    reader.onload = () => {
                        const rst = reader.result as string;
                        const file64 = rst.split(",")[1];

                        results.push({
                            success: true,
                            message: "completed process",
                            data: {
                                filename: file.name,
                                base64: file64,
                                src: URL.createObjectURL(file),
                            },
                        });

                        // Resolve only when all files have been processed
                        if (results.length === files.length) {
                            resolve(results);
                        }
                    };

                    reader.onerror = (error) => {
                        reject({ success: false, message: "Error reading file", error });
                    };

                    reader.readAsDataURL(file);
                }
            });

            // If there are invalid files, reject with a message
            if (invalidFiles.length > 0) {
                reject({ success: false, message: `Formato(s) no permitido(s): ${invalidFiles.join(", ")}`, data: [] });
            }
        });
    }

    return Promise.resolve(results); // If no files are passed
};
