import { Link } from "react-router-dom";
import { useAppSelector } from "@/hooks";
import "./Gate.scss";
import { AllCategory, Module } from "@/models/sidebar";

const roleMapping  = {
    nurse: "de enfermería",
    anesthesiologist: "anestesiólogo",
    default: "médico"
};

export default function Gate() {
    const userRole = useAppSelector(state => state.workspace.profile?.prefijo);
    const { all_categories } = useAppSelector(state => state.category.category);
    const accountingModules = all_categories?.find((cat: AllCategory) => cat?.name === "Historia clínica");
    const modulesAccess = accountingModules?.modules && accountingModules?.modules;
    const renderAccordion = (accordion: Module) => {
        return (
            <div className="accordion-item mb-3 no-user-select" key={`#p_${accordion.id}_k`}>
                <h4 className="accordion-header pointer text-muted fw-bold" data-bs-toggle="collapse" data-bs-target={`#p_${accordion.id}_k`}>
                    {accordion.name}
                </h4>
                <div id={`p_${accordion.id}_k`} className="accordion-collapse collapse" data-bs-parent={`#p_${accordion.id}_k`}>
                    <ul className="medicgate-accordion__list">
                        {
                            accordion.functionalities?.map((option, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={option.url}>
                                            {option.name}
                                        </Link>
                                    </li>
                                );
                            }
                            )
                        }
                    </ul>
                </div>
            </div>
        );
    };

    const render = () => {

        return (
            <div className="medicgate">
                <h1 className="text-primary fw-bold mb-5">
                    Portal {roleMapping[userRole?.toLowerCase() as keyof typeof roleMapping] || roleMapping.default}
                </h1>
                {modulesAccess && modulesAccess.map((item: Module) => renderAccordion(item))}
            </div>
        );
    };

    return render();
}