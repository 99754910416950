import { Button, Modal } from "@/components";
import { finishLoading, startLoading } from "@/config/slices";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { TData } from "@/models";
import { fireWarningAlert } from "@/utils";
import { Dispatch, MouseEvent, SetStateAction, useState } from "react";

interface ClearenceCentralPayload {
    isOpen?: boolean,
    eye?: string | number,
    cvftObservation?: string,
    cvftXCoordinates?: number,
    cvftYCoordinates?: number,
    index?: number,
    cvftId?: number,
    appId:            number,
}

export default function ConfrontationComponentEye({
    payload,
    readOnly,
    isDisabledForm,
    setDataScreening,
    onSaveScreeningDebounce
}: {
    payload: TData;
    readOnly?: boolean;
    isDisabledForm?: boolean;
    setDataScreening?:  Dispatch<SetStateAction<TData>>;
    onSaveScreeningDebounce?: ()=> void
}) {
    const dispatch = useAppDispatch();
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [clearenceCentralModal, setClearenceCentralModal] = useState<ClearenceCentralPayload>({
        isOpen: false,
        eye: "",
        cvftObservation: "",
        cvftXCoordinates: undefined,
        cvftYCoordinates: undefined,
        index: undefined,
        cvftId: undefined,
        appId: appointmentId,
    });


    const onCloseClearenceCentralModal = () => {
        setClearenceCentralModal({
            isOpen: false,
            eye: "",
            cvftXCoordinates: undefined,
            cvftYCoordinates: undefined,
            cvftId: undefined,
            index: undefined,
            cvftObservation: "",
            appId: appointmentId,
        });
    };

    const onOpenClearenceCentralModal = (eye: "od" | "oi", e: MouseEvent) => {
        setClearenceCentralModal({
            ...clearenceCentralModal,
            isOpen: true,
            eye: eye,
            cvftXCoordinates: e?.nativeEvent?.offsetX,
            cvftYCoordinates: e?.nativeEvent?.offsetY
        });
    };

    const onAddAnElementEye = async (isEditing: boolean) => {
        try {
            if (clearenceCentralModal.cvftObservation === "") {
                fireWarningAlert("La observación es obligatoria", "Validar campo");
            } else {
                const tempArr = [...(payload?.confrontationVisualFieldTest ?? [])];
                if (clearenceCentralModal?.index !== undefined) {
                    tempArr[clearenceCentralModal?.index] = {
                        cvftXCoordinates: clearenceCentralModal.cvftXCoordinates,
                        cvftYCoordinates: clearenceCentralModal.cvftYCoordinates,
                        eye: clearenceCentralModal.eye,
                        cvftObservation: clearenceCentralModal.cvftObservation,
                        cvftId: clearenceCentralModal.cvftId,
                        appId: appointmentId
                    };
                }

                const options: TData = {
                    ...payload,
                    confrontationVisualFieldTest: [...(payload?.confrontationVisualFieldTest ?? []), {
                        cvftXCoordinates: clearenceCentralModal.cvftXCoordinates,
                        cvftYCoordinates: clearenceCentralModal.cvftYCoordinates,
                        eye: clearenceCentralModal.eye,
                        cvftObservation: clearenceCentralModal.cvftObservation,
                        appId: appointmentId
                    }]
                };
                if (isEditing) {
                    setIsLoading(true);
                    setDataScreening && setDataScreening((state: TData)=>({...state, confrontationVisualFieldTest: tempArr }));
                    onSaveScreeningDebounce && onSaveScreeningDebounce();

                } else {
                    setIsLoading(true);
                    setDataScreening && setDataScreening((state: TData)=>({...state, ...options }));
                    onSaveScreeningDebounce && onSaveScreeningDebounce();

                }
                onCloseClearenceCentralModal();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onDeleteAnElementEye = async () => {
        try {
            const optionsFilters = payload.confrontationVisualFieldTest?.filter(x=> x.cvftId !== clearenceCentralModal.cvftId );
            dispatch(startLoading());
            setDataScreening && setDataScreening((state: TData)=>({...state, confrontationVisualFieldTest: optionsFilters }));
            onSaveScreeningDebounce && onSaveScreeningDebounce();
            onCloseClearenceCentralModal();
        } finally {
            dispatch(finishLoading());
        }
    };


    const renderModal = () => {
        return (
            <Modal onClose={() => onCloseClearenceCentralModal?.()}
                isOpen={clearenceCentralModal.isOpen}
                positionModal={"center"}
                width={500}
                className="textconfig-modal">
                <div className="p-4">
                    <div className="d-flex justify-content-center my-2">
                        <b className="text-secondary fw-bold fs-2 ms-2 text-center">Observación {String(clearenceCentralModal?.eye).toUpperCase()}</b>
                    </div>
                    <textarea className="form-control"
                        disabled={isDisabledForm}
                        style={{ height: " 150px" }}
                        name="cvftObservation"
                        value={clearenceCentralModal.cvftObservation}
                        onChange={({ target }) => {
                            setClearenceCentralModal?.({ ...clearenceCentralModal, cvftObservation: target.value });
                        }}
                    />
                </div>
                <div className="d-flex align-self-end px-4">
                    {(clearenceCentralModal?.cvftId !== undefined && !isDisabledForm) &&
                        <button
                            className="align-self-end mt-3 mx-2 btn btn-outline-danger"
                            disabled={isDisabledForm}
                            onClick={() => {
                                onDeleteAnElementEye?.();
                            }}
                        >
                            Eliminar
                        </button>
                    }
                    <Button
                        variant="primary"
                        className="align-self-end mt-3"
                        disabled={isDisabledForm}
                        isLoading={isLoading}
                        onClick={() => {
                            onAddAnElementEye?.(clearenceCentralModal?.cvftId !== undefined ? true : false);
                        }}
                    >
                        Guardar
                    </Button>
                </div>
            </Modal>
        );
    };

    const render = () => {
        return (
            <>
                <div className={"d-flex w-100 align-items-center"} style={{ height: 200 }}>
                    <div className="me-4 text-wrap flex-grow-1 justify-content-end d-flex h-100">
                        <div className="d-flex flex-column w-100 h-100" style={{ maxWidth: 350, pageBreakInside: "avoid" }}>
                            <h5 className={`fw-bold ${readOnly ? "text-center" : "text-secondary"}`}>OD</h5>
                            <div
                                className="border rounded overflow-hidden eye"
                                onClick={(e) => {
                                    !isDisabledForm && onOpenClearenceCentralModal("od", e);
                                }}
                                style={{ pointerEvents: readOnly ? "none" : "initial", pageBreakInside: "avoid" }}
                            >
                                {(payload?.confrontationVisualFieldTest && payload?.confrontationVisualFieldTest?.length > 0) &&
                                    payload?.confrontationVisualFieldTest?.map((elem, ind) => {
                                        let tempArr;
                                        if (elem.eye === "od") {
                                            tempArr = <div className="element text-center pointer" key={ind}
                                                style={{
                                                    left: `${elem.cvftXCoordinates}px`,
                                                    top: `${elem.cvftYCoordinates}px`,
                                                    pageBreakInside: "avoid",
                                                }}
                                                onClickCapture={(event) => {
                                                    event.stopPropagation();

                                                    setClearenceCentralModal?.({
                                                        ...clearenceCentralModal, ...elem,
                                                        isOpen: true,
                                                        index: ind,
                                                    });
                                                }}
                                            >
                                                {elem.ord}
                                            </div>;

                                        }
                                        return tempArr;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-wrap flex-grow-1 d-flex flex-column h-100">
                        <div className="d-flex flex-column w-100 h-100" style={{ maxWidth: 350, pageBreakInside: "avoid" }}>
                            <h5 className={`fw-bold ${readOnly ? "text-center" : "text-secondary"}`}>OI</h5>
                            <div
                                className="border rounded overflow-hidden eye"
                                onClick={(e) => {
                                    !isDisabledForm && onOpenClearenceCentralModal("oi", e);
                                }}
                                style={{ pointerEvents: readOnly ? "none" : "initial", pageBreakInside: "avoid" }}
                            >
                                {(payload?.confrontationVisualFieldTest && payload?.confrontationVisualFieldTest?.length > 0) &&
                                    payload?.confrontationVisualFieldTest?.map((elem, ind) => {
                                        let tempArr;
                                        if (elem.eye === "oi") {
                                            tempArr = <div className="element text-center pointer" key={ind}
                                                style={{
                                                    left: `${elem.cvftXCoordinates}px`,
                                                    top: `${elem.cvftYCoordinates}px`,
                                                    pageBreakInside: "avoid",
                                                }}
                                                onClickCapture={(event) => {
                                                    event.stopPropagation();
                                                    setClearenceCentralModal?.({
                                                        ...clearenceCentralModal, ...elem,
                                                        isOpen: true,
                                                        index: ind,
                                                    });
                                                }
                                                }
                                            > {elem.ord}</div>;
                                        }
                                        return tempArr;

                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div >
                {(readOnly && payload?.confrontationVisualFieldTest?.length) ? (
                    <div className="d-flex w-100 align-items-center mt-1">
                        {payload?.confrontationVisualFieldTest?.filter(el => el.eye === "od").length
                            ? (
                                <div className="my-2 ml-4 text-wrap flex-grow-1 ">
                                    <h5>Observaciones OD: </h5>
                                    <ol>
                                        {payload?.confrontationVisualFieldTest?.filter(el => el.eye === "od").map(item => (
                                            <li key={item.cvftId}>{item.cvftObservation}</li>
                                        ))}
                                    </ol>
                                </div>
                            )
                            : ""}
                        {payload?.confrontationVisualFieldTest?.filter(el => el.eye === "oi").length
                            ? (
                                <div className="my-4 text-wrap flex-grow-1 ">
                                    <h5>Observaciones OI: </h5>
                                    <ol>
                                        {payload?.confrontationVisualFieldTest?.filter(el => el.eye === "oi").map(item => (
                                            <li key={item.cvftId}>{item.cvftObservation}</li>
                                        ))}
                                    </ol>
                                </div>
                            )
                            : ""}
                    </div>
                ) : ""}
                {renderModal()}
            </>
        );
    };

    return render();
}