import React from "react";

interface IColorDetailCard {
    title: string;
    value: string;
    overrideColor: React.CSSProperties;
    className?: string;
}

export const ColorDetailCard = ({ value, overrideColor, className, title }: IColorDetailCard) => {
    return (
        <div
            style={overrideColor ?? {}}
            className={`${
                className ?? ""
            } p-1 px-2 text-[13px] overflow-hidden rounded grid grid-cols-[100px_1fr] gap-x-4 py-4`}
        >
            <div 
                className='flex items-center'
            >
                <span className="fw-bold">{title}: </span>
                <span style={{color: "gray"}}>{value}</span>
            </div>
        </div>
    );
};

