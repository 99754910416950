import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { DiagnosticAidsParams, DiagnosticAidsResponse, DiagnosticAidsSheetPayload, DoctorListParams, DoctorListResponse } from "@/models/sheets/diagnosticAids";
import { setHeaders } from "@/utils";

export async function getDiagnosticAidsService(params: DiagnosticAidsParams): Promise<DiagnosticAidsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DiagnosticAidsResponse>(
        "/medical/clinicHistory/diagnosticAids/", axiosParams
    );
    return serviceData;
}

export async function updateDiagnosticAidsService(payload: DiagnosticAidsSheetPayload): Promise<IResponse> {
    const headers = setHeaders();

    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/diagnosticAids/", payload, axiosParams
    );
    return serviceData;
}

export async function deletePressureDiagnosticAidsService(payload: DiagnosticAidsSheetPayload): Promise<IResponse> {
    const headers = setHeaders();

    const axiosParams = { headers, data: payload }; 
    const { data: serviceData } = await axiosInstance.delete<IResponse>(
        "/medical/clinicHistory/diagnosticAids/", axiosParams
    );
    return serviceData;
}
export async function getReadingService(payload: DiagnosticAidsParams): Promise<DiagnosticAidsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<DiagnosticAidsResponse>(
        "/medical/clinicHistory/startExamDetailsByReading/", payload, axiosParams
    );
    return serviceData;
}

export async function updateReadingService(payload: DiagnosticAidsSheetPayload): Promise<IResponse> {
    const headers = setHeaders();

    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/examDetailsByReading/", payload, axiosParams
    );
    return serviceData;
}

export async function getDoctorListService(params: DoctorListParams): Promise<DoctorListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DoctorListResponse>(
        "/medical/clinicHistory/doctorsAvailableToRead/", axiosParams
    );
    return serviceData;
}
