import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { FinishAppointmentPayload } from "@/models";
import { finishAppointmentDiagnosticAidsService, finishAppointmentService } from "@/services";

export function finishAppointment(payload: FinishAppointmentPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await finishAppointmentService(payload);
        return response.success;
    };
}
export function finishAppointmentDiagnosticAids(payload: FinishAppointmentPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading()); 
            const response = await finishAppointmentDiagnosticAidsService(payload);
            return response.success;
        } finally { 
            dispatch(finishLoading());
        }
    };
}