import { useState } from "react";
import dayjs from "dayjs";
import { IconSend, IconTrash } from "@tabler/icons-react";
//
import { Comment } from "@/models/comment";
import { IModal } from "@/components/Modal/modal";
//
import { useAppSelector } from "@/hooks/redux";
import { Modal } from "@/components";

import "../Consultation.scss";

interface CommentsProps<T> extends IModal<T> {
    onCreateComment: (value: string) => void;
    onDeleteComment: (id: string) => void;
}

export default function Comments({
    data: comments,
    onSuccess,
    onCreateComment,
    onDeleteComment,
    ...rest
}: CommentsProps<Comment[]>) {
    const user = useAppSelector(state => state.auth.user_data);

    const [userComment, setUserComment] = useState<string>("");

    const handleSubmitComment = async (): Promise<void> => {
        onCreateComment(userComment);
        setUserComment("");
    };

    const renderCommentsDrawer = () => {
        return (
            <>
                <h2 className="text-primary fw-bold align-self-center">Comentarios</h2>

                <div className="services-list mt-3 flex-grow-1">
                    {comments?.map((item, index) => (
                        <div key={index} className="services-list__item">
                            <div className="mb-1 d-flex align-items-center justify-content-between">
                                <span className="text-secondary fw-bold">{item.userName}</span>
                                {item.userId === user.id && (
                                    <span className="text-muted pointer" onClick={() => onDeleteComment(`${item.id}`)}>
                                        <IconTrash size={18} />
                                    </span>
                                )}
                            </div>
                            <p>{item.comment}</p>
                            <span className="text-secondary">{dayjs(item.date).format("DD/MM/YYYY hh:mm A")}</span>

                        </div>
                    ))}
                </div>
                <div className="section-comments">
                    <span className="text-primary">{user.first_name} {user.first_surname}</span>
                    <textarea
                        className="form-control"
                        placeholder="Escribir..."
                        value={userComment}
                        onChange={({ target }) => {
                            setUserComment(target.value);
                        }}
                    />
                    <div className="text-secondary align-self-end mt-3 pointer" onClick={handleSubmitComment}>
                        <IconSend />
                    </div>
                </div>
            </>
        );
    };

    const render = () => {
        return (
            <Modal {...rest}>
                {renderCommentsDrawer()}
            </Modal>
        );
    };

    return render();
}