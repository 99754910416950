import { useEffect, useState } from "react";
import { IconCheck, IconCirclePlus, IconSearch, IconX } from "@tabler/icons-react";
import { Button, Modal, Select, Table, TextField } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { useAppDispatch, useAppSelector } from "@/hooks";
import "../../Workspace/PatientAttention/Consultation/Oftalmology/TextConfig/TextConfig.scss";
import { MedicalConceptsPayload, RiskColor, medicalConcepts } from "@/models/transcription/transcription";
import { deleteMedicalConcept, getRiskColor, updateMedicalConcept } from "./Transcription.actions";
import { IconEdit } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";

interface ITextConfigModal extends IModal {
    onSelectItem?: (current: string, item: { label: string; value: number; color: string; }, index: number) => void;
    current: string;
    dataArray: medicalConcepts[];
    srvId?: number;
}

export default function ConceptsModal({ onClose, onSelectItem, current, setSearchValue, setTriggerConcepts, triggerConcepts, srvId, ...rest }: ITextConfigModal) {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);

    const [selectedText, setSelectedText] = useState<MedicalConceptsPayload>({
        "name": "",
        "riskId": null,
        "srvId": srvId ?? null,
        "userId": userId ?? null,
        "id": null,
        color: "",
        nameTemp: ""

    });
    const [riskColorList, setRiskColorList] = useState<RiskColor[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen) {
                const data = await dispatch(getRiskColor(
                    accountId
                ));
                setRiskColorList(data);

            }
        }
        fetchData();
    }, [dispatch, accountId, userId, current, currentSheet, rest.isOpen]);



    const handleCloseModal = () => {
        setSelectedText({
            "name": "",
            "riskId": null,
            "srvId": srvId ?? null,
            "userId": userId ?? null,
            "id": null,
            color: "",
            nameTemp: "",
        });
        setIsEditing(false);
        setSearchValue && setSearchValue("");
        // setrest?.dataArray(undefined);
        onClose();
    };



    const handleSearch = (value: string): void => setSearchValue && setSearchValue(value ?? "");

    const handleSelectText = (text: medicalConcepts) => {
        setSelectedText({
            "name": text.name,
            "riskId": text.riskId,
            "srvId": text.srvId,
            "userId": userId,
            "id": text.id,
            color: text.risk.color,
            nameTemp: text.name,

        });
    };

    const deleteMedicalConceptFunction = () => {

        dispatch(deleteMedicalConcept({
            "id": selectedText.id ?? null,
            "enabled": 0,
        }, () => {
            setTriggerConcepts && setTriggerConcepts((triggerConcepts ?? 0) + 1);
            setSelectedText({
                "name": "",
                "riskId": null,
                "srvId": srvId ?? null,
                "userId": userId ?? null,
                "id": null,
                color: "",
                nameTemp: "",
            });
        },
        ));


    };

    const saveConcept = () => {
        const data = { ...selectedText, name: selectedText.nameTemp ?? "" };
        dispatch(updateMedicalConcept(data, () => {
            setSelectedText(data);
            setIsEditing(false);
            setTriggerConcepts && setTriggerConcepts((triggerConcepts ?? 0) + 1);
        },
        () => {
            setSelectedText({
                "name": "",
                "riskId": null,
                "srvId": srvId ?? null,
                "userId": userId ?? null,
                "id": null,
                color: "",
                nameTemp: "",
            });
        }
        ));

    };

    const renderSelectedText = (item: MedicalConceptsPayload) => {
        const riskColorOptions = riskColorList && riskColorList.map(concept => ({
            label: concept.name,
            value: concept.id,
            color: concept.color
        }));
        return (
            <div className="flex-grow-1" >
                <div className="d-flex justify-content-end">
                    <div className="">
                        <Button
                            variant="outline"
                            className=""
                            endIcon={<IconCirclePlus />}
                            onClick={() => {
                                setIsEditing(true);
                                setSelectedText({
                                    ...selectedText,
                                    "name": "",
                                    "riskId": null,
                                    "id": null,
                                    color: "",
                                    nameTemp: "",
                                });
                            }}
                        >
                            Agregar
                        </Button> </div>
                </div>
                <div className="textarea__with-header mt-3">
                    <div className="textarea-header">
                        <span>Descripción</span>
                    </div>
                    <textarea
                        value={item.nameTemp}
                        style={{ color: item.color }}
                        onChange={({ target }) => setSelectedText({ ...selectedText, nameTemp: target.value ?? null })}
                        rows={6}
                        readOnly={!isEditing}
                    />
                </div>
                {selectedText.name !== "" && !isEditing &&
                    <div className="d-flex justify-content-end ">
                        <Button
                            variant="text"
                            className="px-0"
                            endIcon={<IconEdit />}
                            onClick={() => {
                                setIsEditing(true);
                            }}
                        >
                        </Button>
                        <Button
                            variant="text"
                            endIcon={<IconTrash />}
                            onClick={() => {
                                deleteMedicalConceptFunction();
                            }}
                        >
                        </Button>
                    </div>
                }
                {isEditing &&
                    <div className="mt-3 row">
                        <div className="col-10">
                            <Select
                                variant="normal"
                                placeholder="Seleccionar..."
                                options={riskColorOptions}
                                emptyOptionsLabel="No hay artículos disponibles"
                                optionColor
                                // key={trigger}
                                value={riskColorOptions.find(risk => risk.value === selectedText.riskId) ?? { label: "", value: "" }}
                                onChange={({ option }) => setSelectedText({ ...selectedText, riskId: option.value ?? null, color: option?.color })}
                            // disabled={isDisabledForm}
                            />

                        </div>
                        <div className="d-flex justify-content-center align-self-center mt-2 px-0 col-2">
                            <IconCheck
                                className="mb-1 text-success pointer"
                                onClick={saveConcept}
                            />
                            <IconX
                                className="mb-1 ms-1 pointer text-danger"
                                onClick={() => {
                                    setIsEditing(false);
                                    setSelectedText({ ...selectedText, nameTemp: selectedText.name });
                                    // setDisabledSubstance(true);
                                    // setNewMedicinePayload({
                                    //     ...newMedicinePayload,
                                    //     chemicalSubstance: "",
                                    //     name: "",
                                    //     id: undefined
                                    // });
                                }}
                            />
                        </div>
                    </div>
                }


            </div>
        );
    };

    const renderRow = (item: medicalConcepts, index: number) => {
        return (
            <tr key={item.id}>
                <td
                    style={{ width: "100%" }}
                    onClick={() => {
                        handleSelectText(item);
                        setIsEditing(false);
                    }}
                >
                    <span style={{ color: item?.risk?.color }}>
                        {item.name}
                    </span>
                </td>
                <td>

                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <Table className="textconfig-modal__table-wrapper" isCompact>
                <thead>
                    <tr>
                        <th style={{ width: "100%" }}>Descripción</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ maxHeight: 250 }}>
                    {rest?.dataArray?.length
                        ? rest?.dataArray?.map((item, index) => renderRow(item, index + 1))
                        : (
                            <tr>
                                <td className="text-center" style={{ width: "100%" }}>
                                    No hay conceptos médicos disponibles
                                </td>
                            </tr>
                        )}
                </tbody>
            </Table>
        );
    };

    const render = () => {
        return (
            <Modal onClose={handleCloseModal} {...rest} positionModal="center">
                <h2 className="fw-bold text-secondary">
                    Conceptos médicos
                </h2>
                <div className="row gx-5 w-100 mt-3">
                    <div className="col">
                        <TextField
                            className="w-100 mb-3"
                            variant="filled"
                            endIcon={<IconSearch />}
                            placeholder="Escribe código o descripción..."
                            onChange={({ target }) => handleSearch(target.value)}
                        />
                        {renderTable()}
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="text-muted fw-bold">Total</span>
                            <span className="text-muted">{rest?.dataArray?.length}</span>
                            <div></div>
                        </div>
                    </div>
                    <div className="col d-flex flex-column">
                        {selectedText ? renderSelectedText(selectedText) : (
                            <h3 className="text-muted text-center mt-1">
                                Seleccione un concepto médico
                            </h3>
                        )}
                    </div>
                </div>
            </Modal >
        );
    };

    return render();
}