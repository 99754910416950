import { DilatationBody, IVitalSigns, TData } from "@/models";
import { Segments } from "@/models/generalFields";
import { Preconsulting } from "@/models/generalHistory/generalHistory";
import { formatHistoryDetails } from "@/utils";
import ConfrontationComponentEye from "../../../PreConsultation/Tabs/ConfrontationComponentEye";
import "../Details.scss";

export default function PreconsultDetails({ preconsultingDetail }: { preconsultingDetail: Preconsulting; }) {

    const renderAttentionOrigin = (attentionOrigin: Segments | null) => {

        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin?.generalFields?.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin?.generalFields?.attentionOrigin?.name}
                        </h5>
                    ) : ""}
                    {attentionOrigin.generalFields.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.generalFields.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}
                    {attentionOrigin?.reasonForConsultingJst ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Motivo consulta:
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.reasonForConsultingJst}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin?.currentDiseaseJst ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Enfermedad actual:
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.currentDiseaseJst || ""}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin.generalFields.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.generalFields.evolutionTime}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderDilation = (dilation: DilatationBody[] | null) => {
        if (dilation?.length) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Dilatación
                    </h4>

                    <h5 className=" fw-bold mt-4">
                        Registro de dilatación
                    </h5>
                    <table className="table table-bordered mt-2 mb-4" style={{ maxWidth: 300 }}>
                        <thead>
                            <tr className="text-secondary">
                                <th>Medicamentos</th>
                                <th>Gotas</th>
                                <th>Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dilation?.map(dil => (
                                <tr>
                                    <td>{dil.artDescription}</td>
                                    <td>{dil.qty}</td>
                                    <td>{dil.time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            );
        } else return "";
    };

    const renderVitalSigns = (vitalSigns: IVitalSigns) => {
        if (vitalSigns) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Signos vitales
                    </h4>
                    {vitalSigns.bloodPressureSystolic ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Tensión arterial sistólica: </span>{" "}
                            {vitalSigns.bloodPressureSystolic}
                        </h5>
                    ) : ""}
                    {vitalSigns.bloodPressureDiastolic ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Tensión arterial diastólica: </span>{" "}
                            {vitalSigns.bloodPressureDiastolic}
                        </h5>
                    ) : ""}
                    {vitalSigns.heartRate ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Frecuencia cardiaca: </span>{" "}
                            {vitalSigns.heartRate}
                        </h5>
                    ) : ""}
                    {vitalSigns.breathingFrequency ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Frecuencia respiratoria: </span>{" "}
                            {vitalSigns.breathingFrequency}
                        </h5>
                    ) : ""}
                    {vitalSigns.temperature ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Temperatura: </span>{" "}
                            {vitalSigns.temperature}
                        </h5>
                    ) : ""}
                    {vitalSigns.bodyWeight ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Peso corporal: </span>{" "}
                            {vitalSigns.bodyWeight}
                        </h5>
                    ) : ""}
                    {vitalSigns.height ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Estatura: </span>{" "}
                            {vitalSigns.height}
                        </h5>
                    ) : ""}
                    {vitalSigns.notes ? (
                        <h5 className=" mb-2">
                            <span className="fw-bold">Notas: </span>{" "}
                            {vitalSigns.notes}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderCentralClearence = (centralClearance: TData | null) => {
        if (centralClearance) {
            return (
                <div>
                    <hr className=" mb-3" />

                    <div className="">
                        <h4 className="text-primary fw-bold mb-3">
                        Campo visual por confrontación                        </h4>
                        <div className="">

                            <ConfrontationComponentEye
                                payload={centralClearance}
                                readOnly
                            />
                        </div>
                    </div>


                </div>
            );
        } else return "";
    };

    const renderOcularSiftning = (ocularSiftning: TData | null) => {
        if (ocularSiftning) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Tamizaje ocular
                    </h4>
                    <div className="mb-2">
                        <h5 className="fw-bold">Toma de visión</h5>
                        <h5>
                            {ocularSiftning.visionShootOdName ? (
                                <>
                                    <span className="fw-bold">OD: </span>
                                    {ocularSiftning.visionShootOdName}
                                </>
                            ) : ""}
                            {(ocularSiftning.visionShootOdName && ocularSiftning.visionShootOiName) ? " / " : ""}
                            {ocularSiftning.visionShootOiName ? (
                                <>
                                    <span className="fw-bold">OI: </span>
                                    {ocularSiftning.visionShootOiName}
                                </>
                            ) : ""}
                        </h5>
                    </div>
                    <div className="mb-3">
                        <h5 className=" fw-bold">Paquimetría</h5>
                        <h5>
                            {ocularSiftning.pachymetryOi ? (
                                <>
                                    <span className="fw-bold">OI: </span>
                                    {ocularSiftning.pachymetryOi}
                                </>
                            ) : ""}
                            {(ocularSiftning.pachymetryOi && ocularSiftning.pachymetryOd) ? " / " : ""}
                            {ocularSiftning.pachymetryOd ? (
                                <>
                                    <span className="fw-bold">OD: </span>
                                    {ocularSiftning.pachymetryOd}
                                </>
                            ) : ""}
                        </h5>
                        <h5>
                            {ocularSiftning.pachymetryOi ? (
                                <>
                                    <span className="fw-bold">OI Corrección: </span>
                                    {ocularSiftning.pachymetryCorrectionOi}
                                </>
                            ) : ""}
                            {(ocularSiftning.pachymetryOi && ocularSiftning.pachymetryOd) ? " / " : ""}
                            {ocularSiftning.pachymetryOd ? (
                                <>
                                    <span className="fw-bold">OD Corrección: </span>
                                    {ocularSiftning.pachymetryCorrectionOd}
                                </>
                            ) : ""}
                        </h5>
                    </div>

                    <div>
                        <h5 className="fw-bold">Presión intraocular</h5>
                        <h5 className="mb-2">
                            {ocularSiftning.intraocularPressureUncorrectedOi ? (
                                <>
                                    <span className="fw-bold">PIO OI: </span>
                                    {ocularSiftning.intraocularPressureUncorrectedOi}
                                </>
                            ) : ""}
                            {(ocularSiftning.intraocularPressureUncorrectedOd && ocularSiftning.intraocularPressureUncorrectedOi) ? " / " : ""}
                            {ocularSiftning.intraocularPressureUncorrectedOd ? (
                                <>
                                    <span className="fw-bold">PIO OD: </span>
                                    {ocularSiftning.intraocularPressureUncorrectedOd}
                                </>
                            ) : ""}
                        </h5>
                        <h5>
                            {ocularSiftning.correctedIntraocularPressureOi ? (
                                <>
                                    <span className="fw-bold">PIO Ajustada OI: </span>
                                    {ocularSiftning.correctedIntraocularPressureOi}
                                </>
                            ) : ""}
                            {(ocularSiftning.correctedIntraocularPressureOi && ocularSiftning.correctedIntraocularPressureOd) ? " / " : ""}
                            {ocularSiftning.correctedIntraocularPressureOd ? (
                                <>
                                    <span className="fw-bold">PIO Ajustada OD: </span>
                                    {ocularSiftning.correctedIntraocularPressureOd}
                                </>
                            ) : ""}
                        </h5>
                    </div>

                </div>
            );
        } else return "";
    };

    const renderSiftningPictures = (ocularSiftning: TData | null) => {
        if (ocularSiftning?.backgroundEyeOiUrl || ocularSiftning?.octRetinaOiUrl || ocularSiftning?.octOpticNerveOiUrl) {
            return (
                <div className="row text-muted">
                    <div className="col-12 mb-3">
                        <hr className=" mb-3" />
                        <h4 className="text-primary fw-bold mb-3">
                            Fotos tamizaje
                        </h4>
                        <div className="row">
                            {ocularSiftning?.backgroundEyeOiUrl &&
                                <div className="col">
                                    <div className="border p-3">
                                        <h5 className=" fw-bold">
                                            Foto fondo ojo
                                        </h5>
                                        <div className="row">
                                            {ocularSiftning?.backgroundEyeOiUrl ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OI</div>
                                                    <img
                                                        src={ocularSiftning.backgroundEyeOiUrl}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                            {ocularSiftning?.backgroundEyeOdUrl ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OD</div>
                                                    <img
                                                        src={ocularSiftning?.backgroundEyeOdUrl}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            }
                            {ocularSiftning?.octRetinaOiUrl &&
                                <div className="col">
                                    <div className="border p-3">
                                        <h5 className=" fw-bold">
                                            Tomografía óptica coherente de retina
                                        </h5>
                                        <div className="row">
                                            {ocularSiftning?.octRetinaOiUrl ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OI</div>
                                                    <img
                                                        src={ocularSiftning?.octRetinaOiUrl}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                            {ocularSiftning?.octRetinaOdUrl ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OD</div>
                                                    <img
                                                        src={ocularSiftning?.octRetinaOdUrl}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            }
                            {ocularSiftning?.octOpticNerveOiUrl &&
                                <div className="col">
                                    <div className="border p-3">
                                        <h5 className=" fw-bold">
                                            Tomografía óptica coherente nervio óptico
                                        </h5>
                                        <div className="row">
                                            {ocularSiftning?.octOpticNerveOiUrl ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OI</div>
                                                    <img
                                                        src={ocularSiftning?.octOpticNerveOiUrl}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                            {ocularSiftning?.octOpticNerveOdUrl ? (
                                                <div className="col">
                                                    <div className="fw-bold ">OD</div>
                                                    <img
                                                        src={ocularSiftning?.octOpticNerveOdUrl}
                                                        className="img-fluid"
                                                        width={250}
                                                        style={{ objectFit: "contain" }}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }; 



    const render = () => {
        const attentionOrigin = formatHistoryDetails(preconsultingDetail.generalsFields ? preconsultingDetail.generalsFields[0] : {});
        const dilatation = preconsultingDetail?.dilation?.dilations.results ?? [];
        const vitalSigns = formatHistoryDetails(preconsultingDetail.vitalSigns);
        const ocularSifting = formatHistoryDetails(preconsultingDetail.ocularSifting);
        const central = preconsultingDetail.ocularSifting ?? null;

        return (
            <div className="row overflow-auto">
                <div className="detail-container">
                    <div className="columns">
                        {renderAttentionOrigin(attentionOrigin)}
                        {renderDilation(dilatation)}
                        {renderVitalSigns(vitalSigns)}
                        {renderOcularSiftning(ocularSifting)}
                    </div>
                </div>
                {renderSiftningPictures(ocularSifting)}
                {renderCentralClearence(central)}
            </div>
        );
    };

    return render();
}