import { SectionCard, Select } from "@/components";
import { useAppDispatch } from "@/hooks";
import { IdentifiedLetter } from "@/models/select/indentifiedLetter";
import { OptotypeItem } from "@/models/select/optotype";
import { Vision } from "@/models/select/vision";
import { GeneralSelects, ObjectiveRefractionEye } from "@/models/shared/objectiveRefraction";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { OptometryPayload } from "@/models/sheets/optometry";
import { ChangeEvent, useEffect, useState } from "react";
import { getSelects } from "./ObjectiveRefraction.actions";

interface SubjectiveRefractionParams {
    externalPayload: OptometryPayload | LowVisionPayload;
    setExternalPayload: (visualPayload: OptometryPayload | LowVisionPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    historySheet?: boolean;
    title?: string;
    prefix?: string;
}

const ObjectiveRefraction = ({ externalPayload, setExternalPayload, onSubmit, isDisabledForm, historySheet, prefix, title }: SubjectiveRefractionParams) => {
    const dispatch = useAppDispatch();
    const [generalSelects, setGeneralSelects] = useState<GeneralSelects>({
        optotypes: [],
        farVision: [],
        nearVision: [],
        idLetters: [],
        lensAdd: [],
        lensType: []
    });
    const formattedAddSelect = generalSelects.lensAdd.map(sel => ({
        label: sel.value,
        value: sel.id
    }));

    const startKey = prefix === "cicloplejia" ? externalPayload.objectiveRefractionCycloplegia :  externalPayload.objectiveRefraction;

    const odAddValue = formattedAddSelect.find(sel => sel.value === startKey?.od?.add);
    const odAddValueInput = startKey?.od?.add;
    const oiAddValue = formattedAddSelect.find(sel => sel.value === startKey?.oi?.add);
    const oiAddValueInput = startKey?.oi?.add;
    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {

                const data = await dispatch(getSelects());
                setGeneralSelects({
                    optotypes: data?.optotypesResponse as OptotypeItem[],
                    farVision: data?.farVisionResponse as Vision[],
                    nearVision: data?.nearVisionResponse as Vision[],
                    idLetters: data?.idLettersResponse as IdentifiedLetter[],
                    lensAdd: data?.lensAddResponse as Vision[],
                    lensType: data?.lensTypeResponse as Vision[]
                });
            }
        }
        fetchData();
    }, [dispatch]);

    const onChangeORefractionValue = (eye: "od" | "oi", label: keyof ObjectiveRefractionEye, value: string | number) => {
        const key = prefix === "cicloplejia" ? "objectiveRefractionCycloplegia"   :"objectiveRefraction" ;
        const options: OptometryPayload = {
            ...externalPayload,
            [key]: {
                ...startKey,
                [eye]: {
                    ...startKey?.[eye],
                    [label]: value
                }
            }
        };
        setExternalPayload(options);
        onSubmit();
    }; 

    const onChangeRefractionObservation = (refraction: "objectiveRefraction" | "subjectiveRefraction", value: string) => {

        let key:  "objectiveRefraction" | "subjectiveRefraction" | "subjectiveRefractionCycloplegia" | "objectiveRefractionCycloplegia" ;
        if(refraction === "objectiveRefraction"){
            key = prefix === "cicloplejia" ?  "objectiveRefractionCycloplegia" : "objectiveRefraction";
        }else{
            key = prefix === "cicloplejia" ? "subjectiveRefractionCycloplegia" : "subjectiveRefraction"  ; 
        }

        if(key){
            const options: OptometryPayload = {
                ...externalPayload,
                [key]: {
                    ...externalPayload[key],
                    observations: value
                }
            };
            setExternalPayload(options);
            onSubmit(); 
        }

    };

    const validateInputNumber = (
        event: ChangeEvent<HTMLInputElement>, 
        eye: "od" | "oi", 
        label: keyof ObjectiveRefractionEye
    ) => {
        const regex = /^(^(\+|-)[0-9]{1,2}\.[0-9]{2}|)$/;

        if (event.target.value.length > 0 && !(regex).test(event.target.value)) {
            event.target.style.borderColor = "red";
            event.preventDefault();
        } else {
            event.target.style.borderColor = "#ced4da";
            onChangeORefractionValue(
                eye,
                label,
                event.target.value
            );
        }
    };


    return (
        <SectionCard>
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="text-secondary fw-bold">{title ? title : "Autorefracto sin cicloplejía"}</h5> 
            </div>
            <hr className="text-primary mt-2" />
            <table>
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td className="text-secondary" align="center">Esfera</td>
                        <td className="text-secondary" align="center">Cilindro</td>
                        <td className="text-secondary" align="center">Eje°</td>
                        <td className="text-secondary" align="center">ADD</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="py-1 ps-2 text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                        <td className="p-2 bg-od" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                defaultValue={startKey?.od?.sphere}
                                onChange={(event) => validateInputNumber(event, "od", "sphere")}
                            />
                        </td>
                        <td className="p-2 bg-od" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                defaultValue={startKey?.od?.cylinder}
                                onChange={(event) => validateInputNumber(event, "od", "cylinder")}
                            />
                        </td>
                        <td className="p-2 bg-od" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.od?.axis}
                                onChange={({ target }) => onChangeORefractionValue("od", "axis", target.value)}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }} width={150}>
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={odAddValueInput}
                                options={formattedAddSelect}
                                value={odAddValue}
                                onChange={({ option }) => onChangeORefractionValue("od", "add", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="py-1 ps-2 text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                        <td className="p-2 bg-oi" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                defaultValue={startKey?.oi?.sphere}
                                onChange={(event) => validateInputNumber(event, "oi", "sphere")}
                            />
                        </td>
                        <td className="p-2 bg-oi" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="text"
                                className="form-control"
                                defaultValue={startKey?.oi?.cylinder}
                                onChange={(event) => validateInputNumber(event, "oi", "cylinder")}
                            />
                        </td>
                        <td className="p-2 bg-oi" width={150}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={startKey?.oi?.axis}
                                onChange={({ target }) => onChangeORefractionValue("oi", "axis", target.value)}
                                onKeyDown={(event) => event.key === "." && event.preventDefault()}
                            />
                        </td>
                        <td className="p-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }} width={150}>
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={oiAddValueInput}
                                options={formattedAddSelect}
                                value={oiAddValue}
                                onChange={({ option }) => onChangeORefractionValue("oi", "add", option.value)}
                                style={{ width: "100%" }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2" colSpan={6}>
                            <textarea
                                className="form-control no-resize"
                                disabled={isDisabledForm}
                                style={{ height: 40 }}
                                value={startKey?.observations}
                                onChange={({ target }) => onChangeRefractionObservation("objectiveRefraction", target.value)}
                                placeholder="Observaciones..."
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </SectionCard>
    );
};

export default ObjectiveRefraction;
