import { Modal } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { ColorDetailCard } from "./ColorDetailCard";

const ColorDetailDrawer = ({ ...res }: IModal) => {
    return (
        <Modal {...res}>
            <div className="d-flex w-100 h-100 flex-column align-items-start">
                <div className="flex-fill w-100 mt-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <h1 className="text-secondary fw-bold">Detalle de colores</h1>
                    </div>
                    <div className="row w-100 mt-5 mb-2">
                        <ColorDetailCard
                            key={"admitted"}
                            className='m-auto mb-6'
                            title="Admitida"
                            value="Cita admitida, pendiente por atención en tamizaje o estudios"
                            overrideColor={{ color: "#f66a6a", background: "#febaba" }}
                        />
                    </div>
                    <div className="row w-100 mb-2">
                        <ColorDetailCard
                            key={"optometry"}
                            className='m-auto mb-6'
                            title="Tamizaje"
                            value="Paciente está siendo atendido por el técnico"
                            overrideColor={{ color: "#ff26a7", background: "#ffc4e8" }}
                        />
                    </div>
                    <div className="row w-100 mb-2">
                        <ColorDetailCard
                            key={"optometry"}
                            className='m-auto mb-6'
                            title="Estudios"
                            value="Paciente está siendo atendido en estudios e imágenes"
                            overrideColor={{ color: "#800080", background: "#ebdaf3" }}
                        />
                    </div>
                    <div className="row w-100 mb-2">
                        <ColorDetailCard
                            key={"attended"}
                            className='m-auto mb-6'
                            title="Atención médica"
                            value="Paciente está listo para ser atendido por el médico olfálmico"
                            overrideColor={{ color: "#83C036", background: "#e2feba" }}
                        />
                    </div>
                    <div className="row w-100 mb-2">
                        <ColorDetailCard
                            key={"attended"}
                            className='m-auto mb-6'
                            title="Atención exitosa"
                            value="Paciente atendido exitosamente"
                            overrideColor={{ color: "#01A0F6", background: "#E6F6FF" }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ColorDetailDrawer;
