import { Outlet } from "react-router-dom";

export default function Template() {

    const render = () => {
        return (
            <div className="app">
                <Outlet />
            </div>
        );
    };

    return render();
}