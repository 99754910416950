import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getEyesLateralityService, getHandsLateralityService } from "@/services";
import { useEffect, useState } from "react";

interface LateralityParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    historySheet?: boolean;
}

export default function Laterality({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm, historySheet }: LateralityParams) {
    const [eyesLaterality, setEyesLaterality] = useState<CommonSelect[]>([]);
    const [handsLaterality, setHandsLaterality] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const dataE = await getEyesLateralityService();
                const dataH = await getHandsLateralityService();

                setEyesLaterality(dataE.results);
                setHandsLaterality(dataH.results);
            }
        }
        fetchData();
    }, []);

    const onChangeLaterality = (laterality: "eylId" | "halId", value: number) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            laterality: {
                ...orthopticPayload.laterality,
                [laterality]: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const eyesLateralityFormatted = eyesLaterality.map(item => ({ label: item.description, value: item.id }));
        const handsLateralityFormatted = handsLaterality.map(item => ({ label: item.description, value: item.id }));

        const eyesLateralityValue = eyesLateralityFormatted.find(item => item.value === orthopticPayload.laterality?.eylId);
        const eyesLateralityValueInput = orthopticPayload.laterality?.eye;
        const handsLateralityValue = handsLateralityFormatted.find(item => item.value === orthopticPayload.laterality?.halId);
        const handsLateralityValueInput = orthopticPayload.laterality?.hand;

        return (
            <SectionCard className="h-100">
                <h5 className="text-label fw-bold">Lateralidad</h5>
                <hr className="text-primary mt-2 mb-2" />
                <div className="d-flex align-items-center mt-3">
                    <span style={{ width: 180 }} className="nowrap text-secondary">Ojo dominante</span>
                    <Select
                        disabled={isDisabledForm}
                        changeToInput={historySheet}
                        inputValue={eyesLateralityValueInput}
                        width="100%"
                        style={{ width: "100%" }}
                        options={eyesLateralityFormatted}
                        value={eyesLateralityValue}
                        onChange={({ option }) => onChangeLaterality("eylId", option.value)}
                    />
                </div>
                <div className="d-flex mt-3">
                    <span style={{ width: 180 }} className="nowrap text-secondary">Mano dominante</span>
                    <Select
                        disabled={isDisabledForm}
                        changeToInput={historySheet}
                        inputValue={handsLateralityValueInput}
                        width="100%"
                        style={{ width: "100%" }}
                        options={handsLateralityFormatted}
                        value={handsLateralityValue}
                        onChange={({ option }) => onChangeLaterality("halId", option.value)}
                    />
                </div>
            </SectionCard>
        );
    };

    return render();
}