import { axiosInstance } from "@/config/axios";
import * as HistoryModels from "@/models/history";
import { AdaptationDetailResponse } from "@/models/historyDetails/adaptation";
import { AnesthesiaNotesResponse } from "@/models/historyDetails/anesthesiaNotes";
import { BackgroundDetailResponse } from "@/models/historyDetails/background";
import { CheckListResponse } from "@/models/historyDetails/checkList";
import { DiagnosticAdxDetailsParams, DiagnosticAdxDetailsResponse } from "@/models/historyDetails/diagnosticAdxDetails";
import { AHDIDetailResponse } from "@/models/historyDetails/diagnosticImpression";
import { AttentionDownloadResponse, ExternalOptionsResponse } from "@/models/historyDetails/download";
import { LowVisionDetailResponse } from "@/models/historyDetails/lowVision";
import { NurseryNotesDetailResponse } from "@/models/historyDetails/nurseryDetails";
import { OphthalmologyDetailResponse } from "@/models/historyDetails/ophthalmology";
import { OptometryDetailResponse } from "@/models/historyDetails/optometry";
import { OrderDetailResponse } from "@/models/historyDetails/orders";
import { OrthopticDetailResponse } from "@/models/historyDetails/orthoptic";
import { PreconsultingDetailResponse } from "@/models/historyDetails/preconsulting";
import { PreoperativeDetailResponse } from "@/models/historyDetails/preoperative";
import { SurgicalDescriptionResponse } from "@/models/historyDetails/surgicalDescription";
import { UnexpectedSituationResponse } from "@/models/historyDetails/unexpectedSituation";
import { setHeaders } from "@/utils";

export async function getAHListService(params: HistoryModels.AttentionHistoryListParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AttentionHistoryListResponse>(
        "/medical/clinicHistory/attentionSheetByDate/", axiosParams
    );
    return serviceData;
}

// --------------------- OPTOMETRY ----------------------------

export async function getAHOptometryService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHOptometryResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHOptometrySectionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHOptometrySectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHOptometryDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OptometryDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// --------------------- PREOPERATIVE ----------------------------

export async function getAHPreoperativeService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHPreoperativeResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHPreoperativeSectionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHPreoperativeSectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHPreoperativeDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PreoperativeDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ------------------- LOW VISION -----------------------------

export async function getAHLowVisionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHLowVisionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHLowVisionSectionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHLowVisionSectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHLowVisionDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<LowVisionDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ----------------------- ADAPTATION -----------------------------

export async function getAHAdaptationService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHAdaptationResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHAdaptationSectionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHAdaptationSectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHAdaptationDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AdaptationDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ----------------------- OPHTHALMOLOGY -----------------------------

export async function getAHOphthalmologyService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHOphthalmologyResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHOphthalmologySectionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHOphthalmologySectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHOphthalmologyDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OphthalmologyDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ------------------------- ORTHOPTIC -----------------------------

export async function getAHOrthopticService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHOrthopticResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHOrthopticSectionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHOrthopticSectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHOrthopticDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OrthopticDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ------------------------ ATTENTION ORIGIN ----------------------------
export async function getAHAttentionOriginService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHAttentionOriginResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

// ------------------------ BACKGROUNDS ----------------------------
export async function getAHBackgroundService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHBackgroundResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHBackgroundDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<BackgroundDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ------------------------ ANAlYSIS AND PLAN ----------------------------
export async function getAHAnalysisPlanService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHAnalysisPlanResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

// ----------------------- PRE CONSULTING -----------------------------
export async function getAHPreconsultingService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHPreconsultingResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHPreconsultingSectionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHPreconsultingSectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHPreconsultingDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PreconsultingDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ------------------------ PACHYMETRY ----------------------------
export async function getAHPachymetryService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHPachymetryResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

// ------------------------- DIAGNOSTIC IMPRESSION --------------------------
export async function getAHDiagnosticImpressionService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHDiagnosticImpresionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHDIDetailsService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AHDIDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// ------------------------ PIO ----------------------------
export async function getAHPioService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHPioResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

// ------------------------ SURGERY ----------------------------
export async function getAHSurgeryService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.AHSurgeryResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getAHNurseryNotesDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<NurseryNotesDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

export async function getAHAnesthesiaDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AnesthesiaNotesResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

export async function getSurgicalDescriptionDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<SurgicalDescriptionResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

export async function getCheckListDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<CheckListResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

export async function getUnexpectedSituationDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<UnexpectedSituationResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

export async function getOrderDetailService(params: HistoryModels.AttentionHistoryFindParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OrderDetailResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}

// --------------------------- ADX ---------------------------

export async function getAdxDetailsService(params: HistoryModels.AttentionHistoryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.ADXSectionResponse>(
        "/medical/clinicHistory/attentionHistory/", axiosParams
    );
    return serviceData;
}

export async function getDiagnosticADXDetailsService(params: DiagnosticAdxDetailsParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DiagnosticAdxDetailsResponse>(
        "/medical/clinicHistory/attentionHistoryFind/", axiosParams
    );
    return serviceData;
}


// ----------------------- DOWNLOAD ---------------------------------
export async function getDownloadDataService(eaccount: number | null, mcfId?: number | null, appId?: number, patientId?: number) {
    const headers = setHeaders();
    const axiosParams = { headers, params: { eaccount, mcfId, appId, patient_id: patientId } };

    const { data: serviceData } = await axiosInstance.get<AttentionDownloadResponse>(
        "/medical/clinicHistory/attentionHistoryDetailsForDownload/", axiosParams
    );
    return serviceData;
}

export async function getAllImpressionDiagnosticService(params: HistoryModels.getAllDataDiagnosticParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<HistoryModels.GetAllDiagnosticImpressionResponse>(
        "/medical/clinicHistory/downloadAllDiagnosticImpresions/", axiosParams
    );
    return serviceData;
}

export async function getExternalOptionsService(params: {eaccount: number}) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ExternalOptionsResponse>(
        "/medical/clinicHistory/externalSystems", axiosParams
    );
    return serviceData;
}