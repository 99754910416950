import { IconExclamationCircle, IconFileSearch } from "@tabler/icons-react";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//
import { Appointment, DayService, ServiceData } from "@/models/appointment";
//
import { IModal } from "@/components/Modal/modal";
import { setAppointmentSelected, setPatientStatus } from "@/config/slices";
import routes from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { getDayServices } from "../consultation.actions";
import { callPatient, validatePatientAttentionStatus, validatePatientAttentionStatusDiagnostic } from "./appointment.action";
//
import { Button, Modal } from "@/components";
import { Comment } from "@/models/comment";
import { fireCautionAlert, fireInfoAlert, fireWarningAlert, formatteDocument} from "@/utils";
import Comments from "../Comments/Comments";
import { createComment, deleteComment, getComments } from "../Comments/comments.actions";
import DayServices from "../DayServices/DayServices";

export default function AppointmentDetail({ data: appointment, onSuccess, ...rest }: IModal<Appointment>) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    dayjs.extend(isToday);

    const userRole = useAppSelector(state => state.workspace.profile?.prefijo);
    const userSignature = useAppSelector(state => state.auth.user_data.signature);
    const entityAccount = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [comments, setComments] = useState<Comment[]>([]);
    const [dayServicesModalConfig, setDayServicesModalConfig] =
        useState<{ isOpen: boolean; data: ServiceData | null; }>({
            isOpen: false,
            data: null
        });
    const [dayServices, setDayServices] = useState<DayService[]>([]);
    const [commentsConfig, setCommentsConfig] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            if (appointment?.patientId) {
                const data = await dispatch(getComments({
                    entity: "userclient",
                    id: appointment.patientId
                }));
                setComments(data as Comment[]);
            }
        }
        fetchData();
        return () => setComments([]);
    }, [dispatch, appointment?.patientId]);

    useEffect(() => {
        (async function () {
            if (appointment?.app_id) {
                const data = await dispatch(getDayServices({ appId: appointment?.app_id as number }));
                setDayServices(data as DayService[]);
            }
        })();
        return () => setDayServices([]);
    }, [dispatch, appointment?.app_id]);

    const handleOpenServicesModal = (): void => setDayServicesModalConfig({
        isOpen: true,
        data: { appointment: appointment as Appointment, dayServices }
    });
    const handleCloseServicesModal = (): void => setDayServicesModalConfig({ isOpen: false, data: null });

    const handleAttendPatient = async (): Promise<void> => {
        const result = await dispatch(validatePatientAttentionStatus({
            appId: appointment?.app_id as number,
            eaccount: entityAccount,
            userId: userId as number
        }));
        if (result) {
            if (userRole?.toLowerCase() !== "nurse") {
                if (result.sheet === "preoperative" && (!userSignature || !userSignature.length)) {
                    fireWarningAlert("Debe agregar la firma para ingresar a la atención", "Intenta de nuevo");
                    return;
                }
                dispatch(setPatientStatus(result));
                localStorage.setItem("patientStatus", JSON.stringify(result));

                navigate(routes.workspaceConsultation + `/${result.sheet}`);
            } else {
                dispatch(setPatientStatus(result));
                localStorage.setItem("patientStatus", JSON.stringify(result));
                navigate(routes.workspaceConsultation + "/preconsulting", { state: { tab: "dilatation" } });
            }
        }
        localStorage.setItem("appointment", JSON.stringify(appointment));
        dispatch(setAppointmentSelected({ ...appointment as Appointment }));
    };

    const handleAttendPatientDiagnosticAids = async (): Promise<void> => {
        const result = await dispatch(validatePatientAttentionStatusDiagnostic({
            appId: appointment?.app_id as number,
            eaccount: entityAccount,
            userId: userId as number
        }));
        if (result) {
            dispatch(setPatientStatus(result));
            localStorage.setItem("patientStatus", JSON.stringify(result));
            navigate(routes.workspaceDiagnosticAidsSheet, { state: { tab: "adx", showRenderSection: true } });
        }
        localStorage.setItem("appointment", JSON.stringify(appointment));
        dispatch(setAppointmentSelected({ ...appointment as Appointment }));
    };

    const handleOpenCommentsDrawer = (): void => {
        setCommentsConfig(true);
    };

    const handleCloseCommentsDrawer = (): void => setCommentsConfig(false);

    const handleSubmitComment = async (text: string): Promise<void> => {
        await dispatch(createComment({
            comment: text,
            entity: "userclient",
            userId: userId as number,
            id: appointment?.patientId as number,
        }));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const handleDeleteComment = async (id: string): Promise<void> => {
        await dispatch(deleteComment(id));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const onCallPatient = async () => {
        const isSuccess = await dispatch(callPatient({
            APP_ID: appointment?.app_id,
            TIPO_DOC: appointment?.patientIdentification.slice(0, 2),
            EN_ESPERA: 1,
            ID_PAC: appointment?.patientId,
            NUM_DOC: appointment?.patientIdentification.slice(4),
            ESTADO: appointment?.status,
            module: location.pathname.includes("DiagnosticAids") ? "diagnostic_aids" : "attention"
        }));
        if (isSuccess) {
            fireInfoAlert("El paciente está siendo llamado.", "Llamando paciente", () => {
                onSuccess?.(undefined);
            });
        }
    };

    const handleCallPatient = async (isInOtherService: boolean) => {
        if (isInOtherService) {
            fireCautionAlert("El paciente no ha completado una o más atenciones previas", undefined, async () => await onCallPatient());
        } else {
            await onCallPatient();
        }
    };

    const renderModalContent = () => {
        const isInOtherService = dayServices.some(srv => (srv.statusPrefix === "consulting" || srv.statusPrefix === "preconsulting"));
        const validStatusAttend: string[] = ["admitted", "calling", "preconsulting", "consulting", "attended", "failed"];
        const validStatusCall: string[] = ["admitted", "calling", "attended"];

        const startAppointment = dayjs(appointment?.startAppointment);

        const disabledAttendButton = (
            appointment?.clhStatus === "close" ||
            !startAppointment.isToday() ||
            !validStatusAttend.some(st => st === appointment?.status) ||
            appointment?.modalityAppoinment === "tmed"
        );
        const disabledCallButton = (
            appointment?.clhStatus === "close" ||
            appointment?.status === "attended" ||
            !startAppointment.isToday() ||
            !validStatusCall.some(st => st === appointment?.status) ||
            appointment?.modalityAppoinment === "tmed"
        );

        return (
            <div className="d-flex w-100 h-100 flex-column align-items-start">
                <div className="flex-fill w-100 mt-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <h1 className="text-secondary fw-bold">Detalle de la consulta</h1>
                        <div className="message-icon" onClick={handleOpenCommentsDrawer}>
                            {comments?.length}
                        </div>
                    </div>
                    <h5 className="text-secondary mb-5">{appointment?.patientName}</h5>

                    <h5 className="text-secondary fw-bold mb-2">Detalles del paciente</h5>
                    <div className="row w-100 mb-4">
                        <div className="col-5">
                            <div className="fw-bold">Identificación</div>
                            <div className="fw-bold">Aseguradora</div>
                            <div className="fw-bold">Tipo de aseguradora</div>
                            <div className="fw-bold">Contrato</div>
                        </div>
                        <div className="col">
                            <div>{formatteDocument({ value: appointment?.patientIdentification ?? ""}).format}</div>
                            <div>{appointment?.companyName}</div>
                            <div>{appointment?.companyType}</div>
                            <div>{appointment?.contractName}</div>
                        </div>
                    </div>

                    <h5 className="text-secondary fw-bold mb-2">Detalles del servicio</h5>
                    <div className="row w-100 mb-4">
                        <div className="col-5">
                            <div className="fw-bold">Servicio</div>
                            <div className="fw-bold">Tipo</div>
                            <div className="fw-bold">Médico</div>
                        </div>
                        <div className="col overflow-hidden">
                            <div
                                className="nowrap overflow-hidden"
                                style={{ textOverflow: "ellipsis" }}
                                title={appointment?.serviceName}
                            >
                                {appointment?.cupCode} - {appointment?.serviceName}
                            </div>
                            <div>{appointment?.serviceType}</div>
                            <div>
                                {appointment?.medFirstName}{" "}
                                {appointment?.medSecondName ? appointment?.medSecondName : ""}{" "}
                                {appointment?.medLastName}{" "}
                                {appointment?.medSecondSurname}
                            </div>
                        </div>
                    </div>
                    {(dayServices.length > 0 && startAppointment.isToday()) && (
                        <Button
                            className="mt-3"
                            variant="text"
                            startIcon={<IconFileSearch />}
                            onClick={handleOpenServicesModal}
                        >
                            Ver servicios del día
                        </Button>
                    )}
                </div>
                <div className="d-flex flex-column w-100">
                    {(isInOtherService && startAppointment.isToday() && appointment?.status !== "attended") && (
                        <div className="d-flex align-items-center text-danger mb-3">
                            <IconExclamationCircle />
                            <span className="ms-2">El paciente está siendo atendido en otro servicio.</span>
                        </div>
                    )}
                    <div className="d-flex align-items-center">
                        { false && <Button
                            className="px-5"
                            variant="secondary"
                            disabled={disabledCallButton}
                            onClick={() => handleCallPatient(isInOtherService)}
                        >
                            Llamar
                        </Button> }
                        <div className="flex-fill"></div>
                        <Button className="me-3" variant="outline" onClick={rest.onClose}>Cancelar</Button>
                        <Button
                            variant="primary"
                            onClick={location.pathname.includes("DiagnosticAids") ? handleAttendPatientDiagnosticAids : handleAttendPatient}
                            disabled={disabledAttendButton}
                        >
                            {appointment?.status === "attended" || appointment?.status === "failed" ? "Ver detalle" : "Atender paciente"}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <Modal {...rest}>
                {renderModalContent()}
                <DayServices
                    isOpen={dayServicesModalConfig.isOpen}
                    onClose={handleCloseServicesModal}
                    width={400}
                    data={dayServicesModalConfig.data as ServiceData}
                />
                <Comments
                    data={comments}
                    isOpen={commentsConfig}
                    onClose={handleCloseCommentsDrawer}
                    width={400}
                    drawer="right"
                    onCreateComment={(value) => handleSubmitComment(value)}
                    onDeleteComment={(id) => handleDeleteComment(id)}
                />
            </Modal>
        );
    };

    return render();
}