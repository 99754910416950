import { useMemo } from "react";
import { DateRange } from "react-day-picker";
import { Line } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
//
import { AttentionHistoryParams, AttentionPio } from "@/models/history";
import { IModal } from "@/components/Modal/modal";
import { CustomDayPicker, Modal } from "@/components";

interface ChartModal extends IModal<AttentionPio[]> {
    attentionHistoryParams: AttentionHistoryParams,
    onChangeDate: (e?: DateRange) => void;
    range: DateRange | undefined;
}

export default function PioChart({
    data,
    attentionHistoryParams,
    onChangeDate,
    range,
    ...rest
}: ChartModal) {
    const currentYear = new Date().getFullYear();

    const options: ChartOptions<"line"> = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: "PIO",
                    color: "#003F80",
                }
            },
            x: {
                title: {
                    display: true,
                    text: "Hora",
                    color: "#003F80",

                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    boxHeight: 8,
                },
                align: "start",
                title: {
                    display: true,
                    position: "start"
                },
            },
        },
    };

    const plugin = {
        id: "",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeInit(chart: any) {
            const originalFit = chart.legend.fit;

            // override the fit function
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 30;
            };
        }
    };

    const chartData = useMemo(() => ({
        labels: data?.map(el => el.createdAt).reverse(),
        datasets: [
            {
                label: "OD",
                data: data?.map(el => el.od).reverse(),
                borderColor: "#A3E4EC",
                backgroundColor: "#A3E4EC",
            },
            {
                label: "OI",
                data: data?.map(el => el.oi).reverse(),
                borderColor: "#5C84AD",
                backgroundColor: "#5C84AD",
            }
        ],
    }), [data]);

    const render = () => {
        return (
            <Modal {...rest}>
                <div className="d-flex align-items-center justify-content-between">
                    <h1 className="text-secondary fw-bold">Historial de presiones intraoculares</h1>
                    <div className="d-flex align-items-center me-5">
                        {attentionHistoryParams.endDate || attentionHistoryParams.startDate ? (
                            <span className="text-muted fs-5">{attentionHistoryParams.startDate} al {attentionHistoryParams.endDate}</span>
                        ) : ""}
                        <CustomDayPicker
                            dayPickerProps={{
                                mode: "range",
                                selected: range,
                                onSelect: (e) => onChangeDate(e),
                                className: "my-rdp dropdown-item p-0",
                                captionLayout: "dropdown-buttons",
                                fromYear: 2010,
                                toYear: currentYear
                            }}
                        />
                    </div>
                </div>
                <Line options={options} data={chartData} plugins={[plugin]} />
            </Modal>
        );
    };

    return render();
}