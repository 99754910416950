import { SectionCard } from "@/components";
import { OrthopticPayload } from "@/models/sheets/orthoptic";

interface FusionalBreadthsParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

export default function FusionalBreadths({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: FusionalBreadthsParams) {

    const onChangeBreadth = (type: "vl" | "vp", segment: "or" | "luz", field: "divergent" | "convergent", value: string) => {
        const options: typeof orthopticPayload = {
            ...orthopticPayload,
            fusionalBreadths: {
                ...orthopticPayload.fusionalBreadths,
                [type]: {
                    ...orthopticPayload.fusionalBreadths?.[type],
                    [segment]: {
                        ...orthopticPayload.fusionalBreadths?.[type]?.[segment],
                        [field]: value
                    }
                }
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const renderTable = (type: "vl" | "vp", showLabel: boolean) => {
        return (
            <table className="w-100">
                <tbody>
                    <tr>
                        <td></td>
                        <td align="center" className="text-secondary">Divergencia</td>
                        <td align="center" className="text-secondary">Convergencia</td>
                    </tr>
                    <tr>
                        <td className={`${showLabel && "ps-3"} py-2 text-secondary`}>{showLabel && "OR"}</td>
                        <td className={`${showLabel && "ps-3"} py-2 `}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={orthopticPayload.fusionalBreadths?.[type]?.or?.divergent}
                                onChange={({ target }) => onChangeBreadth(type, "or", "divergent", target.value)}
                                onKeyDown={(e) => (e.key === "." || e.key === "," || e.key === "+") && e.preventDefault()}
                            />
                        </td>
                        <td className="ps-3 py-2">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={orthopticPayload.fusionalBreadths?.[type]?.or?.convergent}
                                onChange={({ target }) => onChangeBreadth(type, "or", "convergent", target.value)}
                                onKeyDown={(e) => (e.key === "." || e.key === "," || e.key === "+") && e.preventDefault()}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={`${showLabel && "ps-3"} py-2 text-secondary`}>{showLabel && "LUZ"}</td>
                        <td className={`${showLabel && "ps-3"} py-2`}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={orthopticPayload.fusionalBreadths?.[type]?.luz?.divergent}
                                onChange={({ target }) => onChangeBreadth(type, "luz", "divergent", target.value)}
                                onKeyDown={(e) => (e.key === "." || e.key === "," || e.key === "+") && e.preventDefault()}
                            />
                        </td>
                        <td className="ps-3 py-2">
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                value={orthopticPayload.fusionalBreadths?.[type]?.luz?.convergent}
                                onChange={({ target }) => onChangeBreadth(type, "luz", "convergent", target.value)}
                                onKeyDown={(e) => (e.key === "." || e.key === "," || e.key === "+") && e.preventDefault()}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const render = () => {
        return (
            <SectionCard>
                <h5 className="text-secondary fw-bold align-self-start">
                    Amplitudes fusionales
                </h5>
                <hr className="text-primary mt-2 mb-4" />
                <div className="row">
                    <div className="col-lg-6">
                        <h5 className="fw-bold text-secondary text-center ms-5">Visión Lejana</h5>
                        {renderTable("vl", true)}
                    </div>
                    <div className="col-lg-6"  style={{ borderLeft: "0.5px solid rgb(0, 180, 204, 0.25)" }}>
                        <h5 className="fw-bold text-secondary text-center ms-5">Visión Cercana</h5>
                        {renderTable("vp", false)}
                    </div>
                    <div className="col-lg-12 mt-2">
                        <textarea
                            disabled={isDisabledForm}
                            className="form-control h-100 no-resize"
                            value={orthopticPayload.fusionalBreadths?.observations}
                            onChange={({ target }) => {
                                setOrthopticPayload({
                                    ...orthopticPayload,
                                    fusionalBreadths: {
                                        ...orthopticPayload.fusionalBreadths,
                                        observations: target.value
                                    }
                                });
                                onSubmit();
                            }}
                            placeholder="Observaciones..."
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}
