import { useState } from "react";
import {
    IconUserCircle,
    IconKey,
    IconMail,
    IconSend
} from "@tabler/icons-react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//
import BiowellLogo from "@/assets/biowell-logo.png";
import Shape1 from "@/assets/auth/shape-1.svg";
import Shape2 from "@/assets/auth/shape-2.svg";
//
import { useAppDispatch } from "@/hooks/redux";
import { loginUser, recoverPassword } from "./auth.actions";
import {
    AuthViews,
    loginSchema,
    LoginInputs,
    recoverSchema,
    RecoverInput,
    RecoverPayload
} from "@/models/auth";
//
import {
    Button,
    TextField
} from "@/components";
//
import "./Auth.scss";

export default function Auth() {
    const dispatch = useAppDispatch();
    const [currentView, setCurrentView] = useState<AuthViews>("login");

    const {
        register: registerLogin,
        handleSubmit: handleSubmitLogin,
        formState: { errors: errorsLogin },
        reset: resetLoginInputs
    } = useForm<LoginInputs>({ resolver: yupResolver(loginSchema) });

    const {
        register: registerRecover,
        handleSubmit: handleSubmitRecover,
        formState: { errors: errorsRecover },
        reset: resetRecoverInput
    } = useForm<RecoverInput>({ resolver: yupResolver(recoverSchema) });

    const onSubmitLogin: SubmitHandler<LoginInputs> = async (data) => {
        const isSuccess = await dispatch(loginUser(data));
        if (isSuccess) window.location.reload();
    };

    const onSubmitRecover: SubmitHandler<RecoverInput> = (data) => {
        const payload: RecoverPayload = {
            email: data.user_email,
            forgotPassword: true,
            username: "",
            password: ""
        };
        dispatch(recoverPassword(payload));
    };

    const handleChangeView = (view: AuthViews) => {
        resetLoginInputs();
        resetRecoverInput();
        setCurrentView(view);
    };

    const renderLogin = () => {
        return (
            <form className="d-flex flex-column" onSubmit={handleSubmitLogin(onSubmitLogin)}>
                <img
                    src={BiowellLogo}
                    className="img-fluid mb-3 align-self-center"
                    width={300}
                    alt="biowell-logo"
                    draggable="false"
                />
                <TextField
                    type="email"
                    className="w-100 mt-3"
                    placeholder="Usuario"
                    startIcon={<IconUserCircle />}
                    {...registerLogin("email", { required: true })}
                />
                {errorsLogin.email && <span className="text-danger mt-1">Este campo es requerido</span>}

                <TextField
                    type="password"
                    className="w-100 mt-3"
                    placeholder="Contraseña"
                    startIcon={<IconKey />}
                    {...registerLogin("password", { required: true })}
                />
                {errorsLogin.password && <span className="text-danger mt-1">Este campo es requerido</span>}

                <div className="d-flex align-items-center mt-3">
                    <div className="flex-grow-1">
                        <p className="text-primary pointer mb-2" onClick={() => handleChangeView("recover")}>
                            ¿Olvidó su contraseña?
                        </p>
                    </div>

                    <Button className="w-25" variant="primary" type="submit">
                        ACCEDER
                    </Button>
                </div>
            </form>
        );
    };

    const renderRecover = () => {
        return (
            <form className="d-flex flex-column" onSubmit={handleSubmitRecover(onSubmitRecover)}>
                <img
                    src={BiowellLogo}
                    className="img-fluid mb-3 align-self-center"
                    width={300}
                    alt="biowell-logo"
                    draggable="false"
                />
                <h3 className="text-primary fw-bold mt-3">¿Olvidó su contraseña?</h3>
                <p>Ingrese su correo electrónico y le enviaremos una contraseña provicional de ingreso</p>
                <TextField
                    className="w-100 "
                    placeholder="Correo electrónico"
                    startIcon={<IconMail />}
                    {...registerRecover("user_email", { required: true })}
                />
                {errorsRecover.user_email && <span className="text-danger mt-1">Ingrese un correo electrónico válido.</span>}

                <div className="d-flex justify-content-end mt-3">
                    <Button className="me-3 w-25" type="button" onClick={() => handleChangeView("login")}>
                        Atrás
                    </Button>
                    <Button
                        className="w-25"
                        variant="primary"
                        type="submit"
                        startIcon={<IconSend />}
                    >
                        Enviar
                    </Button>
                </div>
            </form>
        );
    };

    const render = () => {
        return (
            <section className="auth-section">
                <img src={Shape1} className="shape shape-1" alt="" />
                <img src={Shape2} className="shape shape-2" alt="" />
                <div className="card auth-section__card">
                    {currentView === "login" && renderLogin()}
                    {currentView === "recover" && renderRecover()}
                </div>
            </section>
        );
    };

    return render();
}