import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { CheckListResponse, ResultsCheckList } from "@/models/surgery/patientAttention/checklist";
import { HeaderSurgeryResponse } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { AddLotArticlePayload, AnesthesiaTimeResponse, AttentionAppParams, AttentionAppointmentSurgeryResponse, CancellationListResponse, ConsumptionCenterParamsSheet, ConsumptionCentersResponse, ConsumptionCentersTableResponse, CreateVitalSingsPayload, EvidencePayload, IntervenedPayload, JustificationListResponse, JustificationMotivesResponse, NurseryNotes, NurseryNotesParams, NurseryNotesResponse, OperatingRoomsResponse, PreoperativeDiagnosesResponse, QuantityPayload, RecordChartResponse, SheetsIdsResponse, StartAttentionResponse, StartSurgeryPayload, TransferOperatingRoomPayload, VitalSingsResponse, deleteSupplyParams } from "@/models/surgery/patientAttention/patientAttention";
import { setHeaders } from "@/utils";

export async function getAttentionAppointmentService(params: AttentionAppParams): Promise<AttentionAppointmentSurgeryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AttentionAppointmentSurgeryResponse>(
        "/medical/clinicHistory/surgeryAppointments/", axiosParams
    );
    return serviceData;
}

export async function getHeaderSurgeryService(appId: number): Promise<HeaderSurgeryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: { appId } };

    const { data: serviceData } = await axiosInstance.get<HeaderSurgeryResponse>(
        "/medical/clinicHistory/appointmentHeader/", axiosParams
    );
    return serviceData;
}

export async function startSurgeryAttentionService(appId: number, userId: number | null, eaccount: number): Promise<StartAttentionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const payload = { appId, userId, eaccount };

    const { data: serviceData } = await axiosInstance.put<StartAttentionResponse>(
        "/medical/clinicHistory/startSurgeryAttention/", payload, axiosParams
    );
    return serviceData;
}

export async function translateSurgeryAttentionService(params: { appId: number, stagePrefix: string, eaccount: number; }): Promise<StartAttentionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const payload = params;

    const { data: serviceData } = await axiosInstance.put<StartAttentionResponse>(
        "/medical/clinicHistory/transferSurgery/", payload, axiosParams
    );
    return serviceData;
}

export async function getNurseryNotesService(params: NurseryNotesParams): Promise<NurseryNotesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<NurseryNotesResponse>(
        "/medical/clinicHistory/nurseryNotes/", axiosParams
    );
    return serviceData;
}

export async function updateNurseryNotesService(payload: NurseryNotes): Promise<NurseryNotesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<NurseryNotesResponse>(
        "/medical/clinicHistory/nurseryNotes/", payload, axiosParams
    );
    return serviceData;
}


export async function getConsumptionCentersService(params: ConsumptionCenterParamsSheet): Promise<ConsumptionCentersResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ConsumptionCentersResponse>(
        "/medical/clinicHistory/ConsumptionSheet", axiosParams
    );
    return serviceData;
}

export async function addLotArticleService(payload: AddLotArticlePayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", payload, axiosParams
    );
    return serviceData;
}

export async function getConsumptionSheetService(params: ConsumptionCenterParamsSheet): Promise<ConsumptionCentersTableResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ConsumptionCentersTableResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", axiosParams
    );
    return serviceData;
}
export async function deleteSupplyService(payload: deleteSupplyParams): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.patch<IResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", payload, axiosParams
    );
    return serviceData;
}

export async function changeQuantityService(payload: QuantityPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/ConsumptionSheetSurgery", payload, axiosParams
    );
    return serviceData;
}

export async function changeEvidenceService(payload: EvidencePayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/SpecialSupplyEvidence", payload, axiosParams
    );
    return serviceData;
}



export async function deleteEvidenceService(payload: { filId: number; }): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };

    const { data: serviceData } = await axiosInstance.delete<IResponse>("/medical/clinicHistory/SpecialSupplyEvidence", axiosParams);
    return serviceData;
}


export async function getOperatingRoomsService(params: { appId: number; }): Promise<OperatingRoomsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OperatingRoomsResponse>(
        "/medical/clinicHistory/operatingRooms/", axiosParams
    );
    return serviceData;
}

export async function selectOperatingRoomService(payload: { "appId": number, "consultingRoomId": number; }): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/selectOperatingRooms/", payload, axiosParams
    );
    return serviceData;
}

export async function sendAuthCodeService(payload: { "appId": number, "code": number; stgPrefix: string; }): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/surgeryNotesLog/", payload, axiosParams
    );
    return serviceData;
}

export async function getJustificationsListService(): Promise<JustificationListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<JustificationListResponse>(
        "/medical/justificationsTransfer/", axiosParams
    );
    return serviceData;
}

export async function transferOperatingRoomService(payload: TransferOperatingRoomPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/movementOperatingRooms/", payload, axiosParams
    );
    return serviceData;
}

export async function getCheckListService(params: { numId: number; }): Promise<CheckListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<CheckListResponse>(
        "/medical/clinicHistory/nuseryNotesChecklist/", axiosParams
    );
    return serviceData;
}
export async function setRecordChartService(params: { appId: number; }): Promise<RecordChartResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<RecordChartResponse>(
        "/medical/clinicHistory/anestheticRecord/", axiosParams
    );
    return serviceData;
}

export async function vitalSingListService(params: { enabled: number; stgPrefix: string; }): Promise<VitalSingsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<VitalSingsResponse>(
        "/medical/vital-signs", axiosParams
    );
    return serviceData;
}


export async function updateCheckListService(payload: ResultsCheckList): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/nuseryNotesChecklist/", payload, axiosParams
    );
    return serviceData;
}

export async function createVitalSingsService(payload: CreateVitalSingsPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/appointment-vital-signs/", payload, axiosParams
    );
    return serviceData;
}

export async function startSurgerySegmentService(payload: StartSurgeryPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/controlSurgeryState/", payload, axiosParams
    );
    return serviceData;
}


export async function anesthesiaTimeService(params: { configTag: string; careTag: string; eaccount: number; }): Promise<AnesthesiaTimeResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AnesthesiaTimeResponse>(
        "/medical/appointment/configuration/", axiosParams
    );
    return serviceData;
}

export async function attentionIntervenedService(payload: IntervenedPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/ReinterventionRequest", payload, axiosParams
    );
    return serviceData;
}

export async function cancellationListService(): Promise<CancellationListResponse> {
    const headers = setHeaders();
    const params = { status: "enabled" };
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<CancellationListResponse>(
        "/medical/generals/cancellation-reasons/types", axiosParams
    );
    return serviceData;
}
export async function justificationMotivesListService(param: { cancellationReasonId: number; }): Promise<JustificationMotivesResponse> {
    const headers = setHeaders();
    const params = { ...param, status: "enabled" };
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<JustificationMotivesResponse>(
        "/medical/generals/cancellationReason/", axiosParams
    );
    return serviceData;
}


export async function preoperativeDiagnosesService(params: { appId: number; }): Promise<PreoperativeDiagnosesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PreoperativeDiagnosesResponse>(
        "/medical/clinicHistory/surgicalNotesDiagnosis", axiosParams
    );
    return serviceData;
}
export async function getSheetsIdsService(appId: number): Promise<SheetsIdsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<SheetsIdsResponse>(
        `/medical/clinicHistory/sheets-available/${appId}/`, axiosParams
    );
    return serviceData;
}