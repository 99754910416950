import { AttentionOrigin } from "@/models/generalHistory/generalHistory";



export default function AttentionOriginDetails({ data: attentionOrigin }: { data?: AttentionOrigin; }) {
    const renderComponent = () => {
        if (attentionOrigin) {
            return (
                <div className="item py-3">
                    {attentionOrigin?.attendingReason !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin?.attendingReason}
                        </h5>
                    ) : ""}
                    {attentionOrigin.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}
                    {attentionOrigin?.attendingReasonJst ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Motivo consulta:
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.attendingReasonJst}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin?.currentIllness ? (
                        <>
                            <h5 className="fw-bold mt-3">
                                Enfermedad actual:
                            </h5>
                            <h5 style={{ whiteSpace: "pre-line" }}>{attentionOrigin.currentIllness || ""}</h5>
                        </>
                    ) : ""}
                    {attentionOrigin.evolutionTimeUnit ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.evolutionTimeUnit.value}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return <></>;
        }
    };



    return renderComponent();
}
