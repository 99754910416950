import { SectionCard } from "@/components";
import { OrthopticPayload } from "@/models/sheets/orthoptic";

interface ChromaticVisionParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}
export default function NextConvergencePoint({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: ChromaticVisionParams) {

    const onChangeCm = (point: "lightMethod" | "realObjectMethod" | "redFilterMethod", value: string) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            nextConvergencePoint: {
                ...orthopticPayload.nextConvergencePoint,
                [point]: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        return (
            <SectionCard>
                <h5 className="text-secondary fw-bold">
                    Punto próximo de convergencia (PPC)
                </h5>
                <hr className="text-primary mt-2" />
                <h5 className="text-secondary fw-bold">Método</h5>
                <div className="d-flex align-items-center mb-3 w-100">
                    <span className="text-secondary" style={{ width: 130 }}>
                        Objeto real
                    </span>
                    <input
                        disabled={isDisabledForm}
                        type="number"
                        className="form-control"
                        value={orthopticPayload.nextConvergencePoint?.realObjectMethod}
                        onChange={({ target }) => onChangeCm("realObjectMethod", target.value)}
                        onKeyDown={(event) => (event.key === "." || event.key === ",") && event.preventDefault()}
                    />
                    <span className="ms-3">cm</span>
                </div>
                <div className="d-flex align-items-center mb-3 w-100">
                    <span className="text-secondary" style={{ width: 130 }}>
                        Luz
                    </span>
                    <input
                        disabled={isDisabledForm}
                        type="number"
                        className="form-control"
                        value={orthopticPayload.nextConvergencePoint?.lightMethod}
                        onChange={({ target }) => onChangeCm("lightMethod", target.value)}
                        onKeyDown={(event) => (event.key === "." || event.key === ",") && event.preventDefault()}
                    />
                    <span className="ms-3">cm</span>
                </div>
                <div className="d-flex align-items-center mb-3 w-100">
                    <span className="text-secondary" style={{ width: 130 }}>
                        Filtro rojo
                    </span>
                    <input
                        disabled={isDisabledForm}
                        type="number"
                        className="form-control"
                        value={orthopticPayload.nextConvergencePoint?.redFilterMethod}
                        onChange={({ target }) => onChangeCm("redFilterMethod", target.value)}
                        onKeyDown={(event) => (event.key === "." || event.key === ",") && event.preventDefault()}
                    />
                    <span className="ms-3">cm</span>
                </div>
            </SectionCard>
        );
    };

    return render();
}