import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { AdaptationParams, AdaptationPayload, AdaptationResponse } from "@/models/sheets/adaptation";
import { setHeaders } from "@/utils";

export async function getAdaptationService(params: AdaptationParams): Promise<AdaptationResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AdaptationResponse>(
        "/medical/clinicHistory/contactLensFitting/", axiosParams
    );
    return serviceData;
}

export async function updateAdaptationService(payload: AdaptationPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/contactLensFitting/", payload, axiosParams
    );
    return serviceData;
}
export async function deleteAdaptationClearenceCentralService(payload: AdaptationPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };

    const { data: serviceData } = await axiosInstance.delete<IResponse>(
        "/medical/clinicHistory/contactLensFitting/", axiosParams
    );
    return serviceData;
}

