import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { PhysicalExamNormalPayload, PhysicalExamsPayload, PreviousPhysicalExamResponse } from "@/models/physicalExam";
import { DeleteEvidencePayload, FinalRefractionResponse, OftalmologyPayloadResponse, OftalmologySheet, PIOCorrectionParams, PIOCorrectionResponse, PachymetryCorrectionResponse, PhysicalExamsParams, PhysicalExamsResponse, ProvocativeChartResponse, ProvocativeEvidenceGraphParams, ProvocativeEvidenceParams, ProvocativeEvidencePayload, ProvocativeEvidenceResponse, TonometryParams, TonometryPayload, TonometryResponse, TonometryResultResponse } from "@/models/sheets/oftalmology";
import { setHeaders } from "@/utils";

export async function getPhysicalExamsService(params: PhysicalExamsParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PhysicalExamsResponse>(
        "/medical/clinicHistory/physicalExam/", axiosParams
    );
    return serviceData;
}

export async function savePhysicalExamsService(payload: PhysicalExamsPayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/physicalExam/", payload, axiosParams
    );
    return serviceData;
}
export async function getOftalmologySheetService(params: PhysicalExamsParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<OftalmologyPayloadResponse>(
        "/medical/clinicHistory/ophthalmology/", axiosParams
    );
    return serviceData;
}

export async function updateOftalmologySheetService(payload: OftalmologySheet) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/ophthalmology/", payload, axiosParams
    );
    return serviceData;
}

export async function getProvocativeEvidenceService(params: ProvocativeEvidenceParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ProvocativeEvidenceResponse>(
        "/medical/clinicHistory/provocativeEvidence/", axiosParams
    );
    return serviceData;
}

export async function saveProvocativeEvidenceService(payload: ProvocativeEvidencePayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/provocativeEvidence/", payload, axiosParams
    );
    return serviceData;
}

export async function deleteProvocativeEvidenceService(payload: DeleteEvidencePayload) {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };

    const { data: serviceData } = await axiosInstance.delete<IResponse>(
        "/medical/clinicHistory/provocativeEvidence/", axiosParams
    );
    return serviceData;
}

export async function getTonometryService(params: TonometryParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<TonometryResponse>(
        "/medical/clinicHistory/traditionalTonometry/", axiosParams
    );
    return serviceData;
}

export async function saveTonometryService(payload: TonometryPayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<TonometryResultResponse>(
        "/medical/clinicHistory/traditionalTonometry/", payload, axiosParams
    );
    return serviceData;
}

export async function getPachymetryCorrectionService(thickness: number) {
    const headers = setHeaders();
    const axiosParams = { headers, params: { thickness } };

    const { data: serviceData } = await axiosInstance.get<PachymetryCorrectionResponse>(
        "/medical/clinicHistory/calculatePachymetryCorrection/", axiosParams
    );
    return serviceData;
}

export async function getCorrectedPIOService(params: PIOCorrectionParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PIOCorrectionResponse>(
        "/medical/clinicHistory/calculateCorrectedIntraocularPressure/", axiosParams
    );
    return serviceData;
}

export async function getProvocativeEvidenceGraphService(params: ProvocativeEvidenceGraphParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ProvocativeChartResponse>(
        "/medical/clinicHistory/infoGraphicEvidenceProvocative/", axiosParams
    );
    return serviceData;
}

export async function setPhysicalExamNormalService(payload: PhysicalExamNormalPayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/setNormalPhysicalExam/", payload, axiosParams
    );
    return serviceData;
}

export async function getFinalRefractionService(params: { patientId: number }) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<FinalRefractionResponse>(
        "/medical/clinicHistory/final-refraction-from-optometry", axiosParams
    );
    return serviceData;
}

export async function getPreviousPhysicalExamService(params: { appId: number; patientId: number }) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PreviousPhysicalExamResponse>(
        "/medical/clinicHistory/previous-physical-exam", axiosParams
    );
    return serviceData;
}