import { useAppDispatch, useAppSelector } from "@/hooks";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
//
import {
    AttentionOriginFields,
    AttentionOriginItem,
    AttentionTime,
    MasterFields,
    PredefinedTextItem
} from "@/models/attentionOrigin";
import { getGeneralFields } from "../AttentionOrigin/attentionOrigin.actions";
//
import TextConfigModal from "./TextConfig/TextConfig";
import TextConfigNoInsModal from "./TextConfig/TextConfigNoIns";
import { getAttentionOrigins, getAttentionTimes, saveAttentionOrigin } from "./attentionOrigin.actions";
//
import { BadgeTextField, SectionCard, Select } from "@/components";
import { SelectOption } from "@/components/Select/select";
import { Segments } from "@/models/generalFields";
import { fireWarningAlert } from "@/utils";
interface AttentionOriginProps {
    attentionOriginOptions: AttentionOriginFields,
    setAttentionOriginOptions: Dispatch<SetStateAction<AttentionOriginFields>>;
    shouldWrap?: boolean;
    isDisabledForm: boolean;
    data?: Segments[];
    historySheet?: boolean;

}

export default function AttentionOrigin({ attentionOriginOptions, setAttentionOriginOptions, shouldWrap, isDisabledForm, data, historySheet }: AttentionOriginProps) {
    const dispatch = useAppDispatch();
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [insTextModal, setInsTextModal] = useState<{ isOpen: boolean; current: string | null; }>({
        isOpen: false,
        current: null
    });
    const [noInsTextModal, setNoInsTextModal] = useState<{ isOpen: boolean; current: string | null; }>({
        isOpen: false,
        current: null
    });

    const [attentionOriginList, setAttentionOriginList] = useState<AttentionOriginItem[]>([]);
    const [attentionTimes, setAttentionTimes] = useState<AttentionTime[]>([]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const attentionListData = await getAttentionOrigins();
                setAttentionOriginList(attentionListData as AttentionOriginItem[]);
                const attentionTimesData = await getAttentionTimes();
                setAttentionTimes(attentionTimesData as AttentionTime[]);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                if (appointmentId) {
                    const data = await dispatch(getGeneralFields({
                        appId: appointmentId,
                        eaccount: accountId,
                        userId: userId as number
                    }));


                    if (data?.length) {
                        const generalFields = data[0];

                        const predefinedTextsCD = generalFields.predefinedTxts.find(item => item.prefix === "current_disease");
                        const predefinedTextsCR = generalFields.predefinedTxts.find(item => item.prefix === "reason_attention");

                        setAttentionOriginOptions(prevState => ({
                            ...prevState,
                            reasonForConsulting: {
                                predefinedTxts: predefinedTextsCR?.predifinedText as PredefinedTextItem[],
                                justification: generalFields.reasonForConsultingJst as string
                            },
                            currentDisease: {
                                justification: generalFields.currentDiseaseJst as string,
                                predefinedTxts: predefinedTextsCD?.predifinedText as PredefinedTextItem[],
                            },
                            generalFields: {
                                abserveEvent: generalFields.generalFields?.abserveEvent || 0,
                                attentionOriginId: generalFields.generalFields?.attentionOrigin?.id,
                                evolutionTime: generalFields.generalFields?.evolutionTime,
                                evolutionTimeUnitId: generalFields.generalFields.evolutionTimeUnitId
                            },
                            analysisPlan: generalFields.analysisPlanJst
                        }));
                    }
                }
            } else {
                if (data) {
                    const generalFields = data[0];

                    const predefinedTextsCD = generalFields.predefinedTxts.find(item => item.prefix === "current_disease");
                    const predefinedTextsCR = generalFields.predefinedTxts.find(item => item.prefix === "reason_attention");

                    setAttentionOriginOptions(prevState => ({
                        ...prevState,
                        reasonForConsulting: {
                            predefinedTxts: predefinedTextsCR?.predifinedText as PredefinedTextItem[],
                            justification: generalFields.reasonForConsultingJst as string
                        },
                        currentDisease: {
                            justification: generalFields.currentDiseaseJst as string,
                            predefinedTxts: predefinedTextsCD?.predifinedText as PredefinedTextItem[],
                        },
                        generalFields: {
                            abserveEvent: generalFields.generalFields?.abserveEvent || 0,
                            attentionOriginId: generalFields.generalFields?.attentionOrigin?.id,
                            evolutionTime: generalFields.generalFields?.evolutionTime,
                            evolutionTimeUnitId: generalFields.generalFields.evolutionTimeUnitId,
                            evolutionTimeUnit: generalFields?.generalFields?.evolutionTimeUnit,
                            attentionOrigin: generalFields.generalFields?.attentionOrigin,
                        },
                        analysisPlan: generalFields.analysisPlanJst
                    }));
                }
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, userId, setAttentionOriginOptions, data, historySheet]);

    const saveAttentionOriginDebounced = useDebouncedCallback((options: AttentionOriginFields) => {
        dispatch(saveAttentionOrigin(options));
    }, 2000);

    const handleOpenTextModal = (name: MasterFields): void => setInsTextModal({
        current: name as string, isOpen: true
    });
    const handleCloseTextModal = (): void => setInsTextModal({
        current: null, isOpen: false
    });

    const handleOpenNoInsTextModal = (name: keyof AttentionOriginFields): void => setNoInsTextModal({
        current: name, isOpen: true
    });
    const handleCloseNoInsTextModal = (): void => setNoInsTextModal({
        current: null, isOpen: false
    });

    const handleOnSelectItem = (field: MasterFields, newItem: { value: number; label: string; }, index: number) => {
        if (attentionOriginOptions[field]?.predefinedTxts && attentionOriginOptions[field]?.predefinedTxts.length > 0) {
            if (!(attentionOriginOptions[field]?.predefinedTxts?.some(text => text.id === newItem.value))) {
                const options: AttentionOriginFields = {
                    ...attentionOriginOptions,
                    [field]: {
                        ...attentionOriginOptions[field],
                        predefinedTxts: [...attentionOriginOptions[field].predefinedTxts || [], {
                            id: newItem.value,
                            name: newItem.label,
                            order: index
                        }]
                    }
                };
                setAttentionOriginOptions(options);
                saveAttentionOriginDebounced(options);
                handleCloseTextModal();

            } else {
                fireWarningAlert("El texto predefinido que desea seleccionar ya ha sido añadido", "Intente de nuevo");
            }
        } else {
            const options: AttentionOriginFields = {
                ...attentionOriginOptions,
                [field]: {
                    ...attentionOriginOptions[field],
                    predefinedTxts: [...attentionOriginOptions[field].predefinedTxts || [], {
                        id: newItem.value,
                        name: newItem.label,
                        order: index
                    }]
                }
            };
            setAttentionOriginOptions(options);
            saveAttentionOriginDebounced(options);
            handleCloseTextModal();
        }
    };

    const handleOnDeleteItem = (name: MasterFields, itemValue: number | string) => {
        const options = {
            ...attentionOriginOptions,
            [name]: {
                ...attentionOriginOptions[name],
                predefinedTxts: [...attentionOriginOptions[name].predefinedTxts.filter(op => op.id !== itemValue)]
            }
        };
        setAttentionOriginOptions(options);
        saveAttentionOriginDebounced(options);
    };

    const handleAttatchText = (name: MasterFields, text: string) => {
        const options = {
            ...attentionOriginOptions,
            [name]: {
                ...attentionOriginOptions[name],
                justification: attentionOriginOptions[name].justification?.length
                    ? `${attentionOriginOptions[name].justification}\n${text}`
                    : text
            }
        };
        setAttentionOriginOptions(options);
        handleCloseNoInsTextModal();
        saveAttentionOriginDebounced(options);
    };

    const handleSetJustification = (name: MasterFields, text: string) => {
        const options = {
            ...attentionOriginOptions,
            [name]: {
                ...attentionOriginOptions[name],
                justification: text
            }
        };
        setAttentionOriginOptions(options);
        saveAttentionOriginDebounced(options);
    };

    const handleSelectAttention = (option: SelectOption, name: string) => {
        const options: AttentionOriginFields = {
            ...attentionOriginOptions,
            generalFields: {
                ...attentionOriginOptions.generalFields,
                [name]: option.value
            }
        };
        setAttentionOriginOptions(options);
        saveAttentionOriginDebounced(options);
    };

    const formatPredefinedTexts = (texts: PredefinedTextItem[]) => {
        return texts?.map(text => ({
            label: text.name as string,
            value: text.id
        }));
    };

    const renderAttentionOriginHeader = () => {
        const formattedOriginAttentionList = attentionOriginList.map(item => ({ label: item.name, value: item.id }));
        const formattedAttentionTimes = attentionTimes.map(item => ({ label: item.tim_name, value: item.tim_id }));

        return (
            shouldWrap ?
                <>
                    <div className="row g-3">
                        <div className="col-5 d-xxl-inline-flex align-items-center">
                            <label className="nowrap fw-bold text-secondary me-3">Origen de la atención</label>
                            <Select
                                disabled={isDisabledForm}
                                changeToInput={historySheet}
                                inputValue={attentionOriginOptions?.generalFields?.attentionOrigin?.name}
                                placeholder="Origen de la atención"
                                variant="plain"
                                options={formattedOriginAttentionList}
                                name="attentionOriginId"
                                value={formattedOriginAttentionList.find(item => item.value === attentionOriginOptions.generalFields.attentionOriginId)}
                                onChange={({ option, name }) => handleSelectAttention(option, name)}
                                style={{ width: "110%" }}
                            />
                        </div>
                        <div className="col-2">

                        </div>
                        <div className="col-5 d-xxl-inline-flex align-items-center justify-content-end">
                            <label className="nowrap fw-bold text-secondary text-end me-3">Tiempo de evolución</label>
                            <div className="input-group flex-nowrap" style={{ width: "auto" }}>
                                <input
                                    disabled={isDisabledForm}
                                    type="number"
                                    className="form-control"
                                    placeholder="Cantidad"
                                    value={attentionOriginOptions.generalFields.evolutionTime}
                                    onChange={({ target }) => setAttentionOriginOptions({
                                        ...attentionOriginOptions,
                                        generalFields: {
                                            ...attentionOriginOptions.generalFields,
                                            evolutionTime: target.value
                                        }
                                    })}
                                    style={{ maxWidth: 80, minWidth: 50 }}
                                    onKeyDown={(event) => event.key === "-" && event.preventDefault()}
                                />
                                <Select
                                    disabled={isDisabledForm}
                                    changeToInput={historySheet}
                                    inputValue={attentionOriginOptions.generalFields.evolutionTimeUnit?.name}
                                    inputGroup
                                    style={{ width: "100%", maxWidth: 50 }}
                                    placeholder="Tiempo"
                                    options={formattedAttentionTimes}
                                    name="evolutionTimeUnitId"
                                    onChange={({ option, name }) => handleSelectAttention(option, name)}
                                    value={formattedAttentionTimes.find(el => el.value === attentionOriginOptions.generalFields.evolutionTimeUnitId)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col d-inline-flex align-items-center justify-content-start">
                            <div className="form-check form-switch d-flex align-items-center justify-content-center"
                                style={{ paddingLeft: 0, marginTop: 12 }}
                            >
                                <label className="form-check-label fw-bold text-secondary me-2 text-center">Evento adverso</label>
                                <input
                                    disabled={isDisabledForm}
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    style={{ width: 25, height: 15 }}
                                    checked={attentionOriginOptions.generalFields?.abserveEvent ? true : false}
                                    onChange={({ target }) => {
                                        const options: typeof attentionOriginOptions = {
                                            ...attentionOriginOptions,
                                            generalFields: {
                                                ...attentionOriginOptions.generalFields,
                                                abserveEvent: target.checked ? 1 : 0
                                            }
                                        };
                                        setAttentionOriginOptions(options);
                                        saveAttentionOriginDebounced(options);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </> :

                <div className="row g-3">
                    <div className="col d-inline-flex align-items-center">
                        <label className="nowrap fw-bold text-secondary me-3">Origen de la atención</label>
                        <Select
                            changeToInput={historySheet}
                            inputValue={attentionOriginOptions?.generalFields?.attentionOrigin?.name}
                            disabled={isDisabledForm}
                            placeholder="Origen de la atención"
                            variant="plain"
                            options={formattedOriginAttentionList}
                            name="attentionOriginId"
                            value={formattedOriginAttentionList.find(item => item.value === attentionOriginOptions.generalFields.attentionOriginId)}
                            onChange={({ option, name }) => handleSelectAttention(option, name)}
                            style={{ width: "110%" }}
                        />
                    </div>
                    <div className="col d-inline-flex align-items-center justify-content-center">
                        <div className="form-check form-switch d-flex align-items-center justify-content-center">
                            <label className="form-check-label fw-bold text-secondary me-2 text-center">Evento adverso</label>
                            <input
                                disabled={isDisabledForm}
                                className="form-check-input m-0"
                                type="checkbox"
                                role="switch"
                                style={{ width: 25, height: 15 }}
                                checked={attentionOriginOptions.generalFields?.abserveEvent ? true : false}
                                onChange={({ target }) => {
                                    const options: typeof attentionOriginOptions = {
                                        ...attentionOriginOptions,
                                        generalFields: {
                                            ...attentionOriginOptions.generalFields,
                                            abserveEvent: target.checked ? 1 : 0
                                        }
                                    };
                                    setAttentionOriginOptions(options);
                                    saveAttentionOriginDebounced(options);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col d-inline-flex align-items-center justify-content-end">
                        <label className="nowrap fw-bold text-secondary text-end me-3">Tiempo de evolución</label>
                        <div className="input-group flex-nowrap" style={{ width: "auto" }}>
                            <input
                                disabled={isDisabledForm}
                                type="number"
                                className="form-control"
                                placeholder="Cantidad"
                                value={attentionOriginOptions.generalFields.evolutionTime}
                                onChange={({ target }) => setAttentionOriginOptions({
                                    ...attentionOriginOptions,
                                    generalFields: {
                                        ...attentionOriginOptions.generalFields,
                                        evolutionTime: target.value
                                    }
                                })}
                                style={{ maxWidth: 80, minWidth: 50 }}
                                onKeyDown={(event) => event.key === "-" && event.preventDefault()}
                            />
                            <Select
                                disabled={isDisabledForm}
                                inputGroup
                                inputValue={attentionOriginOptions.generalFields.evolutionTimeUnit?.name}
                                changeToInput={historySheet}
                                style={{ width: "100%", maxWidth: 50 }}
                                placeholder="Tiempo"
                                options={formattedAttentionTimes}
                                name="evolutionTimeUnitId"
                                onChange={({ option, name }) => handleSelectAttention(option, name)}
                                value={formattedAttentionTimes.find(el => el.value === attentionOriginOptions.generalFields.evolutionTimeUnitId)}
                            />
                        </div>
                    </div>
                </div>
        );
    };

    const render = () => {
        return (
            <SectionCard>
                {renderAttentionOriginHeader()}
                <hr className="mb-3 text-primary" />
                <div className="row g-3">
                    <div className="col-6">
                        <label className="text-label">Motivo de consulta</label>
                        <BadgeTextField
                            bookmarks={formatPredefinedTexts(attentionOriginOptions.reasonForConsulting.predefinedTxts)}
                            withDetails
                            onClick={() => !isDisabledForm && handleOpenTextModal("reasonForConsulting")}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("reasonForConsulting");
                            }}
                            onDeleteItem={(item) => handleOnDeleteItem("reasonForConsulting", item)}
                            value={attentionOriginOptions.reasonForConsulting.justification}
                            onChangeJustification={(value) => handleSetJustification("reasonForConsulting", value)}
                            isErasable={!isDisabledForm}
                            disabled={isDisabledForm}
                        />

                    </div>
                    <div className="col-6">
                        <label className="text-label">Enfermedad actual</label>
                        <BadgeTextField
                            bookmarks={formatPredefinedTexts(attentionOriginOptions.currentDisease.predefinedTxts)}
                            withDetails
                            onClick={() => !isDisabledForm && handleOpenTextModal("currentDisease")}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                !isDisabledForm && handleOpenNoInsTextModal("currentDisease");
                            }}
                            onDeleteItem={(item) => handleOnDeleteItem("currentDisease", item)}
                            value={attentionOriginOptions.currentDisease.justification}
                            onChangeJustification={(value) => handleSetJustification("currentDisease", value)}
                            isErasable={!isDisabledForm}
                            disabled={isDisabledForm}
                        />

                    </div>
                </div>
                <TextConfigModal
                    isOpen={insTextModal.isOpen}
                    current={insTextModal.current as string}
                    onClose={handleCloseTextModal}
                    width={800}
                    onSelectItem={(current, item, index) => handleOnSelectItem(current as MasterFields, item, index)}
                />
                <TextConfigNoInsModal
                    isOpen={noInsTextModal.isOpen}
                    current={noInsTextModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    onSelectItem={(current, item) => handleAttatchText(current as MasterFields, item)}
                />
            </SectionCard>
        );
    };

    return render();
}