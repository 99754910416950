import axios from "axios";
//
import { statusCodes } from "@/constants/http";
import { IResponse } from "@/models";
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { fireErrorAlert } from "@/utils";
import { AppDispatch } from "@/config/store";
import {
    getAttentionOriginsService,
    getAttentionTimesService,
    getGeneralFieldsService,
    saveAttentionOriginService
} from "@/services";
import { finishLoading, startLoading } from "@/config/slices";
import { GeneralFieldsParams } from "@/models/generalFields";

export function getGeneralFields(params: GeneralFieldsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getGeneralFieldsService(params);
            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error("There was an error => ", error);

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function saveAttentionOrigin(payload: AttentionOriginFields) {
    return async () => {
        const response = await saveAttentionOriginService(payload);
        return response.results;
    };
}

export async function getAttentionOrigins() {
    try {
        const response = await getAttentionOriginsService({ active: 1 });
        if (response.status && response.status !== statusCodes.OK) {
            fireErrorAlert(response.message as string);
            return;
        }
        return response.results;

    } catch (error) {
        if (axios.isAxiosError<IResponse>(error)) {
            fireErrorAlert(error.response?.data.message as string);
        }
        console.error("There was an error =>", error);
    }
}

export async function getAttentionTimes() {
    try {
        const response = await getAttentionTimesService();
        if (response.status && response.status !== statusCodes.OK) {
            fireErrorAlert(response.message as string);
            return;
        }
        return response.results;

    } catch (error) {
        if (axios.isAxiosError<IResponse>(error)) {
            fireErrorAlert(error.response?.data.message as string);
        }
        console.error("There was an error =>", error);
    }
}