import { IconBell, IconBuildingHospital, IconStethoscope, IconTicket } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//
import BiowellLogo from "../../../assets/biowell-logo.png";
//
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { logoutUser } from "../../Auth/auth.actions";
//
import { Avatar } from "../../../components";
//
import routes from "@/constants/routes";
import ChangeAccount from "./ChangeAccount/ChangeAccount";
import "./Header.scss";
import HelpModal from "./Help/Help";
import ProfileModal from "./Profile/Profile";
import { getNotifications } from "./header.actions";
import SocketsNotification from "./sockets/SocketsNotification";



export default function Header() {
    const dispatch = useAppDispatch();
    const userData = useAppSelector(state => state.auth.user_data);
    const workspace = useAppSelector(state => state.workspace);
    const navigate = useNavigate();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const location = useLocation();
    const menuRef = useRef<HTMLDivElement>(null);
    const notificationRef = useRef<HTMLDivElement>(null);
    const [openConfigurationMenu, setOpenConfigurationMenu] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const [openHelpModal, setOpenHelpModal] = useState(false);
    const [hasNotifications, setHasNotifications] = useState<{
        show: boolean,
        pendings: number | MessageEvent;
    }>({
        show: false,
        pendings: 0
    });
    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getNotifications(userId, workspace.id));
            getRealTimeData(data as number);
        }
        fetchData();
    }, [dispatch, workspace.id, userId, location.pathname]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent): void => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setOpenConfigurationMenu(false);
            }
        };
        openConfigurationMenu && document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [menuRef, setOpenConfigurationMenu, openConfigurationMenu]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent): void => {
            if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
                setOpenNotifications(false);
            }
        };
        openNotifications && document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [notificationRef, setOpenNotifications, openNotifications]);


    const getRealTimeData = (data: number) => {
        setHasNotifications({
            show: Number(data) > 0 ? true : false,
            pendings: Number(data),
        });
    };

    const handleCloseProfileModal = (): void => setOpenProfileModal(false);
    const handleToggleConfigurationMenu = (): void => setOpenConfigurationMenu(!openConfigurationMenu);
    const handleToggleNotifications = (): void => setOpenNotifications(!openNotifications);

    const handleOpenAccountModal = (): void => {
        setOpenConfigurationMenu(false);
        setOpenAccountModal(!openAccountModal);
    };

    const handleCloseAccountModal = (): void => setOpenAccountModal(false);

    const handleOpenHelpModal = (): void => setOpenHelpModal(true);
    const handleCloseHelpModal = (): void => setOpenHelpModal(false);

    const handleLogout = (): void => { dispatch(logoutUser()); };

    const { REACT_APP_URL_REDIRECT_BIOWEL } = process.env;
    const renderNotifications = () => {
        return (
            <div className="">
                <div className="headerNotifications ">
                    <div className="pt-2 px-3 text-muted">Notificaciones</div>
                    <hr className="my-1" />
                    <div className="py-2 px-3 d-flex">
                        <div className="align-self-center d-flex align-items-center"
                            style={{
                                paddingRight: 6,
                                borderRight: "2px solid #ff9300",
                                height: 30,
                                color: "#ff9300"
                            }}
                        >
                            <IconTicket />
                        </div>
                        {
                            hasNotifications &&
                            <div className="d-flex flex-column mx-2 pointer"
                                onClick={() => {
                                    navigate(routes.workspaceDiagnosticAidReadingsSheet);
                                }}
                            >
                                <span className="text-primary">Lecturas pendientes ({String(hasNotifications.pendings)})</span>
                                <span className="text-muted">{userData.first_name} {userData.first_surname}</span>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    };
    const renderMenuOptions = () => {
        return (
            <div className="header-account__options">
                <div className="d-flex flex-column align-items-center justify-content-center my-3">
                    <Avatar src={userData.image ? userData.image : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"} size="large" />
                    <span className="text-muted fw-bold mt-2">{userData.first_name} {userData.first_surname}</span>
                    <small className="text-primary">{workspace.name} - {workspace.profile?.nombre}</small>
                </div>
                <div className="header-account__options-item" onClick={handleOpenAccountModal}>
                    Cambiar rol y cuenta
                </div>
                <a className="header-account__options-item" href={`${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE}/configuracion/miperfil`}>
                    Configuración
                </a>
                <div className="header-account__options-item" onClick={handleOpenHelpModal}>
                    Ayuda
                </div>
                <div className="header-account__options-item" onClick={handleLogout}>
                    Cerrar sesión
                </div>
            </div>
        );
    };

    const renderUserMenu = () => {
        return (
            <div className="header-account" ref={menuRef}>
                <div className="w-100 d-flex justify-content-between align-items-center pointer" onClick={handleToggleConfigurationMenu}>
                    {workspace.name ? (
                        <div className="header-account__labels">
                            <IconBuildingHospital />
                            <span>{workspace.name}</span>
                        </div>
                    ) : <div></div>}
                    <Avatar src={userData.image ? userData.image : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"} size="small" />
                </div>
                {openConfigurationMenu && renderMenuOptions()}
            </div>
        );
    };

    const render = () => {
        return (
            <div className="header">
                <a href={`${REACT_APP_URL_REDIRECT_BIOWEL}/categoryselect`}
                >
                    <img
                        width={100}
                        src={BiowellLogo}
                        className="img-fluid pointer"
                        alt="Biowell Workspace"
                    />

                </a>
                <div className="circle"></div>
                <span className="header-title">Historia Clínica</span>
                {(workspace.name && (Number(hasNotifications.pendings) > 0)) &&
                    <div className="position-relative mx-2" ref={notificationRef}>
                        <div className="pointer text-muted mx-2 "
                            onClick={handleToggleNotifications}
                        >
                            <IconBell />
                            {hasNotifications.show && <div className="notification-dot"></div>}
                        </div>
                    </div>
                }
                {openNotifications && renderNotifications()}
                <div className="header-detail">
                    <IconStethoscope />
                    <span>{userData.first_name} {userData.first_surname}</span>
                </div>
                {renderUserMenu()}
                <ProfileModal
                    isOpen={openProfileModal}
                    onClose={handleCloseProfileModal}
                    userData={userData}
                />
                <ChangeAccount
                    isOpen={openAccountModal}
                    onClose={handleCloseAccountModal}
                    userData={userData}
                />
                <HelpModal
                    isOpen={openHelpModal}
                    onClose={handleCloseHelpModal}
                />
                <SocketsNotification />
            </div>
        );
    };

    return render();
}