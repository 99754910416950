import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { SuspendAppointmentPayload, UnexpectedSituationParams, UnexpectedSituationPayload, UnexpectedSituationResponse } from "@/models/shared/unexpectedSituation";
import { setHeaders } from "@/utils";

export async function getUnexpectedSituationService(params: UnexpectedSituationParams): Promise<UnexpectedSituationResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<UnexpectedSituationResponse>(
        "/medical/clinicHistory/unexpectedSituation/", axiosParams
    );
    return serviceData;
}

export async function updateUnexpectedSituationService(payload: UnexpectedSituationPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/unexpectedSituation/", payload, axiosParams
    );
    return serviceData;
}
export async function createUnexpectedSituationService(payload: UnexpectedSituationPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/unexpectedSituation/", payload, axiosParams
    );
    return serviceData;
}
export async function suspendAttentionService(payload: SuspendAppointmentPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/suspensionReasonUnexpectedSituation/", payload, axiosParams
    );
    return serviceData;
}


