import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import { ITable, TablePaginationProps } from "./tableAccordion";
import "./TableAccordion.scss";

export function TableAccordion({ children, isCompact = false, className = "" }: ITable) {
    return <table className={`table-wrapper-accordion table-wrapper-primaryAccordion ${className} ${isCompact ? "compact" : ""}`}>{children}</table>;
}

export function TablePagination(props: TablePaginationProps) {

    const onNextPage = (): void => {
        if (props.currentPage < Math.ceil(props.totalPages / props.perPage)) {
            const newPage = props.currentPage + 1;
            props.onNextPage(newPage);
        }
    };

    const onPrevPage = (): void => {
        if (props.currentPage > 1) {
            const newPage = props.currentPage - 1;
            props.onNextPage(newPage);
        }
    };

    const onMaxPage = (): void => {
        props.onMaxPage(Math.ceil(props.totalPages / props.perPage));
    };

    const onMinPage = (): void => props.onMinPage(1);

    return (
        <div className="table-pagination">
            <div className="table-pagination__total">
                Pag. {props.currentPage} de {Math.ceil(props.totalPages / props.perPage)} {`(${props.totalCount} encontrados)`}
            </div>
            <div className="table-pagination__controls">
                <IconChevronsLeft className="pointer no-user-select" onClick={() => onMinPage()} />
                <IconChevronLeft className="pointer no-user-select" onClick={() => onPrevPage()} />
                <span className="number">{props.currentPage} / {Math.ceil(props.totalPages / props.perPage)}</span>
                <IconChevronRight className="pointer no-user-select" onClick={() => onNextPage()} />
                <IconChevronsRight className="pointer no-user-select" onClick={() => onMaxPage()} />
            </div>
        </div>
    );
}