import { Outlet } from "react-router-dom";
import "./PatientAttention.scss";

export default function PatientAttention() {

    const render = () => {
        return (
            <div className="patient-attention">
                <Outlet />
            </div>
        );
    };

    return render();
}