import * as Yup from "yup";

const noNegatives = Yup.number().integer().notRequired().transform((value) => Number.isNaN(value) ? undefined : value).nullable();

export const schemaScreening = Yup.object({
    idScreening: noNegatives.min(0),
    pachymetry_od: noNegatives.min(445).max(645),
    pachymetry_oi: noNegatives.min(445).max(645),
    intraocular_pressure_uncorrected_od: noNegatives.min(0),
    intraocular_pressure_uncorrected_oi: noNegatives.min(0),
});

export type TFormDataScreening = Yup.InferType<typeof schemaScreening>;