import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { getFarVisionList, getIdentifiedLetters, getLensAdd, getLensType, getNearVisionList, getOptotypes } from "@/services";

export function getOptometrySelects() {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const optotypesResponse = await getOptotypes();
            const farVisionResponse = await getFarVisionList();
            const nearVisionResponse = await getNearVisionList();
            const idLettersResponse = await getIdentifiedLetters();
            const lensAddResponse = await getLensAdd();
            const lensTypeResponse = await getLensType();

            return {
                optotypesResponse: optotypesResponse.results,
                farVisionResponse: farVisionResponse.results,
                nearVisionResponse: nearVisionResponse.results,
                idLettersResponse: idLettersResponse.results,
                lensAddResponse: lensAddResponse.results,
                lensTypeResponse: lensTypeResponse.results
            };

        } finally {
            dispatch(finishLoading());
        }
    };
}
