import {
    IconChevronDown,
    IconChevronLeft,
    IconClipboardHeart,
    IconExclamationMark,
    IconX
} from "@tabler/icons-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
//
import {
    Avatar,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Modal
} from "@/components";
import routes from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Comment } from "@/models/comment";
import { PatientHeader } from "@/models/preconsultation";
import Comments from "../Comments/Comments";
import { createComment, deleteComment, getComments } from "../Comments/comments.actions";
import { getPatientHeader } from "../PreConsultation/preConsultation.actions";
import Antecedents from "./Antecedents/AntedecentsModal";
import "./Header.scss";
import { formatteDocument } from "@/utils";
import { Timer } from "./Timer";

type PreconsultItems = { value: string, label: string; };

export default function ConsultationHeader(
    { currentSection, showHistory, setShowHistory, isDisabledForm, patientId, appId, cluId }:
        {
            currentSection: string;
            showHistory: boolean;
            setShowHistory?: (state: boolean) => void;
            isDisabledForm?: boolean;
            patientId?: number;
            appId?: number | null;
            cluId?: number | null;
        }
) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    dayjs.extend(relativeTime);
    const location = useLocation();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const appointment = useAppSelector(state => state.patientAttention.appointmentSelected);
    const eaccount = useAppSelector(state => state.workspace.id);

    const [patientHeader, setPatientHeader] = useState<PatientHeader>();
    const [comments, setComments] = useState<Comment[]>([]);
    const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState<boolean>(false);
    const [patientInfoModal, setPatientInfoModal] = useState({
        isOpen: false,
    });
    const [antecedentsConfig, setAntecedentsConfig] = useState({
        isOpen: false
    });

    const preconsult: PreconsultItems[] = [
        { label: "Dilatación", value: "dilatation" },
        { label: "Signos vitales", value: "vitalSigns" },
        { label: "Tamizaje ocular", value: "eyescreening" },
        { label: "Conciliación medicamentosa", value: "medicines" },
    ];

    useEffect(() => {
        async function fetchData() {
            if (patientId ?? appointment?.patientId) {
                const data = await dispatch(getComments({
                    entity: "userclient",
                    id: patientId ?? appointment?.patientId
                }));
                setComments(data as Comment[]);
            }
        }
        fetchData();
    }, [dispatch, patientId, appointment?.patientId]);

    useEffect(() => {
        async function fetchData() {
            if ((appId ?? appointment?.app_id) && eaccount) {
                const data = await dispatch(getPatientHeader({
                    appId: appId ?? appointment?.app_id,
                    eaccount: eaccount
                }));
                setPatientHeader(data);
            }
        }
        fetchData();
    }, [appId, appointment?.app_id, eaccount, dispatch]);

    const handleCloseCommentsDrawer = (): void => setIsCommentsDrawerOpen(false);
    const handleOpenCommentsDrawer = (): void => setIsCommentsDrawerOpen(true);

    const handleCloseAntecedentsModal = (): void => setAntecedentsConfig({
        isOpen: false
    });
    const handleOpenAntecedentsModal = (): void => setAntecedentsConfig({
        isOpen: true
    });

    const handleReloadHeader = async () => {
        if ((appId ?? appointment?.app_id) && eaccount) {
            const data = await dispatch(getPatientHeader({
                appId: appId ?? appointment?.app_id,
                eaccount: eaccount
            }));
            setPatientHeader(data);
        }
    };

    const handleSubmitComment = async (text: string): Promise<void> => {
        await dispatch(createComment({
            comment: text,
            entity: "userclient",
            userId: userId as number,
            id: patientId ?? appointment?.patientId as number,
        }));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: patientId ?? appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const handleDeleteComment = async (id: string): Promise<void> => {
        await dispatch(deleteComment(id));
        const data = await dispatch(getComments({
            entity: "userclient",
            id: patientId ?? appointment?.patientId
        }));
        setComments(data as Comment[]);
    };

    const handleOpenPatientInfoModal = () => {
        setPatientInfoModal({ ...patientInfoModal, isOpen: true });
    };

    const handleClosePatientInfoModal = () => {
        setPatientInfoModal({ ...patientInfoModal, isOpen: false });
    };

    const IMG_EXT_REGEX = /\.(jpeg|jpg|png|gif)$/i;

    const renderModal = () => {
        return (
            <Modal onClose={handleClosePatientInfoModal}
                isOpen={patientInfoModal.isOpen}
                positionModal={"center"}
                width={700}
                className="textconfig-modal">
                <div className="p-4">
                    <div className="d-flex justify-content-center">
                        <Avatar
                            size="extraLarge"
                            src={
                                patientHeader?.url_photo
                                    ? IMG_EXT_REGEX.test(patientHeader.url_photo)
                                        ? patientHeader?.url_photo
                                        : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                                    : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                            }
                            onClick={handleOpenPatientInfoModal}
                        />

                    </div>
                    <div className="d-flex flex-column text-center p-2">
                        <b className="text-secondary fw-bold fs-2 ms-2">{patientHeader?.cluFirstName} {patientHeader?.cluSecondName} {patientHeader?.cluLastName} {patientHeader?.cluSecondSurname}</b>
                        <span className="text-secondary fw-bold fs-6 ms-2">CC {formatteDocument({ value: patientHeader?.cluDocument }).format} | EPS {patientHeader?.cclientName}</span>
                    </div>
                    <div className="d-flex px-4 py-2 f-4 text-muted">
                        <div className="col-6 px-4">
                            <div className="d-flex">
                                <b className="col-4">Edad</b>
                                <div className="col-8"> {patientHeader?.age}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Género</b>
                                <div className="col-8"> {patientHeader?.gender}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Teléfono</b>
                                <div className="col-8"> {patientHeader?.cellphone}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Email</b>
                                <div className="col-8"> {patientHeader?.email}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Servicios</b>
                                <div className="col-8"> {patientHeader?.service_offered}</div>
                            </div>
                        </div>
                        <div className="col-6 px-4">
                            <div className="d-flex">
                                <b className="col-4">Aseguradora</b>
                                <div className="col-8"> {patientHeader?.insurance_company}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Contrato</b>
                                <div className="col-8"> {patientHeader?.contractName}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Médico</b>
                                <div className="col-8"> {patientHeader?.med}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Fecha</b>
                                <div className="col-8"> {patientHeader?.appointment_date}</div>
                            </div>
                            <div className="d-flex">
                                <b className="col-4">Hora</b>
                                <div className="col-8"> {patientHeader?.appointment_time}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <Button
                    variant="primary"
                    className="align-self-center mt-3"
                    onClick={() => {
                        handleClosePatientInfoModal();
                    }}
                >
                    Aceptar
                </Button>
            </Modal>
        );
    };

    const render = () => {
        return (
            <div className="consultation-header align-items-end">
                {renderModal()}
                {
                    (currentSection === "reading" || currentSection === "transcription" || currentSection === "adx") &&
                    <div className="pointer text-secondary align-self-center"
                        onClick={() => {
                            currentSection === "reading" && navigate(routes.workspaceDiagnosticAidReadingsSheet);
                            currentSection === "transcription" && navigate(routes.workspaceTranscription);
                            currentSection === "adx" && navigate(routes.workspaceDiagnosticAids);
                        }}
                    >
                        <IconChevronLeft />
                    </div>
                }
                <Avatar
                    src={
                        patientHeader?.url_photo
                            ? IMG_EXT_REGEX.test(patientHeader.url_photo)
                                ? patientHeader?.url_photo
                                : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                            : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                    }
                    onClick={handleOpenPatientInfoModal} className="pointer align-self-center"
                />
                <span className="text-secondary fw-bold fs-5 ms-2 align-self-center">{!location.pathname.includes("DiagnosticAids") && currentSection}</span>
                <div className="d-flex align-items-center ms-3">
                    <div className="ms-3">
                        <small className="text-muted">Paciente</small>
                        <div className="fw-bold text-secondary">{patientHeader?.cluFirstName} {patientHeader?.cluSecondName} {patientHeader?.cluLastName}</div>
                    </div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Identificación</small>
                    <div className="fw-bold text-secondary">{patientHeader?.docTypeDescription} {formatteDocument({ value: patientHeader?.cluDocument ?? "" }).format}</div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Aseguradora</small>
                    <div className="fw-bold text-secondary">{patientHeader?.cclientName}</div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Contrato</small>
                    <div className="fw-bold text-secondary">{patientHeader?.contractName}</div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Edad</small>
                    <div className="fw-bold text-secondary">{patientHeader?.age} años</div>
                </div>
                {
                    !location.pathname.includes("DiagnosticAids") &&
                    <Timer
                        startTime={patientHeader?.startAttentionTime || ""}
                        endTime={patientHeader?.endAttentionTime}
                    />
                }
                {
                    !location.pathname.includes("DiagnosticAids") &&
                    <Dropdown className="dropdown ms-4">
                        <div className="d-flex align-items-end pointer no-user-select" data-bs-toggle="dropdown">
                            <span className="fw-bold me-1">Preconsulta</span>
                            <div>
                                <IconChevronDown width={15} style={{ paddingTop: "4px" }} />
                            </div>
                        </div>
                        <DropdownMenu isOpen={true}>
                            {preconsult.map((item, index) => (
                                <Link
                                    key={item.value + `-${index}`}
                                    className="no-decoration"
                                    to={routes.consultationPreconsultation}
                                    state={{ tab: item.value }}
                                >
                                    <DropdownItem>
                                        {item.label}
                                    </DropdownItem>
                                </Link>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                }
                <div className="flex-fill"></div>
                {currentSection !== "transcription" &&
                    <>
                        <div
                            className="pointer mx-2 text-primary align-self-center"
                            onClick={handleOpenAntecedentsModal}
                            data-tooltip-id="allergies-tooltip"
                            data-tooltip-content={"Antecedentes"}
                        >
                            <IconClipboardHeart />
                        </div>
                        <div
                            className="d-flex align-items-center align-self-center">
                            {patientHeader?.alertsOcularNotes && (
                                <div
                                    className="dynamic-icon dynamic-icon__warning ms-3"
                                    data-tooltip-id="allergies-tooltip"
                                    data-tooltip-content={patientHeader.alertsOcularNotes}
                                >
                                    <IconX />
                                </div>
                            )}
                            {patientHeader?.allergiesGeneralNotes && (
                                <div
                                    className="dynamic-icon dynamic-icon__danger ms-3"
                                    data-tooltip-id="allergies-tooltip"
                                    data-tooltip-content={patientHeader.allergiesGeneralNotes}
                                >
                                    <IconExclamationMark strokeWidth={3} />
                                </div>
                            )}
                            <div className="message-icon ms-3" onClick={handleOpenCommentsDrawer}>
                                {comments?.length}
                            </div>
                        </div>
                        <Antecedents
                            isOpen={antecedentsConfig.isOpen}
                            onClose={handleCloseAntecedentsModal}
                            isDisabledForm={isDisabledForm}
                            onSuccess={handleReloadHeader}
                            appId={appId ?? appointment?.app_id}
                            data={{ tag: "adx" }}
                            cluId={cluId}
                        />
                        <Comments
                            data={comments}
                            isOpen={isCommentsDrawerOpen}
                            onClose={handleCloseCommentsDrawer}
                            width={400}
                            drawer="right"
                            onCreateComment={(value) => handleSubmitComment(value)}
                            onDeleteComment={(id) => handleDeleteComment(id)}
                        />
                        <Tooltip id="allergies-tooltip" />
                    </>
                }
            </div >
        );
    };

    return render();
}