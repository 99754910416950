import axios from "axios";
import * as uiActions from "@/config/slices/ui";
import * as consultationService from "@/services/consultation.service";
//
import { fireErrorAlert } from "@/utils/utils";
import { IResponse } from "@/models/general";
import { AppDispatch } from "@/config/store";
import { statusCodes } from "@/constants/http";
import { CommentPayload } from "@/models/comment";

export function getComments(params: Record<string, unknown>) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const filters = { ...params };
            const response = await consultationService.getComments(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error(error);

        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}

export function createComment(payload: CommentPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const response = await consultationService.createComment(payload);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error(error);

        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}

export function deleteComment(id: string) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const response = await consultationService.deleteComment(id);

            if (response.status && response.status !== statusCodes.CREATED) {
                fireErrorAlert(response.message as string);
                return;
            }

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error(error);

        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}
