
import { IconSchema } from "@/assets/icons";
import { DayPicker } from "react-day-picker";
import { ICustomDayPicker } from "./customDayPicker";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";

export const CustomDayPicker = ({ dayPickerProps }: ICustomDayPicker) => {

    const [isActiveDayPicker, setIsActiveDayPicker] = useState<boolean>(false);
    const handleToggleDayPicker = () => setIsActiveDayPicker(state => !state);

    useEffect(() => {
        if (isActiveDayPicker) {
            const optionsYears = document.querySelector(".rdp-dropdown_year")?.children[1].children;
            const elements = [].slice.call(optionsYears);
            Array.from(elements)?.forEach((year: HTMLInputElement, i) => {
                year.textContent = String(Number(new Date().getFullYear()) - i);
                year.value = String(Number(new Date().getFullYear()) - i);
            });
        }

    }, [isActiveDayPicker]);

    return (
        <div className="btn-group">
            <button
                className="dropdown-toggle"
                type="button"
                id="dropdownMenuHistory"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                <IconSchema
                    height={20}
                    width={20}
                    className="pointer textGray100"
                    onClick={handleToggleDayPicker}
                />
            </button>
            <div className="dropdown-menu dayPickerStyles" aria-labelledby="dropdownMenuHistory">
                <DayPicker {...dayPickerProps} locale={es} />
            </div>
        </div>
    );
};