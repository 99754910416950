import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { SitesParams } from "@/models";
import { ResultsCheckList } from "@/models/surgery/patientAttention/checklist";
import { AddLotArticlePayload, AttentionAppParams, ConsumptionCenterParamsSheet, CreateVitalSingsPayload, EvidencePayload, IntervenedPayload, NurseryNotes, NurseryNotesParams, QuantityPayload, StartSurgeryPayload, TransferOperatingRoomPayload, deleteSupplyParams } from "@/models/surgery/patientAttention/patientAttention";
import { getSitesListService, getSurgeonsService, getSurgeriesRoomService } from "@/services";
import { addLotArticleService, anesthesiaTimeService, attentionIntervenedService, cancellationListService, changeEvidenceService, changeQuantityService, createVitalSingsService, deleteEvidenceService, deleteSupplyService, getAttentionAppointmentService, getCheckListService, getConsumptionCentersService, getConsumptionSheetService, getHeaderSurgeryService, getJustificationsListService, getNurseryNotesService, getOperatingRoomsService, getSheetsIdsService, justificationMotivesListService, preoperativeDiagnosesService, selectOperatingRoomService, sendAuthCodeService, setRecordChartService, startSurgeryAttentionService, startSurgerySegmentService, transferOperatingRoomService, translateSurgeryAttentionService, updateCheckListService, updateNurseryNotesService, vitalSingListService } from "@/services/surgery/patientAttention";


export function getAttentionAppointment(params: AttentionAppParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAttentionAppointmentService(params);
            if (response.success) return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getHeaderSurgery(appId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getHeaderSurgeryService(appId);
            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getSitesSurgery(params: SitesParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSitesListService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getSurgeryRooms() {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSurgeriesRoomService();
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getSurgeons(params?: { profilePrefix?: string; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSurgeonsService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function startSurgeryAttention(appId: number, userId: number | null, eaccount: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await startSurgeryAttentionService(appId, userId, eaccount);
            return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function translateSurgeryAttention(params: { appId: number, stagePrefix: string, eaccount: number; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await translateSurgeryAttentionService(params);
            return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getNurseryNotes(params: NurseryNotesParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getNurseryNotesService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateNurseryNotes(payload: NurseryNotes) {
    return async function (dispatch: AppDispatch) {
        const response = await updateNurseryNotesService(payload);
        return response;
    };
}

export function getConsumptionCenters(params: ConsumptionCenterParamsSheet) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getConsumptionCentersService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function addLotArticle(payload: AddLotArticlePayload) {
    return async function (dispatch: AppDispatch) {
        const response = await addLotArticleService(payload);
        return response;
    };
}

export function getConsumptionCentersSheet(params: ConsumptionCenterParamsSheet) {
    return async function (dispatch: AppDispatch) {
        const response = await getConsumptionSheetService(params);
        return response.results;
    };
}

export function deleteSupply(params: deleteSupplyParams) {
    return async function (dispatch: AppDispatch) {
        const response = await deleteSupplyService(params);
        return response;
    };
}


export function changeQuantity(params: QuantityPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await changeQuantityService(params);
        return response;
    };
}
export function changeEvidence(params: EvidencePayload) {
    return async function (dispatch: AppDispatch) {
        const response = await changeEvidenceService(params);
        return response;
    };
}


export function deleteEvidence(params: { filId: number; }) {
    return async function (dispatch: AppDispatch) {
        const response = await deleteEvidenceService(params);
        return response;
    };
}

export function getOperatingRooms(params: { appId: number; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getOperatingRoomsService(params);
            return response.results;
        } finally {
            dispatch(finishLoading());
        }

    };
}

export function selectOperatingRoom(params: { "appId": number, "consultingRoomId": number; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await selectOperatingRoomService(params);
            return response;
        } finally {
            dispatch(finishLoading());
        }

    };
}

export function sendAuthCode(params: { "appId": number, "code": number; stgPrefix: string; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await sendAuthCodeService(params);
            return response;
        } finally {
            dispatch(finishLoading());
        }

    };
}

export function getJustificationsList() {
    return async function () {
        const response = await getJustificationsListService();
        return response.results;
    };
}

export function transferOperatingRoom(params: TransferOperatingRoomPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await transferOperatingRoomService(params);
            return response;
        } finally {
            dispatch(finishLoading());
        }

    };
}

export function getCheckList(params: { numId: number; }) {
    return async function () {
        const response = await getCheckListService(params);
        return response.results;
    };
}
export function getRecordChart(params: { appId: number; }) {
    return async function () {
        const response = await setRecordChartService(params);
        return response.results;
    };
}
export function updateCheckList(params: ResultsCheckList) {
    return async function (dispatch: AppDispatch) {
        const response = await updateCheckListService(params);
        return response;
    };
}

export function getVitalSingList(params: { enabled: number; stgPrefix: string; }) {
    return async function (dispatch: AppDispatch) {
        const response = await vitalSingListService(params);
        return response.results;
    };
}

export function createVitalSingsSurgery(params: CreateVitalSingsPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await createVitalSingsService(params);
        return response;
    };
}
export function startSurgerySegment(params: StartSurgeryPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await startSurgerySegmentService(params);
        return response;
    };
}


export function getAnesthesiaTime(params: { configTag: string; careTag: string; eaccount: number; }) {
    return async function (dispatch: AppDispatch) {
        const response = await anesthesiaTimeService(params);
        return response.results;
    };
}


export function attentionIntervened(params: IntervenedPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await attentionIntervenedService(params);
        return response;
    };
}

export function cancellationList() {
    return async function (dispatch: AppDispatch) {
        const response = await cancellationListService();
        return response.results;
    };
}

export function justificationMotivesList(param: { cancellationReasonId: number; }) {
    return async function (dispatch: AppDispatch) {
        const response = await justificationMotivesListService(param);
        return response.results;
    };
}
export function preoperativeDiagnoses(appId: number) {
    return async function (dispatch: AppDispatch) {
        const response = await preoperativeDiagnosesService({ appId });
        return response.results;
    };
}
export function getSheetsIds(appId: number) {
    return async function (dispatch: AppDispatch) {
        const response = await getSheetsIdsService(appId);
        return response.results;
    };
}

