import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getBreadthAccommodationLevelService } from "@/services";
import { useEffect, useState } from "react";

interface BreadthAccParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    historySheet?: boolean;
}

export default function Accomodation({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm, historySheet }: BreadthAccParams) {
    const [levels, setLevels] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await getBreadthAccommodationLevelService();
                setLevels(data.results);
            }
        }
        fetchData();
    }, []);

    const onChangeAcc = (acc: "balId" | "breadthAccommodationDistance" | "breadthAccommodationOd" | "breadthAccommodationOi", value: string) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            breadthAccommodation: {
                ...orthopticPayload.breadthAccommodation,
                [acc]: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const levelsFormatted = levels.map(item => ({ label: item.description, value: item.id }));
        const levelsValue = levelsFormatted.find(item => item.value === orthopticPayload.breadthAccommodation?.balId);
        const levelsValueInput = orthopticPayload.breadthAccommodation?.level;

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold align-self-start">
                    Amplitud de acomodación
                </h5>
                <hr className="text-primary mt-2 mb-4" />
                <div className="row d-flex align-items-center"
                    style={{ paddingLeft: 8 }}
                >
                    <div className="col-lg-5 d-flex align-items-center p-2 bg-od" style={{ borderRadius: "0.5rem 0.5rem 0rem 0rem" }}>
                        <span className="text-secondary me-3" style={{ width: 30 }}>OD</span>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.breadthAccommodation?.breadthAccommodationOd ?? orthopticPayload.breadthAccommodation?.od}
                            onChange={(event) => {
                                const values = event.target.value.split(".");
                                if (values[1] && values[1].length > 2) {
                                    event.preventDefault();
                                } else {
                                    onChangeAcc("breadthAccommodationOd", event.target.value);
                                }
                            }}
                        />
                    </div>
                    <div className="col-lg-7 d-flex align-items-center justify-content-between">
                        <span className="text-secondary " style={{ width: 85 }}>Nivel</span>
                        <Select
                            disabled={isDisabledForm}
                            changeToInput={historySheet}
                            inputValue={levelsValueInput}
                            style={{ width: "100%" }}
                            width="73%"
                            options={levelsFormatted}
                            value={levelsValue}
                            onChange={({ option }) => onChangeAcc("balId", option.value)}
                        />
                    </div>
                </div>
                <div className="row d-flex align-items-center "
                    style={{ paddingLeft: 8 }}
                >
                    <div className="col-lg-5 d-flex align-items-center bg-oi p-2 " style={{ borderRadius: "0rem 0rem 0.5rem 0.5rem" }}>
                        <span className="text-secondary me-3" style={{ width: 30 }}>OI</span>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.breadthAccommodation?.breadthAccommodationOi ?? orthopticPayload.breadthAccommodation?.oi}
                            onChange={(event) => {
                                const values = event.target.value.split(".");
                                if (values[1] && values[1].length > 2) {
                                    event.preventDefault();
                                } else {
                                    onChangeAcc("breadthAccommodationOi", event.target.value);
                                }
                            }}
                        />
                    </div>
                    <div className="col-lg-7 d-flex align-items-center justify-content-between">
                        <span className="text-secondary me-3 nowrap">Distancia (cm)</span>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.breadthAccommodation?.breadthAccommodationDistance ?? orthopticPayload.breadthAccommodation?.distance}
                            onChange={({ target }) => onChangeAcc("breadthAccommodationDistance", target.value)}
                            onKeyDown={(event) => event.key === "." && event.preventDefault()}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}