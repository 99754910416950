import { SectionCard, Select } from "@/components";
import { Vision } from "@/models/select/vision";
import { OrthopticPayload } from "@/models/sheets/orthoptic";
import { useEffect, useState } from "react";
import { getLensAV, getLensAdd } from "../orthoptic.action";

interface ChromaticVisionParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    historySheet?: boolean;
}

export default function FinalFormula({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm, historySheet }: ChromaticVisionParams) {
    const [lensAdd, setLensAdd] = useState<Vision[]>([]);
    const [lensAV, setLensAV] = useState<Vision[]>([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getLensAdd();
            if (data) setLensAdd(data);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await getLensAV();
                if (data) setLensAV(data);
            }
        }
        fetchData();
    }, []);

    const onChangeLentsInputs = (inputType: {
        lentsType: "od" | "oi";
        lentsItem: "sphere" | "cylinder" | "axis" | "brand" | "addId" | "farId";
        value: number | string;
    }) => {
        const options: typeof orthopticPayload = {
            ...orthopticPayload,
            functionalOptometryFinalFormula: {
                ...orthopticPayload.functionalOptometryFinalFormula,
                [inputType.lentsType]: {
                    ...orthopticPayload.functionalOptometryFinalFormula?.[inputType.lentsType],
                    [inputType.lentsItem]: inputType.value
                }
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const renderTableBody = () => {
        const lensAddFormatted = lensAdd.map(item => ({ label: item.value, value: item.id }));
        const lensAddODValue = lensAddFormatted.find(item => item.value === orthopticPayload.functionalOptometryFinalFormula?.od?.addId);
        const lensAddODValueInput = orthopticPayload.functionalOptometryFinalFormula?.od?.add;
        const lensAddOIValue = lensAddFormatted.find(item => item.value === orthopticPayload.functionalOptometryFinalFormula?.oi?.addId);
        const lensAddOIValueInput = orthopticPayload.functionalOptometryFinalFormula?.oi?.add;

        const lensAVFormatted = lensAV.map(item => ({ label: item.value, value: item.id }));
        const lensAVODValue = lensAVFormatted.find(item => item.value === orthopticPayload.functionalOptometryFinalFormula?.od?.farId);
        const lensAVODValueInput = orthopticPayload.functionalOptometryFinalFormula?.od?.far;
        const lensAVOIValue = lensAVFormatted.find(item => item.value === orthopticPayload.functionalOptometryFinalFormula?.oi?.farId);
        const lensAVOIValueInput = orthopticPayload.functionalOptometryFinalFormula?.oi?.far;

        return (
            <tbody>
                <tr>
                    <td className="pt-2 pe-2 ps-2 text-secondary bg-od" style={{ borderTopLeftRadius: "0.5rem" }} width={30}>OD</td>
                    <td className="py-2 pe-2 ps-2 bg-od" width={130}>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.functionalOptometryFinalFormula?.od?.sphere}
                            onChange={(event) => {
                                const values = event.target.value.split(".");
                                if (values[1] && values[1].length > 2) {
                                    event.preventDefault();
                                } else {
                                    onChangeLentsInputs({
                                        lentsType: "od",
                                        lentsItem: "sphere",
                                        value: event.target.value
                                    });
                                }
                            }}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-od" width={130}>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.functionalOptometryFinalFormula?.od?.cylinder}
                            onChange={(event) => {
                                const values = event.target.value.split(".");
                                if (values[1] && values[1].length > 2) {
                                    event.preventDefault();
                                } else {
                                    onChangeLentsInputs({
                                        lentsType: "od",
                                        lentsItem: "cylinder",
                                        value: event.target.value
                                    });
                                }
                            }}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-od" width={130}>
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.functionalOptometryFinalFormula?.od?.axis}
                            onChange={({ target }) => onChangeLentsInputs({
                                lentsType: "od",
                                lentsItem: "axis",
                                value: target.value
                            })}
                            onKeyDown={(event) => (event.key === "." || event.key === ",") && event.preventDefault()}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-od" width={140}>
                        <Select
                            disabled={isDisabledForm}
                            changeToInput={historySheet}
                            inputValue={lensAddODValueInput}
                            style={{ width: "100%" }}
                            options={lensAddFormatted}
                            value={lensAddODValue}
                            onChange={({ option }) => onChangeLentsInputs({
                                lentsType: "od",
                                lentsItem: "addId",
                                value: option.value
                            })}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-od" style={{ borderTopRightRadius: "0.5rem" }} width={140}>
                        <Select
                            disabled={isDisabledForm}
                            changeToInput={historySheet}
                            inputValue={lensAVODValueInput}
                            style={{ width: "100%" }}
                            options={lensAVFormatted}
                            value={lensAVODValue}
                            onChange={({ option }) => onChangeLentsInputs({
                                lentsType: "od",
                                lentsItem: "farId",
                                value: option.value
                            })}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="py-2 pe-2 ps-2 text-secondary bg-oi" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                    <td className="py-2 pe-2 ps-2 bg-oi">
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.functionalOptometryFinalFormula?.oi?.sphere}
                            onChange={(event) => {
                                const values = event.target.value.split(".");
                                if (values[1] && values[1].length > 2) {
                                    event.preventDefault();
                                } else {
                                    onChangeLentsInputs({
                                        lentsType: "oi",
                                        lentsItem: "sphere",
                                        value: event.target.value
                                    });
                                }
                            }}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-oi">
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.functionalOptometryFinalFormula?.oi?.cylinder}
                            onChange={(event) => {
                                const values = event.target.value.split(".");
                                if (values[1] && values[1].length > 2) {
                                    event.preventDefault();
                                } else {
                                    onChangeLentsInputs({
                                        lentsType: "oi",
                                        lentsItem: "cylinder",
                                        value: event.target.value
                                    });
                                }
                            }}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-oi">
                        <input
                            disabled={isDisabledForm}
                            type="number"
                            className="form-control"
                            value={orthopticPayload.functionalOptometryFinalFormula?.oi?.axis}
                            onChange={({ target }) => onChangeLentsInputs({
                                lentsType: "oi",
                                lentsItem: "axis",
                                value: target.value
                            })}
                            onKeyDown={(event) => (event.key === "." || event.key === ",") && event.preventDefault()}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-oi" width={140}>
                        <Select
                            disabled={isDisabledForm}
                            changeToInput={historySheet}
                            inputValue={lensAddOIValueInput}
                            options={lensAddFormatted}
                            style={{ width: "100%" }}
                            value={lensAddOIValue}
                            onChange={({ option }) => onChangeLentsInputs({
                                lentsType: "oi",
                                lentsItem: "addId",
                                value: option.value
                            })}
                        />
                    </td>
                    <td className="py-2 pe-2 ps-2 bg-oi" style={{ borderBottomRightRadius: "0.5rem" }} width={140}>
                        <Select
                            disabled={isDisabledForm}
                            changeToInput={historySheet}
                            inputValue={lensAVOIValueInput}
                            style={{ width: "100%" }}
                            options={lensAVFormatted}
                            value={lensAVOIValue}
                            onChange={({ option }) => onChangeLentsInputs({
                                lentsType: "oi",
                                lentsItem: "farId",
                                value: option.value
                            })}
                        />
                    </td>
                </tr>
            </tbody>
        );
    };

    const render = () => {
        return (
            <SectionCard className="h-100">
                <h5 className="text-label fw-bold">Fórmula final de optometría funcional</h5>
                <hr className="text-primary mt-2 mb-3" />
                <div className="d-flex align-items-end">
                    <table className="w-100">
                        <thead>
                            <tr>
                                <td className="p-2"></td>
                                <td align="center" className="text-secondary">Esfera</td>
                                <td align="center" className="text-secondary">Cilindro</td>
                                <td align="center" className="text-secondary">Eje</td>
                                <td align="center" className="text-secondary">ADD</td>
                                <td align="center" className="text-secondary">AV</td>
                            </tr>
                        </thead>
                        {renderTableBody()}
                    </table>
                </div>
                <div className="mt-3">
                    <textarea
                        disabled={isDisabledForm}
                        placeholder="Observaciones..."
                        style={{ height: 68 }}
                        className="form-control flex-fill no-resize"
                        value={orthopticPayload.functionalOptometryFinalFormula?.observations}
                        onChange={({ target }) => {
                            setOrthopticPayload({
                                ...orthopticPayload,
                                functionalOptometryFinalFormula: {
                                    ...orthopticPayload.functionalOptometryFinalFormula,
                                    observations: target.value
                                }
                            });
                            onSubmit();
                        }}
                    />
                </div>
            </SectionCard>
        );
    };

    return render();
}