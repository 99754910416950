import { useAppDispatch, useAppSelector } from "@/hooks";
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//
import { SectionCard } from "@/components";
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import "../Consultation.scss";
import ConsultationControls from "../Controls/Controls";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import ConsultationHeader from "../Header/Header";
// import VisualAcuity from "../shared/VisualAcuity/VisualAcuity";
import { Segments } from "@/models/generalFields";
import { DIListItem } from "@/models/select/diagnosticImpression";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { useDebouncedCallback } from "use-debounce";
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
import History from "../History/History";
import Distances from "../shared/Distances/Distances";
import Keratometry from "../shared/Keratometry/Keratometry";
import ObjectiveRefraction from "../shared/ObjectiveRefraction/ObjectiveRefraction";
import SubjectiveRefraction from "../shared/SubjectiveRefraction/SubjectiveRefraction";
import { AVGlassesOnUse, AVPH, AVWithCorrection, AVWithoutCorrection, Optotipe } from "../shared/VisualAcuity/VisualAcuity";
import { getLowVision, updateLowVision } from "./lowVision.actions";

export default function LowVision({ historySheet, data, appId }: {
    historySheet?: boolean;
    data?: {
        dataSheet: LowVisionPayload, generalsFields: Segments[]; diagnosticImpression: DIListItem[];
    };
    appId?: number;
}) {
    const location = useLocation();
    const navigate = useNavigate();
    const { dataSheet, diagnosticImpression, generalsFields } = data || {};
    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const isDisabledForm = historySheet ? historySheet : false;
    const dispatch = useAppDispatch();

    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: undefined,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });

    const [visualPayload, setVisualPayload] = useState<LowVisionPayload>({
        mcfId: mcfId,
        eaccount: accountId,
        userId: userId as number,
        appId: appointmentId,
    });

    useEffect(() => {
        if (!historySheet) {

            const splittedPath = location.pathname.split("/");
            const sheet = splittedPath[splittedPath.length - 1];

            if (sheet !== currentSheet) {
                navigate(-1);
            }
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            if (historySheet) {
                if (dataSheet) {
                    setVisualPayload({
                        ...dataSheet,
                        mcfId: mcfId,
                        eaccount: accountId,
                        userId: userId as number,
                        appId: appointmentId,
                    });
                }
            } else {

                const data = await dispatch(getLowVision({
                    appId: appointmentId,
                    eaccount: accountId,
                    mcfId: mcfId,
                    userId: userId as number
                }));
                if (data) {
                    setVisualPayload({
                        ...data,
                        mcfId: mcfId,
                        eaccount: accountId,
                        userId: userId as number,
                        appId: appointmentId,
                    });
                }
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, mcfId, userId, dataSheet, historySheet]);

    const onSubmitLowVision = useDebouncedCallback(() => {
        if (visualPayload) {
            setVisualPayload(visualPayload);
            dispatch(updateLowVision(visualPayload));
        }
    }, 2000);
    const render = () => {
        return (
            <div className="d-flex flex-column h-100 overflow-hidden">
                {!historySheet &&
                    <ConsultationHeader isDisabledForm={isDisabledForm} currentSection="Baja visión" showHistory={false} />
                }
                <div className="d-flex overflow-hidden h-100">
                    <section className="consultation-section">
                        <div className="row g-3">
                            <div className="col-12">
                                <AttentionOrigin
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    data={generalsFields}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                />
                            </div>
                            <div className="col-2">
                                <Optotipe
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />

                            </div>
                            <div className="col-8">
                                <AVWithoutCorrection
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />

                            </div>
                            <div className="col-2">
                                <AVPH
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-12">
                                <AVGlassesOnUse
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <ObjectiveRefraction
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <Keratometry
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <SubjectiveRefraction
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>
                            <div className="col-6">
                                <AVWithCorrection
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>

                            <div className="col-4">
                                <Distances
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={visualPayload}
                                    setExternalPayload={(payload) => setVisualPayload(payload as LowVisionPayload)}
                                    onSubmit={onSubmitLowVision}
                                />
                            </div>

                            <div className="col-8">
                                <SectionCard className="h-100">
                                    <h5 className="text-secondary fw-bold">Ayudas VSN - Tiflo en uso</h5>
                                    <hr className="text-primary mt-2" />
                                    <textarea className="form-control h-100 no-resize"
                                        disabled={isDisabledForm}
                                        value={visualPayload.supportsVsnTifloInUse}
                                        onChange={({ target }) => {
                                            setVisualPayload({ ...visualPayload, supportsVsnTifloInUse: target.value });
                                            onSubmitLowVision();
                                        }}
                                    />
                                </SectionCard>
                            </div>

                            <div className="col-6">
                                <DiagnosticImpression isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    data={{
                                        appId,
                                        cluId: undefined,
                                        userId: NaN,
                                        eaccount: accountId,
                                        clhId: undefined,
                                        sheet: "lowVision",
                                        information: diagnosticImpression
                                    }
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <AnalysisAndPlan
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    height={260}
                                    data={generalsFields && generalsFields[0].analysisPlanJst}

                                />
                            </div>
                        </div>
                    </section>
                    {!historySheet &&
                        <History showClose={false} />
                    }
                </div>
                {!historySheet &&
                    <ConsultationControls isDisabledForm={isDisabledForm} />
                }
            </div>
        );
    };

    return render();
}