import { useAppDispatch, useAppSelector } from "@/hooks";
import { IconChevronUp, IconPrinter } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { getHeaderSurgery } from "@/features/Workspace/PatientAttention/Surgery/patientAttentionSurgery.action";
import { getSurgeryDownloadDetails, getSurgeryStages } from "./surgeryDetails.actions";

import {
    Badge,
    Button,
    Checkbox,
    Dropdown,
    DropdownItem,
    DropdownMenu
} from "@/components";

import AnesthesiaNotesDetail from "./AnesthesiaNotesDetail/AnesthesiaNotesDetail";
import BackgroundDetail from "./BackgroundDetail/BackgroundDetail";
import CheckListDetail from "./CheckListDetail/CheckListDetail";
import NurseryNotesDetail from "./NurseryNotesDetail/NurseryNotesDetail";
import OrdersDetail from "./OrdersDetail/OrdersDetail";
import SurgeryHeader from "./SurgeryHeader";
import SurgicalDescriptionDetail from "./SurgicalDescription/SurgicalDescription";
import UnexpectedSituationDetail from "./UnexpectedSituationDetail/UnexpectedSituationDetail";

import { HeaderSurgery } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { SurgeryHistoryDownload, SurgeryStage } from "@/models/surgery/surgeryStages";

import "../../../../../Surgery/PatientAttentionSurgery.scss";
import "./SurgeryDetails.scss";
import SurgeryDownloadFile from "./SurgeryDownloadFile";

export default function SurgeryDetails({ appId: appIdState }: { appId?: number; }) {
    const dispatch = useAppDispatch();
    const printFile = useRef<HTMLTableElement>(null);

    const printAction = useReactToPrint({
        content: () => printFile.current
    });
    const eaccount = useAppSelector(state => state.workspace.id);

    const [surgeryHeader, setSurgeryHeader] = useState<HeaderSurgery>();
    const [downloadableStageOptions, setDownloadableStageOptions] = useState<SurgeryStage[]>([]);
    const [stageOptions, setStageOptions] = useState<SurgeryStage[]>([]);
    const [selectedSheets, setSelectedSheets] = useState<string[]>([]);
    const [downloadData, setDownloadData] = useState<SurgeryHistoryDownload | null>();
    const [downloadDataOld, setDownloadDataOld] = useState<SurgeryHistoryDownload | null>();
    const [trigger, setTrigger] = useState(0);
    const [selectedOption, setSelectedOption] = useState<SurgeryStage>({
        name: "Notas de enfermería",
        prefix: "nurseryNotes"
    });
    const [appId, setAppId] = useState(appIdState);




    useEffect(() => {
        void (async function () {
            const response = await dispatch(getSurgeryStages({ appId: appId || NaN, toDownload: 1 }));
            setDownloadableStageOptions(response);
            setSelectedSheets(response.map(r => r.prefix));
        })();
    }, [appId, dispatch]);

    useEffect(() => {
        void (async function () {
            const response = await dispatch(getSurgeryStages({ appId: appId || NaN, toDownload: 0 }));
            setStageOptions(response);
            setSelectedOption({
                name: "Notas de enfermería",
                prefix: "nurseryNotes"
            });
        })();
    }, [appId, dispatch]);

    useEffect(() => {
        void (async function () {
            const response = await dispatch(getHeaderSurgery(appId || NaN));
            if (response) setSurgeryHeader(response);
        })();
    }, [appId, dispatch]);

    const handleOnSelectCheckbox = (stage: string) => {
        if (selectedSheets.includes(stage)) {
            setSelectedSheets(state => state.filter(s => s !== stage));
        }
        else {
            setSelectedSheets(state => [...state, stage]);
        }
    };

    const handleOnSelectAll = () => {
        if (selectedSheets.length === downloadableStageOptions.length) {
            setSelectedSheets([]);
        }
        else {
            setSelectedSheets(downloadableStageOptions.map(o => o.prefix));
        }
    };

    const handlePrint = async () => {
        const stages: Record<string, number> = {};
        for (const item of selectedSheets) {
            stages[item] = 1;
        }
        const response = await dispatch(getSurgeryDownloadDetails({
            appId: appId || NaN,
            eaccount,
            ...stages
        }));
        setDownloadDataOld(downloadData);
        if (response) {
            setDownloadData(response);
            setTrigger(trigger + 1);
        }
    };

    useEffect(() => {
        if (downloadData !== downloadDataOld) {
            printAction();
        }
    }, [trigger, downloadData]);

    const render = () => {
        return (
            <>
                {surgeryHeader && (
                    <SurgeryHeader patientHeader={surgeryHeader} setAppIdState={setAppId} />
                )}

                {selectedOption.prefix === "nurseryNotes" && (
                    <NurseryNotesDetail appId={appId || NaN} />
                )}

                {selectedOption.prefix === "anesthesiaNotes" && (
                    <AnesthesiaNotesDetail appId={appId || NaN} />
                )}

                {selectedOption.prefix === "surgicalDescription" && (
                    <SurgicalDescriptionDetail appId={appId || NaN} />
                )}

                {selectedOption.prefix === "checklists" && (
                    <CheckListDetail appId={appId || NaN} />
                )}

                {selectedOption.prefix === "background" && (
                    <BackgroundDetail appId={appId || NaN} />
                )}

                {selectedOption.prefix === "unexpectedSituation" && (
                    <UnexpectedSituationDetail appId={appId || NaN} />
                )}

                {selectedOption.prefix === "orders" && (
                    <OrdersDetail appId={appId || NaN} />
                )}

                <div className="surgery-footer">
                    <Dropdown className="dropdown">
                        <Button
                            endIcon={<IconChevronUp className="text-primary" />}
                            color="secondary"
                            variant="outline"
                            data-bs-toggle="dropdown"
                            style={{ width: 150 }}
                        >
                            {selectedOption.name}
                        </Button>
                        <DropdownMenu className="w-100">
                            {stageOptions.map(opt => (
                                <DropdownItem
                                    key={opt.prefix}
                                    onClick={() => {
                                        setDownloadData(null);
                                        setSelectedOption({ name: opt.name, prefix: opt.prefix });
                                    }}
                                >
                                    {opt.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    <div className="flex-grow-1"></div>
                    {surgeryHeader && (
                        <Badge className="ms-4" style={{
                            backgroundColor: surgeryHeader.status.bgColor || "#E8E8EA",
                            color: surgeryHeader.status.fontColor || "#6E6F7C"
                        }}>
                            {surgeryHeader.status.name}
                        </Badge>
                    )}
                    <Dropdown className="dropdown">
                        <IconPrinter
                            className="text-muted pointer ms-3"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                        />
                        <DropdownMenu isOpen={true} style={{ width: 250 }}>
                            <DropdownItem className="py-3" style={{ borderBottom: "1px solid #ccc" }}>
                                <Checkbox
                                    label="Todas las pestañas"
                                    checked={selectedSheets.length === downloadableStageOptions.length}
                                    onChange={handleOnSelectAll}
                                />
                            </DropdownItem>
                            {downloadableStageOptions.map(opt => (
                                <DropdownItem
                                    key={opt.prefix}
                                    className="py-3"
                                >
                                    <Checkbox
                                        label={opt.name}
                                        onChange={() => handleOnSelectCheckbox(opt.prefix)}
                                        checked={selectedSheets.includes(opt.prefix)}
                                    />
                                </DropdownItem>
                            ))}
                            <div className="w-100 d-flex justify-content-center mt-2">
                                <Button
                                    className="w-75 mb-2"
                                    variant="primary"
                                    onClick={handlePrint}
                                    disabled={!selectedSheets.length}
                                >
                                    Imprimir
                                </Button>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div style={{ overflow: "hidden", height: 0 }} key={`${downloadData} ${trigger}`}>
                    {downloadData &&
                        <SurgeryDownloadFile
                            key={`${downloadData} ${trigger}`}
                            appId={appId || NaN}
                            ref={printFile}
                            data={downloadData}
                        />}

                </div>
            </>
        );
    };

    return render();
}