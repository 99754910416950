import { useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";

import { getCheckListDetail } from "../../../../history.actions";

import { Checkbox, RadioButton, SectionCard } from "@/components";
import { Checklist, Question } from "@/models/surgery/patientAttention/checklist";

export default function CheckListDetail({ appId }: { appId: number; }) {

    const dispatch = useAppDispatch();

    const [checklist, setChecklist] = useState<Checklist[]>([]);

    useEffect(() => {
        void (async function () {
            const response = await dispatch(getCheckListDetail({ prefix: "checklists", appId: appId }));
            if (response) setChecklist(response.checklists);
        })();
    }, [appId, dispatch]);

    const renderMultipleChoiceQuestion = (question: Question, checkListId: number, categoryId: number, isDisabled: boolean) => {
        return (
            <div className="mb-2" key={question.id}>
                <b className="text-muted pb-2"> {question.description}</b>
                {question.answersCheckListArray.length > 0 &&
                    question.answersCheckListArray.map(answer => {
                        return (
                            <div
                                className="mb-2"
                                key={question.id + answer.id}
                            >
                                <RadioButton
                                    className="text-muted fw-normal"
                                    disabled={isDisabled}
                                    label={answer.description}
                                    value="pending"
                                    key={answer.id}
                                    id={String(answer.id)}
                                    name={`${answer.id}-${answer.value}-${question.id}`}
                                    checked={answer?.selected}
                                    onChange={(e) => e.stopPropagation()}
                                />
                            </div>
                        );
                    })}
            </div>
        );
    };


    const renderCloseQuestion = (question: Question, checkListId: number, categoryId: number, idDisabled: boolean) => {
        const res = question.answersCheckListArray.find(answer => answer.value === 1);
        return (
            <div className="mb-2"
                key={question.id}
            >
                <Checkbox
                    className="text-muted fw-normal  align-items-start"
                    disabled={idDisabled}
                    label={question.description}
                    onChange={(e) => e.stopPropagation()}
                    checked={res?.selected}
                />
            </div>
        );
    };

    const renderComponent = () => {
        return (
            <div className="row h-100 p-4 overflow-hidden">
                {checklist && checklist.map(item => (
                    <div className="col h-100">
                        <SectionCard className="h-100 ps-3 pe-0 py-3">
                            <h5 className="fw-bold text-secondary">{item.name}</h5>
                            <hr className="lineCards" />
                            <div className='pe-1' style={{ overflowY: "auto" }}>
                                {item.categories.map((category) => {
                                    return <div key={category.id}>
                                        {Boolean(category.hasTitle) &&
                                            <span className="text-primary">
                                                {category.name}
                                            </span>
                                        }
                                        {category?.questions.length > 0 && (
                                            <>
                                                {category.questions
                                                    .filter(question => question.type === "closed")
                                                    .map(question => renderCloseQuestion(question, item.id, category.id, true))
                                                }
                                                {category.questions
                                                    .filter(question => question.type === "multipleChoice")
                                                    .map(question => renderMultipleChoiceQuestion(question, item.id, category.id, true))
                                                }
                                            </>
                                        )}
                                    </div>;
                                })}
                            </div>
                        </SectionCard>
                    </div>
                ))}
            </div>
        );
    };

    return renderComponent();
}