import { useEffect, useState } from "react";
//
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
//
import { SectionCard } from "@/components";
import { getVersionsList } from "@/services";
import Version from "./Version/Version";
import "./Versions.scss";

interface VersionsParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    readOnly?: boolean;
    showHistory?: boolean;
    isDisabledForm: boolean;
}

export default function Versions({ orthopticPayload, setOrthopticPayload, onSubmit, readOnly, showHistory, isDisabledForm }: VersionsParams) {
    const [versions, setVersions] = useState<CommonSelect[]>([]);


    useEffect(() => {
        async function fetchData() {
            const data = await getVersionsList();
            if (data.results) setVersions(data.results);
        }
        fetchData();
    }, []);

    const render = () => {
        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold">Versiones</h5>
                <hr className="text-primary mt-2" />

                <Version
                    isDisabledForm={isDisabledForm}
                    versions={versions}
                    orthopticPayload={orthopticPayload}
                    setOrthopticPayload={setOrthopticPayload}
                    onSubmit={onSubmit}
                    showHistory={showHistory}
                />
            </SectionCard>
        );
    };

    return render();
}