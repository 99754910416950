import { Checkbox, SectionCard } from "@/components";
import { IncomeNotes } from "@/models/surgery/patientAttention/patientAttention";
import { useEffect, useState } from "react";
import TextConfigModal from "../../Consultation/AttentionOrigin/TextConfig/TextConfigNoIns";

const Income = ({ data, onSubmitNotes, readOnly }: {
    data?: IncomeNotes;
    onSubmitNotes: (section: string, payload: IncomeNotes) => void;
    readOnly?: boolean;
}) => {
    const [incomeNotes, setIncomeNotes] = useState<IncomeNotes>();
    const [incomeModal, setIncomeModal] = useState<{ isOpen: boolean; current: string; }>({
        isOpen: false,
        current: "surgery_area_admission_notes"
    });
    const handleOpenNoInsTextModal = (name: string): void => setIncomeModal({
        current: name, isOpen: true
    });

    const handleCloseNoInsTextModal = (): void => setIncomeModal({
        current: "surgery_area_admission_notes", isOpen: false
    });

    const handleAttatchText = (text: string) => {
        if (incomeNotes) {
            const payload = {
                ...incomeNotes,
                numSurgeryAreaAdmissionNotes: incomeNotes?.numSurgeryAreaAdmissionNotes?.length
                    ? `${incomeNotes.numSurgeryAreaAdmissionNotes}\n${text}`
                    : text
            };
            setIncomeNotes(payload);
            onSubmitNotes("income", payload);
        }
        handleCloseNoInsTextModal();
    };

    const handleChange = (value: string | number, name: string) => {
        if (incomeNotes) {
            const payload = {
                ...incomeNotes,
                [name]: value
            };
            setIncomeNotes(payload);
            onSubmitNotes("income", payload);

        }
    };


    useEffect(() => {

        if (data) {
            setIncomeNotes(data);
        }
    }, [data]);

    return (
        <div className="my-3 mx-4 ">
            {incomeNotes?.userInfo &&
                <SectionCard className=" cardAidStyle mb-3 "  >
                    <span className="text-muted"><b className="text-secondary">Último cambio realizado por: </b>
                        {typeof incomeNotes.userInfo === "string" ? incomeNotes.userInfo : incomeNotes.userInfo.fullName}
                    </span>
                </SectionCard>}
            {incomeNotes?.transferredBy &&
                <SectionCard className=" cardAidStyle mb-3 "  >
                    <span className="text-muted"><b className="text-secondary">Transferido por:</b>  {incomeNotes?.transferredBy ?? "-"}</span>
                </SectionCard>}
            <SectionCard className=" cardAidStyle "  >
                <Checkbox
                    className="text-muted fw-normal"
                    label="Se marca órgano a operar"
                    onChange={({ target }) => handleChange(target.checked ? 1 : 0, "numCheckOrgan")}
                    checked={incomeNotes?.numCheckOrgan === 1 ? true : false}
                    disabled={readOnly}
                />
            </SectionCard>
            <SectionCard className="mt-3" cardAidStyle >
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary required">Notas de ingreso área quirúrgica</h5>
                </div>
                <hr className="lineCards" />
                <textarea
                    className="form-control no-resize w-100"
                    style={{ height: 100 }}
                    onContextMenu={(event) => {
                        event.preventDefault();
                        handleOpenNoInsTextModal("surgery_area_admission_notes");
                    }}
                    onChange={({ target }) => handleChange(target.value, "numSurgeryAreaAdmissionNotes")}
                    value={incomeNotes?.numSurgeryAreaAdmissionNotes}
                    readOnly={readOnly}
                />
            </SectionCard>
            {!readOnly && (
                <TextConfigModal
                    title="Textos predefinidos no institucionales"
                    sheet="nurseryNotes"
                    isOpen={incomeModal.isOpen}
                    segment={incomeModal.current}
                    current={incomeModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    positionModal="center"
                    onSelectItem={(_, item) => handleAttatchText(item)}
                />
            )}

        </div>
    );
};

export default Income;