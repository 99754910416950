import { Button, Checkbox, Modal, RadioButton, SectionCard } from "@/components";
import { IModal } from "@/components/Modal/modal";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Checklist, Question } from "@/models/surgery/patientAttention/checklist";
import { useEffect, useState } from "react";
import { getCheckList, updateCheckList } from "../patientAttentionSurgery.action";


interface CheckListInterface {
    details?: boolean;
    numId?: number;
    stage?: string;
    isStageTrue?: boolean;
    setAuthValidation?: (data: string) => void;
    handleGenericFunction?: (data?: string) => Promise<boolean>;
}

export default function CheckList({
    onSuccess,
    isStageTrue,
    setAuthValidation,
    handleGenericFunction,
    appId,
    stage,
    numId,
    details,
    ...rest }: IModal & CheckListInterface) {

    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const currenCheckStage = useAppSelector(state => state.surgery.currentChecklist);
    const [checkListData, setCheckListData] = useState<Checklist[]>([]);

    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen && numId) {
                const data = await dispatch(getCheckList({
                    numId,
                }));
                setCheckListData(data.checklists as Checklist[]);
            }
        }
        fetchData();
    }, [numId, rest.isOpen]);




    const handleRadioChange = (
        value: boolean,
        checkListId: number,
        categoryId: number,
        questionId: number,
        answerId: number
    ) => {
        setCheckListData((prevCheckListData) =>
            prevCheckListData.map((checkList) => {
                if (checkList.id !== checkListId) return checkList;
                return {
                    ...checkList,
                    categories: checkList.categories.map((category) => {
                        if (category.id !== categoryId) return category;

                        return {
                            ...category,
                            questions: category.questions.map((question) => {
                                if (question.id !== questionId) return question;
                                return {
                                    ...question,
                                    answersCheckListArray: question.answersCheckListArray.map((answer) =>
                                        answer.id === answerId ? { ...answer, selected: value } : { ...answer, selected: false }
                                    ),
                                };
                            }),
                        };
                    }),
                };
            })
        );
    };

    const handleCheckBoxChange = (
        value: boolean,
        checkListId: number,
        categoryId: number,
        questionId: number,
    ) => {
        setCheckListData((prevCheckListData) =>
            prevCheckListData.map((checkList) => {
                if (checkList.id !== checkListId) return checkList;
                return {
                    ...checkList,
                    categories: checkList.categories.map((category) => {
                        if (category.id !== categoryId) return category;

                        return {
                            ...category,
                            questions: category.questions.map((question) => {
                                if (question.id !== questionId) return question;
                                return {
                                    ...question,
                                    answersCheckListArray: question.answersCheckListArray.map((answer) =>
                                        answer.value === 1 ? { ...answer, selected: value ? value : false } : { ...answer, selected: value ? false : true }
                                    ),
                                };
                            }),
                        };
                    }),
                };
            })
        );
    };




    const multipleChoiceQuestion = (question: Question, checkListId: number, categoryId: number, isDisabled: boolean) => {
        return (
            <div className="mb-2" key={question.id}>
                <b className="text-muted pb-2"> {question.description}</b>
                {question.answersCheckListArray.length > 0 &&
                    question.answersCheckListArray.map(answer => {
                        return (
                            <div
                                className="mb-2"
                                key={question.id + answer.id}
                            >
                                <RadioButton
                                    className="text-muted fw-normal"
                                    disabled={isDisabled}
                                    label={answer.description}
                                    value="pending"
                                    key={answer.id}
                                    id={String(answer.id)}
                                    name={`${answer.id}` + `${answer.value} + ${question.id}`}
                                    checked={answer?.selected}
                                    onChange={(e) => handleRadioChange(e.target.checked, checkListId, categoryId, question.id, answer.id)}
                                />
                            </div>
                        );
                    })}
            </div>
        );
    };


    const closeQuestion = (question: Question, checkListId: number, categoryId: number, idDisabled: boolean) => {
        const res = question.answersCheckListArray.find(answer => answer.value === 1);
        return (
            <div className="mb-2"
                key={question.id}
            >
                <Checkbox
                    className="text-muted fw-normal  align-items-start"
                    disabled={idDisabled}
                    label={question.description}
                    onChange={(e) => handleCheckBoxChange(e.target.checked, checkListId, categoryId, question.id)}
                    checked={res?.selected}
                />
            </div>
        );
    };

    const handleSubmit = async () => {

        if (userId && numId) {
            const res = await dispatch(updateCheckList({
                checklists: checkListData,
                numId,
                userId,
                currentChecklist: currenCheckStage,

            }));
            if (res.success) {
                rest.onClose();
            }
        }
    };

    return (
        <Modal {...rest} dontClose width={1400} positionModal="center" onClose={() => {
            rest.onClose();
        }}>
            <div className="p-4 d-flex flex-column">
                <h1 className="text-secondary fw-bold text-start ">Lista de chequeo</h1>
                <div className=" row ">
                    {checkListData.length > 0 && checkListData.map((item) => {
                        let isDisabled = false;
                        if (currenCheckStage === "entry") {
                            isDisabled = item.prefix !== currenCheckStage;
                        } else if (currenCheckStage === "pause") {
                            isDisabled = (item.prefix === "exit");
                        } else {
                            isDisabled = false;
                        }

                        return <div className="col-4" key={item.id}
                            style={{ maxHeight: "70vh", overflowY: "auto" }}
                        >
                            <SectionCard className="mt-3 mb-3" cardAidStyle
                                backgroundColor={isDisabled ? "#99999912" : ""}
                                opacity={isDisabled ? 0.5 : 1}
                            >
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="fw-bold text-secondary">{item.name}</h5>
                                </div>
                                <hr className="lineCards" />
                                {item.categories.map((category) => {
                                    return <div key={category.id}>
                                        {Boolean(category.hasTitle) &&
                                            <span className="text-primary">
                                                {category.name}
                                            </span>
                                        }
                                        {category?.questions.length > 0 && (
                                            <>
                                                {category.questions
                                                    .filter(question => question.type === "closed")
                                                    .map(question => closeQuestion(question, item.id, category.id, isDisabled))
                                                }
                                                {category.questions
                                                    .filter(question => question.type === "multipleChoice")
                                                    .map(question => multipleChoiceQuestion(question, item.id, category.id, isDisabled))
                                                }
                                            </>
                                        )}
                                    </div>;
                                })}
                            </SectionCard>
                        </div>;
                    })}
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Button variant="primary" size="large" className="py-2"
                        onClick={handleSubmit}
                    >
                        Guardar
                    </Button>
                </div>
            </div>
        </Modal>
    );
}




