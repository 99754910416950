import { useEffect, useState } from "react";
import { IconChevronRight } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
//
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { createNewToken, getAccountsByUser, saveAccount } from "../../Home/home.actions";
import { AuthUserData } from "../../../../models/auth";
import { Account, CurrentAccount } from "../../../../models/account";
//
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Modal
} from "../../../../components";
import "../Header.scss";
import { getCategory } from "../../Sidebar/sidebar.actions";
import { setCategorySlice } from "@/config/slices/category";

interface IProfile {
    userData: AuthUserData;
    isOpen: boolean;
    onClose: () => void;
}

export default function ChangeAccount({ userData, isOpen, onClose }: IProfile) {
    const userId = useAppSelector(state => state.auth.user_data.id);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [accounts, setAccounts] = useState<Account[]>();
    const [profile, setProfile] = useState<null | number>(null);

    useEffect(() => {
        (async function () {
            if (userId) {
                const response = await dispatch(getAccountsByUser(`${userId}`, location));
                setAccounts(response);
            }
        })();
    }, [dispatch, userId, location]);

    const handleAccount = async (account: CurrentAccount) => {
        const res = await dispatch(createNewToken({ eaccount: account.id, profile: account.profile?.id }));
        if (res) {
            dispatch(saveAccount(account));
            navigate("gate");
            onClose();
        }
    };

    const getCategoryFunction = (idProfile: number) => {
        setProfile(idProfile);
        async function fetchData() {
            const data = await dispatch(getCategory(idProfile));
            if (data) {
                dispatch(setCategorySlice(data ?? {}));
                localStorage.setItem("category", JSON.stringify(data));
            }
        }
        fetchData();
    };


    useEffect(() => {
        if (profile !== null) {
            getCategoryFunction(profile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const render = () => {
        return (
            <Modal isOpen={isOpen} onClose={onClose} width={600}>
                <div className="header-profile">
                    <h3 className="text-primary fw-bold text-center">Seleccionar cuenta</h3>
                    <hr />
                    <div className="header-profile__selection">
                        {accounts?.length && accounts.map(account => (
                            <Dropdown key={account.id} className="dropend h-100 me-3">
                                <div className="home-account mb-3" data-bs-toggle="dropdown" data-bs-offset="0,10">
                                    <div className="detail"></div>
                                    <span className="fw-bold">{account.name}</span>
                                    <div className="home-account__roles" >
                                        <IconChevronRight />
                                    </div>
                                    <DropdownMenu>
                                        {account.profile.map((profile) => (
                                            <DropdownItem key={profile.id} onClick={() => {
                                                getCategoryFunction(profile.id);
                                                handleAccount({ ...account, profile });
                                            }}>
                                                {profile.nombre}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </div>
                            </Dropdown>
                        ))}
                    </div>
                </div>
            </Modal>
        );
    };

    return render();
}