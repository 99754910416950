import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { DebouncedState } from "use-debounce";
//
import { SectionCard } from "@/components";
import { KTM } from "@/models/shared/keratometry";
import { LowVisionPayload } from "@/models/sheets/lowVision";
import { OptometryPayload } from "@/models/sheets/optometry";

interface KeratometryParams {
    externalPayload: OptometryPayload | LowVisionPayload;
    setExternalPayload: (visualPayload: OptometryPayload | LowVisionPayload) => void;
    onSubmit: DebouncedState<() => void>;
    isDisabledForm: boolean;
    historySheet?: boolean;
}

interface GradesRefs {
    odk1: HTMLInputElement | null;
    odk2: HTMLInputElement | null;
    oik1: HTMLInputElement | null;
    oik2: HTMLInputElement | null;
}

const Keratometry = ({ externalPayload, setExternalPayload, onSubmit, isDisabledForm }: KeratometryParams) => {
    const gradesRef = useRef<GradesRefs>({
        odk1: null,
        odk2: null,
        oik1: null,
        oik2: null
    });

    const [diopters, setDiopters] = useState({
        diopterOd: "",
        diopterOi: ""
    });

    useEffect(() => {
        const odk1 = externalPayload.keratometry?.od?.k1?.value;
        const odk2 = externalPayload.keratometry?.od?.k2?.value;

        if (odk1 && odk2) {
            const calc = odk2 - odk1;
            setDiopters(prevState => ({ ...prevState, diopterOd: calc.toFixed(2).toString() }));
        } else {
            setDiopters(prevState => ({ ...prevState, diopterOd: "" }));
        }

    }, [externalPayload.keratometry?.od?.k1?.value, externalPayload.keratometry?.od?.k2?.value]);

    useEffect(() => {
        const oik1 = externalPayload.keratometry?.oi?.k1?.value;
        const oik2 = externalPayload.keratometry?.oi?.k2?.value;

        if (oik1 && oik2) {
            const calc = oik2 - oik1;
            setDiopters(prevState => ({ ...prevState, diopterOi: calc.toFixed(2).toString() }));
        } else {
            setDiopters(prevState => ({ ...prevState, diopterOi: "" }));
        }
    }, [externalPayload.keratometry?.oi?.k1?.value, externalPayload.keratometry?.oi?.k2?.value]);

    const onChangeKeratometryValue = (eye: "od" | "oi", k: "k1" | "k2", label: keyof KTM, value: string | number) => {
        const options: typeof externalPayload = {
            ...externalPayload,
            keratometry: {
                ...externalPayload.keratometry,
                [eye]: {
                    ...externalPayload.keratometry?.[eye],
                    [k]: {
                        ...externalPayload.keratometry?.[eye]?.[k],
                        [label]: value
                    }
                },
            }
        };
        setExternalPayload(options);
        onSubmit();
    };

    const onChangeKeratometryGrades = (eye: "od" | "oi", k: "k1" | "k2", value: string | number) => {
        const options: OptometryPayload = {
            ...externalPayload,
            keratometry: {
                ...externalPayload.keratometry,
                [eye]: {
                    ...externalPayload.keratometry?.[eye],
                    [k]: {
                        ...externalPayload.keratometry?.[eye]?.[k],
                        grade: value
                    }
                },
            }
        };
        setExternalPayload(options);
        const isValid = validateKGrades(eye, k, options);
        const union = (eye + k) as keyof GradesRefs;

        if (isValid) {
            gradesRef.current?.[union]?.classList.remove("border-danger");
            onSubmit();
        } else {
            gradesRef.current?.[union]?.classList.add("border-danger");
            onSubmit.cancel();
        }
    };

    const validateKGrades = (eye: "od" | "oi", k: "k1" | "k2", options: typeof externalPayload) => {

        if (k === "k1") {
            const k1Grades = Number(options.keratometry?.[eye]?.k1?.grade);

            if (k1Grades >= 0 && k1Grades <= 180) {
                return true;
            }
            return false;
        }

        if (k === "k2") {
            const k2Grades = Number(options.keratometry?.[eye]?.k2?.grade);

            if (k2Grades >= 180 && k2Grades <= 360) {
                return true;
            }
            return false;
        }

    };

    return (
        <SectionCard className="h-100">
            <h5 className="text-secondary fw-bold">Queratometría</h5>
            <hr className="text-primary mt-2" />
            <div className="row gx-3">
                <div className="col-lg-4 d-flex flex-column">
                    <table className="mt-2">
                        <thead>
                            <tr>
                                <td></td>
                                <td
                                    colSpan={2}
                                    align="center"
                                    className="pt-1 fw-bold text-secondary bg-od"
                                    style={{ borderRadius: "0.5rem 0.5rem 0 0" }}

                                >
                                    OD
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td align="center" className="text-secondary bg-od">Poder (D)</td>
                                <td align="center" className="text-secondary bg-od">Grados</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-secondary" width={30}>K1</td>
                                <td className="p-2 bg-od" width={130}>
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.od?.k1?.value}
                                        onChange={(event) => {
                                            const value = event.target.value.split(".");
                                            if (value[1] && value[1].length > 2) {
                                                event.preventDefault();
                                            } else {
                                                onChangeKeratometryValue(
                                                    "od", "k1", "value", event.target.value
                                                );
                                            }
                                        }}
                                    />
                                </td>
                                <td className="p-2 bg-od" width={130}>
                                    <input
                                        disabled={isDisabledForm}
                                        ref={ref => gradesRef.current.odk1 = ref}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.od?.k1?.grade}
                                        onChange={({ target }) => onChangeKeratometryGrades(
                                            "od", "k1", target.value
                                        )}
                                        onKeyDown={(event) => (event.key === "." || event.key === "-") && event.preventDefault()}
                                        data-tooltip-id="keratometryTooltip"
                                        data-tooltip-content="Valor entre 0° a 180°"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-secondary">K2</td>
                                <td className="p-2 bg-od" style={{ borderRadius: "0 0 0 0.5rem" }}>
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.od?.k2?.value}
                                        onChange={(event) => {
                                            const value = event.target.value.split(".");
                                            if (value[1] && value[1].length > 2) {
                                                event.preventDefault();
                                            } else {
                                                onChangeKeratometryValue(
                                                    "od", "k2", "value", event.target.value
                                                );
                                            }
                                        }}
                                    />
                                </td>
                                <td className="p-2 bg-od" style={{ borderRadius: "0 0 0.5rem 0" }}>
                                    <input
                                        disabled={isDisabledForm}
                                        ref={ref => gradesRef.current.odk2 = ref}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.od?.k2?.grade}
                                        onChange={({ target }) => onChangeKeratometryGrades(
                                            "od", "k2", target.value
                                        )}
                                        onKeyDown={(event) => (event.key === "." || event.key === "-") && event.preventDefault()}
                                        data-tooltip-id="keratometryTooltip"
                                        data-tooltip-content="Valor entre 180° a 360°"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-lg-4 d-flex flex-column">
                    <table className="mt-2">
                        <thead>
                            <tr>
                                <td
                                    colSpan={2}
                                    align="center"
                                    className="pt-1 fw-bold text-secondary bg-oi"
                                    style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                                >
                                    OI
                                </td>
                            </tr>
                            <tr>
                                <td className="text-secondary bg-oi" align="center">Poder (D)</td>
                                <td className="text-secondary bg-oi" align="center">Grados</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-2 bg-oi" width={130}>
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.oi?.k1?.value}
                                        onChange={(event) => {
                                            const value = event.target.value.split(".");
                                            if (value[1] && value[1].length > 2) {
                                                event.preventDefault();
                                            } else {
                                                onChangeKeratometryValue(
                                                    "oi", "k1", "value", event.target.value
                                                );
                                            }
                                        }}
                                    />
                                </td>
                                <td className="p-2 bg-oi" width={130}>
                                    <input
                                        disabled={isDisabledForm}
                                        ref={ref => gradesRef.current.oik1 = ref}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.oi?.k1?.grade}
                                        onChange={({ target }) => onChangeKeratometryGrades(
                                            "oi", "k1", target.value
                                        )}
                                        onKeyDown={(event) => (event.key === "." || event.key === "-") && event.preventDefault()}
                                        data-tooltip-id="keratometryTooltip"
                                        data-tooltip-content="Valor entre 0° a 180°"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2 bg-oi" style={{ borderRadius: "0 0 0 0.5rem" }}>
                                    <input
                                        disabled={isDisabledForm}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.oi?.k2?.value}
                                        onChange={(event) => {
                                            const value = event.target.value.split(".");
                                            if (value[1] && value[1].length > 2) {
                                                event.preventDefault();
                                            } else {
                                                onChangeKeratometryValue(
                                                    "oi", "k2", "value", event.target.value
                                                );
                                            }
                                        }}
                                    />
                                </td>
                                <td className="p-2 bg-oi" style={{ borderRadius: "0 0 0.5rem 0" }}>
                                    <input
                                        disabled={isDisabledForm}
                                        ref={ref => gradesRef.current.oik2 = ref}
                                        type="number"
                                        className="form-control"
                                        value={externalPayload.keratometry?.oi?.k2?.grade}
                                        onChange={({ target }) => onChangeKeratometryGrades(
                                            "oi", "k2", target.value
                                        )}
                                        onKeyDown={(event) => (event.key === "." || event.key === "-") && event.preventDefault()}
                                        data-tooltip-id="keratometryTooltip"
                                        data-tooltip-content="Valor entre 180° a 360°"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-lg-4">
                    <table className="h-100">
                        <thead>
                            <tr>
                                <td>
                                    <td className="text-secondary fw-bold">Astigmatismo corneal OD: {diopters.diopterOd || 0} D</td>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <td className="text-secondary fw-bold">Astigmatismo corneal OI: {diopters.diopterOi || 0} D</td>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="pb-1">
                                    <textarea
                                        className="form-control no-resize h-100"
                                        value={externalPayload.keratometry?.observations}
                                        onChange={({ target }) => {
                                            setExternalPayload({
                                                ...externalPayload,
                                                keratometry: {
                                                    ...externalPayload.keratometry,
                                                    observations: target.value
                                                }
                                            });
                                            onSubmit();
                                        }}
                                        placeholder="Observaciones..."
                                        disabled={isDisabledForm}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Tooltip id="keratometryTooltip" />
        </SectionCard>
    );
};
export default Keratometry;