import { Fragment } from "react";
import { createPortal } from "react-dom";
import { useAppSelector } from "../../hooks/redux";
//
import "./Loader.scss";

export function Loader() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const loaderId = document.getElementById("loader")!;
    const isLoading = useAppSelector(state => state.ui.isLoading);

    const render = () => {
        return (
            <Fragment>
                {isLoading && (
                    <div className="loader-wrapper">
                        <div className="lds-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    };

    return createPortal(render(), loaderId);
}