import React from "react";
import { IDropdownMenu } from "./dropdown";
import "./Dropdown.scss";

export function Dropdown({ children, ...rest }: React.HTMLAttributes<HTMLElement>) {

    return (
        <div {...rest}>
            {children}
        </div>
    );
}

export function DropdownMenu({ children, className = "", isOpen = false, ...rest }: IDropdownMenu) {
    return (
        <div className={`dropdown-menu ${className} ${isOpen ? "show-menu" : ""}`} {...rest}>
            {children}
        </div>
    );
}

export function DropdownItem({ children, className = "", ...rest }: React.HTMLAttributes<HTMLElement>) {

    return (
        <div className={`dropdown-item ${className}`} {...rest}>{children}</div>
    );
}