import { createSlice } from "@reduxjs/toolkit";

const initialState: { diCounter: number; selectedDI: boolean; } = {
    diCounter: 0,
    selectedDI: false
};

export const countersSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        setDICounter: (state, action) => {
            state.diCounter = action.payload;
        },
        setSelectedDI: (state, action) => {
            state.selectedDI = action.payload;
        },
        clearCounters: (state) => {
            state = initialState;
        }
    }
});

export const { setDICounter, clearCounters, setSelectedDI } = countersSlice.actions;