import { axiosInstance } from "@/config/axios";
import { AuthInitialState, LoginInputs, RecoverPayload } from "@/models/auth";
import { IResponse } from "@/models/general";
import { setHeaders } from "@/utils/utils";

export async function authLogin(payload: LoginInputs): Promise<AuthInitialState> {
    const { data: serviceData } = await axiosInstance.post("/users/login/", payload);
    return serviceData as AuthInitialState;
}

export async function verifyToken(): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const { data: serviceData } = await axiosInstance.get("/security/validate", axiosParams);

    return serviceData as IResponse;
}

export async function recoverPassword(payload: RecoverPayload): Promise<IResponse> {
    const { data: serviceData } = await axiosInstance.post("/composite/forgotPassword/", payload);
    return serviceData as IResponse;
}