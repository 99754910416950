import { IBadge } from "./badge";
import "./Badge.scss";

export function Badge({
    children,
    className,
    color,
    size = "normal",
    ...rest
}: IBadge) {

    return (
        <span className={`badge-wrapper ${color && `badge-wrapper__${color}`} badge-wrapper__${size} ${className}`} {...rest}>
            {children}
        </span>
    );
}