import { axiosInstance } from "@/config/axios";
import { setHeaders } from "@/utils";
//
import {
    INewScreeningParams,
    IGetScreeningResponse,
    IResponse,
    IUpdateScreeningParams,
    IGetScreeningParams,
    IScreeningResponse,
} from "@/models";

export async function createScreeningService(payload: INewScreeningParams): Promise<IResponse> {
    const headers = setHeaders();
    const { data: serviceData } = await axiosInstance.post<IResponse>("/medical/clinicHistory/preattentionOcularSifting/", payload, { headers });
    return serviceData;
}

export async function updateScreeningService(payload: IUpdateScreeningParams): Promise<IScreeningResponse> {
    const headers = setHeaders();
    const { data: serviceData } = await axiosInstance.put<IScreeningResponse>("/medical/clinicHistory/preattentionOcularSifting/", payload, { headers });
    return serviceData;
}

export const getScreeningService = async (params: IGetScreeningParams): Promise<IGetScreeningResponse> => {
    const headers = setHeaders();
    const axiosParams = { headers, params };
    const { data } = await axiosInstance.get<IGetScreeningResponse>("/medical/clinicHistory/preattentionOcularSifting/", axiosParams);
    return data;
};