import { AppDispatch } from "@/config/store";
import { getNotificationsServices } from "@/services/shared/notification";


export function getNotifications(userId: number | null, eaccount: number) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await getNotificationsServices(userId, eaccount);
            return response.results;

        } finally {
            // dispatch(finishLoading());
        }
    };
}
