import React, { useEffect, useState } from "react";
import {
    IconChevronRight,

    IconSearch,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
//
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
    Select,
    TextField,
    Table,
    Badge,
    TablePagination,
} from "@/components";
//
import "./Transcription.scss";
import { getDoctorsByContractsList, getStatus, getTranscription } from "./Transcription.actions";
import { DoctorsByContractsPayload, StatusPayload, TranscriptionParams, TranscriptionPayload, TranscriptionResponse } from "@/models/transcription/transcription";
import { useNavigate } from "react-router-dom";
import routes from "@/constants/routes";

export default function Transcription() {
    const dispatch = useAppDispatch();
    dayjs.extend(relativeTime);
    dayjs.extend(isToday);
    const workspace = useAppSelector(state => state.workspace);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const navigate = useNavigate();
    const [status, setStatus] = useState<StatusPayload[]>([]);
    const [doctors, setDoctors] = useState<DoctorsByContractsPayload[]>([]);
    const [trigger, setTrigger] = useState(0);
    const [diagnosticReadingList, setTranscriptionList] = useState<TranscriptionResponse>();
    const [filters, setFilters] = useState<TranscriptionParams>({
        eaccount: workspace.id,
        status: "",
        page: 1,
        perpage: 10,
        responsibleName: "",
        search: "",
        date: "",
        userId: userId,
        startDate: "",
        endDate: "",
    });

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getStatus({ eaccount: workspace.id, view: "adxTranscript" }));
            setStatus(data as StatusPayload[]);

            const dataDoc = await dispatch(getDoctorsByContractsList(workspace.id));
            setDoctors(dataDoc as DoctorsByContractsPayload[]);
        }
        fetchData();


        fetchData();
    }, [dispatch, workspace.id, workspace.profile]);

    useEffect(() => {
        (async function () {
            const data = await dispatch(getTranscription(filters));
            setTranscriptionList(data as TranscriptionResponse);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, trigger]);

    const handleNextPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handlePrevPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handleMaxPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handleMinPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, [target.name]: target.value });
    };
    const handleSelectChange = (value: number, name: string) => {
        setFilters({ ...filters, [name]: value });
        setTrigger(trigger + 1);
    };


    const renderRow = (reader: TranscriptionPayload) => {
        return (
            <tr
                key={reader.id}
            >
                <td><span className="">{reader.date}</span></td>
                <td><span className="">{reader.srvName}</span></td>
                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 150 }}
                    title={reader.organ ?? "-"}
                >
                    <span className="">{reader.organ ?? "-"}</span>
                </td>
                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 100 }}
                    title={reader.patientName}
                >
                    <span className="cursor"
                        onClick={() => {
                            // setShowHistory({ show: true, patientId: reader.patientId });
                        }}
                    >{reader.patientName}</span>
                </td>
                <td className="text-center">

                    {reader.responsibleName}
                </td>
                <td className="text-center">
                    <Badge style={{
                        backgroundColor: reader.statusBgColor || "#E8E8EA",
                        color: reader.statusFontColor || "#6E6F7C"
                    }}>
                        {reader.statusName}
                    </Badge>
                </td>
                <td className="text-primary pointer"
                    onClick={() => navigate(routes.workspaceDiagnosticTranscriptionSheet,
                        { state: { tab: "transcription", data: reader, reportRequired: false, requiresDilation: false, requiresImage: true, showRenderSection: true } })}
                >
                    <IconChevronRight />
                </td>
            </tr >
        );
    };

    const renderTable = () => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th className="col-1">Fecha examen</th>
                        <th className="col-2">Examen</th>
                        <th className="col-2">Órgano</th>
                        <th className="col-2">Paciente</th>
                        <th className="text-center col-2">Leído por</th>
                        <th className="text-center col-2">Estado</th>
                        <th className="col-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {diagnosticReadingList?.results?.length ? diagnosticReadingList?.results?.map(reader => renderRow(reader)) : (
                        <tr>
                            <td colSpan={12} align="center" style={{ padding: "15px 0" }}>
                                No hay informes pendientes por transcribir
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    };

    const renderFilters = () => {
        const formattedStatus = status.map(doc => ({
            value: doc.id,
            label: doc.description
        }));

        const formattedDoctors = doctors.map(doc => ({
            value: doc.id,
            label: doc.name,
        }));

        return (
            <div className="d-flex align-items-end mb-5">
                <TextField
                    placeholder="Escribe aquí para buscar"
                    variant="filled"
                    endIcon={
                        <IconSearch className="pointer" onClick={() => setTrigger(trigger + 1)} />
                    }
                    className="flex-grow-1 me-4"
                    name="search"
                    onChange={handleInputChange}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            setTrigger(trigger + 1);
                        }
                    }}
                />
                <div className="me-3" style={{ width: "12%" }}>
                    <label className="text-label">Desde</label>
                    <TextField
                        type="date"
                        value={filters.startDate}
                        onChange={({ target }) => {
                            setFilters({ ...filters, startDate: target.value });
                        }}
                        name="appDate"
                        placeholder="DD/MM/AAAA"
                        style={{ color: "#999" }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setTrigger(trigger + 1);
                            }
                        }}
                    />
                </div>
                <div className="me-3" style={{ width: "12%" }}>
                    <label className="text-label">Hasta</label>
                    <TextField
                        type="date"
                        value={filters.endDate}
                        onChange={({ target }) => {
                            setFilters({ ...filters, endDate: target.value });
                        }} name="appDate"
                        placeholder="DD/MM/AAAA"
                        style={{ color: "#999" }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setTrigger(trigger + 1);
                            }
                        }}
                    />
                </div>
                <div className="me-3" style={{ width: "12%" }}>
                    <label htmlFor="status" className="text-label">Leído por</label>
                    <Select
                        placeholder="Seleccionar..."
                        emptyOptionsLabel="No hay opciones disponibles."
                        options={formattedDoctors}
                        name="responsibleName"
                        onChange={({ option, name }) => handleSelectChange(option.value, name)}
                    />
                </div>
                <div className="me-3" style={{ width: "12%" }}>
                    <label htmlFor="status" className="text-label">Estado</label>
                    <Select
                        placeholder="Seleccionar..."
                        emptyOptionsLabel="No hay opciones disponibles."
                        options={formattedStatus}
                        name="status"
                        onChange={({ option, name }) => handleSelectChange(option.value, name)}
                    />
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <div className="d-flex w-100">
                <section className="consultation d-flex flex-column w-100 h-100 overflow-auto">
                    <h1 className="text-secondary fw-bold mb-4 display-5">Transcripción ayudas diagnósticas</h1>
                    {renderFilters()}

                    <div className="d-flex flex-column flex-grow-1">
                        <div className="table-responsive">
                            {renderTable()}
                        </div>
                        <TablePagination
                            totalPages={diagnosticReadingList?.rowTotal as number}
                            perPage={10}
                            currentPage={filters.page as number}
                            totalCount={diagnosticReadingList?.results?.length as number}
                            onNextPage={(value) => handleNextPage(value as number)}
                            onPrevPage={(value) => handlePrevPage(value as number)}
                            onMaxPage={(value) => handleMaxPage(value as number)}
                            onMinPage={(value) => handleMinPage(value as number)}
                        />
                    </div>
                </section>
            </div>
        );
    };

    return render();
}