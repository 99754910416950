import { setAttentionEnd } from "@/config/slices/canAttentionEnd";
import { AppDispatch, RootState } from "@/config/store";
import { IResponse } from "@/models";
import { DIListParams, DIPayload, DISelectParams, DeleteDIPayload, UpdateDIPayload } from "@/models/select/diagnosticImpression";
import {
    createDIService,
    deleteDIListService,
    getBodyOrgansService,
    getDIListService,
    getDISelectService,
    getDIStatusService,
    updateDIService
} from "@/services/select/diagnosticImpression";
import { fireErrorAlert } from "@/utils";
import axios from "axios";
import { getCanAttentionEnd } from "../consultation.actions";

export function getDIList(params: DIListParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getDIListService(params);
        return response.results;
    };
}

export function getDISelects(params: DISelectParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const diSelectResponse = await getDISelectService(params);
            const eyesResponse = await getBodyOrgansService();

            return {
                diSelectResponse: diSelectResponse.results,
                eyesResponse: eyesResponse.results
            };

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
        } finally {
            // dispatch(finishLoading());

        }
    };
}

export function createDI(payload: DIPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await createDIService(payload);
            return response.success;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());

        }
    };
}

export function updateDI(payload: UpdateDIPayload, isSurgery = false) {
    return async function (dispatch: AppDispatch, state: () => RootState) {        
        const response = await updateDIService(payload);
        if (!isSurgery) {
            const data = await dispatch(getCanAttentionEnd(state().patientAttention.patientStatus.mcfId));
            dispatch(setAttentionEnd(data.results ?? false));
        }
        return response.success;
    };
}

export function deleteDI(payload: DeleteDIPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await deleteDIListService(payload);
            return response.success;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());

        }
    };
}

export async function getDIStatus() {
    const response = await getDIStatusService();
    return response.results;
}