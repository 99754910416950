import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { statusCodes } from "@/constants";
import { IResponse } from "@/models";
import { SuspendAppointmentPayload, UnexpectedSituationParams, UnexpectedSituationUpdatePayload } from "@/models/shared/unexpectedSituation";
import { getDetailedClassificationsService, getGeneralClassificationsService } from "@/services";
import { createUnexpectedSituationService, getUnexpectedSituationService, suspendAttentionService, updateUnexpectedSituationService } from "@/services/shared/unexpectedSituation";
import { fireErrorAlert } from "@/utils";
import axios from "axios";

export function getUnexpectedSituation(params: UnexpectedSituationParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getUnexpectedSituationService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export async function getGeneralClassification(params: { active: number, mcfId?: number; appId?: number | null | undefined; }, doAfterSuccess?: () => void) {
    try {
        const response = await getGeneralClassificationsService(params);
        if (response.status && response.status !== statusCodes.OK) {
            fireErrorAlert(response.message as string);
            return;
        } else {
            doAfterSuccess && doAfterSuccess();
        }
        return response.results;

    } catch (error) {
        if (axios.isAxiosError<IResponse>(error)) {
            fireErrorAlert(error.response?.data.message as string);
        }
        console.error("There was an error =>", error);
    }

}


export function updateUnexpectedSituation(payload: UnexpectedSituationUpdatePayload, doAfterSuccess?: () => void) {
    return async function () {
        const response = await updateUnexpectedSituationService(payload);
        if (response.success) {
            doAfterSuccess &&
                doAfterSuccess();
        }
        return response.success;
    };
}
export function createUnexpectedSituation(payload: UnexpectedSituationUpdatePayload, doAfterSuccess?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await createUnexpectedSituationService(payload);
            if (response.success) {
                // fireSuccessAlert(response.message, response.title);
                doAfterSuccess &&
                    doAfterSuccess();
            }
            return response.success;

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function suspendAttentionMethod(payload: SuspendAppointmentPayload, doAfterSuccess?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await suspendAttentionService(payload);
            if (response.success) {
                // fireSuccessAlert(response.message, response.title);
                doAfterSuccess &&
                    doAfterSuccess();
            }
            return response.success;
        } finally {
            // dispatch(finishLoading());
        }
    };
}





export async function getDetailedClassification(params: { active: number, stcId?: number, appId?: number; }, doAfterSuccess: () => void) {
    try {
        const response = await getDetailedClassificationsService(params);
        if (response.status && response.status !== statusCodes.OK) {
            fireErrorAlert(response.message as string);
            return;
        } else {
            doAfterSuccess && doAfterSuccess();
        }
        return response.results;

    } catch (error) {
        if (axios.isAxiosError<IResponse>(error)) {
            fireErrorAlert(error.response?.data.message as string);
        }
        console.error("There was an error =>", error);
    }
}

