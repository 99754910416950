/* eslint-disable no-undef */
import { useEffect } from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
//
import { checkAuthentication } from "@/features/Auth/auth.actions";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
//
import routes from "@/constants/routes";
import Auth from "@/features/Auth/Auth";
import Template from "@/features/Template";
import DiagnosticAidReadings from "@/features/Workspace/DiagnosticAidReadings/DiagnosticAidReadings/DiagnosticAidReadings";
import DiagnosticAidsSheet from "@/features/Workspace/DiagnosticAids/DiagnosticAidsSheet";
import Gate from "@/features/Workspace/Gate/Gate";
import PredefinedTextsNoIns from "@/features/Workspace/Gate/PredefinedTextsNoIns";
import GeneralHistory from "@/features/Workspace/GeneralHistory/GeneralHistory";
import Home from "@/features/Workspace/Home/Home";
import Adaptation from "@/features/Workspace/PatientAttention/Consultation/Adaptation/Adaptation";
import Consultation from "@/features/Workspace/PatientAttention/Consultation/Consultation";
import LowVision from "@/features/Workspace/PatientAttention/Consultation/LowVision/LowVision";
import Oftalmology from "@/features/Workspace/PatientAttention/Consultation/Oftalmology/Oftalmology";
import Optometry from "@/features/Workspace/PatientAttention/Consultation/Optometry/Optometry";
import Orthoptic from "@/features/Workspace/PatientAttention/Consultation/Orthoptic/Orthoptic";
import PreConsultation from "@/features/Workspace/PatientAttention/Consultation/PreConsultation/PreConsultation";
import Preoperative from "@/features/Workspace/PatientAttention/Consultation/Preoperative/Preoperative";
import PatientAttentionSurgery from "@/features/Workspace/PatientAttention/Surgery/PatientAttentionSurgery";
import AttentionSurgery from "@/features/Workspace/PatientAttention/Surgery/stages/AttentionSurgeryPrincipal";
import Anesthesiologist from "@/features/Workspace/PatientAttention/Surgery/stages/doctorsSheets/anesthesiologist/Anesthesiologist";
import Surgeon from "@/features/Workspace/PatientAttention/Surgery/stages/doctorsSheets/surgeon/Surgeon";
import PatientAttention from "@/features/Workspace/PatientAttention/shared/PatientAttention";
import Transcription from "@/features/Workspace/Transcription/Transcription";
import Workspace from "@/features/Workspace/Workspace";
import ValidateAuth from "./Validate";

function PublicRoute({ children }: { children: JSX.Element; }) {
    const isAuthenticated = useAppSelector(state => state.auth.is_auth);
    const location = useLocation();

    if (isAuthenticated) {
        return <Navigate
            to={!location.state?.from ? routes.workspace : location.state.from}
            state={{ from: location }} replace
        />;
    }

    return children;
}

function PrivateRoute({ children }: { children: JSX.Element; }) {
    const location = useLocation();

    const access_token = localStorage.getItem("x_token");
    location.pathname = !access_token ? routes.workspaceHome : location.pathname;

    if (!access_token) {
        return <Navigate to={routes.auth} state={{ from: location }} replace />;
    }

    return children;
}

function AppRouter() {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(state => state.auth.is_auth);

    useEffect(() => {
        dispatch(checkAuthentication());
    }, [dispatch]);

    return (
        <Routes>
            <Route path="*" element={isAuthenticated ? <Navigate to={routes.workspace} /> : <Navigate to={routes.auth} replace />} />
            <Route path="/validate" element={<ValidateAuth />} />

            <Route path={routes.root} element={<Template />}>
                <Route index element={isAuthenticated ? <Navigate to={routes.workspace} /> : <Navigate to={routes.auth} replace />} />

                <Route path={routes.auth} element={
                    <PublicRoute>
                        <Auth />
                    </PublicRoute>
                } />

                <Route path={routes.workspace} element={<Navigate to="home" />} />
                <Route path={routes.workspace} element={
                    <PrivateRoute>
                        <Workspace />
                    </PrivateRoute>
                }>
                    <Route path={routes.workspaceHome} element={<Home />} />
                    <Route path={routes.workspaceGate} element={<Gate />} />
                    <Route path={routes.workspaceGatePredefinedTexts} element={<PredefinedTextsNoIns />} />
                    <Route path={routes.workspaceGeneralHistory} element={<GeneralHistory />} />
                    <Route path={routes.workspaceDiagnosticAidReadingsSheet} element={<DiagnosticAidReadings />} />
                    <Route path={routes.workspaceDiagnosticReadingSheet} element={<DiagnosticAidsSheet />} />
                    <Route path={routes.workspaceTranscription} element={<Transcription />} />
                    <Route path={routes.workspaceDiagnosticTranscriptionSheet} element={<DiagnosticAidsSheet />} />
                    <Route path={routes.workspacePatientAttention} element={<PatientAttention />}>
                        <Route path={routes.workspaceConsultation} element={<Consultation />} />
                        <Route path={routes.workspaceSurgery} element={<PatientAttentionSurgery />} />
                        <Route path={routes.workspaceSurgeryAttentionAnesthesiologist} element={<Anesthesiologist />} />
                        <Route path={routes.workspaceSurgeryAttentionSurgeon} element={<Surgeon />} />
                        <Route path={routes.workspaceSurgeryAttention} element={<AttentionSurgery />} />
                        <Route path={routes.workspaceDiagnosticAids} element={<Consultation />} />
                        <Route path={routes.workspaceDiagnosticAidsSheet} element={<DiagnosticAidsSheet />} />
                        <Route path={routes.consultationOftalmology} element={<Oftalmology />} />
                        <Route path={routes.consultationOptometry} element={<Optometry />} />
                        <Route path={routes.consultationPreconsultation} element={<PreConsultation />} />
                        <Route path={routes.consultationPreoperative} element={<Preoperative />} />
                        <Route path={routes.consultationAdaptation} element={<Adaptation />} />
                        <Route path={routes.consultationOrthoptic} element={<Orthoptic />} />
                        <Route path={routes.consultationLowVision} element={<LowVision />} />
                    </Route>
                </Route>
            </Route>
        </Routes >
    );
}

export default AppRouter;