import { Button, Modal } from "@/components";
import { finishLoading, startLoading } from "@/config/slices";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AdaptationPayload } from "@/models/sheets/adaptation";
import { fireWarningAlert } from "@/utils";
import { MouseEvent, useState } from "react";
import { deleteAdaptationClearenceCentral, getAdaptation, updateAdaptation } from "../adaptation.actions";

interface ClearenceCentralPayload {
    isOpen?: boolean,
    eye?: string | number,
    observation?: string,
    XCoordinates?: number,
    YCoordinates?: number,
    index?: number,
    id?: number,
}

export default function CentralClearance({
    adaptationPayload,
    readOnly,
    isDisabledForm,
    isHistory,
}: {
    adaptationPayload: AdaptationPayload;
    readOnly?: boolean;
    isHistory?: boolean;
    isDisabledForm?: boolean;
}) {
    const dispatch = useAppDispatch();

    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [adaptationState, setAdaptationState] = useState<AdaptationPayload>({
        appId: appointmentId,
        eaccount: accountId,
        mcfId: mcfId,
        userId: userId as number,
        ...adaptationPayload
    });

    const [clearenceCentralModal, setClearenceCentralModal] = useState<ClearenceCentralPayload>({
        isOpen: false,
        eye: "",
        observation: "",
        XCoordinates: undefined,
        YCoordinates: undefined,
        index: undefined,
        id: undefined,
    });

    const onCloseClearenceCentralModal = () => {
        setClearenceCentralModal({
            isOpen: false,
            eye: "",
            XCoordinates: undefined,
            YCoordinates: undefined,
            id: undefined,
            index: undefined,
            observation: "",
        });
    };

    const onOpenClearenceCentralModal = (eye: "od" | "oi", e: MouseEvent) => {
        setClearenceCentralModal({
            ...clearenceCentralModal,
            isOpen: true,
            eye: eye,
            XCoordinates: e?.nativeEvent?.offsetX,
            YCoordinates: e?.nativeEvent?.offsetY
        });
    };

    const onAddAnElementEye = async (isEditing: boolean) => {
        try {
            if (clearenceCentralModal.observation === "") {
                fireWarningAlert("La observación es obligatoria", "Validar campo");
            } else {
                const tempArr = [...(adaptationState?.clearenceCentral ?? [])];
                if (clearenceCentralModal?.index !== undefined) {
                    tempArr[clearenceCentralModal?.index] = {
                        XCoordinates: clearenceCentralModal.XCoordinates,
                        YCoordinates: clearenceCentralModal.YCoordinates,
                        eye: clearenceCentralModal.eye,
                        observation: clearenceCentralModal.observation,
                        id: clearenceCentralModal.id,
                    };
                }

                const options: AdaptationPayload = {
                    ...adaptationState,
                    clearenceCentral: [...(adaptationState?.clearenceCentral ?? []), {
                        XCoordinates: clearenceCentralModal.XCoordinates,
                        YCoordinates: clearenceCentralModal.YCoordinates,
                        eye: clearenceCentralModal.eye,
                        observation: clearenceCentralModal.observation,
                    }]
                };
                if (isEditing) {
                    setIsLoading(true);
                    const isSuccess = await dispatch(updateAdaptation({ ...adaptationState, clearenceCentral: tempArr }));
                    if (isSuccess) {
                        const data = await dispatch(getAdaptation({
                            appId: appointmentId,
                            eaccount: accountId,
                            mcfId: mcfId,
                            userId: userId as number
                        }));
                        setAdaptationState({
                            ...adaptationState,
                            ...data
                        });
                    }
                } else {
                    setIsLoading(true);
                    const isSuccess = await dispatch(updateAdaptation(options));
                    if (isSuccess) {
                        const data = await dispatch(getAdaptation({
                            appId: appointmentId,
                            eaccount: accountId,
                            mcfId: mcfId,
                            userId: userId as number
                        }));
                        setAdaptationState({
                            ...adaptationState,
                            ...data
                        });
                    }
                }
                onCloseClearenceCentralModal();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onDeleteAnElementEye = async () => {
        try {
            const options: AdaptationPayload = {
                ...adaptationState,
                clearenceCentral: [{
                    XCoordinates: clearenceCentralModal.XCoordinates,
                    YCoordinates: clearenceCentralModal.YCoordinates,
                    eye: clearenceCentralModal.eye,
                    observation: clearenceCentralModal.observation,
                    id: clearenceCentralModal.id,
                }]
            };
            dispatch(startLoading());
            const isSuccess = await dispatch(deleteAdaptationClearenceCentral(options));
            if (isSuccess) {
                const data = await dispatch(getAdaptation({
                    appId: appointmentId,
                    eaccount: accountId,
                    mcfId: mcfId,
                    userId: userId as number
                }));
                setAdaptationState({
                    ...adaptationState,
                    ...data
                });
            }
            onCloseClearenceCentralModal();
        } finally {
            dispatch(finishLoading());
        }
    };


    const renderModal = () => {
        return (
            <Modal onClose={() => onCloseClearenceCentralModal?.()}
                isOpen={clearenceCentralModal.isOpen}
                positionModal={"center"}
                width={500}
                className="textconfig-modal">
                <div className="p-4">
                    <div className="d-flex justify-content-center my-2">
                        <b className="text-secondary fw-bold fs-2 ms-2 text-center">Observación {String(clearenceCentralModal?.eye).toUpperCase()}</b>
                    </div>
                    <textarea className="form-control"
                        disabled={isDisabledForm}
                        style={{ height: " 150px" }}
                        name="observation"
                        value={clearenceCentralModal.observation}
                        onChange={({ target }) => {
                            setClearenceCentralModal?.({ ...clearenceCentralModal, observation: target.value });
                        }}
                    />
                </div>
                <div className="d-flex align-self-end px-4">
                    {(clearenceCentralModal?.id !== undefined && !isDisabledForm) &&
                        <button
                            className="align-self-end mt-3 mx-2 btn btn-outline-danger"
                            disabled={isDisabledForm}
                            onClick={() => {
                                onDeleteAnElementEye?.();
                            }}
                        >
                            Eliminar
                        </button>
                    }
                    <Button
                        variant="primary"
                        className="align-self-end mt-3"
                        disabled={isDisabledForm}
                        isLoading={isLoading}
                        onClick={() => {
                            onAddAnElementEye?.(clearenceCentralModal?.id !== undefined ? true : false);
                        }}
                    >
                        Guardar
                    </Button>
                </div>
            </Modal>
        );
    };

    const render = () => {
        return (
            <>
                <div className={`  d-flex ${isHistory ? "flex-column" : ""} w-100 align-items-center`} style={{ height: isHistory ? 420 : 200 }}>
                    <div className={`${isHistory ? "" : "me-4"}  text-wrap flex-grow-1 justify-content-end d-flex h-100`}>
                        <div className="d-flex flex-column  h-100" style={{ width: 350 }}>
                            <h5 className={`fw-bold ${readOnly ? "text-center" : "text-secondary"}`}>OD</h5>
                            <div className="border rounded overflow-hidden eye"
                                onClick={(e) => {
                                    !isDisabledForm && onOpenClearenceCentralModal("od", e);
                                }}
                                style={{ pointerEvents: readOnly ? "none" : "initial" }}
                            >
                                {(adaptationState?.clearenceCentral && adaptationState?.clearenceCentral?.length > 0) &&
                                    adaptationState?.clearenceCentral?.map((elem, ind) => {
                                        let tempArr;
                                        if (elem.eye === "od") {
                                            tempArr = <div className="element text-center pointer" key={ind}
                                                style={{
                                                    left: `${elem.XCoordinates}px`,
                                                    top: `${elem.YCoordinates}px`,
                                                }}
                                                onClickCapture={(event) => {
                                                    event.stopPropagation();

                                                    setClearenceCentralModal?.({
                                                        ...clearenceCentralModal, ...elem,
                                                        isOpen: true,
                                                        index: ind,
                                                    });
                                                }}
                                            >
                                                {elem.ord}
                                            </div>;

                                        }
                                        return tempArr;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-wrap flex-grow-1 d-flex flex-column h-100">
                        <div className="d-flex flex-column  h-100" style={{ width: 350 }}>
                            <h5 className={`fw-bold ${readOnly ? "text-center" : "text-secondary"}`}>OI</h5>
                            <div className="border rounded overflow-hidden eye"
                                onClick={(e) => {
                                    !isDisabledForm && onOpenClearenceCentralModal("oi", e);
                                }}
                                style={{ pointerEvents: readOnly ? "none" : "initial" }}
                            >
                                {(adaptationState?.clearenceCentral && adaptationState?.clearenceCentral?.length > 0) &&
                                    adaptationState?.clearenceCentral?.map((elem, ind) => {
                                        let tempArr;
                                        if (elem.eye === "oi") {
                                            tempArr = <div className="element text-center pointer" key={ind}
                                                style={{
                                                    left: `${elem.XCoordinates}px`,
                                                    top: `${elem.YCoordinates}px`,
                                                }}
                                                onClickCapture={(event) => {
                                                    event.stopPropagation();

                                                    setClearenceCentralModal?.({
                                                        ...clearenceCentralModal, ...elem,
                                                        isOpen: true,
                                                        index: ind,
                                                    });
                                                }
                                                }
                                            > {elem.ord}</div>;
                                        }
                                        return tempArr;

                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div >
                {(readOnly && adaptationState?.clearenceCentral?.length) ? (
                    <div>
                        {adaptationState?.clearenceCentral?.filter(el => el.eye === "od").length
                            ? (
                                <div className="my-2">
                                    <h5>Observaciones OD: </h5>
                                    <ol>
                                        {adaptationState?.clearenceCentral?.filter(el => el.eye === "od").map(item => (
                                            <li key={item.id}>{item.observation}</li>
                                        ))}
                                    </ol>
                                </div>
                            )
                            : ""}
                        {adaptationState?.clearenceCentral?.filter(el => el.eye === "oi").length
                            ? (
                                <div className="my-2">
                                    <h5>Observaciones OI: </h5>
                                    <ol>
                                        {adaptationState?.clearenceCentral?.filter(el => el.eye === "oi").map(item => (
                                            <li key={item.id}>{item.observation}</li>
                                        ))}
                                    </ol>
                                </div>
                            )
                            : ""}
                    </div>
                ) : ""}
                {renderModal()}
            </>
        );
    };

    return render();
}