import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
//
import { store } from "./config/store";
import AppRouter from "./router/AppRouter";
import { Loader } from "./components/Loader";

function Root() {
    return (
        <Provider store={store}>
            <Loader />
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
            {/* <RouterProvider router={router} /> */}
        </Provider>
    );
}

export default Root;
