import { IconCircleCheck } from "@tabler/icons-react";

import { useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";
import { getAHNurseryNotesDetail } from "../../../../history.actions";

import Admission from "@/features/Workspace/PatientAttention/Surgery/stages/Admission";
import Income from "@/features/Workspace/PatientAttention/Surgery/stages/Income";
import Preparation from "@/features/Workspace/PatientAttention/Surgery/stages/Preparation";
import Recovery from "@/features/Workspace/PatientAttention/Surgery/stages/Recovery";
import Surgery from "@/features/Workspace/PatientAttention/Surgery/stages/Surgery";

import { NurseryNotesDetail as INurseryNotesDetail } from "@/models/historyDetails/nurseryDetails";

const tabs = [
    {
        label: "Admisión",
        value: "admission",
    },
    {
        label: "Ingreso",
        value: "income",
    },
    {
        label: "Preparación",
        value: "preparation",
    },
    {
        label: "Cirugía",
        value: "surgery",
    },
    {
        label: "Recuperación y egreso",
        value: "recovery",
    },
];

export default function NurseryNotesDetail({ appId }: { appId: number; }) {
    const dispatch = useAppDispatch();

    const [currentTab, setCurrentTab] = useState<string>("income");
    const [nurseryDetail, setNurseryDetail] = useState<INurseryNotesDetail | null>(null);

    useEffect(() => {
        void (async function () {
            const response = await dispatch(getAHNurseryNotesDetail({ prefix: "nurseryNotes", appId: appId }));
            if (response) {
                setNurseryDetail(response);
            }
        })();
    }, [appId, dispatch]);


    const handleChangeTab = (stage: string): void => {
        setCurrentTab(stage);
    };

    const renderStages = () => {
        return (
            <div className="attentionSurgeryControls-tabs mt-3">
                {tabs.map(tab => (
                    <div
                        key={tab.value}
                        className={`attentionSurgeryControls-tab ${tab.value === currentTab && "active"}`}
                        onClick={() => handleChangeTab(tab.value)}
                    >
                        <IconCircleCheck />
                        <span>{tab.label}</span>
                    </div>
                ))}
            </div>

        );
    };

    const renderComponent = () => {
        return (
            <>
                <div className="attentionSurgeryControls mx-4 pb-2">
                    {renderStages()}
                </div>
                <div className="bodyHeight surgeryBody h-100">
                    {(currentTab === "admission") && (
                        <Admission readOnly data={nurseryDetail?.nurseryNotes.admission} />
                    )}
                    {(currentTab === "income" && nurseryDetail?.nurseryNotes.income) && (
                        <Income
                            data={nurseryDetail?.nurseryNotes.income}
                            onSubmitNotes={() => null}
                            readOnly
                        />
                    )}
                    {(currentTab === "preparation" && nurseryDetail?.nurseryNotes.preparation) && (
                        <Preparation
                            data={nurseryDetail?.nurseryNotes.preparation}
                            readOnly
                            onSubmitNotes={() => null}
                        />
                    )}
                    {(currentTab === "surgery" && nurseryDetail?.nurseryNotes.surgery) && (
                        <Surgery
                            data={nurseryDetail?.nurseryNotes.surgery}
                            appId={appId}
                            numId={NaN}
                            onSubmitNotes={() => null}
                            statusSurgery=''
                            readOnly
                        />
                    )}
                    {(currentTab === "recovery" && nurseryDetail?.nurseryNotes.recovery) && (
                        <Recovery
                            data={nurseryDetail?.nurseryNotes.recovery}
                            onSubmitNotes={() => null}
                            readOnly
                        />
                    )}
                </div>
            </>
        );
    };

    return renderComponent();
}