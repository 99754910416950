import { PatientRecord } from "@/models";
//
import {
    Checkbox,
    SectionCard,
    TextField
} from "@/components";
import { BackgroundDetail } from "@/models/historyDetails/background";

interface PreconsultationOptions {
    checked: boolean;
    label: string;
    value?: string;
    idName: keyof PatientRecord;
    idNotes?: keyof PatientRecord;
}

export default function AntecedentsDetails({ backgroundDetail
    , forDownload }: {
        backgroundDetail
        : BackgroundDetail; forDownload?: boolean;
    }) {

    const renderCheckbox = (item: PreconsultationOptions) => {
        return (
            <div className="d-flex align-items-center mb-3" style={{ height: "25px" }}>
                <div style={{ minWidth: "10vw" }}>
                    <Checkbox
                        className="nowrap"
                        label={item.label}
                        type="checkbox"
                        name={item.idName}
                        id={item.idName}
                        checked={item.checked}
                        disabled
                    />
                </div>
                {(item.checked && item.value) && (
                    <TextField
                        className="w-50 ms-3"
                        variant="default"
                        type="text"
                        placeholder="Comentarios..."
                        value={item.value}
                        readOnly
                    />
                )}
            </div>
        );
    };

    const renderGeneralForDownload = () => {
        if (backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals) {
            return (
                <div className="text-muted mb-2">
                    <h5 className="fw-bold text-primary">Generales</h5>
                    <ul>
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.arterialHypertension) ? (
                            <li>
                                <span className="fw-bold">Hipertensión arterial: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.arterialHypertensionNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.diabetes) ? (
                            <li>
                                <span className="fw-bold">Diabetes: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.diabetesGeneralNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.asthma) ? (
                            <li>
                                <span className="fw-bold">Asma: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.asthmaNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.cancer) ? (
                            <li>
                                <span className="fw-bold">Cáncer: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.cancerNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.coronaryHeartDisease) ? (
                            <li>
                                <span className="fw-bold">Enfermedad coronaria: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.coronaryHeartDiseaseNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.tuberculosis) ? (
                            <li>
                                <span className="fw-bold">Tuberculosis: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.tuberculosisNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.rheumatoidArthritis) ? (
                            <li>
                                <span className="fw-bold">Artritis Reumatoidea: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.rheumatoidArthritisNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.COPD) ? (
                            <li>
                                <span className="fw-bold">EPOC: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.COPDNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.useMedications) ? (
                            <li>
                                <span className="fw-bold">¿Usa medicamentos?: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.useMedicationsNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.previousSurgeriesGeneral) ? (
                            <li>
                                <span className="fw-bold">Cirugías previas: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.previousSurgeriesGeneralNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.allergies) ? (
                            <li>
                                <span className="fw-bold">Alergias: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.allergiesNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.others) ? (
                            <li>
                                <span className="fw-bold">Otros: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.othersNotes}
                            </li>
                        ) : ""}
                    </ul>
                    {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.notes ? (
                        <div><span className="fw-bold">Notas: </span>{backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.notes}</div>
                    ) : ""}
                </div>
            );
        }
    };

    const renderOcularForDownload = () => {
        if (backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular) {
            return (
                <div className="text-muted mb-2">
                    <h5 className="fw-bold text-primary">Oculares</h5>
                    <ul>
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glaucoma) ? (
                            <li>
                                <span className="fw-bold">Glaucoma: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glaucomaNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.ROP) ? (
                            <li>
                                <span className="fw-bold">ROP: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.ROPNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.DMRE) ? (
                            <li>
                                <span className="fw-bold">DMRE: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.DMRENotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glasses) ? (
                            <li>
                                <span className="fw-bold">Gafas: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glassesNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.dryEye) ? (
                            <li>
                                <span className="fw-bold">Ojo seco: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.dryEyeNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.diabetic_retinopathy) ? (
                            <li>
                                <span className="fw-bold">Retinopatía diabética: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.diabetic_retinopathyNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.uveitis) ? (
                            <li>
                                <span className="fw-bold">Uveitis: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.uveitisNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.contactLenses) ? (
                            <li>
                                <span className="fw-bold">Lentes de contacto: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.contactLensesNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.traumas) ? (
                            <li>
                                <span className="fw-bold">Traumas: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.traumasNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.surgeries) ? (
                            <li>
                                <span className="fw-bold">Cirugía: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.surgeriesNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.alerts) ? (
                            <li>
                                <span className="fw-bold">Alertas: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.alertsNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.others) ? (
                            <li>
                                <span className="fw-bold">Otros: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.othersNotes}
                            </li>
                        ) : ""}
                    </ul>
                    {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.eyeNotesOcular ? (
                        <div><span className="fw-bold">Notas: </span>{backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.eyeNotesOcular}</div>
                    ) : ""}
                </div>
            );
        }
    };

    const renderFamilyForDownload = () => {
        if (backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar) {
            return (
                <div className="text-muted mb-2">
                    <h5 className="fw-bold text-primary">Familiares</h5>
                    <ul>
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.AHT) ? (
                            <li>
                                <span className="fw-bold">HTA: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.AHTNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.diabetes) ? (
                            <li>
                                <span className="fw-bold">Diabetes: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.diabetesNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.asthma) ? (
                            <li>
                                <span className="fw-bold">Asma: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.asthmaNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.coronaryHeartDisease) ? (
                            <li>
                                <span className="fw-bold">Enfermedad coronaria: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.coronaryHeartDiseaseNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.collagenDisease) ? (
                            <li>
                                <span className="fw-bold">Enfermedad colágeno: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.collagenDiseaseNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.glaucoma) ? (
                            <li>
                                <span className="fw-bold">Glaucoma: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.glaucomaNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.keratoconus) ? (
                            <li>
                                <span className="fw-bold">Queratocono: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.keratoconusNotes}
                            </li>
                        ) : ""}
                        {Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.others) ? (
                            <li>
                                <span className="fw-bold">Otros: </span>
                                {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.othersNotes}
                            </li>
                        ) : ""}
                    </ul>
                    {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.family_notes ? (
                        <div><span className="fw-bold">Notas: </span>{backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.family_notes}</div>
                    ) : ""}
                </div>
            );
        }
    };

    const renderGeneralOptions = () => {
        if (backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals) {
            return (
                <div className="overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                    <SectionCard className="h-100 p-4" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                        <div className="d-flex justify-content-between text-secondary">
                            <h5 className="fw-bold">Generales</h5>
                        </div>
                        <hr />
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.arterialHypertension) ? true : false,
                            label: "Hipertensión arterial",
                            idName: "arterialHypertension",
                            idNotes: "arterialHypertensionNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.arterialHypertensionNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.diabetes) ? true : false,
                            label: "Diabetes",
                            idName: "diabetesGeneral",
                            idNotes: "diabetesGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.diabetesGeneralNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.asthma) ? true : false,
                            label: "Asma",
                            idName: "asthmaGeneral",
                            idNotes: "asthmaGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.asthmaNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.cancer) ? true : false,
                            label: "Cáncer",
                            idName: "cancerGeneral",
                            idNotes: "cancerGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.cancerNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.coronaryHeartDisease) ? true : false,
                            label: "Enfermedad coronaria",
                            idName: "coronaryHeartDiseaseGeneral",
                            idNotes: "coronaryHeartDiseaseGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.coronaryHeartDiseaseNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.tuberculosis) ? true : false,
                            label: "Tuberculosis",
                            idName: "tuberculosisGeneral",
                            idNotes: "tuberculosisGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.tuberculosisNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.rheumatoidArthritis) ? true : false,
                            label: "Artritis Reumatoidea",
                            idName: "rheumatoidArthritisGeneral",
                            idNotes: "rheumatoidArthritisGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.rheumatoidArthritisNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.COPD) ? true : false,
                            label: "EPOC",
                            idName: "copdGeneral",
                            idNotes: "copdGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.COPDNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.useMedications) ? true : false,
                            label: "¿Usa medicamentos?",
                            idName: "useMedicationsGeneral",
                            idNotes: "useMedicationsGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.useMedicationsNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.previousSurgeriesGeneral) ? true : false,
                            label: "Cirugías previas",
                            idName: "previousSurgeriesGeneral",
                            idNotes: "previousSurgeriesGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.previousSurgeriesGeneralNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.allergies) ? true : false,
                            label: "Alergias",
                            idName: "allergiesGeneral",
                            idNotes: "allergiesGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.allergiesNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.others) ? true : false,
                            label: "Otros",
                            idName: "othersGeneral",
                            idNotes: "othersGeneralNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.othersNotes
                        })}
                        {backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.notes ? (
                            <div>
                                <label htmlFor="" className="text-label">Nota</label>
                                <textarea
                                    className="form-control no-resize"
                                    defaultValue={backgroundDetail.patientBackground && backgroundDetail.patientBackground.generals.notes}
                                    placeholder="Notas..."
                                    readOnly
                                />
                            </div>
                        ) : ""}
                    </SectionCard>
                </div>
            );
        }
    };

    const renderOcularOptions = () => {
        if (backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular) {
            return (
                <div className="overflow-hidden" style={{ borderRadius: "0.7rem" }}>
                    <SectionCard className="h-100 p-4" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                        <div className="d-flex justify-content-between text-secondary">
                            <h5 className="fw-bold">Oculares</h5>
                        </div>
                        <hr />
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glaucoma) ? true : false,
                            label: "Glaucoma",
                            idName: "glaucomaOcular",
                            idNotes: "glaucomaOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glaucomaNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.ROP) ? true : false,
                            label: "ROP",
                            idName: "ropOcular",
                            idNotes: "ropOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.ROPNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.DMRE) ? true : false,
                            label: "DMRE",
                            idName: "dmreOcular",
                            idNotes: "dmreOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.DMRENotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glasses) ? true : false,
                            label: "Gafas",
                            idName: "glassesOcular",
                            idNotes: "glassesOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.glassesNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.dryEye) ? true : false,
                            label: "Ojo seco",
                            idName: "dryEyeOcular",
                            idNotes: "dryEyeOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.dryEyeNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.diabetic_retinopathy) ? true : false,
                            label: "Retinopatía diabética",
                            idName: "diabeticRetinoPathyOcular",
                            idNotes: "diabeticRetinoPathyOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.diabetic_retinopathyNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.uveitis) ? true : false,
                            label: "Uveitis",
                            idName: "uveitisOcular",
                            idNotes: "uveitisOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.uveitisNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.contactLenses) ? true : false,
                            label: "Lentes de contacto",
                            idName: "contactLensesOcular",
                            idNotes: "contactLensesOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.contactLensesNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.traumas) ? true : false,
                            label: "Traumas",
                            idName: "traumasOcular",
                            idNotes: "traumasOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.traumasNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.surgeries) ? true : false,
                            label: "Cirugía",
                            idName: "surgeriesOcular",
                            idNotes: "surgeriesOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.surgeriesNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.alerts) ? true : false,
                            label: "Alertas",
                            idName: "alertsOcular",
                            idNotes: "alertsOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.alertsNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.others) ? true : false,
                            label: "Otros",
                            idName: "othersOcular",
                            idNotes: "othersOcularNotes",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.othersNotes
                        })}
                        {backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.eyeNotesOcular ? (
                            <div>
                                <label htmlFor="" className="text-label">Nota</label>
                                <textarea
                                    className="form-control no-resize"
                                    value={backgroundDetail.patientBackground && backgroundDetail.patientBackground.ocular.eyeNotesOcular}
                                    placeholder="Notas..."
                                    readOnly
                                />
                            </div>
                        ) : ""}
                    </SectionCard >
                </div>
            );
        }
    };

    const renderFamiliarOptions = () => {
        if (backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar) {
            return (
                <div className="overflow-hidden" style={{ borderRadius: "0.7rem" }}>

                    <SectionCard className="h-100 p-4" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                        <div className="d-flex justify-content-between text-secondary">
                            <h5 className="fw-bold">Familiares</h5>
                        </div>
                        <hr />
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.AHT) ? true : false,
                            label: "HTA",
                            idName: "ahtFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.AHTNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.diabetes) ? true : false,
                            label: "Diabetes",
                            idName: "diabetesFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.diabetesNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.asthma) ? true : false,
                            label: "Asma",
                            idName: "asthmaFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.asthmaNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.coronaryHeartDisease) ? true : false,
                            label: "Enfermedad coronaria",
                            idName: "coronaryHeartDiseaseFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.coronaryHeartDiseaseNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.collagenDisease) ? true : false,
                            label: "Enfermedad colágeno",
                            idName: "collagenDiseaseFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.collagenDiseaseNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.glaucoma) ? true : false,
                            label: "Glaucoma",
                            idName: "glaucomaFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.glaucomaNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.keratoconus) ? true : false,
                            label: "Queratocono",
                            idName: "keratoconusFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.keratoconusNotes
                        })}
                        {renderCheckbox({
                            checked: Number(backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.others) ? true : false,
                            label: "Otros",
                            idName: "othersFamiliar",
                            value: backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.othersNotes
                        })}
                        {backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.family_notes ? (
                            <div>
                                <label htmlFor="" className="text-label">Nota</label>
                                <textarea
                                    className="form-control no-resize"
                                    value={backgroundDetail.patientBackground && backgroundDetail.patientBackground.familiar.family_notes}
                                    placeholder="Escribir..."
                                    readOnly
                                />
                            </div>
                        ) : ""}

                    </SectionCard >
                </div>
            );
        }
    };

    const render = () => {
        return (
            <>
                {!forDownload ? (
                    <div className="d-flex flex-column h-100 mt-5">
                        <div className="row h-100">
                            <div className="col-lg-4">
                                {renderGeneralOptions()}
                            </div>
                            <div className="col-lg-4">
                                {renderOcularOptions()}
                            </div>
                            <div className="col-lg-4">
                                {renderFamiliarOptions()}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-3">
                        {renderGeneralForDownload()}
                        {renderOcularForDownload()}
                        {renderFamilyForDownload()}
                    </div>
                )}
            </>
        );
    };

    return render();
}