import { useEffect, useState, memo } from "react";
import { IconClock } from "@tabler/icons-react";
import dayjs from "dayjs";
import "dayjs/locale/es-do";

const dominicanDayjs = (time?: string) => dayjs.utc(time).tz("America/Santo_Domingo");

export const Timer = memo(({ startTime, endTime }: { startTime: string; endTime?: string; }) => {

    const [timeInSeconds, setTimeInSeconds] = useState(0);

    useEffect(() => {
        if (startTime) {
            const currentTime = dominicanDayjs().format("YYYY-MM-DD HH:mm:ss");
            const seconds = dayjs(currentTime).diff(startTime, "seconds");
            setTimeInSeconds(seconds);
        }
    }, [startTime]);

    useEffect(() => {
        if (!endTime) {
            const interval = setInterval(() => {
                setTimeInSeconds(prevTime => prevTime + 1);
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        } else {
            const st = dominicanDayjs(startTime);
            const et = dominicanDayjs(endTime);
            const seconds = et.diff(st, "seconds");

            setTimeInSeconds(seconds);
        }
    }, [startTime, endTime]);

    const formatTime = () => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    const render = () => {
        return (
            <div className="d-flex align-items-end text-secondary ms-3 no-user-select">
                <span className="fw-bold mx-2">{formatTime()}</span>
                <IconClock width={15} style={{ paddingTop: "4px" }} />
            </div>
        );
    };

    return render();
});
