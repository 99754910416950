import { Fragment, useEffect, useState } from "react";
import { IconChevronRight } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
//
import SelectionSvg from "@/assets/selection.svg";
//
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
//
import { createNewToken, getAccountsByUser, loadAccount, loader, saveAccount } from "./home.actions";
import { Account, CurrentAccount } from "@/models/account";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
} from "@/components";
//
import "./Home.scss";
import { getCategory } from "../Sidebar/sidebar.actions";
import { setCategorySlice } from "@/config/slices/category";

export default function Home() {
    const userId = useAppSelector(state => state.auth.user_data.id);
    const workspace = useAppSelector(state => state.workspace);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [profile, setProfile] = useState<null | number>(null);

    const [accounts, setAccounts] = useState<Account[]>();
    useEffect(() => {
        (async function () {
            if (userId) {
                // dispatch(loader(true)); 
                const response = await dispatch(getAccountsByUser(`${userId}`, location));
                setAccounts(response);
            }
        })();
    }, [dispatch, userId, location]);

    useEffect(() => {
        if (workspace.id) {
            navigate("../gate");
        } else {
            dispatch(loadAccount());
        }
    }, [dispatch, workspace.id, navigate]);

    const handleAccount = async (account: CurrentAccount) => {
        const res = await dispatch(createNewToken({ eaccount: account.id, profile: account.profile?.id }));
        if (res){
            dispatch(saveAccount(account));
            navigate("../gate"); 
        }
    };

    const getCategoryFunction = (idProfile: number) => {
        setProfile(idProfile);
        async function fetchData() {
            const data = await dispatch(getCategory(idProfile));
            if (data) {
                dispatch(setCategorySlice(data ?? {}));
                localStorage.setItem("category", JSON.stringify(data));
            }
        }
        fetchData();
    };

    useEffect(() => {
        if (profile !== null) {
            getCategoryFunction(profile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);


    const renderAccountSelection = () => {
        return (
            <Fragment>
                <h2 className="mb-4 text-muted">Indique la cuenta con la que desea trabajar</h2>
                <div className="d-flex flex-wrap flex-column mt-3" style={{ maxHeight: 300 }}>
                    {accounts?.length && accounts.map((account, index) => {
                        if (Number(accounts.length) - 1 === Number(index)) {
                            dispatch(loader(false));
                        }
                        return (
                            <Dropdown key={account.id} className="dropend" style={{ marginRight: "10vw" }}>
                                <div className="home-account mb-4 me-5" data-bs-toggle="dropdown" data-bs-offset="0,15">
                                    <div className="detail"></div>
                                    <span className="fw-bold">{account.name}</span>
                                    <div className="home-account__roles" >
                                        <IconChevronRight />
                                    </div>
                                    <DropdownMenu className="home__dropdown-menu">
                                        {account.profile.map((profile) => (
                                            <DropdownItem key={profile.id} className="home-dropdown-item" onClick={() => {
                                                getCategoryFunction(profile.id);
                                                handleAccount({ ...account, profile });
                                            }}>
                                                {profile.nombre}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </div>
                            </Dropdown>
                        );
                    })}
                </div>
            </Fragment>
        );
    };

    const render = () => {
        return (
            <div className="home">
                <img src={SelectionSvg} className="svg" alt="selection" />
                {renderAccountSelection()}
            </div>
        );
    };

    return render();
}