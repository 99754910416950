import React, { useRef, useEffect, useState } from "react";
import "./AudioPlayer.scss";
import { IconPlayerPause, IconPlayerPlay } from "@tabler/icons-react";

const AudioPlayer = ({ audio, width }: { audio: string | undefined; width?: string | undefined; }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState<number | "loading">(0);
    const [triggerAudio, setTriggerAudio] = useState(0);

    const advanceAudio = () => {
        if (audioRef.current) {
            audioRef.current.currentTime += 5;
        }
    };

    const rewindAudio = () => {
        if (audioRef.current) {
            audioRef.current.currentTime -= 5;
        }
    };

    const keysPressed: Record<string, boolean> = {};

    const handleKeyDown = (event: KeyboardEvent) => {
        keysPressed[event.key] = true;

        const isControlPressed = keysPressed["Control"];
        if (isControlPressed) {
            const isArrowRightPressed = keysPressed["ArrowRight"];
            const isArrowLeftPressed = keysPressed["ArrowLeft"];
            const isSpaceBarPressed = keysPressed[" "];

            if (isArrowRightPressed) {
                advanceAudio();
            } else if (isArrowLeftPressed) {
                rewindAudio();
            } else if (isSpaceBarPressed) {
                if (audioRef.current && audioRef.current.paused) {
                    audioRef.current.play();
                    setIsPlaying(true);
                } else if (audioRef.current) {
                    audioRef.current.pause();
                    setIsPlaying(false);
                }
                // handlePlayPause();
            }
        }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
        delete keysPressed[event.key];
    };


    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio, triggerAudio]);


    const handlePlayPause = () => {
        if (audioRef.current) {
            if (audioRef.current && audioRef.current.paused) {
                audioRef.current.play();
                setIsPlaying(true);
            } else if (audioRef.current) {
                audioRef.current.pause();
                setIsPlaying(false);
            }
            // if (isFinished) {
            //     setCurrentTime(0);
            //     audioRef.current.play();
            //     setIsFinished(false);
            // } else {
            //     if (isPlaying) {
            //         audioRef.current.pause();
            //         // audioRef.current.currentTime = 0;
            //     } else {
            //         audioRef.current.play();
            //     }
            // }
            // setIsPlaying((prevState) => !prevState);
        }
    };

    const updateTime = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (audioRef.current) {
            const time = parseFloat(e.target.value);
            setCurrentTime(time);
            audioRef.current.currentTime = time;
        }
    };

    const formatTime = (time: number | "loading") => {
        if (time === "loading") {
            return "Cargando...";
        }
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    useEffect(() => {
        const audio = audioRef.current;

        if (audio) {
            audio.addEventListener("timeupdate", updateTime);
            audio.addEventListener("loadedmetadata", () => {
                // setDuration(audioRef.current?.duration || 0);
            });
        }
        return () => {
            if (audio) {
                audio.removeEventListener("timeupdate", updateTime);
            }
        };
    }, [audio, triggerAudio,]);

    useEffect(() => {
        const imgDiscard = document.querySelector("[pseudo='-internal-media-controls-overflow-button']") as HTMLImageElement;
        if (imgDiscard) {
            imgDiscard.classList.add("hide");
        }

    }, [audio, triggerAudio]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio, triggerAudio]);

    useEffect(() => {
        if (audio !== undefined) {
            const audio = document.getElementById("durationAudio") as HTMLAudioElement | null;
            if (audio && audio !== null) {
                audio.addEventListener("loadedmetadata", (event) => {
                    if (audio.duration === Infinity || isNaN(Number(audio.duration))) {
                        audio.currentTime = 1e101;
                        audio.addEventListener("timeupdate", getDuration);
                    }
                });

                audio.addEventListener("ended", () => {
                    audio.currentTime = 0;
                    setIsPlaying(false);
                    // setIsFinished(true);
                });

            }
        }
        function getDuration(event: Event) {
            const audioElement = event.target as HTMLAudioElement;
            audioElement.currentTime = 0;
            audioElement.removeEventListener("timeupdate", getDuration);
            setDuration(audioElement.duration);

        }

    }, [audio, triggerAudio]);

    useEffect(() => {
        const audio = document.getElementById(
            "durationAudio"
        ) as HTMLAudioElement | null;
        if (audio) {
            audio.addEventListener("ended", () => {
                // setCurrentTime(audio.duration);
                // setIsPlaying(false);
            });
        }
    }, []);

    useEffect(() => {
        if (audio !== undefined) {
            setTriggerAudio(triggerAudio + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio]);


    return (
        <div className="custom-audio-player"
            style={{ width: width && width }}
        >
            <audio key={triggerAudio} ref={audioRef} controls className="custom-audio hide" controlsList="nodownload noplaybackrate"
                id="durationAudio"
            >
                <source src={audio} type="audio/mpeg" key={triggerAudio} />
                Tu navegador no soporta el elemento de audio.
            </audio>
            <div className="d-flex align-items-center custom-audio-player w-100">
                <div onClick={handlePlayPause} className="pointer text-primary ">
                    {isPlaying ? (
                        <IconPlayerPause />
                    ) : (
                        <IconPlayerPlay />
                    )}
                </div><span className="px-3 textTime">
                    {formatTime(currentTime)} / {formatTime(duration)}
                </span>
                <div className="w-100">
                    <input
                        type="range"
                        min={0}
                        max={duration || 0}
                        value={currentTime}
                        onChange={handleSeek}
                        className="custom-slider custom-audio-player"
                    />
                </div>
            </div>

        </div>
    );
};

export default AudioPlayer;
