const routes = {
    root: "/",

    auth: "/auth",
    workspace: "/workspace",

    get workspaceHome() {
        return `${this.workspace}/home`;
    },
    get workspaceGate() {
        return `${this.workspace}/gate`;
    },
    get workspaceGatePredefinedTexts() {
        return `${this.workspaceGate}/predefinedTextsNoIns`;
    },
    get workspaceGeneralHistory() {
        return `${this.workspaceGate}/generalHistory`;
    },
    get workspaceDiagnosticAidReadings() {
        return `${this.workspaceGate}/DiagnosticAidReadings`;
    },
    get workspaceTranscription() {
        return `${this.workspaceGate}/transcription`;
    },
    get workspaceDiagnosticAidReadingsSheet() {
        return `${this.workspaceDiagnosticAidReadings}/DiagnosticAidReadings`;
    },
    get workspacePatientAttention() {
        return `${this.workspace}/patientattention`;
    },
    get workspaceSurgery() {
        return `${this.workspacePatientAttention}/surgery`;
    },
    get workspaceSurgeryAttention() {
        return `${this.workspaceSurgery}/attention`;
    },
    get workspaceSurgeryAttentionAnesthesiologist() {
        return `${this.workspaceSurgeryAttention}/anesthesiologist`;
    },
    get workspaceSurgeryAttentionSurgeon() {
        return `${this.workspaceSurgeryAttention}/surgeon`;
    },
    get workspaceDiagnosticAids() {
        return `${this.workspacePatientAttention}/DiagnosticAids`;
    },
    get workspaceDiagnosticAidsSheet() {
        return `${this.workspaceDiagnosticAids}/DiagnosticAids`;
    },
    get workspaceDiagnosticReadingSheet() {
        return `${this.workspaceDiagnosticAidReadings}/DiagnosticAids`;
    },
    get workspaceDiagnosticTranscriptionSheet() {
        return `${this.workspaceTranscription}/DiagnosticAids`;
    },
    get workspaceConsultation() {
        return `${this.workspacePatientAttention}/consultation`;
    },
    get consultationOftalmology() {
        return `${this.workspaceConsultation}/ophthalmology`;
    },
    get consultationOptometry() {
        return `${this.workspaceConsultation}/optometry`;
    },
    get consultationPreconsultation() {
        return `${this.workspaceConsultation}/preconsulting`;
    },
    get consultationPreoperative() {
        return `${this.workspaceConsultation}/preoperative`;
    },
    get consultationAdaptation() {
        return `${this.workspaceConsultation}/adaptation`;
    },
    get consultationOrthoptic() {
        return `${this.workspaceConsultation}/orthoptic`;
    },
    get consultationLowVision() {
        return `${this.workspaceConsultation}/lowVision`;
    }
};

export default Object.freeze(routes);