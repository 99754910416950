import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
//
import { SectionCard } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AttentionOriginFields } from "@/models/attentionOrigin";
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import ConsultationControls from "../Controls/Controls";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import ConsultationHeader from "../Header/Header";
//
import {
    OptometryPayload,
} from "@/models/sheets/optometry";
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
// import VisualAcuity from "../shared/VisualAcuity/VisualAcuity";
import { Segments } from "@/models/generalFields";
import { DIListItem } from "@/models/select/diagnosticImpression";
import "../Consultation.scss";
import History from "../History/History";
import AvLensesInUse from "../shared/AvLensesInUse/AvLensesInUse";
import AvNoCorrection from "../shared/AvNoCorrection/AvNoCorrection";
import AvPh from "../shared/AvPh/AvPh";
import AvWithCorrection from "../shared/AvWithCorrection/AvWithCorrection";
import Distances from "../shared/Distances/Distances";
import Keratometry from "../shared/Keratometry/Keratometry";
import ObjectiveRefraction from "../shared/ObjectiveRefraction/ObjectiveRefraction";
import Optotype from "../shared/Optotype/Optotype";
import SubjectiveRefraction from "../shared/SubjectiveRefraction/SubjectiveRefraction";
import {
    getOptometry,
    updateOptometry
} from "./optometry.actions";

export default function Optometry({ historySheet, data, appId }: {
    historySheet?: boolean;
    data?: {
        dataSheet: OptometryPayload, generalsFields: Segments[]; diagnosticImpression: DIListItem[];
    };
    appId?: number;
}) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { dataSheet, diagnosticImpression, generalsFields } = data || {};

    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const mcfId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const isDisabledForm = historySheet ? historySheet : false;

    const [showHistory, setShowHistory] = useState(false);
    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: undefined,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });

    const [optometryPayload, setOptometryPayload] = useState<OptometryPayload>({
        mcfId: mcfId,
        eaccount: accountId,
        userId: userId as number,
        appId: appointmentId,
    });

    useEffect(() => {
        if (!historySheet) {
            const splittedPath = location.pathname.split("/");
            const sheet = splittedPath[splittedPath.length - 1];

            if (sheet !== currentSheet) {
                navigate(-1);
            }
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            if (historySheet) {
                if (dataSheet) {
                    setOptometryPayload({
                        ...dataSheet,
                        eaccount: accountId,
                        userId: userId as number,
                        appId: appointmentId,
                    });
                }
            } else {

                const data = await dispatch(getOptometry({
                    appId: appointmentId,
                    eaccount: accountId,
                    mcfId: mcfId,
                    userId: userId as number
                }));
                if (data) {
                    setOptometryPayload({
                        ...data,
                        mcfId: mcfId,
                        eaccount: accountId,
                        userId: userId as number,
                        appId: appointmentId,
                    });
                }
            }
        }
        fetchData();
    }, [dispatch, appointmentId, accountId, mcfId, userId, historySheet, dataSheet]);

    const onSubmitOptometry = useDebouncedCallback(() => {
        if (optometryPayload) {
            const options = { ...optometryPayload, id: optometryPayload.id };

            setOptometryPayload(options);
            dispatch(updateOptometry(options));
        }
    }, 300);

    const onChangeNewPupilometry = (prefix: "photopic" | "mesotopic", eye: "od" | "oi", value: number | string) => {
        const options: typeof optometryPayload = {
            ...optometryPayload,
            pupillometry: {
                ...optometryPayload.pupillometry,
                [prefix]: {
                    ...optometryPayload.pupillometry?.[prefix],
                    [eye]: value
                }
            }
        };
        setOptometryPayload(options);
        onSubmitOptometry();
    };

    const render = () => {

        return (
            <section className="d-flex flex-column h-100 overflow-hidden">
                {!historySheet &&
                    <ConsultationHeader isDisabledForm={isDisabledForm} currentSection="Optometría" showHistory={showHistory}
                        setShowHistory={(state) => setShowHistory(state)} />}<div className="d-flex overflow-hidden h-100">
                    <section className="consultation-section">
                        <div className="row g-3">
                            <div className="col-12">
                                <AttentionOrigin
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    data={generalsFields}
                                    historySheet={historySheet}
                                />
                            </div>
                            <div className="col-12">
                                <div className="row g-3">
                                    <div className="col-lg-2">
                                        <Optotype
                                            historySheet={historySheet}
                                            isDisabledForm={isDisabledForm}
                                            externalPayload={optometryPayload}
                                            setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                            onSubmit={() => onSubmitOptometry()}
                                        />
                                    </div>
                                    <div className="col-lg-5">
                                        <AvNoCorrection
                                            isDisabledForm={isDisabledForm}
                                            historySheet={historySheet}
                                            externalPayload={optometryPayload}
                                            setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                            onSubmit={() => onSubmitOptometry()}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <AvPh
                                            isDisabledForm={isDisabledForm}
                                            historySheet={historySheet}
                                            externalPayload={optometryPayload}
                                            setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                            onSubmit={() => onSubmitOptometry()}
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <SectionCard className="h-100">
                                            <h5 className="text-secondary fw-bold">Pupilometría</h5>
                                            <hr className="text-primary mt-2 mb-1" />
                                            <table className="">
                                                <thead>
                                                    <tr>
                                                        <td></td>
                                                        <td align="center" className="pt-1 text-secondary " >Fotópica (mm)</td>
                                                        <td align="center" className="pt-1 text-secondary " style={{ borderTopRightRadius: "0.5rem" }}>Mesópica (mm)</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-secondary nowrap bg-od px-2" style={{ borderTopLeftRadius: "0.5rem" }}>OD</td>
                                                        <td className="bg-od p-2">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.photopic?.od}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("photopic", "od", event.target.value);
                                                                    }
                                                                }}
                                                                disabled={isDisabledForm}
                                                            />
                                                        </td>
                                                        <td className="bg-od p-2">
                                                            <input
                                                                disabled={isDisabledForm}
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.mesotopic?.od}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("mesotopic", "od", event.target.value);
                                                                    }
                                                                }}
                                                            />
                                                           
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary nowrap bg-oi px-2" style={{ borderBottomLeftRadius: "0.5rem" }}>OI</td>
                                                        <td className="bg-oi p-2" >
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.photopic?.oi}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("photopic", "oi", event.target.value);
                                                                    }
                                                                }}
                                                                disabled={isDisabledForm}
                                                            />
                                                        </td>
                                                        <td className="bg-oi p-2" style={{ borderBottomRightRadius: "0.5rem" }}>
                                                            <input
                                                                disabled={isDisabledForm}
                                                                type="number"
                                                                className="form-control"
                                                                value={optometryPayload.pupillometry?.mesotopic?.oi}
                                                                onChange={(event) => {
                                                                    const value = event.target.value.split(".");
                                                                    if (value[1] && value[1].length > 2) {
                                                                        event.preventDefault();
                                                                    } else {
                                                                        onChangeNewPupilometry("mesotopic", "oi", event.target.value);
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="pt-2">
                                                <textarea
                                                    disabled={isDisabledForm}
                                                    className="form-control h-100 no-resize"
                                                    value={optometryPayload.pupillometryObservations}
                                                    onChange={({ target }) => {
                                                        setOptometryPayload({ ...optometryPayload, pupillometryObservations: target.value });
                                                        onSubmitOptometry();
                                                    }}
                                                    placeholder="Observaciones..."
                                                /> 
                                            </div>

                                        </SectionCard>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <AvLensesInUse
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-6">
                                <ObjectiveRefraction
                                    title={"Autorefracto sin cicloplejía"}
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    externalPayload={optometryPayload} 
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-6">
                                <ObjectiveRefraction
                                    title={"Autorefracto con cicloplejía"}
                                    prefix={"cicloplejia"}
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-6">
                                <Keratometry
                                    isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-6">
                                <AvWithCorrection
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-7">
                                <SubjectiveRefraction
                                    title={"Refracción subjetiva sin cicloplejía"}
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                            <div className="col-5">
                                <div className="d-flex h-100 gap-2">
                                    <SectionCard className="w-100">
                                        <div className="w-100 h-100 d-flex flex-column">
                                            <h5 className="text-secondary fw-bold">Motilidad</h5>
                                            <hr className="text-primary mt-2" />
                                            <textarea
                                                disabled={isDisabledForm}
                                                className="form-control h-100 no-resize"
                                                value={optometryPayload.commentOnMotility ?? ""}
                                                onChange={({ target }) => {
                                                    setOptometryPayload({ ...optometryPayload, commentOnMotility: target.value });
                                                    onSubmitOptometry();
                                                }}
                                                placeholder="Observaciones..."
                                            />

                                        </div>
                                       
                                    </SectionCard>
                                    <SectionCard className=" w-100">
                                        <div className="w-100 h-100 d-flex flex-column">
                                            <h5 className="text-secondary fw-bold">Alineamiento</h5>
                                            <hr className="text-primary mt-2" />
                                            <textarea
                                                disabled={isDisabledForm}
                                                className="form-control h-100 no-resize"
                                                value={optometryPayload.alignment ?? ""}
                                                onChange={({ target }) => {
                                                    setOptometryPayload({ ...optometryPayload, alignment: target.value });
                                                    onSubmitOptometry();
                                                }}
                                                placeholder="Observaciones..."
                                            /> 
                                        </div> 
                                       
                                    </SectionCard>

                                </div>
                            </div>
                            <div className="col-7">
                                <SubjectiveRefraction
                                    title={"Refracción subjetiva con cicloplejía"}
                                    prefix={"cicloplejia"}
                                    historySheet={historySheet}
                                    isDisabledForm={isDisabledForm}
                                    externalPayload={optometryPayload}
                                    setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                    onSubmit={onSubmitOptometry}
                                />
                            </div>
                          
                            <div className="col-5">
                                <div className="w-100 h-100 "> 
                                    <Distances
                                        isDisabledForm={isDisabledForm}
                                        externalPayload={optometryPayload}
                                        setExternalPayload={(payload) => setOptometryPayload(payload as OptometryPayload)}
                                        onSubmit={onSubmitOptometry}
                                    />
                                </div>
                            </div>
                           
                            <div className="col-6">
                                <DiagnosticImpression isDisabledForm={isDisabledForm}
                                    historySheet={historySheet}
                                    data={{
                                        appId,
                                        cluId: undefined,
                                        userId: NaN,
                                        eaccount: accountId,
                                        clhId: undefined,
                                        sheet: "optometry",
                                        information: diagnosticImpression
                                    }
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <AnalysisAndPlan
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    height={215}
                                    data={generalsFields && generalsFields[0].analysisPlanJst}
                                />
                            </div>
                        </div>
                    </section>
                    {!historySheet &&
                        <History showClose={false} />
                    }
                </div>
                {!historySheet &&

                    <ConsultationControls isDisabledForm={isDisabledForm} />
                }
            </section>
        );
    };

    return render();
}
