import { createSlice } from "@reduxjs/toolkit";

type UiInitialState = {
    isLoading: boolean;
};

const initialState: UiInitialState = {
    isLoading: false
};

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        finishLoading: (state) => {
            state.isLoading = false;
        }
    }
});

export const { startLoading, finishLoading } = uiSlice.actions;