import React, { useEffect, useState } from "react";
import {
    IconChevronRight,

    IconSearch,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
//
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
    Select,
    TextField,
    Table,
    Badge,
    TablePagination,
} from "@/components";
//
import "./DiagnosticAidReadings.scss";
import { getStatus, getDiagnosticReading } from "./DiagnosticAidReadings.actions";
import { StatusPayload, DiagnosticReadingParams, DiagnosticReadingPayload, DiagnosticReadingResponse } from "@/models/diagnosticAidReadings/diagnosticAidReadings";
import History from "../../PatientAttention/Consultation/History/History";
import { useNavigate } from "react-router-dom";
import routes from "@/constants/routes";

export default function DiagnosticAidReadings() {
    const dispatch = useAppDispatch();
    dayjs.extend(relativeTime);
    dayjs.extend(isToday);
    const workspace = useAppSelector(state => state.workspace);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const navigate = useNavigate();
    const [status, setStatus] = useState<StatusPayload[]>([]);
    const [trigger, setTrigger] = useState(0);
    const [diagnosticReadingList, setDiagnosticReadingList] = useState<DiagnosticReadingResponse>();
    const [showHistory, setShowHistory] = useState<{ show: boolean; patientId: number | null; id: number | null; }>({
        show: false,
        patientId: null,
        id: null
    });
    const [filters, setFilters] = useState<DiagnosticReadingParams>({
        eaccount: workspace.id,
        status: "",
        page: 1,
        perpage: 10,
        responsibleId: userId,
        search: "",
        date: "",
        userId: userId,
        startDate: "",
        endDate: ""
    });

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getStatus(
                { eaccount: workspace.id, view: "adxReading" }));
            setStatus(data as StatusPayload[]);
        }
        fetchData();
    }, [dispatch, workspace.id, workspace.profile]);

    useEffect(() => {
        (async function () {
            const data = await dispatch(getDiagnosticReading(filters));
            setDiagnosticReadingList(data as DiagnosticReadingResponse);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, trigger]);

    const handleNextPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handlePrevPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handleMaxPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handleMinPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);

    };
    const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, [target.name]: target.value });
    };
    const handleSelectChange = (value: number, name: string) => {
        setFilters({ ...filters, [name]: value });
        setTrigger(trigger + 1);
    };

    const renderRow = (reader: DiagnosticReadingPayload) => {
        return (
            <tr
                key={reader.id}
                onClick={() => {
                    setShowHistory({ show: true, patientId: reader.patientId, id: reader.id });
                }}
                className={reader.id === showHistory.id ? "bg-warning bg-opacity-10" : ""}
            >
                <td className="pointer">{reader.date}</td>
                <td className="pointer">{reader.srvName}</td>
                <td
                    className="overflow-hidden text-ellipsis pointer"
                    style={{ maxWidth: 150 }}
                    title={reader.organ ?? "-"}
                >
                    {reader.organ ?? "-"}
                </td>
                <td
                    className="overflow-hidden text-ellipsis pointer"
                    style={{ maxWidth: 100 }}
                    title={reader.patientName}
                >
                    {reader.patientName}
                </td>
                <td className="text-center pointer">
                    <Badge style={{
                        backgroundColor: reader.statusBgColor || "#E8E8EA",
                        color: reader.statusFontColor || "#6E6F7C"
                    }}>
                        {reader.statusName}
                    </Badge>
                </td>
                <td className="text-primary pointer"
                    onClick={() => navigate(routes.workspaceDiagnosticReadingSheet,
                        { state: { tab: "reading", data: reader, reportRequired: false, requiresDilation: false, requiresImage: true, showRenderSection: true } })}
                >
                    <IconChevronRight />
                </td>
            </tr >
        );
    };

    const renderTable = () => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th className="col-1">Fecha</th>
                        <th className="col-2">Servicio</th>
                        <th className="col-2">Órgano</th>
                        <th className="col-2">Paciente</th>
                        <th className="text-center col-2">Estado</th>
                        <th className="col-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {diagnosticReadingList?.results?.length ? diagnosticReadingList?.results?.map(reader => renderRow(reader)) : (
                        <tr>
                            <td colSpan={12} align="center" style={{ padding: "15px 0" }}>
                                No hay informes pendientes por leer
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    };

    const renderFilters = () => {
        const formattedStatus = status.map(doc => ({
            value: doc.id,
            label: doc.description
        }));

        return (
            <div className="d-flex align-items-end mb-5">
                <TextField
                    placeholder="Escribe aquí para buscar"
                    variant="filled"
                    endIcon={
                        <IconSearch className="pointer" onClick={() => setTrigger(trigger + 1)} />
                    }
                    className="flex-grow-1 me-4"
                    name="search"
                    onChange={handleInputChange}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            setTrigger(trigger + 1);
                        }
                    }}
                />
                <div className="me-3" style={{ width: "12%" }}>
                    <label className="text-label">Desde</label>
                    <TextField
                        type="date"
                        value={filters.startDate}
                        onChange={(e) => {
                            setFilters({ ...filters, startDate: e.target.value });
                        }}
                        name="appDate"
                        placeholder="DD/MM/AAAA"
                        style={{ color: "#999" }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setTrigger(trigger + 1);
                            }
                        }}
                    />
                </div>                <div className="me-3" style={{ width: "12%" }}>
                    <label className="text-label">Hasta</label>
                    <TextField
                        type="date"
                        value={filters.endDate}
                        onChange={(e) => {
                            setFilters({ ...filters, endDate: e.target.value });
                        }} name="appDate"
                        placeholder="DD/MM/AAAA"
                        style={{ color: "#999" }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setTrigger(trigger + 1);
                            }
                        }}
                    />
                </div>
                <div className="me-3" style={{ width: "12%" }}>
                    <label htmlFor="status" className="text-label">Estado</label>
                    <Select
                        placeholder="Seleccionar..."
                        emptyOptionsLabel="No hay opciones disponibles."
                        options={formattedStatus}
                        name="status"
                        onChange={({ option, name }) => handleSelectChange(option.value, name)}
                    />
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <div className="d-flex w-100">
                <section className="consultation d-flex flex-column w-100 h-100 overflow-auto">
                    <h1 className="text-secondary fw-bold mb-4 display-5">Lectura ayudas diagnósticas</h1>
                    {renderFilters()}

                    <div className="d-flex flex-column flex-grow-1">
                        <div className="table-responsive">
                            {renderTable()}
                        </div>
                        <TablePagination
                            totalPages={diagnosticReadingList?.rowTotal as number}
                            perPage={10}
                            currentPage={filters.page as number}
                            totalCount={diagnosticReadingList?.results?.length as number}
                            onNextPage={(value) => handleNextPage(value as number)}
                            onPrevPage={(value) => handlePrevPage(value as number)}
                            onMaxPage={(value) => handleMaxPage(value as number)}
                            onMinPage={(value) => handleMinPage(value as number)}
                        />
                    </div>
                </section>
                {showHistory.show ?
                    <History key={showHistory.patientId} showClose={false} patientIdProp={showHistory.patientId} defaultHistory="diagnosticAids" />
                    :
                    <div className="text-center text-muted d-flex justify-content-center self-center"
                        style={{
                            borderLeft: "4px solid #00B4CC",
                            height: "100%",
                            width: "30%",
                            fontSize: "16px"
                        }}
                    >
                        <div className="d-flex align-items-center">
                            Seleccione un paciente para mostrar el historial de atención
                        </div>
                    </div>

                }

            </div>
        );
    };

    return render();
}