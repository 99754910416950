import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { AdaptationParams, AdaptationPayload } from "@/models/sheets/adaptation";
import { getDesignLensService, getLensAdd, getLensType, getMaterialLensService, getSoftLensService, getSpecialLensService } from "@/services";
import { deleteAdaptationClearenceCentralService, getAdaptationService, updateAdaptationService } from "@/services/sheets/adaptation";

export function getAdaptation(params: AdaptationParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getAdaptationService(params);
            return response.results;

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function updateAdaptation(payload: AdaptationPayload, doAfterSuccess?: () => void) {
    return async function () {
        const response = await updateAdaptationService(payload);
        if (response.success) {
            doAfterSuccess &&
                doAfterSuccess();
        }
        return response.success;
    };
}
export function deleteAdaptationClearenceCentral(payload: AdaptationPayload, doAfterSuccess?: () => void) {
    return async function () {
        const response = await deleteAdaptationClearenceCentralService(payload);
        if (response.success) {
            doAfterSuccess &&
                doAfterSuccess();
        }
        return response.success;
    };
}

export function getAdaptationSelects() {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const lensAddResponse = await getLensAdd();
            const lensTypeResponse = await getLensType();
            const softLensResponse = await getSoftLensService();
            const materialLensResponse = await getMaterialLensService();
            const specialLensResponse = await getSpecialLensService();
            const designLensResponse = await getDesignLensService();

            return {
                lensAddResponse: lensAddResponse.results,
                lensTypeResponse: lensTypeResponse.results,
                softLensResponse: softLensResponse.results,
                materialLensResponse: materialLensResponse.results,
                specialLensResponse: specialLensResponse.results,
                designLensResponse: designLensResponse.results,
            };

        } finally {
            dispatch(finishLoading());
        }
    };
}
