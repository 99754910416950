import { forwardRef, useId } from "react";
//
import { IFile } from "./file";
import { IconAttach, IconClose } from "@/assets/icons";
//
import "./File.scss";

export const File = forwardRef<HTMLInputElement, IFile>(
    function File({
        className,
        variant = "default",
        placeholder = "Cargar...",
        fileName,
        onChange,
        iconAttach,
        onDelete,
        allowedFormats,
        disabled,
        ...rest
    }, ref) {
        const nameUnique = useId();
        return (
            <div className={`${className} d-flex gap-2`}>
                <div className={"border-0"}>
                    { fileName
                        ? (
                            <div className="d-flex align-items-center gap-2 px-2 fileText">
                                <span className="text-ellipsis w-75 overflow-hidden">{fileName}</span>
                                <div>
                                    <span
                                        className="fileTextIcon"
                                        onClick={(e) => {
                                            onDelete(e);
                                        }}
                                    >
                                        {!disabled &&
                                        <IconClose height={6} width={6} />
                                        }
                                    </span>
                                </div>
                            </div>
                        )
                        : (
                            <span>{placeholder}</span>
                        )
                    }
                </div>
                <label
                    htmlFor={nameUnique}
                    className="d-flex align-items-center w-100 pointer gap-2"
                >
                    <input
                        id={nameUnique}
                        type="file"
                        name={nameUnique}
                        accept={allowedFormats.join(",")}
                        className="d-none w-100"
                        placeholder="Cargar documento"
                        disabled={disabled}
                        onChange={(e) => onChange(e)}
                    />

                    <span className="ms-auto">
                        {iconAttach ? iconAttach : <IconAttach />}
                    </span>

                </label>
            </div>
        );
    });

