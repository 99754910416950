import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { setHeaders } from "@/utils";

export async function getNotificationsServices(userId: number | null, eaccount: number): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<IResponse>(
        `/medical/clinicHistory/notifyReadingsToDoctors/${userId}/${eaccount}/`, axiosParams
    );
    return serviceData;
}