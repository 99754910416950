import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { DiagnosticAidsParams, DiagnosticAidsSheetPayload, DoctorListParams } from "@/models/sheets/diagnosticAids";
import { deletePressureDiagnosticAidsService, getDiagnosticAidsService, getDoctorListService, getReadingService, updateDiagnosticAidsService, updateReadingService } from "@/services/sheets/diagnosticAids";


export function getDoctorList(params: DoctorListParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDoctorListService(params);
            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getDiagnosticAidsSheet(params: DiagnosticAidsParams, doAfterSuccess?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            localStorage.setItem("isPutInProgress", "true");
            const response = await getDiagnosticAidsService(params);
            if (response.success) {
                doAfterSuccess && doAfterSuccess();
                return response.results; }
        } finally {
            localStorage.removeItem("isPutInProgress"); 
            // dispatch(finishLoading());
        }
    };
}

export function updateDiagnosticAids(payload: DiagnosticAidsSheetPayload, doAfterSuccess?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            localStorage.setItem("isPutInProgress", "true");
            const response = await updateDiagnosticAidsService(payload);

            if (response.success) { doAfterSuccess && doAfterSuccess(); }
            return response.success;

        } finally {
            localStorage.removeItem("isPutInProgress");
        }
    };
}
export function deletePressure(payload: DiagnosticAidsSheetPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await deletePressureDiagnosticAidsService(payload);
        return response.success;

    };
}

export function getReading(params: DiagnosticAidsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getReadingService(params);
            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateReading(payload: DiagnosticAidsSheetPayload, doAfterSuccess?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await updateReadingService(payload);
            if (response.success && doAfterSuccess) {
                doAfterSuccess();
            }
            return response.success;

        } finally {
            dispatch(finishLoading());
        }
    };
}


