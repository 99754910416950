import { axiosInstance } from "@/config/axios";
import {
    AttentionAppointmentResponse,
    AttentionCounterParams,
    AttentionCounterResponse,
    DayServicesResponse,
    FinishAppointmentPayload
} from "@/models/appointment";
import { CommentPayload, CommentsResponse } from "@/models/comment";
import { IResponse } from "@/models/general";
import { setHeaders } from "@/utils/utils";
import { CallPatientPayload } from "@/models/patientAttention";
import { DeleteSheetPayload } from "@/models";

export async function callPatientService(payload?: CallPatientPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/callingPatient/", { appId: payload?.APP_ID, module: payload?.module }, axiosParams
    );
    return serviceData;
}

export async function getAttentionAppointments(params?: Record<string, unknown>): Promise<AttentionAppointmentResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AttentionAppointmentResponse>(
        "/medical/clinicHistory/attentionAppointments/", axiosParams
    );
    return serviceData;
}

export async function getAttentionAppointmentsCounters(params: AttentionCounterParams): Promise<AttentionCounterResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<AttentionCounterResponse>(
        "/medical/clinicHistory/countGridRecords/", axiosParams
    );
    return serviceData;
}

export async function getDayServices(params?: Record<string, unknown>): Promise<DayServicesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };
    const { data: serviceData } = await axiosInstance.get<DayServicesResponse>(
        "/medical/clinicHistory/otherServices/", axiosParams
    );
    return serviceData;
}

export async function getComments(params?: Record<string, unknown>): Promise<CommentsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<CommentsResponse>("/medical/comments/", axiosParams);
    return serviceData;
}

export async function createComment(payload: CommentPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>("/medical/comments/", payload, axiosParams);
    return serviceData;
}

export async function deleteComment(id: string): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, data: { id } };

    const { data: serviceData } = await axiosInstance.delete<IResponse>("/medical/comments/", axiosParams);
    return serviceData;
}

export async function deleteSheetService(payload: DeleteSheetPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };

    const { data: serviceData } = await axiosInstance.delete<IResponse>(
        "/medical/clinicHistory/deleteSheetInfo/", axiosParams
    );
    return serviceData;
}

export async function finishAppointmentService(payload: FinishAppointmentPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/endAttention/", payload, axiosParams
    );
    return serviceData;
}
export async function finishAppointmentDiagnosticAidsService(payload: FinishAppointmentPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/endAdxAttention/", payload, axiosParams
    );
    return serviceData;
}