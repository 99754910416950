import { axiosInstance } from "@/config/axios";
import { ConfigurationParams, ConfigurationResponse } from "@/models";
import { setHeaders } from "@/utils";

export async function getConfigurationService(params: ConfigurationParams): Promise<ConfigurationResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ConfigurationResponse>(
        "/medical/clinicHistory/configuration/", axiosParams
    );
    return serviceData;
}