import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { AttentionHistoryFindParams, AttentionHistoryListParams, AttentionHistoryParams, getAllDataDiagnosticParams } from "@/models/history";
import { DiagnosticAdxDetailsParams } from "@/models/historyDetails/diagnosticAdxDetails";
import {
    getAHAdaptationDetailService,
    getAHAdaptationSectionService,
    getAHAdaptationService,
    getAHAnalysisPlanService,
    getAHAnesthesiaDetailService,
    getAHAttentionOriginService,
    getAHBackgroundDetailService,
    getAHBackgroundService,
    getAHDIDetailsService,
    getAHDiagnosticImpressionService,
    getAHListService,
    getAHLowVisionDetailService,
    getAHLowVisionSectionService,
    getAHLowVisionService,
    getAHNurseryNotesDetailService,
    getAHOphthalmologyDetailService, getAHOphthalmologySectionService,
    getAHOphthalmologyService,
    getAHOptometryDetailService,
    getAHOptometrySectionService,
    getAHOptometryService,
    getAHOrthopticDetailService,
    getAHOrthopticSectionService,
    getAHOrthopticService,
    getAHPachymetryService,
    getAHPioService,
    getAHPreconsultingDetailService,
    getAHPreconsultingSectionService,
    getAHPreconsultingService,
    getAHPreoperativeDetailService, getAHPreoperativeSectionService, getAHPreoperativeService,
    getAHSurgeryService,
    getAdxDetailsService,
    getAllImpressionDiagnosticService,
    getCheckListDetailService,
    getDiagnosticADXDetailsService,
    getDownloadDataService,
    getExternalOptionsService,
    getOrderDetailService,
    getSurgicalDescriptionDetailService,
    getUnexpectedSituationDetailService
} from "@/services/history";

// ------------- OPTOMETRY ------------------------

export function getAttentionHistoryList(params: AttentionHistoryListParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHListService(params);
        if (response.success) {
            return response.results;
        }
        return [];
    };
}

// ------------- OPTOMETRY ------------------------

export function getAHOptometry(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHOptometryService(params);
        if (response.success) {
            const formattedResult = response.results?.map(item => ({
                ...item,
                isOpen: false
            }));
            return formattedResult;
        }
        return [];
    };
}

export function getAHOptometrySection(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHOptometrySectionService(params);
        if (response.success) {
            const current: typeof response.results = {
                ...response.results,
                astigmatismOpen: false,
                correctionOpen: false,
                noCorrectionOpen: false,
                subjectiveOpen: false,
                isEmpty: !(
                    response.results?.astigmatism?.length,
                    response.results?.correction?.length,
                    response.results?.noCorrection?.length,
                    response.results?.subjective?.length
                )
            };
            return current;
        }
        return {};
    };
}

export function getAHOptometryDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHOptometryDetailService(params);

            if (response.success) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// ------------- LOW VISION ------------------------

export function getAHLowVision(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHLowVisionService(params);
        if (response.success) {
            const formattedResult = response.results?.map(item => ({
                ...item,
                isOpen: false
            }));
            return formattedResult;
        }
        return [];
    };
}

export function getAHLowVisionSection(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHLowVisionSectionService(params);
        if (response.success) {
            const current: typeof response.results = {
                ...response.results,
                correctionOpen: false,
                noCorrectionOpen: false,
                subjectiveOpen: false,
                supportsVsnTifloInUseOpen: false,
                isEmpty: !(
                    response.results?.supportsVsnTifloInUse?.length,
                    response.results?.correction?.length,
                    response.results?.noCorrection?.length,
                    response.results?.subjective?.length
                )
            };
            return current;
        }
        return {};
    };
}

export function getAHLowVisionDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHLowVisionDetailService(params);

            if (response.success) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// ------------- ADAPTATION ------------------------

export function getAHAdaptation(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHAdaptationService(params);
        if (response.success) {
            const formattedResult = response.results?.map(item => ({
                ...item,
                isOpen: false
            }));
            return formattedResult;
        }
        return [];
    };
}

export function getAHAdaptationSection(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHAdaptationSectionService(params);
        if (response.success) {
            const current: typeof response.results = {
                ...response.results,
                centeredOpen: false,
                contactLensesOpen: false,
                glassesToOrderOpen: false,
                motionOpen: false,
                toleranceOpen: false,
                isEmpty: !(
                    response.results?.centered?.length,
                    response.results?.contactLenses?.length,
                    response.results?.glassesToOrder?.length,
                    response.results?.motion?.length,
                    response.results?.tolerance?.length
                )
            };
            return current;
        }
        return {};
    };
}

export function getAHAdaptationDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHAdaptationDetailService(params);

            if (response.success) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// ------------- OPHTHALMOLOGY ------------------------

export function getAHOphthalmology(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHOphthalmologyService(params);
        if (response.success) {
            const formattedResult = response.results?.map(item => ({
                ...item,
                isOpen: false
            }));
            return formattedResult;
        }
        return [];
    };
}

export function getAHOphthalmologySection(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHOphthalmologySectionService(params);
        if (response.success) {
            const current: typeof response.results = {
                ...response.results,
                corneaOpen: false,
                crystallineOpen: false,
                externalOpen: false,
                irisOpen: false,
                opticNerveOpen: false,
                pioOpen: false,
                pplOpen: false,
                previousChamberOpen: false,
                retinaVitreousOpen: false,
                screraConjunctivaOpen: false,
                isEmpty: !(
                    response.results?.cornea?.length,
                    response.results?.crystalline?.length,
                    response.results?.external?.length,
                    response.results?.iris?.length,
                    response.results?.opticNerve?.length,
                    response.results?.pio?.length,
                    response.results?.ppl?.length,
                    response.results?.previousChamber?.length,
                    response.results?.retinaVitreous?.length,
                    response.results?.screraConjunctiva?.length
                )
            };
            return current;
        }
        return {};
    };
}

export function getAHOphthalmologyDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHOphthalmologyDetailService(params);

            if (response.success) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// ------------- ORTHOPTIC ------------------------

export function getAHOrthoptic(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHOrthopticService(params);
        if (response.success) {
            const formattedResult = response.results?.map(item => ({
                ...item,
                isOpen: false
            }));
            return formattedResult;
        }
        return [];
    };
}

export function getAHOrthopticSection(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getAHOrthopticSectionService(params);
        if (response.success) {
            const current: typeof response.results = {
                ...response.results,
                correctionOpen: false,
                noCorrectionOpen: false,
                accommodationFlexibilityOpen: false,
                functionalOptometryFinalFormulaOpen: false,
                isEmpty: !(
                    response.results?.correction?.length ||
                    response.results?.noCorrection?.length ||
                    response.results?.accommodationFlexibility?.length ||
                    response.results?.functionalOptometryFinalFormul?.length
                )
            };
            return current;
        }
        return {};
    };
}

export function getAHOrthopticDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHOrthopticDetailService(params);

            if (response.success) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// --------------------- ATTENTION ORIGIN ---------------------------
export function getAHAttentionOrigin(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHAttentionOriginService(params);

        if (response.success) return response.results;

        return [];
    };
}

// --------------------- Background ---------------------------
export function getAHBackground(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHBackgroundService(params);

        if (response.success) return response.results;

        return [];
    };
}

export function getAHBackgroundDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHBackgroundDetailService(params);

            if (response.success) return response.results;

            return {};
        } finally {
            dispatch(finishLoading());
        }
    };
}

// --------------------- ANALYSIS AND PLAN ---------------------------
export function getAHAnalysisPlan(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHAnalysisPlanService(params);

        if (response.success) return response.results;

        return [];
    };
}

// --------------------- PRECONSULTING ---------------------------
export function getAHPreconsulting(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHPreconsultingService(params);

        if (response.success) return response.results;

        return [];
    };
}

export function getAHPreconsultingSection(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHPreconsultingSectionService(params);

        if (response.success) {
            const current: typeof response.results = {
                ...response.results,
                isEmpty: !(
                    response.results?.dilated?.length ||
                    response.results?.breathingFrequency?.length ||
                    response.results?.bloodPressureDiastolic?.length ||
                    response.results?.bloodPressureSystolic?.length ||
                    response.results?.bodyWeight?.length ||
                    response.results?.heartRate?.length ||
                    response.results?.temperature?.length
                )
            };

            return current;
        }

        return {};
    };
}

export function getAHPreconsultingDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHPreconsultingDetailService(params);

            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

// --------------------- PACHYMETRY ---------------------------
export function getAHPachymetry(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHPachymetryService(params);
        if (response.success) return response.results;
    };
}
// --------------------- DIAGNOSTIC IMPRESSION -----------------------
export function getAHDiagnosticImpression(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHDiagnosticImpressionService(params);

        if (response.success) return response.results;

        return [];
    };
}

export function getAHDIDetails(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHDIDetailsService(params);

            if (response.success) return response.results;
            return {};

        } finally {
            dispatch(finishLoading());
        }
    };
}

// ------------- ADAPTATION ------------------------

export function getAHPreoperative(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await getAHPreoperativeService(params);
            if (response.success) {
                const formattedResult = response.results?.map(item => ({
                    ...item,
                    isOpen: false
                }));
                return formattedResult;
            }
            return [];

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function getAHPreoperativeSection(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());

            const response = await getAHPreoperativeSectionService(params);
            if (response.success) {
                const current: typeof response.results = {
                    ...response.results,
                    suitableOpen: false,
                    isEmpty: !response.results?.suitable?.length
                };
                return current;
            }
            return {};

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getAHPreoperativeDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHPreoperativeDetailService(params);

            if (response.success) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// --------------------- PACHYMETRY ---------------------------
export function getAHPio(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHPioService(params);

        if (response.success) return response.results;

        return [];
    };
}

// ---------------------- SURGERY --------------------------------

export function getAHSurgery(params: AttentionHistoryParams) {
    return async function () {
        const response = await getAHSurgeryService(params);

        if (response.success) {
            const formattedResult = response.results?.map(item => ({
                ...item,
                isOpen: false
            }));
            return formattedResult;
        }
        return [];
    };
}

// --------------------- Nursery Notes ------------------------------

export function getAHNurseryNotesDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHNurseryNotesDetailService(params);

            if (response.results) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// --------------------- Anesthesia ------------------------------

export function getAHAnesthesiaNotesDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getAHAnesthesiaDetailService(params);

            if (response.results) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// --------------------- Surgical Description ------------------------------

export function getSurgicalDescriptionDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSurgicalDescriptionDetailService(params);

            if (response.results) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getCheckListDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getCheckListDetailService(params);

            if (response.results) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getUnexpectedSituationDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getUnexpectedSituationDetailService(params);

            if (response.results) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getOrderDetail(params: AttentionHistoryFindParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getOrderDetailService(params);

            if (response.results) {
                return response.results;
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

// ------------------------------------------------------------------

export function getAHDownloadData(eaccount: number | null, mcfId?: number | null, appId?: number, patientId?: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDownloadDataService(eaccount, mcfId, appId, patientId);

            if (response.success) {
                return response.results;
            }
            return {};

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getAllImpressionDiagnostic(params: getAllDataDiagnosticParams) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await getAllImpressionDiagnosticService(params);

            if (response.success) return response.results;

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function getAdxDetail(params: AttentionHistoryParams) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await getAdxDetailsService(params);

            if (response.success) return response.results;

        } finally {
            // dispatch(finishLoading());
        }
    };
}
export function getDiagnosticADXDetail(params: DiagnosticAdxDetailsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDiagnosticADXDetailsService(params);

            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getExternalOptions(params: {eaccount: number}) {
    return async function (dispatch: AppDispatch) { 
        const response = await getExternalOptionsService(params); 
        if (response.success) return response.results; 
    };
}