import { BodyD, DownloadHistory, Preconsulting } from "@/models/generalHistory/generalHistory";
import { BackgroundDetail as IBackgroundDetail } from "@/models/historyDetails/background";
import { OrderDetail } from "@/models/historyDetails/orders";
import { UnexpectedSituationDetail as IUnexpectedSituationDetail } from "@/models/historyDetails/unexpectedSituation";
import { CommonSelect, Squints } from "@/models/sheets/orthoptic";
import { formatHistoryDetails, formatteDocument } from "@/utils";
import { forwardRef } from "react";
import AdaptationDetails from "../PatientAttention/Consultation/History/components/Details/AdaptationDetails";
import AnalysisAndPlanDetails from "../PatientAttention/Consultation/History/components/Details/AnalysisAndPlanDetails";
import AttentionOriginDetails from "../PatientAttention/Consultation/History/components/Details/AttentionOriginDetails";
import DiagnosticImpressionNewDetails from "../PatientAttention/Consultation/History/components/Details/DiagnosticImpressionNewDetail";
import LowVisionDetails from "../PatientAttention/Consultation/History/components/Details/LowVisionDetails";
import OphthalmologyDetails from "../PatientAttention/Consultation/History/components/Details/OphthalmologyDetails";
import OptometryDetails from "../PatientAttention/Consultation/History/components/Details/OptometryDetails";
import OrthopticDetails from "../PatientAttention/Consultation/History/components/Details/OrthopticDetails";
import PreconsultDetails from "../PatientAttention/Consultation/History/components/Details/PreconsultDetails";
import PreoperativeDetails from "../PatientAttention/Consultation/History/components/Details/PreoperativeDetails";
import PressionAndPachimetryDetails from "../PatientAttention/Consultation/History/components/Details/PressionAndPachimetryDetails";
import BackgroundDetail from "../PatientAttention/Consultation/History/components/Details/SurgeryDetails/BackgroundDetail/BackgroundDetail";
import OrdersDetail from "../PatientAttention/Consultation/History/components/Details/SurgeryDetails/OrdersDetail/OrdersDetail";
import UnexpectedSituationDetail from "../PatientAttention/Consultation/History/components/Details/SurgeryDetails/UnexpectedSituationDetail/UnexpectedSituationDetail";

interface ISurgeryDownloadFileProps {
    data: DownloadHistory[];
    currentTab?: string;
    fromHistory?: boolean;
    dataVersionList?: CommonSelect[]
    squintsData?: Squints[];
    degreesData?: CommonSelect[]

}





const DownloadHistoryFile = forwardRef<HTMLTableElement, ISurgeryDownloadFileProps>((props, ref,) => {

    const currentTab = props?.currentTab;
    const downloadData = props?.data[0]?.detailForDownload;
    const isGenerals =  currentTab === "generals";
    const renderEvent = (event: BodyD | null, sheet: string) => {
        return (
            <>
                {event && <div className="d-flex justify-content-between text-secondary w-100 bg-primary-lighter p-3" style={{ marginTop: -5 }}>
                    <h5 className="fw-bold m-0">
                        {event?.datetime_it_was_made}
                    </h5>
                    <h5 className="fw-bold m-0">
                        Evento N. {event?.event_number} - {event?.schedule_name}
                    </h5>
                    <h5 className="fw-bold m-0">
                        Edad: {event?.patient_age} años
                    </h5>
                </div>}
                <div><h4 className="fw-bold m-0 text-secondary mt-4">
                    {sheet}
                </h4></div>
            </>
        );
    };

    const unexpectedSituationSheet = (isGeneral?: boolean, data?: IUnexpectedSituationDetail[] | null) => {
        const unexpectedSituation = props.data.some(x => x.unexpectedSituation) ? props.data.filter(x => x.unexpectedSituation).map(data => ({
            sheet: data.unexpectedSituation,
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {unexpectedSituation.length > 0 &&
                    unexpectedSituation.map((data, ind) => {
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                                {(data.event && isGenerals) && renderEvent(data.event, "")}
                                {data.sheet && <UnexpectedSituationDetail data={data.sheet} />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                <UnexpectedSituationDetail data={data} />
            </div>;


    };
    const ordersSheet = (isGeneral?: boolean, data?: OrderDetail | null) => {
        const orders = props.data.some(x => x.orders) ? props.data.filter(x => x.orders).map(data => ({
            sheet: { ...data.orders, },
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {orders.length > 0 &&
                    orders.map((data, ind) => {
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                                {(data.event && isGenerals) && renderEvent(data.event, "")}
                                {data.sheet && <OrdersDetail data={data.sheet} download />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                <OrdersDetail data={data} download />
            </div>;


    };
    const backgroundSheet = (isGeneral?: boolean, data?: IBackgroundDetail | null) => {
        const background = props.data.some(x => x.background) ? props.data.filter(x => x.background).map(data => ({
            sheet: { ...data.background, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {background.length > 0 &&
                    background.map((data, ind) => {
                        return (

                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                                {(data.event && isGenerals) && renderEvent(data.event, "")}
                                {data.sheet && <BackgroundDetail data={data.sheet} download />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                <BackgroundDetail data={data} download />
            </div>;


    };
    const preconsultingSheet = (isGeneral?: boolean, data?: Preconsulting | null) => {
        const preconsulting = props.data.some(x => x.preconsulting) ? props.data.filter(x => x.preconsulting).map(data => ({
            sheet: { ...data.preconsulting, cie10ExtDiagnostic: data.diagnosticImpression, generalsFields: data.generalsFields },
            event: data.detailForDownload?.body
        })) : [];
        return isGeneral
            ?
            <>
                {preconsulting.length > 0 &&
                    preconsulting.map((data) => {
                        return (
                            <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                                {(data.event && isGenerals) && renderEvent(data.event, "Preconsulta")}
                                {data.sheet && <PreconsultDetails preconsultingDetail={data.sheet} />}
                            </div>
                        );
                    })
                }
            </>
            : data && <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }}>
                <div><h4 className="fw-bold m-0 text-secondary mt-4">
                    Preconsulta
                </h4></div>
                <PreconsultDetails preconsultingDetail={data} />
            </div>;


    };






    const sheetsBody = (): JSX.Element[] | null => {
        if (!props.data || props.data.length === 0) {
            return null;
        }
        return props.data?.map((object, index) => {
            const ophthalmology = object?.ophthalmology ? [{
                sheet: { ...object.ophthalmology, cie10ExtDiagnostic: object.diagnosticImpression, generalsFields: object.generalsFields },
                event: object.detailForDownload?.body,
            }] : [];

            const optometry = object?.optometry ? [{
                sheet: { ...object.optometry, cie10ExtDiagnostic: object.diagnosticImpression, generalsFields: object.generalsFields },
                event: object.detailForDownload?.body,
            }] : [];

            const adaptation = object?.adaptation ? [{
                sheet: { ...object.adaptation, cie10ExtDiagnostic: object.diagnosticImpression, generalsFields: object.generalsFields },
                event: object.detailForDownload?.body,
            }] : [];

            const lowVision = object?.lowVision ? [{
                sheet: { ...object.lowVision, cie10ExtDiagnostic: object.diagnosticImpression, generalsFields: object.generalsFields },
                event: object.detailForDownload?.body,
            }] : [];

            const preoperative = object?.preoperative ? [{
                sheet: { ...object.preoperative, cie10ExtDiagnostic: object.diagnosticImpression, generalsFields: object.generalsFields },
                event: object.detailForDownload?.body,
            }] : [];

            const analysisPlan = object?.analysisPlan ? [{
                sheet: object.analysisPlan,
                event: object.detailForDownload?.body,
            }] : [];

            const diagnosticImpression = object?.diagnosticImpression ? [{
                sheet: object.diagnosticImpression,
                event: object.detailForDownload?.body,
            }] : [];

            const attentionOrigin = object?.attentionOrigin ? [{
                sheet: object.attentionOrigin,
                event: object.detailForDownload?.body,
            }] : [];

            const pachymetry = object?.pachymetry ? [{
                sheet: object.pachymetry,
                event: object.detailForDownload?.body,
            }] : [];

            const pio = object?.pio ? [{
                sheet: object.pio,
                event: object.detailForDownload?.body,
            }] : [];

            const orthoptic = object?.orthoptic ? [{
                sheet: { ...object.orthoptic, cie10ExtDiagnostic: object.diagnosticImpression, generalsFields: object.generalsFields },
                event: object.detailForDownload?.body,
            }] : [];

            return (
                <>
                    <div ref={ref} key={index} style={{ pageBreakBefore: "always" }} >
                        {object.detailForDownload?.body && isGenerals && renderEvent(object.detailForDownload?.body, "")}
                        {ophthalmology.length > 0 &&
                            ophthalmology.map((data, ind) => {
                                return (
                                    <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }} key={`info-ophthalmology-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Oftalmología")}
                                        {data.sheet && <OphthalmologyDetails ophthalmologyDetails={data.sheet} />}
                                        {/* {data.preconsultingSheet && preconsultingSheet(false, data.preconsultingSheet)}
                                        {data.background && backgroundSheet(false, data.background)}
                                        {data.orders && ordersSheet(false, data.orders)}
                                        {data.unexpectedSituation && unexpectedSituationSheet(false, data.unexpectedSituation)} */}
                                    </div>
                                );
                            })

                        }
                        {optometry.length > 0 &&
                            optometry.map((data, ind) => {
                                return (

                                    <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }} key={`info-optometry-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Optometría")}
                                        {data.sheet && <OptometryDetails optometryDetails={data.sheet} />}
                                    </div>
                                );
                            })

                        }
                        {adaptation.length > 0 &&
                            adaptation.map((data, ind) => {
                                return (
                                    <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }} key={`info-adaptation-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Adaptación de LC")}
                                        {data.sheet && <AdaptationDetails adaptationDetail={data.sheet} />}
                                    </div>
                                );
                            })

                        }
                        {lowVision.length > 0 &&
                            lowVision.map((data, ind) => {
                                return (
                                    <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }} key={`info-low-vision-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Baja visión")}
                                        {data.sheet && <LowVisionDetails lowVisionDetails={data.sheet} />}
                                    </div>
                                );
                            })

                        }
                        {orthoptic.length > 0 &&
                            orthoptic.map((data, ind) => {
                                return (
                                    <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }} key={`info-orthoptic-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Ortóptica")}
                                        {data.sheet && <OrthopticDetails orthopticDetails={data.sheet} dataVersionList={props.dataVersionList}
                                            degreesData={props.degreesData}
                                            squintsData={props.squintsData}
                                            forDownload
                                        />}
                                    </div>
                                );
                            })
                        }
                        {preoperative.length > 0 &&
                            preoperative.map((data, ind) => {
                                return (
                                    <div style={{ marginTop: "1rem", display: "block", pageBreakBefore: "avoid" }} key={`info-preope-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Valoración preoperatoria")}
                                        {data.sheet && <PreoperativeDetails preoperativeDetails={data.sheet} />}
                                    </div>
                                );
                            })
                        }

                        {object?.preconsulting && preconsultingSheet(false, (object?.preconsulting || null))}
                        {object?.background && backgroundSheet(false, (object?.background || null))}
                        {object?.orders && ordersSheet(false, (object?.orders || null))}
                        {object?.unexpectedSituation && unexpectedSituationSheet(false, (object?.unexpectedSituation || null))}

                        {analysisPlan.length > 0 &&
                            analysisPlan.map((data, ind) => {
                                return (
                                    <div key={`info-analysis-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Análisis y plan")}
                                        {data.sheet && <AnalysisAndPlanDetails data={data.sheet} />}
                                    </div>
                                );
                            })
                        }
                        {(isGenerals && diagnosticImpression.length > 0) &&
                            diagnosticImpression.map((data, ind) => {
                                const cie10ExtDiagnostic = formatHistoryDetails(data.sheet);
                                return (
                                    <div className="py-4" key={`info-diagnostic-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Impresión diagnóstica")}
                                        {data.sheet && <DiagnosticImpressionNewDetails data={cie10ExtDiagnostic} />}
                                    </div>
                                );
                            })
                        }
                        {attentionOrigin.length > 0 &&
                            attentionOrigin.map((data, ind) => {
                                return (
                                    <div className="py-4" key={`info-attention-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "Origen de la atención")}
                                        {data.sheet && <AttentionOriginDetails data={data.sheet} />}
                                    </div>
                                );
                            })
                        }
                        {pachymetry.length > 0 &&
                            pachymetry.map((data, ind) => {
                                return (
                                    <div className="py-4" key={`info-pachymetry-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "")}
                                        {data.sheet && <PressionAndPachimetryDetails dataPa={data.sheet} />}
                                    </div>
                                );
                            })
                        }
                        {pio.length > 0 &&
                            pio.map((data, ind) => {
                                return (
                                    <div className="py-4" key={`info-pio-${ind}`}>
                                        {data.event && renderEvent(isGenerals ? null : data.event, "")}
                                        {data.sheet && <PressionAndPachimetryDetails dataPe={data.sheet} />}
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="printable" style={{ marginTop: "1rem" }}>
                        <img width={200} src={object?.detailForDownload?.footer?.doctor?.signature} alt="" />
                        <h5 className="fw-bold m-0">{object?.detailForDownload?.footer?.doctor?.name}</h5>
                        <h5 className="fst-italic m-0">{object?.detailForDownload?.footer?.doctor?.position}</h5>
                        <h5 className="fst-italic m-0">R.M {object?.detailForDownload?.footer?.doctor?.medical_record}</h5>
                    </div>
                </>

            );
        });
    };

    const renderDownload = () => {
        return (
            downloadData ?
                <table border={0} ref={ref} className="w-100">
                    <thead>
                        <tr>
                            <td>
                                <div className="print-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={downloadData?.header?.logo} alt="cofca_logo" width={150} height={50} style={{ objectFit: "contain" }} />
                                        <h2 className="text-secondary fw-bold">
                                            {downloadData?.header?.name_of_the_institution}
                                        </h2>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div className="row p-3 py-2 align-items-center bg-light text-muted mt-3">
                                        <div className="col-6">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h5 className="fw-bold">Paciente:</h5>
                                                    <h5 className="fw-bold">Documento:</h5>
                                                </div>
                                                <div className="col-8">
                                                    <h5>{downloadData?.header?.patient?.name}</h5>
                                                    <h5>{formatteDocument({ value: downloadData?.header?.patient?.doc }).format}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h5 className="fw-bold nowrap">Fecha de nacimiento:</h5>
                                                    <h5 className="fw-bold">Género:</h5>
                                                </div>
                                                <div className="col-6">
                                                    <h5>{downloadData?.header?.patient?.birthdate}</h5>
                                                    <h5>{downloadData?.header?.patient?.gender}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 10 }}></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="printable">


                                    {sheetsBody()}

                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>
                                <div style={{ height: 25 }}></div>
                                <div className="align-bottom text-end text-muted position-fixed bottom-0 w-100" style={{ marginBottom: 0 }}>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Teléfono: </span>
                                        {downloadData?.footer?.appointment_phone}
                                    </h6>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Email: </span>
                                        {downloadData?.footer?.appointment_email}
                                    </h6>
                                    <h6 className="d-inline text-end mx-2">
                                        <span className="fw-bold">Dirección: </span>
                                        {downloadData?.footer?.address}
                                    </h6>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                :
                <table border={0} ref={ref} className="w-100">
                </table>
        );
    };


    return renderDownload();






});




export default DownloadHistoryFile;