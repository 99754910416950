import { Pachymetry, Pio } from "@/models/generalHistory/generalHistory";



export default function PressionAndPachimetryDetails({ dataPa, dataPe }: { dataPa?: Pachymetry[]; dataPe?: Pio[]; }) {
    const renderComponent = () => {
        return dataPa && dataPa?.length > 0 ? <>
            {
                dataPa.map(data => {
                    return (
                        <div className="item">
                            <h4 className="fw-bold text-secondary">Paquimetría</h4>
                            <h5 className="fw-bold text-primary">Origen - {data.origin.name}</h5>
                            <table className="table table-bordered">
                                <thead>
                                    <tr className="text-secondary">
                                        <th>Detalle</th>
                                        <th>OD</th>
                                        <th>OI</th>
                                    </tr>
                                </thead>
                                <tbody className="text-muted">
                                    {data ? (
                                        <tr>
                                            <td className="fw-bold">Paquimetría</td>
                                            <td>{data.od.pachymetry}</td>
                                            <td>{data.oi.pachymetry}</td>
                                        </tr>
                                    ) : ""}

                                    {data ? (
                                        <tr>
                                            <td className="fw-bold">Corrección</td>
                                            <td>{data.od.correction}</td>
                                            <td>{data.oi.correction}</td>
                                        </tr>
                                    ) : ""}

                                </tbody>
                            </table>
                        </div>
                    );
                })
            }
        </>

            : dataPe && dataPe.length > 0 ?
                <>
                    {dataPe.map(data => {
                        return (
                            <div className="item">
                                <h4 className="fw-bold text-secondary">Presión intraocular</h4>
                                <h5 className="fw-bold text-primary">Origen - {data.originSegment}</h5>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className="text-secondary">
                                            <th>Detalle</th>
                                            <th>OD</th>
                                            <th>OI</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-muted">
                                        <tr>
                                            <td className="fw-bold">PIO</td>
                                            <td>{data.od}</td>
                                            <td>{data.oi}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        );
                    })}
                </>
                : <></>;

    };


    return renderComponent();
}
