import { setActiveTabAttention } from "@/config/slices/surgery";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { AllCategory, Module } from "@/models/sidebar";
import {
    IconMenu2,
    IconX
} from "@tabler/icons-react";
import { NavLink, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import "./Sidebar.scss";

export default function Sidebar() {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { all_categories } = useAppSelector(state => state.category.category);
    const accountingModules = all_categories?.find((cat: AllCategory) => cat?.name === "Historia clínica");
    const modulesAccess = accountingModules?.modules && accountingModules?.modules;
    const [isSidebarOpen, setIsSidebarOpen] = useDebounce(false, 300);
    const onMouseOver = () => setIsSidebarOpen(true);
    const onMouseLeave = () => setIsSidebarOpen(false);

    const renderItem = (item: Module, parentindex: number) => {
        return (
            <div
                key={item.id}
                className="sidebar-menu__item "
            >
                <div style={{ color: location.pathname.includes(String(item.name)) ? "#5CCFDE" : "#CBCBD0" }}
                    className={`${item.functionalities.some(x => x.url === location.pathname) ? "colorBlue" : ""} d-flex`}>
                    <img alt="icon" src={item.img} height={30} width={30}

                    />
                </div>
                <div style={{ minWidth: 40 }}></div>
                <div className="accordion-item w-100">
                    <div
                        className="accordion-header sidebar-menu__item-label pb-3"
                        data-bs-toggle="collapse"
                        data-bs-target={`#t_${item.id}_${parentindex}`}
                        style={{ color: item.functionalities.some(x => x.url === location.pathname) ? "#5CCFDE" : undefined }}
                    >
                        {item.name}
                    </div>
                    <div id={`t_${item.id}_${parentindex}`}
                        onClick={() => dispatch(setActiveTabAttention(""))}
                        className={`accordion-collapse collapsing ${!isSidebarOpen ? "collapse" : ""}`} data-bs-parent="#sidebar-items">
                        <ul className="sidebar-menu__item-list">
                            {item.functionalities?.map((option, index) => {
                                return (
                                    <li key={option.name + "-" + index} className="mb-3">
                                        <NavLink
                                            to={option.url}
                                            style={({ isActive }) => ({
                                                color: isActive ? "#5CCFDE" : undefined,
                                            })}
                                        >
                                            {option.name}
                                        </NavLink>
                                    </li>

                                );
                            })}
                        </ul>
                    </div>
                    <hr className="mt-0 mb-3" />
                </div>
            </div >
        );
    };

    const render = () => {

        return (
            <div className={`sidebar ${isSidebarOpen ? "active" : ""}`} onMouseOver={() => onMouseOver()} onMouseLeave={() => onMouseLeave()}>
                {!isSidebarOpen ? (
                    <div className="sidebar-menu">
                        <IconMenu2 />
                    </div>
                ) : (
                    <div className="sidebar-close" onClick={() => setIsSidebarOpen(false)}>
                        <IconX className="hoverable" />
                    </div>
                )}
                <div className="sidebar-menu__items" style={{ overflowY: isSidebarOpen ? "auto" : "hidden" }} id="sidebar-items">
                    {modulesAccess && modulesAccess.map((item: Module, index: number) => renderItem(item, index))}
                </div>
            </div>
        );
    };

    return render();
}