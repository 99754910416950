import { Badge, Button, Modal, Select, TextField } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ITabs, JustificationList, OperatingRooms } from "@/models/surgery/patientAttention/patientAttention";
import { fireSuccessAlert, fireWarningAlert } from "@/utils";
import { IconChevronRight } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getJustificationsList, getOperatingRooms, selectOperatingRoom, transferOperatingRoom } from "../patientAttentionSurgery.action";
import Auth from "./Auth";


const SelectRoomSurgery = ({ isOpen, onClose, appId, action }: {
    stage?: ITabs; isOpen: boolean, onClose: (update: boolean) => void, appId?: number | null; action?: string;
}) => {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user_data.id);
    const [operatingRoomsList, setOperatingRoomsList] = useState<OperatingRooms[]>([]);
    const [operatingRoomsFullList, setOperatingRoomFullList] = useState<OperatingRooms[]>([]);
    const [hasPendingRoom, setHasPendingRoom] = useState<boolean>(false);
    const [justificationModal, setJustificationModal] = useState<{ isOpen: boolean, justification: string | number; consultingRoomId: string | number; }>({
        isOpen: false,
        justification: "",
        consultingRoomId: "",
    });
    const [justificationOptionList, setJustificationOptionList] = useState<JustificationList[]>([]);
    const [authValidation, setAuthValidation] = useState<{ isOpen: boolean; prefix: string; data: string; }>({
        isOpen: false,
        prefix: "transfer",
        data: ""
    });

    useEffect(() => {
        async function fetchData() {
            if (appId && isOpen) {
                const data = await dispatch(getOperatingRooms({ appId }));
                if (data) {
                    const newArray = data.sort((a, b) => a.inUse - b.inUse);
                    setHasPendingRoom(newArray.some(room => room.pendingRequest === 1));
                    setOperatingRoomsList(newArray);
                    setOperatingRoomFullList(newArray);
                }
            }
        }
        fetchData();
    }, [appId, isOpen]);

    useEffect(() => {
        async function fetchData() {
            if (action === "transfer" && isOpen) {
                const data = await dispatch(getJustificationsList());
                if (data) {
                    setJustificationOptionList(data);
                }
            }
        }
        fetchData();
    }, [action, isOpen]);


    const handleCloseModal = (isSelected: boolean) => {
        onClose(isSelected);
        if (operatingRoomsFullList.length > 0) {
            const newArray = operatingRoomsFullList.sort((a, b) => a.inUse - b.inUse);
            setOperatingRoomsList(newArray);
        }
    };
    const handleSelectRoom = async (consultingRoomId: number) => {
        if (appId && userId) {
            if (action === "transfer") {
                setJustificationModal({ ...justificationModal, isOpen: true, consultingRoomId });
            } else {
                const response = await dispatch(selectOperatingRoom(
                    {
                        appId,
                        consultingRoomId,
                    }
                ));
                if (response.success) {
                    handleCloseModal(true);
                }
            }

        }
    };

    const handleCloseAuthValidation = () => {
        setAuthValidation((state) => ({ ...state, isOpen: false }));
    };
    const handleOpenAuthValidation = () => {
        if (justificationModal.justification === "") {
            fireWarningAlert("Ingrese una justificación", "Intenta de nuevo");
        } else {
            setAuthValidation((state) => ({ ...state, isOpen: true }));
        }

    };

    const handleCloseJustificationModal = () => {
        setJustificationModal({ ...justificationModal, isOpen: false, justification: "" });
    };

    const handleTranslateRoomSurgery = async (data: string) => {
        handleCloseJustificationModal();
        handleCloseModal(false);

        if (appId) {
            const response = await dispatch(transferOperatingRoom({
                appId,
                authenticationCode: Number(data),
                consultingRoomId: Number(justificationModal.consultingRoomId),
                jtyId: Number(justificationModal.justification)
            }));
            if (response.success) {
                fireSuccessAlert("Solicitud de traslado de quirófano con éxito", "Solicitado exitosamente");
            } else {
                fireWarningAlert(response.message, "Intenta de nuevo");
            }
            return response.success;
        }
        return false;
    };





    const searchItemsFunction = (value: string) => {
        if (value !== "") {
            const tempArr = operatingRoomsList.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setOperatingRoomsList(tempArr);
        } else {
            if (operatingRoomsFullList.length > 0) {
                const newArray = operatingRoomsFullList.sort((a, b) => a.inUse - b.inUse);
                setOperatingRoomsList(newArray);
            }
        }
    };

    const render = () => {
        return <>
            {justificationModalComponent()}
            <Modal
                positionModal="center"
                width={500}
                isOpen={isOpen}
                onClose={() => handleCloseModal(false)}
            >
                <h1 className="text-secondary fw-bold text-start">{action === "transfer" ? "Trasladar a otro quirófano" : "Confirmar quirófano"} </h1>
                <div className="d-flex flex-column pt-4">
                    <div>
                        <b className="text-secondary">Quirófano</b>
                    </div>
                </div>
                <div className="py-2">
                    <TextField
                        type="text"
                        onChange={({ target }) => { searchItemsFunction(target.value); }}
                        name="appDate"
                        placeholder="Buscar quirófano..."

                        style={{ color: "#999" }}
                    />
                </div>
                <div className=" px-2 py-2 bodyHeightAdjusted" key={(operatingRoomsList.length)}>
                    {operatingRoomsList?.length > 0
                        ?
                        operatingRoomsList.map(room => {
                            const isInUse = room.inUse === 1;
                            const isPending = room.pendingRequest === 1;
                            return (
                                <div className="row cardLotsSection my-2" key={room.id}
                                    style={{
                                        opacity: isInUse ? 0.6 : 1,
                                        backgroundColor: hasPendingRoom && !isPending ? "#f0f0f0" : "#D6F3F7",
                                        borderColor: hasPendingRoom && !isPending ? "#f0f0f0" : "#D6F3F7"
                                    }}
                                >
                                    <div className="col-8 align-self-center">
                                        <div className="row">
                                            <div className={`col-8 fw-bold ${hasPendingRoom && !isPending  ? "text-muted" : "text-primary"}`}>{room.name}</div>
                                        </div>
                                    </div>
                                    <div className="col-3 fw-bold text-primary align-self-center d-flex justify-content-center">
                                        <Badge style={{
                                            backgroundColor: hasPendingRoom && !isPending ? "#d3d3d3" : isInUse ? "#FEFAF5" : "#FAFDF6",
                                            color: hasPendingRoom && !isPending ? "#f0f0f0" : isInUse ? "#F4CB83" : "#83c036",
                                        }}>
                                            {isInUse ? "En uso" : isPending ? "Solicitud pendiente" : "Disponible"}
                                        </Badge>
                                    </div>
                                    {
                                        !isInUse &&
                                        <div className="col-1 align-self-center text-end"
                                            style={{cursor:hasPendingRoom ? "not-allowed" : "pointer"}}
                                            onClick={() =>{ !hasPendingRoom && handleSelectRoom(room.id); }}
                                        >
                                            <IconChevronRight />
                                        </div>
                                    }
                                </div>
                            );
                        })
                        :
                        <div className="text-muted text-center"> No hay lotes relacionados</div>
                    }
                </div>
            </Modal>
            <Auth
                isOpen={authValidation.isOpen}
                onClose={handleCloseAuthValidation}
                authValidation={authValidation}
                appId={appId}
                handleGenericFunction={(data) => handleTranslateRoomSurgery(data || "")}
                isStageTrue={false}
            />
        </>;

    };




    const justificationModalComponent = () => {

        const options = justificationOptionList.map(just => ({
            value: just.jtyId,
            label: just.jtyName,
        }));


        return (
            <Modal isOpen={justificationModal.isOpen} width={500} positionModal="center"
                onClose={handleCloseJustificationModal}>
                <div className="p-4">
                    <h1 className="text-primary fw-bold text-center ">Justificación</h1>
                    <h4 className="text-muted text-center py-3">Por favor, justifique el traslado del paciente</h4>
                    <div className="px-3 d-flex flex-column ">
                        <div>
                            <Select
                                className="ml-2"
                                options={options}
                                value={options.find(ms => ms.value === justificationModal.justification)}
                                onChange={({ option }) => {
                                    setJustificationModal({ ...justificationModal, justification: option.value });
                                }}
                                style={{ width: "100%" }}
                            />

                        </div>

                    </div>

                    <div className="pt-4">

                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="outline" size="large" className="py-2 me-3" onClick={handleCloseJustificationModal}
                            >
                                Cancelar
                            </Button>
                            <Button variant="primary" size="large" className="py-2" onClick={handleOpenAuthValidation}
                            >
                                Guardar
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };



    return render();
};




export default SelectRoomSurgery;