import { useAppDispatch } from "@/hooks";
import { RecordChart, VitalSignsChart } from "@/models/surgery/patientAttention/patientAttention";
import {
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import "chartjs-adapter-date-fns";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { getRecordChart } from "../patientAttentionSurgery.action";

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function RecordChartComponent({ appId, setDataRecord, reload, heightProp, widthProp, data }: { widthProp?: number | string, heightProp?: number, reload?: number, appId?: number; setDataRecord?: (data: RecordChart) => void; data?: RecordChart; }) {
    const dispatch = useAppDispatch();
    const [chartResult, setChartResult] = useState<VitalSignsChart>({
        fc: [],
        o2: [],
        pad: [],
        pas: [],
    });

    const transformData = (data: RecordChart) => {

        const settingArrayFC = data?.vitalSigns?.fc?.map(item => ({
            x: new Date(item.createdAt).getTime(),
            y: Number(item.value),
        }));

        const settingArrayO2 = data?.vitalSigns?.o2?.map(item => ({
            x: new Date(item.createdAt).getTime(),
            y: Number(item.value),
        }));

        const settingArrayPAD = data?.vitalSigns?.pad?.map(item => ({
            x: new Date(item.createdAt).getTime(),
            y: Number(item.value),
        }));

        const settingArrayPAS = data?.vitalSigns?.pas?.map(item => ({
            x: new Date(item.createdAt).getTime(),
            y: Number(item.value),
        }));

        setChartResult({
            fc: settingArrayFC,
            o2: settingArrayO2,
            pad: settingArrayPAD,
            pas: settingArrayPAS,
        });
    };

    useEffect(() => {
        async function fetchData() {
            if (data) {
                if (setDataRecord) setDataRecord(data);
                transformData(data);
            } else {
                if (appId) {
                    const data = await dispatch(getRecordChart({
                        appId,
                    }));
                    if (setDataRecord) setDataRecord(data);
                    transformData(data);
                }
            }
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, appId, reload]);

    const render = () => {

        const options: ChartOptions<"line"> = {
            scales: {
                x: {
                    type: "time",
                    ticks: {
                        maxTicksLimit: 4,
                        font: {
                            size: 10
                        }
                    },
                    time: {
                        tooltipFormat: "yyyy-MM-dd HH:mm:ss",
                        displayFormats: {
                            hour: "H:mm",
                        },
                    },
                    title: {
                        display: true,
                        text: "Hora",
                        color: "#003F80",
                    },
                },
                y: {
                    ticks: {
                        maxTicksLimit: 5,
                        font: {
                            size: 10
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    display: true,
                    align: "center",
                    labels: {
                        usePointStyle: true,
                        boxHeight: 6,
                        font: { size: 10 }
                    },
                    title: {
                        display: true,
                        position: "start",
                    },
                },

                tooltip: {
                    mode: "index",
                    intersect: false,
                    animation: false,
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 50
            }
        };

        const chartData: ChartData<"line"> = {
            datasets: [
                {
                    label: "FC (lpm)",
                    data: chartResult.fc,
                    borderColor: "#0D99FF",
                    backgroundColor: "#0D99FF",
                    fill: false,
                    borderWidth: 1.5,
                    animation: false,
                    pointRadius: 2,
                },
                {
                    label: "O2 (%)",
                    data: chartResult.o2,
                    borderColor: "#14AE5C",
                    backgroundColor: "#14AE5C",
                    fill: false,
                    borderWidth: 1.5,
                    animation: false,
                    pointRadius: 2,
                },
                {
                    label: "PAD (mm/hg)",
                    data: chartResult.pad,
                    borderColor: "#FFCD29",
                    backgroundColor: "#FFCD29",
                    fill: false,
                    borderWidth: 1.5,
                    animation: false,
                    pointRadius: 2,
                },
                {
                    label: "PAS (mm/hg)",
                    data: chartResult.pas,
                    borderColor: "#F46969",
                    backgroundColor: "#F46969",
                    fill: false,
                    borderWidth: 1.5,
                    animation: false,
                    pointRadius: 2,
                },
            ],
        };

        const plugin = {
            id: "a",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            beforeInit(chart: any) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                    originalFit.bind(chart.legend)();
                    this.height += 15;
                };
            }
        };

        return (
            <div style={{ borderRadius: "10px", height: heightProp ? heightProp : "auto", width: widthProp ? widthProp : "auto" }}>
                <Line options={options} data={chartData} plugins={[plugin]} />
            </div>
        );
    };

    return render();
}

