import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentAccount } from "../../models/account";

const initialState: CurrentAccount =
    JSON.parse(localStorage.getItem("account") as string)?.id
        ? JSON.parse(localStorage.getItem("account") as string) as CurrentAccount
        : {
            id: 0,
            nit: "",
            name: "",
            active: 0,
            profile: null,
            name_bank: null,
            created_at: "",
            updated_at: "",
            bussines_name: "",
            modules: [],
            contractOperators: [],
        };

export const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        setWorkspace: (state, action: PayloadAction<CurrentAccount>) => {
            state.id = action.payload.id;
            state.nit = action.payload.nit;
            state.name = action.payload.name;
            state.active = action.payload.active;
            state.profile = action.payload.profile;
            state.name_bank = action.payload.name_bank;
            state.created_at = action.payload.created_at;
            state.modules = action.payload.modules;
            state.contractOperators = action.payload.contractOperators;
        },
        clearWorkspace: (state) => {
            state = initialState;
        }
    }
});

export const { setWorkspace, clearWorkspace } = workspaceSlice.actions;