// import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch, RootState } from "@/config/store";
import { DeletePreviousSurgery, PreoperativeParams, PreoperativePayload } from "@/models/sheets/preoperative";
import { deleteSurgeryService, getPreoperativeEvaluationService, savePreoperativeEvaluationService } from "@/services/sheets/preoperative";
import { getCanAttentionEnd } from "../consultation.actions";
import { setAttentionEnd } from "@/config/slices/canAttentionEnd";

export function getPreoperativeEvaluation(params: PreoperativeParams) {
    return async function (dispatch: AppDispatch) {
        const response = await getPreoperativeEvaluationService(params);
        return response.success && response.results;
    };
}

export function savePreoperativeEvaluation(payload: PreoperativePayload) {
    return async function (dispatch: AppDispatch, state: () => RootState) {
        try {
            // dispatch(startLoading());
            const response = await savePreoperativeEvaluationService(payload);
            const data = await dispatch(getCanAttentionEnd(state().patientAttention.patientStatus.mcfId));
            dispatch(setAttentionEnd(data.results ?? false));
            return response.results;

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function deleteSurgery(payload: DeletePreviousSurgery) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await deleteSurgeryService(payload);
            return response.success;

        } finally {
            // dispatch(finishLoading());
        }
    };
}