import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { SurgeryHistoryDownloadParams } from "@/models/surgery/surgeryStages";
import { getSurgeryDownloadDetailsService, getSurgeryStagesListService } from "@/services/surgery/surgeryDetails";

export function getSurgeryStages(params: { toDownload?: 1 | 0; appId: number; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSurgeryStagesListService(params);

            if (response.success) return response.results;
            return [];
        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getSurgeryDownloadDetails(params: SurgeryHistoryDownloadParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getSurgeryDownloadDetailsService(params);

            if (response.success) return response.results;
        } finally {
            dispatch(finishLoading());
        }
    };
}