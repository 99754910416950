import axios from "axios";
import * as uiActions from "@/config/slices/ui";
import { validatePatientAttentionStatusDiagnosticService, validatePatientAttentionStatusService } from "@/services/pre-consultation";
//
import { AppDispatch } from "@/config/store";
import { fireErrorAlert } from "@/utils/utils";
import { IResponse } from "@/models/general";
import { ValidateAttentionPayload } from "@/models/appointment";
import { statusCodes } from "@/constants/http";
import { CallPatientPayload } from "@/models/patientAttention";
import { callPatientService } from "@/services";

export function validatePatientAttentionStatus(payload: ValidateAttentionPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const response = await validatePatientAttentionStatusService(payload);
            return response.results;

        } finally {
            dispatch(uiActions.finishLoading());

        }
    };
}
export function validatePatientAttentionStatusDiagnostic(payload: ValidateAttentionPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const response = await validatePatientAttentionStatusDiagnosticService(payload);
            return response.results;

        } finally {
            dispatch(uiActions.finishLoading());

        }
    };
}

export function callPatient(payload: CallPatientPayload) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const response = await callPatientService(payload);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.success;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error("There was an error => ", error);

        } finally {
            dispatch(uiActions.finishLoading());

        }
    };
}