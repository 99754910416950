import { SectionCard } from "@/components";
import { PreparationNotes } from "@/models/surgery/patientAttention/patientAttention";
import { useEffect, useState } from "react";
import TextConfigModal from "../../Consultation/AttentionOrigin/TextConfig/TextConfigNoIns";

const Preparation = ({ data, onSubmitNotes, readOnly }: {
    data?: PreparationNotes;
    onSubmitNotes: (section: string, payload: PreparationNotes) => void;
    readOnly?: boolean;
}) => {
    const [preparationNotes, setPreparationNotes] = useState<PreparationNotes>();
    const [preparationModal, setPreparationModal] = useState<{ isOpen: boolean; current: string; }>({
        isOpen: false,
        current: "asepsisAntisepsis"
    });
    const handleOpenNoInsTextModal = (name: string): void => setPreparationModal({
        current: name, isOpen: true
    });

    const handleCloseNoInsTextModal = (): void => setPreparationModal({
        current: "asepsisAntisepsis", isOpen: false
    });

    const handleAttatchText = (text: string) => {
        if (preparationNotes) {
            const payload = {
                numId: preparationNotes.numId,
                numAsepsisAntisepsis: preparationNotes?.numAsepsisAntisepsis?.length
                    ? `${preparationNotes.numAsepsisAntisepsis}\n${text}`
                    : text
            };
            setPreparationNotes(payload);
            onSubmitNotes("preparation", payload);
        }
        handleCloseNoInsTextModal();
    };

    const handleChange = (value: string, name: "numAsepsisAntisepsis") => {
        if (preparationNotes) {
            const payload = {
                numId: preparationNotes.numId,
                [name]: value
            };
            setPreparationNotes(payload);
            onSubmitNotes("preparation", payload);
        }
    };


    useEffect(() => {
        if (data) {
            setPreparationNotes(data);
        }
    }, [data]);




    return (
        <div className="my-3 mx-4 ">
            {preparationNotes?.userInfo &&
                <SectionCard className=" cardAidStyle mb-3 "  >
                    <span className="text-muted"><b className="text-secondary">Último cambio realizado por: </b>
                        {typeof preparationNotes.userInfo === "string" ? preparationNotes.userInfo : preparationNotes.userInfo.fullName}
                    </span>
                </SectionCard>}
            {preparationNotes?.transferredBy &&
                <SectionCard className=" cardAidStyle mb-3 "  >
                    <span className="text-muted"><b className="text-secondary">Transferido por:</b>  {preparationNotes?.transferredBy ?? "-"}</span>
                </SectionCard>}
            <SectionCard className="mt-3" cardAidStyle >
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary required">Asepsia y antisepsia del paciente</h5>
                </div>
                <hr className="lineCards" />
                <textarea
                    className="form-control no-resize w-100"
                    style={{ height: 100 }}
                    onContextMenu={(event) => {
                        event.preventDefault();
                        handleOpenNoInsTextModal("asepsisAntisepsis");
                    }}
                    onChange={({ target }) => handleChange(target.value, "numAsepsisAntisepsis")}
                    value={preparationNotes?.numAsepsisAntisepsis}
                    readOnly={readOnly}
                />
            </SectionCard>
            <SectionCard className="mt-3" cardAidStyle >
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold text-secondary">Drogas para la inducción</h5>
                </div>
                <hr className="lineCards" />
                <div className="d-flex flex-column">
                    {(preparationNotes?.drugs_for_induction && preparationNotes?.drugs_for_induction?.length > 0)
                        ?
                        preparationNotes?.drugs_for_induction.map((drug) => (
                            <div key={drug} className="text-muted">
                                {drug}
                            </div>
                        ))
                        :
                        <div className="text-muted">No se han agregado insumos</div>
                    }
                </div>
            </SectionCard>
            {!readOnly && (
                <TextConfigModal
                    sheet="nurseryNotes"
                    segment={preparationModal.current}
                    title="Textos predefinidos no institucionales"
                    isOpen={preparationModal.isOpen}
                    current={preparationModal.current as string}
                    onClose={handleCloseNoInsTextModal}
                    width={800}
                    positionModal="center"
                    onSelectItem={(_, item) => handleAttatchText(item)}
                />
            )}

        </div>
    );
};

export default Preparation;