import { IconCirclePlus, IconCircleX, IconDownload } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
//
import { getConfiguration } from "@/features/Workspace/Gate/PredefinedTextsNoIns/Config/config.actions";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Configuration, DilatationBody, MeasurementMethod } from "@/models";
//
import {
    BadgeTextField,
    Button,
    Checkbox,
    Modal,
    SectionCard,
    Select,
} from "@/components";
import { AttentionOriginFields } from "@/models/attentionOrigin";
import { ExamConfig, Exams, EyeOptions, FindingItems, PhysicalExamsPayload } from "@/models/physicalExam";
import { OftalmologySheet, ProvocativeEvidence, ProvocativeEvidencePayload, TonometryPayload } from "@/models/sheets/oftalmology";
import { useDebouncedCallback } from "use-debounce";
import AnalysisAndPlan from "../AttentionOrigin/AnalysisAndPlan";
import AttentionOrigin from "../AttentionOrigin/AttentionOrigin";
import ConsultationControls from "../Controls/Controls";
import DiagnosticImpression from "../DiagnosticImpression/DiagnosticImpression";
import ConsultationHeader from "../Header/Header";
import { getDilatations } from "../PreConsultation/preConsultation.actions";
import ProvocativeChart from "./ProvocativeChart/ProvocativeChart";
import TextConfigModal from "./TextConfig/TextConfig";
import {
    getMeasurementMethods,
    getOftalmologySheet,
    getPreviousPhysicalExam,
    updateOftalmologySheet
} from "./oftalmology.actions";
//
import { startLoading } from "@/config/slices";
import { Segments } from "@/models/generalFields";
import { DIListItem } from "@/models/select/diagnosticImpression";
import History from "../History/History";
import "./Oftalmology.scss";
import RefractionModal from "./RefractionModal/RefractionModal";
import { LentsInUse } from "@/models/sheets/lowVision";
import { fireCautionAlert } from "@/utils";

interface IModalConfig {
    isOpen?: boolean;
    currentTab?: "finding" | "reason";
    segment?: number | string;
    currentEye?: "od" | "oi";
    currentPrefix: Exams | "";
    segmentName: string;
    isNormal?: 1 | 0;
}

export default function Oftalmology({ historySheet, data, appId }: {
    historySheet?: boolean; data?: {
        dataSheet: OftalmologySheet, diagnosticImpression: DIListItem[]; generalsFields: Segments[];
    }; appId?: number;
}) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { dataSheet, diagnosticImpression, generalsFields } = data || {};

    const patientId = useAppSelector(state => state.patientAttention.appointmentSelected.patientId);
    const currentSheet = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const isDisabledForm = historySheet ? historySheet : false;
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const medicalFormId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const [showHistory, setShowHistory] = useState(true);
    const [isAllNormal, setIsAllNormal] = useState<boolean>(false);
    const [measurementMethods, setMeasurementMethods] = useState<MeasurementMethod[]>([]);
    const [physicalExams, setPhysicalExams] = useState<PhysicalExamsPayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number
    });
    const [triggerChart, setTriggerChart] = useState(0);
    const [textConfigModal, setTextConfigModal] = useState<IModalConfig>({
        isOpen: false,
        currentTab: undefined,
        currentEye: undefined,
        currentPrefix: "",
        segmentName: "",
        isNormal: 0
    });
    const [physicalExam, setPhysicalExam] = useState<Configuration[]>([]);
    const [pressionModal, setPressionModal] = useState<IModalConfig>({
        isOpen: false,
        currentTab: undefined,
        currentEye: undefined,
        currentPrefix: "",
        segmentName: "",
        isNormal: 0
    });
    const [refractionModal, setRefractionModal] = useState<boolean>(false);
    const [finalRefraction, setFinalRefraction] = useState<Partial<LentsInUse>>();
    const [attentionOriginOptions, setAttentionOriginOptions] = useState<AttentionOriginFields>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
        generalFields: {
            attentionOriginId: undefined,
            abserveEvent: 0,
            evolutionTime: undefined,
            evolutionTimeUnitId: undefined
        },
        reasonForConsulting: { predefinedTxts: [], justification: "" },
        currentDisease: { predefinedTxts: [], justification: "" },
        analysisPlan: ""
    });
    const [provocativeItems, setProvocativeItems] = useState<ProvocativeEvidencePayload[]>([
        {
            appId: appointmentId,
            eaccount: accountId,
            userId: userId as number,
            provocativeEvidence: {
                order: 1,
                time: dayjs().format("HH:mm"),
            },
            indentifier: new Date().getTime().toString() + 1
        },
    ]);
    const [lastDilation, setLastDilation] = useState<DilatationBody>();
    const [tonometryPayload, setTonometryPayload] = useState<TonometryPayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number,
    });
    const [trigger, setTrigger] = useState<number>(0);
    const saveTonometryDebounce = useDebouncedCallback(async () => {

        const res = await dispatch(updateOftalmologySheet({
            appId: appointmentId,
            eaccount: accountId,
            traditionalTonometry: tonometryPayload,
            userId: userId as number
        }));
        if (res !== "ok") {
            setTrigger(trigger + 1);
        }

    }, 2000);

    useEffect(() => {
        if (!historySheet) {
            const splittedPath = location.pathname.split("/");
            const sheet = splittedPath[splittedPath.length - 1];

            if (sheet !== currentSheet) {
                navigate(-1);
            }
        }
    }, [currentSheet, navigate, location.pathname]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getMeasurementMethods());
                if (data) setMeasurementMethods(data);
            }
        }
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {

                const data = await dispatch(getDilatations({
                    appId: appointmentId,
                    eaccount: accountId,
                    mcfId: medicalFormId,
                    userId: userId as number,
                }));
                if (data) {
                    setLastDilation(data?.results[data?.results?.length - 1]);
                }
            }
        }
        fetchData();
    }, [appointmentId, accountId, medicalFormId, userId, dispatch]);

    useEffect(() => {
        async function fetchData() {
            if (historySheet) {
                if (dataSheet) {
                    setPhysicalExams({
                        external: dataSheet?.external,
                        ppl: dataSheet?.ppl,
                        screra_conjunctiva: dataSheet?.screra_conjunctiva,
                        cornea: dataSheet?.cornea,
                        previous_chamber: dataSheet?.previous_chamber,
                        iris: dataSheet?.iris,
                        crystalline: dataSheet?.crystalline,
                        retina_vitreous: dataSheet?.retina_vitreous,
                        optic_nerve: dataSheet?.optic_nerve,
                    });
                }
                if (dataSheet?.traditionalTonometry) {
                    setTonometryPayload(dataSheet?.traditionalTonometry);
                }
                if (dataSheet?.provocativeEvidence) {
                    const formattedData: ProvocativeEvidencePayload[] = dataSheet.provocativeEvidence.map((item, index) => ({
                        appId: appointmentId,
                        eaccount: accountId,
                        userId: userId as number,
                        ophthalmologyId: item.ophthalmologyId,
                        provocativeEvidence: {
                            order: item.order,
                            id: item.id,
                            time: item.time,
                            evidenceOi: item.evidenceOi,
                            evidenceOd: item.evidenceOd
                        },
                        indentifier: new Date().getTime().toString() + index
                    }));
                    setProvocativeItems(formattedData);
                }

                if (dataSheet?.finalRefraction) {
                    setFinalRefraction(dataSheet.finalRefraction);
                }


            } else {
                const data = await dispatch(getOftalmologySheet({
                    appId: appointmentId,
                    eaccount: accountId,
                    userId: userId as number
                }));
                if (data) {
                    if (data.finalRefraction) {
                        setFinalRefraction(data.finalRefraction);
                    }
                    if (data.traditionalTonometry) {
                        setTonometryPayload({
                            correctionOd: data.traditionalTonometry.correctionOd,
                            correctionOi: data.traditionalTonometry.correctionOi,
                            pachymetryOd: data.traditionalTonometry.pachymetryOd,
                            pachymetryOi: data.traditionalTonometry.pachymetryOi,
                            pioOd: data.traditionalTonometry.pioOd,
                            pioOi: data.traditionalTonometry.pioOi,
                            pioPachymetryAdjustOd: data.traditionalTonometry.pioPachymetryAdjustOd,
                            pioPachymetryAdjustOi: data.traditionalTonometry.pioPachymetryAdjustOi,
                            pmmId: data.traditionalTonometry.pmmId
                        });

                    }
                    if (data.provocativeEvidence) {
                        const formattedData: ProvocativeEvidencePayload[] = data.provocativeEvidence.map((item, index) => ({
                            appId: appointmentId,
                            eaccount: accountId,
                            userId: userId as number,
                            ophthalmologyId: item.ophthalmologyId,
                            provocativeEvidence: {
                                order: item.order,
                                id: item.id,
                                time: item.time,
                                evidenceOi: item.evidenceOi,
                                evidenceOd: item.evidenceOd
                            },
                            indentifier: new Date().getTime().toString() + index
                        }));
                        setProvocativeItems(formattedData);
                    }
                    if (data) {
                        setPhysicalExams({
                            external: data.external,
                            ppl: data.ppl,
                            screra_conjunctiva: data.screra_conjunctiva,
                            cornea: data.cornea,
                            previous_chamber: data.previous_chamber,
                            iris: data.iris,
                            crystalline: data.crystalline,
                            retina_vitreous: data.retina_vitreous,
                            optic_nerve: data.optic_nerve,
                        });
                    }
                }

            }
        }
        fetchData();
    }, [appointmentId, accountId, medicalFormId, userId, dispatch, trigger, dataSheet, historySheet]);

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(getConfiguration({
                segmentId: 3,
                entity: "dtlSegments"
            }));
            setPhysicalExam(data as Configuration[]);
        }
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (physicalExams.external?.od?.normal &&
            physicalExams.external?.oi?.normal &&
            physicalExams.ppl?.od?.normal &&
            physicalExams.ppl?.oi?.normal &&
            physicalExams.screra_conjunctiva?.od?.normal &&
            physicalExams.screra_conjunctiva?.oi?.normal &&
            physicalExams.cornea?.od?.normal &&
            physicalExams.cornea?.oi?.normal &&
            physicalExams.previous_chamber?.od?.normal &&
            physicalExams.previous_chamber?.oi?.normal &&
            physicalExams.iris?.od?.normal &&
            physicalExams.iris?.oi?.normal &&
            physicalExams.crystalline?.od?.normal &&
            physicalExams.crystalline?.oi?.normal &&
            physicalExams.retina_vitreous?.od?.normal &&
            physicalExams.retina_vitreous?.oi?.normal &&
            physicalExams.optic_nerve?.od?.normal &&
            physicalExams.optic_nerve?.oi?.normal) {
            setIsAllNormal(true);
        } else {
            setIsAllNormal(false);
        }
    }, [physicalExams]);

    const handleOpenModal = (config: IModalConfig): void => {
        setTextConfigModal({
            isOpen: true,
            currentTab: config.currentTab,
            segment: config.segment,
            currentEye: config.currentEye,
            currentPrefix: config.currentPrefix,
            segmentName: config.segmentName,
        });
    };

    const handleCloseModal = (): void => {
        setTextConfigModal({
            isOpen: false,
            currentTab: undefined,
            currentEye: undefined,
            currentPrefix: "",
            segmentName: ""
        });
    };


    const handleCheckAllNormal = async (checked: boolean) => {
        dispatch(startLoading);
        const parsedBool = checked ? 1 : 0;

        const temp = physicalExams;
        Object.keys(temp).forEach((r) => {
            const section = temp[r as Exams] as ExamConfig;
            if (typeof section === "object" && section !== null) {
                if ("prefix" in section) {
                    if (section.od && section.oi) {
                        section.od.normal = parsedBool;
                        section.oi.normal = parsedBool;
                        if (parsedBool === 0) {
                            section.od.findings = [];
                            section.oi.findings = [];

                        }
                    }
                }
            }
        });
        setIsAllNormal(checked);
        setPhysicalExams(temp);
        const res = await dispatch(updateOftalmologySheet({
            ...temp,
            appId: appointmentId as number,
            eaccount: accountId,
            userId: userId as number,
        }));
        if (res === "ok") {
            setTrigger(trigger + 1);
        }
    };

    const handleCheckNormal = async (prefix: Exams, currentEye: "od" | "oi", checked: boolean) => {
        const parsedBool = checked ? 1 : 0;
        const options = {
            appId: appointmentId as number,
            eaccount: accountId,
            userId: userId as number,
            ...physicalExams,
            [prefix]: {
                [currentEye]: {
                    ...physicalExams[prefix]?.[currentEye],
                    normal: parsedBool,
                    findings: [],
                    justifications: physicalExams[prefix]?.[currentEye]?.justifications ?? "",
                },
                prefix,
                ...physicalExams[prefix],
            }
        };
        setPhysicalExams(options);
        const optionsSend = {
            appId: appointmentId as number,
            eaccount: accountId,
            userId: userId as number,
            [prefix]: {
                ...physicalExams[prefix],
                [currentEye]: {
                    ...physicalExams[prefix]?.[currentEye],
                    normal: parsedBool,
                    findings: [],
                    justifications: physicalExams[prefix]?.[currentEye]?.justifications ?? "",
                },
                prefix,
            }
        };
        const res = await dispatch(updateOftalmologySheet(optionsSend));
        if (res) {
            setTrigger(trigger + 1);
        }
    };

    const handleSaveConfig = async (result: ExamConfig, eye: "od" | "oi", normal?: boolean) => {
        const options: PhysicalExamsPayload = {
            appId: appointmentId as number,
            eaccount: accountId,
            userId: userId as number,
            [result.prefix]: {
                ...physicalExams[result.prefix],
                [eye]: result[eye],
                prefix: result.prefix
            }
        };
        const optionsState: PhysicalExamsPayload = {
            ...physicalExams,
            [result.prefix]: {
                ...physicalExams[result.prefix],
                [eye]: result[eye],
                prefix: result.prefix
            }
        };
        setPhysicalExams(optionsState);
        const res = await dispatch(updateOftalmologySheet(options));
        if (res !== "ok" || normal) {
            setTrigger(trigger + 1);
        }
    };

    const findExamByPrefix = (prefix: string) => {
        return physicalExam.find(item => item.prefix === prefix) as Configuration;
    };


    const handleSubmitEvidence = useDebouncedCallback(async (currentItem: ProvocativeEvidence[]) => {
        const isSuccess = await dispatch(updateOftalmologySheet({
            appId: appointmentId as number,
            eaccount: accountId,
            userId: userId as number,
            provocativeEvidence: currentItem.map(item => { return { ...item.provocativeEvidence }; }),
        }));
        if (isSuccess !== "ok") {
            setTrigger(trigger + 1);
        }
    }, 1000);

    const handleChangeEvidence = (id: string, eye: "evidenceOd" | "evidenceOi" | "time", value: string) => {
        const optionsTemp = provocativeItems.map(item => {
            if (item.indentifier === id) {
                if (eye === "time") {
                    return {
                        ...item,
                        provocativeEvidence: {
                            ...item.provocativeEvidence,
                            [eye]: value
                        }
                    };
                } else {
                    return {
                        ...item,
                        provocativeEvidence: {
                            ...item.provocativeEvidence,
                            [eye]: +value
                        }
                    };
                }
            }
            return item;
        });

        const options = optionsTemp.sort((a, b) => {
            if (a?.provocativeEvidence?.time && b?.provocativeEvidence?.time) {
                if (a?.provocativeEvidence?.time < b?.provocativeEvidence?.time) {
                    return -1;
                }
                if (a.provocativeEvidence.time > b.provocativeEvidence.time) {
                    return 1;
                }
            }
            return 0;
        });
        const currentItem = options.find(item => item.indentifier === id);
        setProvocativeItems(options);
        if (!!currentItem?.provocativeEvidence.evidenceOd && !!currentItem?.provocativeEvidence.evidenceOi) {
            handleSubmitEvidence(options as ProvocativeEvidence[]);
        }
        setTriggerChart(triggerChart + 1);
    };

    const handleAddProvocativeEvidence = () => {
        setProvocativeItems([
            ...provocativeItems,
            {
                appId: appointmentId,
                eaccount: accountId,
                userId: userId as number,
                provocativeEvidence: {
                    order: provocativeItems.length + 1,
                    time: dayjs().format("HH:mm"),
                },
                indentifier: new Date().getTime().toString() + 1
            }
        ]);
    };

    const handleDeleteEvidence = async (item: ProvocativeEvidencePayload) => {
        const filteredItems = provocativeItems.filter(pi => pi.indentifier !== item.indentifier);
        setProvocativeItems(filteredItems);

        if (item.provocativeEvidence.id) {
            const isSuccess = await dispatch(updateOftalmologySheet({
                appId: appointmentId,
                eaccount: accountId,
                provocativeEvidence: filteredItems.map(item => { return { ...item.provocativeEvidence }; }),
                userId: userId as number
            }));
            if (isSuccess !== "ok") {
                setTrigger(trigger + 1);
            }
            return;

        }
    };

    const handleChangeRefractionValues = async (payload: Partial<LentsInUse>) => {
        await dispatch(updateOftalmologySheet({
            appId: appointmentId,
            eaccount: accountId,
            finalRefraction: payload,
            userId: userId as number
        }));

        setTrigger(trigger + 1);
    };

    const handleChangePachymetry = async (eye: "pachymetryOd" | "pachymetryOi", value: string) => {
        let options: TonometryPayload = {
            ...tonometryPayload,
            [eye]: value
        };

        if (eye === "pachymetryOd") {
            options = {
                ...tonometryPayload,
                [eye]: value === "" ? 0 : Number(value),
                pioPachymetryAdjustOd: Number((tonometryPayload.pioOd ?? 0))
            };
        }
        if (eye === "pachymetryOi") {
            options = {
                ...tonometryPayload,
                [eye]: value === "" ? 0 : Number(value),
                pioPachymetryAdjustOi: Number((tonometryPayload.pioOi ?? 0))
            };
        }

        setTonometryPayload(options);
        if ((Number(value) >= 445 && Number(value) <= 645) || value === "") {
            const res = await dispatch(updateOftalmologySheet({
                appId: appointmentId,
                eaccount: accountId,
                traditionalTonometry: options,
                userId: userId as number
            }));
            if (res !== "ok") {
                setTrigger(trigger + 1);
            }
        }
    };

    const handleChangePIO = (eye: "pioOd" | "pioOi", value: string) => {

        const submit = async (options: TonometryPayload) => {
            const res = await dispatch(updateOftalmologySheet({
                appId: appointmentId,
                eaccount: accountId,
                traditionalTonometry: options,
                userId: userId as number
            }));
            if (res !== "ok") {
                setTrigger(trigger + 1);
            }
        };

        let options: TonometryPayload;

        if (eye === "pioOd") {
            options = {
                ...tonometryPayload,
                [eye]: Number(value),
                pioPachymetryAdjustOd: Number((value === "" ? 0 : Number(value)) + (tonometryPayload?.correctionOd ?? 0))
            };
            setTonometryPayload(options);
            submit(options);
        }
        if (eye === "pioOi") {
            options = {
                ...tonometryPayload,
                [eye]: Number(value),
                pioPachymetryAdjustOi: Number((value === "" ? 0 : Number(value)) + (tonometryPayload.correctionOi ?? 0))
            };
            setTonometryPayload(options);
            submit(options);

        }
    };


    const settingFindingsModal = (data: ExamConfig) => {
        const options = {
            ...tonometryPayload,
            ...data
        };
        setTonometryPayload(options);
    };

    const onGetPreviousPhysicalExam = async () => {
        fireCautionAlert("Se cargará el examen físico anteriormente realizado", undefined, async () => {
            const result = await dispatch(getPreviousPhysicalExam({ patientId: patientId, appId: appointmentId }));
            if (result) {
                setPhysicalExams(state => ({ ...state, ...result }));
                const res = await dispatch(updateOftalmologySheet({
                    ...result,
                    appId: appointmentId as number,
                    eaccount: accountId,
                    userId: userId as number,
                }));
                if (res === "ok") {
                    setTrigger(trigger + 1);
                }
            }
        });
    };

    const renderField = (item: Configuration, currentEye: "od" | "oi") => {
        const handleRemoveFinding = (itemId: number | string) => {
            let options: ExamConfig = { ...physicalExams[item?.prefix]?.[currentEye], prefix: item.prefix };
            options = {
                ...options,
                [currentEye as string]: {
                    findings: options[currentEye as "od" | "oi"]?.findings.filter(item => item.id !== itemId) as FindingItems[],
                    justifications: "" as string
                }
            };
            if (options[currentEye as "od" | "oi"]?.findings?.length as number < 1) {
                options = {
                    ...options,
                    [currentEye as "od" | "oi"]: {
                        ...options[currentEye as "od" | "oi"],
                        justifications: ""
                    }
                };
            }
            handleSaveConfig(options, currentEye);
        };

        return (
            <div className="mb-2">
                <div className="d-flex align-items-center">
                    <label className="text-secondary flex-grow-1">{item?.fieldName}</label>
                    <Checkbox
                        label="Normal"
                        disabled={isDisabledForm}
                        onChange={({ target }) => handleCheckNormal(item.prefix, currentEye, target.checked)}
                        checked={physicalExams[item?.prefix]?.[currentEye]?.normal === 1 ? true : false}
                    />
                </div>
                <BadgeTextField
                    disabled
                    bookmarks={physicalExams[item?.prefix]?.[currentEye]?.findings?.map(fin => ({ label: fin.name as string, value: fin.id }))}
                    onClick={() => !isDisabledForm && handleOpenModal({
                        segment: item?.id,
                        currentTab: "finding",
                        currentEye: currentEye,
                        currentPrefix: item?.prefix,
                        segmentName: item?.fieldName as string
                    })}
                    sameAction
                    centered
                    withDetails
                    isErasable={!isDisabledForm}
                    onDeleteItem={(itemId) => handleRemoveFinding(itemId)}
                    classNameDetails="textareaDetailsExamsSection"
                    value={physicalExams[item?.prefix]?.[currentEye]?.justifications}
                />
                <Tooltip id="hasJustification" />
            </div>
        );
    };

    const renderIntraocularPression = () => {

        const handleOnCloseModal = () => {
            setPressionModal({ ...pressionModal, isOpen: false });
        };


        const formattedMethods = measurementMethods.map(ms => ({
            label: ms.name,
            value: ms.id
        }));

        const formattedDilationTime = lastDilation ? lastDilation?.time : "Sin dilatación...";

        return (
            <Modal onClose={handleOnCloseModal} className="textconfig-modal align-self-center" isOpen={pressionModal.isOpen}
                width={1200}
            >
                <h4 className="text-secondary fw-bold">Presión intraocular</h4>
                <div className="row px-3 pb-2">
                    <div className="col-3" style={{ borderRight: "1px solid #A3E4EC" }}>
                        <label className="text-primary fw-bold">Tonometría tradicional</label>

                        <div className="d-flex mt-3">
                            <span className="text-secondary" style={{ width: 150 }}>Hora dilatación</span>
                            <span className="text-secondary text-start nowrap">{formattedDilationTime}</span>
                        </div>

                        <table className="mt-2">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-secondary" align="center">OD</td>
                                    <td className="text-secondary" align="center">OI</td>
                                </tr>
                                <tr>
                                    <td className="text-secondary pb-3">Paquimetría</td>
                                    <td className="pb-3" align="center">
                                        <input
                                            disabled={isDisabledForm}
                                            type="number"
                                            className="form-control form-control-sm w-75"
                                            value={tonometryPayload.pachymetryOd}
                                            onChange={({ target }) => {
                                                if ((Number(target.value) >= 445 && Number(target.value) <= 645) || target.value === "") {
                                                    target.classList.remove("border-danger");
                                                } else {
                                                    target.classList.add("border-danger");
                                                }
                                                handleChangePachymetry("pachymetryOd", target.value);
                                            }}
                                            onKeyDown={(event) => event.key === "-" && event.preventDefault()}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="El valor ingresado debe ser entre el rango de 445 a 645"
                                            data-tooltip-place="bottom"
                                        />
                                    </td>
                                    <td className="pb-3" align="center">
                                        <input
                                            disabled={isDisabledForm}
                                            type="number"
                                            className="form-control form-control-sm w-75"
                                            value={tonometryPayload.pachymetryOi}
                                            onChange={({ target }) => {
                                                if ((Number(target.value) >= 445 && Number(target.value) <= 645) || target.value === "") {
                                                    target.classList.remove("border-danger");
                                                } else {
                                                    target.classList.add("border-danger");
                                                }
                                                handleChangePachymetry("pachymetryOi", target.value);
                                            }}
                                            onKeyDown={(event) => event.key === "-" && event.preventDefault()}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="El valor ingresado debe ser entre el rango de 445 a 645"
                                            data-tooltip-place="bottom"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-secondary pb-3">Corrección</td>
                                    <td className="pb-3 " align="center">
                                        <span>{tonometryPayload.correctionOd}</span>
                                    </td>
                                    <td className="pb-3" align="center">
                                        <span>{tonometryPayload.correctionOi}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className="text-primary mt-0 mb-2" />
                        <table>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-secondary" align="center">OD</td>
                                    <td className="text-secondary" align="center">OI</td>
                                </tr>
                                <tr>
                                    <td className="text-secondary pb-3" width={62}>PIO</td>
                                    <td className="pb-3" align="center">
                                        <input
                                            disabled={isDisabledForm}
                                            type="number"
                                            className="form-control form-control-sm w-75"
                                            value={tonometryPayload.pioOd === 0 ? "" : tonometryPayload.pioOd}
                                            onChange={({ target }) => handleChangePIO("pioOd", target.value)}
                                        />
                                    </td>
                                    <td className="pb-3" align="center">
                                        <input
                                            disabled={isDisabledForm}
                                            type="number"
                                            className="form-control form-control-sm w-75"
                                            value={tonometryPayload.pioOi === 0 ? "" : tonometryPayload.pioOi}
                                            onChange={({ target }) => handleChangePIO("pioOi", target.value)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className="text-primary mt-0 mb-2" />
                        <table>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-secondary " style={{ width: "46%" }} align="center">OD</td>
                                    <td className="text-secondary " style={{ width: "46%" }} align="center">OI</td>
                                </tr>
                                <tr>
                                    <td className="text-secondary pb-3">
                                        PIO ajustada por paquimetría
                                    </td>
                                    <td className="pb-3" align="center">
                                        <span>{tonometryPayload.pioPachymetryAdjustOd}</span>
                                    </td>
                                    <td className="pb-3" align="center">
                                        <span>{tonometryPayload.pioPachymetryAdjustOi}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className="text-primary mt-0 mb-3" />
                        <table>
                            <tbody>
                                <tr>
                                    <td className="text-secondary mr-2">Método</td>
                                    <td colSpan={2} className="ml-3"
                                        style={{
                                            paddingLeft: 27,
                                            paddingRight: 10,
                                        }}
                                    >
                                        <Select
                                            changeToInput={historySheet}
                                            disabled={isDisabledForm}
                                            inputValue={tonometryPayload?.pmmName ?? ""}
                                            className="ml-2"
                                            options={formattedMethods}
                                            value={formattedMethods.find(ms => ms.value === tonometryPayload.pmmId)}
                                            onChange={({ option }) => {
                                                setTonometryPayload({ ...tonometryPayload, pmmId: option.value });
                                                saveTonometryDebounce();
                                            }}
                                            style={{ width: "100%" }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {renderProvocativeEvidence()}
                    <div className="col-6 align-self-center" key={triggerChart}>
                        <ProvocativeChart
                            data={provocativeItems}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    const renderProvocativeEvidence = () => {
        return (
            <div className="col-3 d-flex flex-column ps-4">
                <label className="text-primary fw-bold">Pruebas provocativas</label>
                <div style={{ maxHeight: 300, overflow: "auto" }}>
                    <table className="w-100 mt-4">
                        <thead>
                            <tr>
                                <td className="text-secondary col-4" >Hora</td>
                                <td className="text-secondary px-3 col-3">OD</td>
                                <td className="text-secondary px-3 col-3">OI</td>
                                <td className="text-secondary col-1" align="right">&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {provocativeItems.map(item => (
                                <tr key={item.indentifier}>
                                    <td className="pe-2 py-2 col-3">
                                        <input
                                            style={{ width: "110px" }}
                                            className="form-control form-control-sm"
                                            value={item.provocativeEvidence.time as string}
                                            onChange={({ target }) => handleChangeEvidence(item.indentifier, "time", target.value)}
                                            type="time"
                                        />
                                    </td>
                                    <td className="px-2 py-2  col-3">
                                        <input
                                            disabled={isDisabledForm}
                                            type="number"
                                            className="form-control form-control-sm"
                                            value={Number(item.provocativeEvidence.evidenceOd).toString()}
                                            onChange={({ target }) => handleChangeEvidence(item.indentifier, "evidenceOd", target.value)}
                                            onKeyDown={(event) => {
                                                if (event.key === "-") {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </td>
                                    <td className="px-2 py-2 col-3">
                                        <input
                                            disabled={isDisabledForm}
                                            type="number"
                                            className="form-control form-control-sm"
                                            value={Number(item.provocativeEvidence.evidenceOi).toString()}
                                            onChange={({ target }) => handleChangeEvidence(item.indentifier, "evidenceOi", target.value)}
                                            onKeyDown={(event) => {
                                                if (event.key === "-") {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </td>
                                    <td align="right" className="col-1">
                                        {provocativeItems.length > 1 && (
                                            <IconCircleX
                                                className="pointer text-black-50"
                                                style={{ width: 20 }}
                                                onClick={() => handleDeleteEvidence(item)}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="w-100 d-flex flex-column mt-4">
                    <Button
                        variant="text"
                        disabled={isDisabledForm}
                        endIcon={<IconCirclePlus />}
                        className="align-self-end"
                        onClick={() => handleAddProvocativeEvidence()}
                    >
                        Añadir
                    </Button>
                </div>
                <div className="flex-grow-1"></div>
            </div>
        );
    };

    const renderEyeExams = () => {
        return (
            <div className="col-12">
                <SectionCard className="h-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="fw-bold text-secondary my-0">Examen físico / ocular</h5>
                        <div className="d-flex align-items-center gap-3">
                            <Button
                                variant="primary"
                                endIcon={<IconDownload />}
                                onClick={() => void onGetPreviousPhysicalExam()}
                            >
                                Cargar examen físico previo
                            </Button>
                            <div className="p-2 rounded bg-primary-lighter" >
                                <Checkbox
                                    disabled={isDisabledForm}
                                    style={{ borderWidth: 2 }}
                                    label="Examen normal en ambos ojos"
                                    className="text-primary fw-bold"
                                    checked={isAllNormal}
                                    onChange={({ target }) => handleCheckAllNormal(target.checked)}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className="text-primary my-3" />
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-12 d-flex gap-4 mt-3 mb-4">
                                    <button className="button button-small button-primary w-50 justify-content-start"
                                        onClick={() => {
                                            setPressionModal({ ...pressionModal, isOpen: true });
                                        }}
                                    >
                                        Presión intraocular
                                    </button>
                                    <button className="button button-small button-warning w-50 justify-content-start"
                                        onClick={() => {
                                            setRefractionModal(true);
                                        }}
                                    >
                                        Refracción final
                                    </button>
                                </div>
                                <div className="col-6 ">
                                    <div className=" sectionExamEyeOD">
                                        <div className="d-flex justify-content-center pb-4 pt-2">
                                            <label className="fw-bold text-secondary mb-2 text-center">OD</label>
                                        </div>
                                        {renderField(findExamByPrefix("external"), "od")}
                                        {renderField(findExamByPrefix("ppl"), "od")}
                                        {renderField(findExamByPrefix("screra_conjunctiva"), "od")}
                                        {renderField(findExamByPrefix("cornea"), "od")}
                                    </div>
                                </div>
                                <div className="col-6 ">
                                    <div className="sectionExamEyeOI">
                                        <div className="d-flex justify-content-center pb-4 pt-2">
                                            <label className="fw-bold text-secondary mb-2 text-center">OI</label>
                                        </div>
                                        {renderField(findExamByPrefix("external"), "oi")}
                                        {renderField(findExamByPrefix("ppl"), "oi")}
                                        {renderField(findExamByPrefix("screra_conjunctiva"), "oi")}
                                        {renderField(findExamByPrefix("cornea"), "oi")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row ">
                                <div className="col-6 ">
                                    <div className=" sectionExamEyeOD">
                                        <div className="d-flex justify-content-center">
                                            <label className="fw-bold text-secondary mb-2 text-center">OD</label>
                                        </div>
                                        {renderField(findExamByPrefix("previous_chamber"), "od")}
                                        {renderField(findExamByPrefix("iris"), "od")}
                                        {renderField(findExamByPrefix("crystalline"), "od")}
                                        {renderField(findExamByPrefix("retina_vitreous"), "od")}
                                        {renderField(findExamByPrefix("optic_nerve"), "od")}
                                    </div>
                                </div>
                                <div className="col-6 ">
                                    <div className="sectionExamEyeOI">
                                        <div className="d-flex justify-content-center">
                                            <label className="fw-bold text-secondary mb-2 text-center">OI</label>
                                        </div>
                                        {renderField(findExamByPrefix("previous_chamber"), "oi")}
                                        {renderField(findExamByPrefix("iris"), "oi")}
                                        {renderField(findExamByPrefix("crystalline"), "oi")}
                                        {renderField(findExamByPrefix("retina_vitreous"), "oi")}
                                        {renderField(findExamByPrefix("optic_nerve"), "oi")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </SectionCard>
            </div>
        );
    };
    const render = () => {
        return (
            <section className="oftalmology">
                {!historySheet &&
                    <ConsultationHeader
                        isDisabledForm={isDisabledForm}
                        currentSection="Oftalmología"
                        showHistory={showHistory} setShowHistory={(state) => setShowHistory(state)} />
                }
                <div className="d-flex h-100 overflow-hidden">
                    <section className="consultation-section">
                        <div className="row g-4">
                            <div className="col-lg-12 col-md-12 d-flex flex-column">
                                <AttentionOrigin
                                    isDisabledForm={isDisabledForm}
                                    attentionOriginOptions={attentionOriginOptions}
                                    setAttentionOriginOptions={setAttentionOriginOptions}
                                    data={generalsFields}
                                    historySheet={historySheet}

                                />
                                <div className="row mt-1 mb-3 g-3">
                                    {renderEyeExams()}
                                </div>

                            </div>
                            <div className="col-sm-12 col-lg-12 d-flex flex-column">
                                {renderIntraocularPression()}
                                <div className="row">
                                    <div className="col-6">
                                        <DiagnosticImpression isDisabledForm={isDisabledForm}
                                            historySheet={historySheet}
                                            data={{
                                                appId,
                                                cluId: undefined,
                                                userId: NaN,
                                                eaccount: accountId,
                                                clhId: undefined,
                                                sheet: "ophthalmology",
                                                information: diagnosticImpression
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="col-6">
                                        <AnalysisAndPlan
                                            isDisabledForm={isDisabledForm}
                                            attentionOriginOptions={attentionOriginOptions}
                                            setAttentionOriginOptions={setAttentionOriginOptions}
                                            height={270}
                                            data={generalsFields && generalsFields[0].analysisPlanJst}

                                        />
                                    </div>
                                </div>
                            </div >
                        </div >
                    </section >
                    {(showHistory && !historySheet) && <History setShowHistory={setShowHistory} showClose={false} />}
                </div>
                {!historySheet &&
                    <ConsultationControls isDisabledForm={isDisabledForm} />
                }
                <Tooltip id="my-tooltip" />
                {!historySheet &&
                    <TextConfigModal
                        isDisabledForm={isDisabledForm}
                        isOpen={textConfigModal.isOpen}
                        onClose={handleCloseModal}
                        width={500}
                        config={{
                            currentTab: textConfigModal.currentTab,
                            segment: textConfigModal.segment,
                            currentEye: textConfigModal.currentEye as "od" | "oi",
                            segmentPrefix: textConfigModal.currentPrefix as Exams,
                            segmentName: textConfigModal.segmentName,
                            isNormal: textConfigModal.isNormal as 1 | 0,
                            options: physicalExams[textConfigModal.currentPrefix as Exams]?.[textConfigModal.currentEye as "od" | "oi"] as EyeOptions,
                        }}
                        settingFindingsModal={settingFindingsModal}
                        onSaveConfig={handleSaveConfig}
                    />
                }

                <RefractionModal
                    isOpen={refractionModal}
                    onClose={() => void setRefractionModal(false)}
                    isDisabledForm={isDisabledForm}
                    changeToInput={historySheet}
                    onChange={handleChangeRefractionValues}
                    finalRefraction={finalRefraction}
                />

            </section >
        );
    };

    return render();
}
