import { SectionCard } from "@/components";
import { useAppDispatch } from "@/hooks";
import { SectionsChanged } from "@/models/surgery/patientAttention/Anesthesiologist";
import { Surgery as ISurgery, Patient } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { SurgeonNotes } from "@/models/surgery/patientAttention/surgeon";
import { fireSuccessAlert } from "@/utils";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConsultationControls from "../../../Consultation/Controls/Controls";
import History from "../../../Consultation/History/History";
import SurgeryHeader from "../../components/HeaderSurgeryComponeny";
import { finishEvent } from "./anesthesiologist/anesthesiologist.action";
import { finishSurgeryEvent } from "./surgeon/surgeon.action";


interface ParentComponentProps {
    children: React.ReactNode;
    setDataTemplate?: (data: { clhId: number; cluId: number; }) => void;
    sectionsChanged?: SectionsChanged | SurgeonNotes | undefined;
    sheet?: string;
}

const currentConsumption: Record<string, { value: string; label: string }> = {
    surgery: { value: "surgery", label: "Cirugía" },
    anesthesiaNotes: { value: "preparation", label: "Preparación" },
};

const TemplateBodyDoctor: React.FC<ParentComponentProps> = ({ children, setDataTemplate, sectionsChanged, sheet }) => {
    const location = useLocation();
    const appId = location.state.data.appId;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [services, setServices] = useState<{ services: ISurgery[], patientInfo: Patient; status: string; clhId: number | null, cluId: number | null; }>(
        {
            services: [],
            patientInfo: { age: "", document: "", documentType: "", fullName: "", id: 0, photo: "", },
            status: "",
            clhId: null,
            cluId: null,
        });

    function mapState(state: string): string {
        const stateMap: { [key: string]: string; } = {
            "preparation": "preparation",
            "in_preparation": "preparation",
            "in_surgery": "surgery",
            "surgery": "surgery",
            "in_recovery": "recovery",
            "recovery": "recovery",
        };
        return stateMap[state] || state;
    }
    const handleGoBack = (): void => navigate(-1);

    const finishEventFunction = async () => {
        if (!sectionsChanged) {
            console.error("sectionsChanged is undefined");
            return;
        }

        try {
            if (sheet === "surgery") {
                const data = sectionsChanged as SurgeonNotes;
                const response = await dispatch(finishSurgeryEvent({ ...data, clhId: services.clhId }));
                if (response.success) {
                    handleGoBack();
                    fireSuccessAlert(response.message, "Cerrado con éxito");
                }
                return sectionsChanged;
            } else {
                const data = sectionsChanged as SectionsChanged;
                const response = await dispatch(finishEvent(data));
                if (response.success) {
                    handleGoBack();
                }
                return sectionsChanged;


            }
        } catch (error) {
            console.error("Failed to finish event", error);
        }
    };


    const renderSurgeries = () => {
        return (
            <div className='row'>
                {services?.services.length > 0 ? services?.services.map((service, ind) => {
                    return (
                        <div className="col">
                            <SectionCard className="w-100 py-2 px-3" cardAidStyle key={ind}>
                                <div>
                                    <div className="text-primary fw-bold">{service.name}</div>
                                    <div className="text-secondary">{service.surgeon}</div>
                                </div>
                            </SectionCard>
                        </div>
                    );
                }) : null}
            </div>
        );

    };

    useEffect(() => {
        if (services.clhId && services.cluId && setDataTemplate)
            setDataTemplate({
                clhId: services.clhId,
                cluId: services.cluId,
            });

    }, [services.clhId, services.cluId]);


    return (
        <div className="d-flex flex-column w-100 h-100 surgeryBody">
            {
                location.state.data.appId &&
                <SurgeryHeader
                    appId={appId}
                    setServices={setServices}
                    mapState={mapState}
                    dataSurgery={location.state}
                />
            }
            <div className="d-flex h-100 overflow-hidden">
                <div className="d-flex flex-column w-100 h-100 overflow-hidden">
                    <div className="px-4 pt-3 pb-3">
                        {renderSurgeries()}
                    </div>
                    <div className="ps-4 pb-4 pt-1 h-100 flex-1" style={{ overflowY: "auto", paddingRight: "1.25rem" }}>
                        {children}
                    </div>
                    <ConsultationControls
                        sectionsChanged={sectionsChanged}
                        finishEvent={finishEventFunction}
                        tab={"surgery"}
                        appId={appId}
                        clhIdProp={services.clhId}
                        cluId={services.cluId || NaN}
                        stage={currentConsumption[sheet || ""]}
                        statusSurgery={mapState(services.status)} 
                        realStatusSurgery={services.status}
                    />
                </div>

                <History showClose={false} patientIdProp={location.state.data.patient.id}
                    patientAge={services.patientInfo.age}
                    patientName={services.patientInfo.fullName}
                    patientIdentification={services.patientInfo.document}
                    appId={appId}
                    defaultHistory="ophthalmology" allData />

            </div>

        </div>
    );
};

export default TemplateBodyDoctor;


