import { IconCirclePlus, IconCircleX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
//
import { useAppDispatch, useAppSelector } from "@/hooks";
import { getArticles, getDilatations } from "../preConsultation.actions";
//
import { Button, SectionCard, Select, TextField } from "@/components";
import { SelectOption } from "@/components/Select/select";
import { DilatationItem, DilatationRes } from "@/models";
import { Article } from "@/models/pre-consultation/generals";
import { fireSuccessAlert } from "@/utils";
import dayjs from "dayjs";
import { useDebouncedCallback } from "use-debounce";
import { saveDilatations } from "../preConsultation.actions";
import { AxiosError } from "axios";

interface Example {
    id?: number;
    quantity?: number;
    articleId?: number;
    articleName?: string;
    lotId?: number;
    lotName?: string;
    index: number;
    wmId?: number;
    time?: string;
    artDescription?: string;
}
interface DilatationProps {
    isDisabledForm: boolean;
    historySheet?: boolean;
    data?: DilatationRes;
}

export function Dilatation({ isDisabledForm, historySheet, data }: DilatationProps) {
    const dispatch = useAppDispatch();

    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const medicalFormId = useAppSelector(state => state.patientAttention.patientStatus.mcfId);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const [dilatations, setDilatations] = useState<Example[]>([]);
    const [isDilated, setIsDilated] = useState<boolean>(false);
    const [articles, setArticles] = useState<Article[]>([]);
    const [enabledQuestion, setEnableQuestions] = useState<{
        continueAttention: boolean,
        letDilatation: boolean,
    }>({
        continueAttention: false,
        letDilatation: false,
    });

    useEffect(() => {
        async function fetchData() {
            if (historySheet) {
                if (data) {
                    setEnableQuestions({
                        continueAttention: data.continueAttention,
                        letDilatation: data.letDilatation,
                    });
                    setIsDilated(data.isDilated);
                }
                if (data?.results?.length) {
                    const formattedData: Example[] = data.results?.map((item, index) => ({
                        id: item.id,
                        quantity: item.qty,
                        articleId: item.artId,
                        artDescription: item.artDescription,
                        lotId: item.lotId,
                        lotName: item.lotName,
                        index: index + 1,
                        wmId: item.wmId,
                        time: item.time
                    }));
                    setDilatations(formattedData);
                }

            } else {
                const data = await dispatch(getDilatations({
                    appId: appointmentId,
                    eaccount: accountId,
                    mcfId: medicalFormId,
                    userId: userId as number
                }));
                if (data) {
                    setEnableQuestions({
                        continueAttention: data.continueAttention,
                        letDilatation: data.letDilatation,
                    });
                    setIsDilated(data.isDilated);
                }
                if (data?.results?.length) {
                    const formattedData: Example[] = data.results?.map((item, index) => ({
                        id: item.id,
                        quantity: item.qty,
                        articleId: item.artId,
                        lotId: item.lotId,
                        lotName: item.lotName,
                        index: index + 1,
                        wmId: item.wmId,
                        time: item.time
                    }));
                    setDilatations(formattedData);
                }
            }
        }
        fetchData();
    }, [dispatch, accountId, appointmentId, medicalFormId, userId, historySheet, data]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const data = await dispatch(getArticles(appointmentId));
                setArticles(data as Article[]);
            }
        }
        fetchData();
    }, [dispatch, accountId, appointmentId, medicalFormId, userId]);

    const onAddNewDilatation = () => {
        setDilatations([...dilatations, {
            articleId: undefined,
            lotId: undefined,
            quantity: undefined,
            articleName: "",
            lotName: "",
            index: dilatations.length + 1,
            wmId: undefined,
            time: dayjs().format("HH:mm"),
        }]);
    };

    const onSubmitDilation = useDebouncedCallback((message?:boolean) => {
        onSubmitChanges(message);
    }, 1000);

    const onMarkDilated = useDebouncedCallback(async (message?: boolean, value?:boolean) => {
        const success = await onSubmitChanges(message);
        if (!success) {
            setIsDilated(!value);
        }
    }, 1000);

    const onSubmitChanges = async (message?: boolean) => {
        const formattedDilatations: DilatationItem[] = dilatations.map(dl => ({
            id: dl?.id,
            qty: dl.quantity as number,
            artId: dl.articleId as number,
            lotId: dl.lotId as number,
            wmId: dl.wmId as number,
            time: dl.time as string,
        }));
        try {

            const data = await dispatch(saveDilatations({
                appId: appointmentId,
                userId: userId as number,
                eaccount: accountId,
                mcfId: medicalFormId,
                dilations: formattedDilatations,
                isDilated
            }));

            if(data.success && message){
                fireSuccessAlert("Dilatación actualizada satisfactoriamente.");
            }
            return data.success;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                return error.response.data.success as boolean;
            }
            return false;
        }
    };

    const onChangeQuantity = (value: string, index: number, param?: string) => {
        const updateTemp = dilatations.map(item => {
            if (item.index === index) {
                if (param && param === "time") {
                    return {
                        ...item,
                        [param]: value,
                    };
                } else {
                    return {
                        ...item,
                        quantity: +value
                    };
                }
            } else {
                return item;
            }
        });


        const updated = updateTemp.sort((a, b) => {
            if (a?.time && b?.time) {
                if (a?.time < b?.time) {
                    return -1;
                }
                if (a.time > b.time) {
                    return 1;
                }
            }
            return 0;
        });

        setDilatations(updated);
    };

    const onChangeArticle = (option: SelectOption, index: number) => {
        const lot = articles.find(art => art.artId === option.value);

        const updated = dilatations.map(item => {
            if (item.index === index) {
                return {
                    ...item,
                    articleId: +option.value,
                    lotId: lot?.lotId,
                    lotName: lot?.lotName,
                    wmId: lot?.wmId
                };
            } else {
                return item;
            }
        });
        setDilatations(updated);
    };

    const onDeleteDilatation = (index: number) => {
        const newOptions = dilatations.filter(dil => dil.index !== index);
        setDilatations(newOptions);
        onSubmitDilation();
    };

    const renderQuestions = () => {
        return (
            <SectionCard className="mt-4">
                <h5 className="fw-bold text-secondary">Permisos de dilatación</h5>
                <hr />
                <div className="preconsultation-quest">
                    <span className="me-4 nowrap">¿El paciente requiere de dilatación?</span>
                    <div className="form-check form-check-inline">
                        <input
                            disabled
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="optionYes"
                            checked={enabledQuestion.letDilatation ? true : false}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">Sí</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            disabled
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="optionNo"
                            checked={!enabledQuestion.letDilatation ? true : false}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                    </div>
                </div>

                {/* <div className="preconsultation-quest">
                    <span className="me-4 nowrap">¿Médico continúa la consulta?</span>
                    <div className="form-check form-check-inline">
                        <input
                            disabled
                            className="form-check-input"
                            type="radio"
                            name="aaaaa"
                            id="optionYes"
                            checked={enabledQuestion.continueAttention ? true : false}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">Sí</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            disabled
                            className="form-check-input"
                            type="radio"
                            name="aaaaa"
                            id="optionNo"
                            checked={!enabledQuestion.continueAttention ? true : false}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                    </div>
                </div> */}
            </SectionCard>
        );
    };

    const renderDilatationItem = (dilatation: Example) => {
        const formattedArticles = articles.map(article => ({
            label: article.artDescription,
            value: article.artId
        }));

        return (
            <div key={dilatation.id || `key-${dilatation.index}`} className="preconsultation-quest mt-4">
                <span className="text-secondary fw-bold nowrap me-2">Dilatación {dilatation.index}</span>
                <div className="ms-4" style={{ width: 150 }}>
                    <label className="text-muted">Hora</label>
                    <TextField
                        variant="plain"
                        disabled={isDisabledForm || isDilated}
                        value={dilatation.time as string}
                        onChange={({ target }) => {
                            onChangeQuantity(target.value, dilatation.index, "time");

                        }}
                        type="time"
                    />
                </div>
                <div className="ms-4" style={{ width: 120 }}>
                    <label className="text-muted">No. gotas</label>
                    <TextField
                        variant="plain"
                        placeholder="No. Gotas"
                        disabled={isDisabledForm || isDilated}

                        value={Number(dilatation.quantity).toString()}
                        onChange={({ target }) => {
                            onChangeQuantity(target.value, dilatation.index);
                        }}
                        type="number"
                        min={0}
                        onKeyDown={(event) => {
                            if (event.key === "-") {
                                event.preventDefault();
                            }
                        }}
                    />
                </div>
                <div className="ms-4 w-100">
                    <label className="text-muted">Medicamento</label>
                    <Select
                        variant="plain"
                        changeToInput={historySheet}
                        inputValue={dilatation.artDescription}
                        disabled={isDisabledForm || isDilated}
                        placeholder="Seleccionar artículo"
                        options={formattedArticles}
                        emptyOptionsLabel="No hay artículos disponibles"
                        value={formattedArticles.find(item => item.value === dilatation.articleId)}
                        name={`article-${dilatation.index}`}
                        onChange={({ option }) => {
                            onChangeArticle(option, dilatation.index);
                        }}
                    />
                </div>
                <div className="ms-4" style={{ width: 300 }}>
                    <label className="text-muted">Lote</label>
                    <TextField
                        variant="plain"
                        placeholder="Lote"
                        disabled={isDisabledForm || isDilated}
                        value={dilatation.lotName}
                        readOnly
                    />
                </div>
                <div className="ms-4">
                    {!isDisabledForm && !isDilated &&
                        <IconCircleX
                            className="text-primary pointer"
                            size={20}
                            onClick={() => {
                                onDeleteDilatation(dilatation.index);
                            }
                            }
                        />
                    }
                </div>
            </div>
        );
    };

    const isDisabledDilatedCheck = dilatations?.some(
        (item) =>
            !item.quantity ||
        !item.articleId ||
        !item.lotId ||
        !item.lotName ||
        !item.time
    );

    const render = () => {
        return (
            <>
                {renderQuestions()}
                <SectionCard className="mt-3 p-4"
                    backgroundColor={`${!enabledQuestion.letDilatation ? "#ccc2" : ""}`}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Registro de dilatación</h5>
                        <div className="d-flex gap-2">
                            <div className="form-check form-switch d-flex align-items-center justify-content-center p-0">
                                <small className="form-check-label textGray100 me-2">Paciente dilatado</small>
                                <input
                                    checked={isDilated}
                                    disabled={isDisabledDilatedCheck || isDisabledForm}
                                    className="form-check-input m-0"
                                    type="checkbox"
                                    role="switch"
                                    style={{ minWidth: 25, minHeight: 15,cursor: "pointer" }}
                                    onChange={({ target }) => {
                                        setIsDilated(target.checked);
                                        onMarkDilated(false,target.checked);
                                    }}
                                />
                            </div>
                            <Button
                                variant="text"
                                className="align-self-end"
                                startIcon={<IconCirclePlus />}
                                onClick={onAddNewDilatation}
                                disabled={isDisabledForm || !enabledQuestion.letDilatation ||isDilated}
                            >
                                Agregar registro
                            </Button>
                        </div>
                    </div>
                    <hr />
                    {dilatations.map((dilatation) => renderDilatationItem(dilatation))}
                    {dilatations.length > 0 &&
                    <div className="d-flez justify-content-end align-self-end py-4">
                        <Button
                            disabled={isDilated}
                            variant="outline"
                            onClick={()=>onSubmitDilation(true)}
                        >
                            Guardar
                        </Button>
                    </div>

                    }
                </SectionCard>
            </>
        );
    };

    return render();
}