import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { OrthopticParams, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getOrthopticService, saveOrthopticService } from "@/services/sheets/orthoptic";
import * as generalServices from "@/services/select/generals";

export function getOrthoptic(params: OrthopticParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getOrthopticService(params);
            if (response.results) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function saveOrthoptic(payload: OrthopticPayload) {
    return async function () {
        await saveOrthopticService(payload);
    };
}

export async function getLensAdd() {
    const lensAddResponse = await generalServices.getLensAdd();
    return lensAddResponse.results;
}

export async function getLensAV() {
    const lensAVResponse = await generalServices.getFarVisionList();
    return lensAVResponse.results;
}