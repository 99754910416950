import React from "react";
import "./SectionCard.scss";

interface SectionCardInterface extends React.HTMLAttributes<HTMLElement> {
    cardAidStyle?: boolean;
    height?: string;
    backgroundColor?: string;
    opacity?: number;
}

export function SectionCard({
    children,
    className,
    height,
    opacity,
    cardAidStyle = false,
    backgroundColor,
    ...rest
}: SectionCardInterface) {

    return (
        <div className={`${cardAidStyle ? "section-card-aids" : "section-card"}  ${className}`} {...rest}
            style={{
                height: height ?? "auto", backgroundColor: backgroundColor ?? "", opacity: opacity ?? 1,
                overflowY: height ? "auto" : "unset", overflowX: height ? "hidden" : "unset"
            }}
        >
            {children}
        </div>
    );
}