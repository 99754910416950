import axios from "axios";
//
import { fireErrorAlert } from "@/utils";
import { statusCodes } from "@/constants/http";
import { FindingsParams } from "@/models/select/findings";
import { IResponse } from "@/models";
import { AppDispatch } from "@/config/store";
//
import { getFindingsService } from "@/services";
// import { finishLoading, startLoading } from "@/config/slices";

export function getFindings(params: FindingsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await getFindingsService(params);
            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error("There was an error =>", error);
        } finally {
            // dispatch(finishLoading());
        }
    };
}