import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";

import { hasData, parseSearchParams } from "@/utils";
import { clearAuth, createAuth, finishLoading, startLoading } from "@/config/slices";
import { useAppDispatch } from "@/hooks";
import { AuthUserData } from "@/models";
import { verifyToken } from "@/services";
import { statusCodes } from "@/constants";
import { getAccountsByUser, saveAccount } from "@/features/Workspace/Home/home.actions";
import routes from "@/constants/routes";
import { getCategory } from "@/features/Workspace/Sidebar/sidebar.actions";
import { setCategorySlice } from "@/config/slices/category";

export interface IDataBiowel {
    idModule: number;
    idAccount: number;
    idProfile: number;
    token: string;
}

export default function ValidateAuth() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const parsed = useMemo(() => {
        return parseSearchParams<IDataBiowel>(location.search);
    }, [location.search]);

    const onClearAuth = useCallback(() => {
        dispatch(clearAuth());
        navigate("/");
    }, [dispatch, navigate]);

    useEffect(() => {
        (async function () {
            if (
                hasData(parsed.idAccount) &&
                hasData(parsed.idModule) &&
                hasData(parsed.idProfile) &&
                hasData(parsed.token)
            ) {
                dispatch(startLoading());
                localStorage.clear();

                const decodedToken = jwtDecode<JwtPayload & { user_data: AuthUserData; }>(parsed.token);
                localStorage.setItem("x_token", parsed.token as string);

                const response = await verifyToken();

                if (response.status === statusCodes.OK) {
                    localStorage.setItem("user_data", JSON.stringify(decodedToken.user_data));

                    const accounts = await dispatch(getAccountsByUser(`${decodedToken.user_data.id}`)) || [];

                    const currentAccount = accounts.find(acc => acc.id === Number(parsed.idAccount));
                    if (currentAccount) {
                        const currentProfile = currentAccount.profile.find(prof => prof.id === Number(parsed.idProfile));

                        const data = await dispatch(getCategory(parsed.idProfile));
                        if (data) {
                            dispatch(setCategorySlice(data ?? {}));
                            localStorage.setItem("category", JSON.stringify(data));
                        }

                        if (currentProfile) dispatch(saveAccount({ ...currentAccount, profile: currentProfile }));
                    }

                    dispatch(createAuth({
                        access_token: parsed.token,
                        is_auth: true,
                        message: "Autenticaión correcta",
                        status: 200,
                        user_data: decodedToken.user_data,
                    }));
                    navigate(routes.workspace);
                }
                else {
                    onClearAuth();
                }
            } else {
                onClearAuth();
            }
            dispatch(finishLoading());
        })();
    }, [dispatch, navigate, parsed, onClearAuth]);

    return <div className="w-100 vh-100"></div>;
}
