import { SectionCard, Select } from "@/components";
import { CommonSelect, OrthopticPayload } from "@/models/sheets/orthoptic";
import { getCycleMinService, getFlexibilityService } from "@/services";
import { useEffect, useState } from "react";

interface FlexibilityParams {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
    historySheet?: boolean;
}

export default function Flexibility({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm, historySheet }: FlexibilityParams) {
    const [flexibility, setFlexibility] = useState<CommonSelect[]>([]);
    const [cycleMin, setCycleMin] = useState<CommonSelect[]>([]);

    useEffect(() => {
        async function fetchData() {
            if (!historySheet) {
                const dataF = await getFlexibilityService();
                const dataC = await getCycleMinService();

                setFlexibility(dataF.results);
                setCycleMin(dataC.results);
            }
        }
        fetchData();
    }, [historySheet]);

    const onChangeFlexibility = (flex: "cymId" | "flexibilityIdOd" | "flexibilityIdOi", value: string) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            accommodationFlexibility: {
                ...orthopticPayload.accommodationFlexibility,
                [flex]: value
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        const flexibilityFormatted = flexibility.map(item => ({ label: item.description, value: item.id }));
        const flexibilityOdValue = flexibilityFormatted.find(item => item.value === orthopticPayload.accommodationFlexibility?.flexibilityIdOd);
        const flexibilityOdValueInput = orthopticPayload.accommodationFlexibility?.od;
        const flexibilityOiValue = flexibilityFormatted.find(item => item.value === orthopticPayload.accommodationFlexibility?.flexibilityIdOi);
        const flexibilityOiValueInput = orthopticPayload.accommodationFlexibility?.oi;

        const cycleMinFormatted = cycleMin.map(item => ({ label: item.description, value: item.id }));
        const cycleMinValue = cycleMinFormatted.find(item => item.value === orthopticPayload.accommodationFlexibility?.cymId);
        const cycleMinValueInput = orthopticPayload.accommodationFlexibility?.cym;

        return (
            <SectionCard className="h-100">
                <h5 className="text-secondary fw-bold align-self-start">
                    Flexibilidad de acomodación
                </h5>
                <hr className="text-primary mt-2 mb-4" />
                <div className="d-flex align-items-center p-2 bg-od" style={{ borderRadius: "0.5rem 0.5rem 0rem 0rem" }}>
                    <span className="text-secondary" style={{ width: 100 }}>
                        OD
                    </span>
                    <Select
                        disabled={isDisabledForm}
                        changeToInput={historySheet}
                        inputValue={flexibilityOdValueInput}
                        width="100%"
                        style={{ width: "100%" }}
                        options={flexibilityFormatted}
                        onChange={({ option }) => onChangeFlexibility("flexibilityIdOd", option.value)}
                        value={flexibilityOdValue}
                    />
                </div>
                <div className="d-flex align-items-center p-2 mb-3 bg-oi" style={{ borderRadius: "0rem 0rem 0.5rem 0.5rem" }}>
                    <span className="text-secondary" style={{ width: 100 }}>
                        OI
                    </span>
                    <Select
                        disabled={isDisabledForm}
                        changeToInput={historySheet}
                        inputValue={flexibilityOiValueInput}
                        width="100%"
                        style={{ width: "100%" }}
                        options={flexibilityFormatted}
                        onChange={({ option }) => onChangeFlexibility("flexibilityIdOi", option.value)}
                        value={flexibilityOiValue}
                    />
                </div>
                <div className="d-flex align-items-center mb-3 px-2">
                    <span className="text-secondary " style={{ width: 100 }}>
                        Ciclos/min
                    </span>
                    <Select
                        disabled={isDisabledForm}
                        changeToInput={historySheet}
                        inputValue={cycleMinValueInput}
                        width="100%"
                        style={{ width: "100%" }}
                        options={cycleMinFormatted}
                        maxHeight={100}
                        onChange={({ option }) => onChangeFlexibility("cymId", option.value)}
                        value={cycleMinValue}
                    />
                </div>
            </SectionCard>
        );
    };

    return render();
}