import { axiosInstance } from "@/config/axios";
import { IGetVisionShootResponse, IVisionShootParams } from "@/models";
import { setHeaders } from "@/utils";

export const getVisionShootService = async (params?: IVisionShootParams): Promise<IGetVisionShootResponse> => {
    const headers = setHeaders();
    const axiosParams = { headers, params };
    const { data } = await axiosInstance.get<IGetVisionShootResponse>("/medical/generals/visionShoot/", axiosParams);
    return data;
};
