




const SocketsNotification = () => {

    // const user = useAppSelector(state => state.auth.user_data.id);
    // const socket = io(`${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE}`, {
    //     reconnectionDelayMax: 10000,
    //     extraHeaders: {
    //         token: useAppSelector(state => state.auth.access_token) ?? ""
    //     }
    //     // query: {
    //     //     "user": useAppSelector(state => state.auth.user_data.id)
    //     // }
    // });


    // useEffect(() => {

    //     socket.on("connect", () => {
    //         // Manejo adicional del evento 'connect' aquí
    //     });



    //     socket.on("message", (res: { id: string; }) => {
    //         // Manejo adicional del evento 'message' aquí
    //     });
    //     socket.io.on("error", (error) => {
    //         // ...
    //     });

    //     // Emitir un evento personalizado con el ID de usuario 

    // }, []);

    return (
        <div></div>
    );
};

export default SocketsNotification;