
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    canAttentionEnd: false
};

export const canAttentionEndSlice = createSlice({
    name: "canAttentionEnd",
    initialState,
    reducers: {
        setAttentionEnd: (state, action: PayloadAction<boolean>) => {
            state.canAttentionEnd = action.payload;
        }
    }
});

export const { setAttentionEnd } = canAttentionEndSlice.actions;