import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import {
    DIListParams,
    DIListResponse,
    DIPayload,
    DISelectParams,
    DISelectResponse,
    DIStatusResponse,
    DeleteDIPayload,
    EyesListResponse,
    UpdateDIPayload
} from "@/models/select/diagnosticImpression";
import { setHeaders } from "@/utils";

export async function deleteDIListService(payload: DeleteDIPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, data: payload };

    const { data: serviceData } = await axiosInstance.delete<IResponse>(
        "/medical/clinicHistory/gridCie10ExtendedDiagnostic/", axiosParams
    );
    return serviceData;
}

export async function getDIListService(params: DIListParams): Promise<DIListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DIListResponse>(
        "/medical/clinicHistory/gridCie10ExtendedDiagnostic/", axiosParams
    );
    return serviceData;
}

export async function updateDIService(payload: UpdateDIPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/gridCie10ExtendedDiagnostic/", payload, axiosParams
    );
    return serviceData;
}

export async function getDISelectService(params: DISelectParams): Promise<DISelectResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DISelectResponse>(
        "/medical/clinicHistory/cie10ExtendedDiagnostic/", axiosParams
    );
    return serviceData;
}

export async function getBodyOrgansService(): Promise<EyesListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<EyesListResponse>(
        "/medical/telemedicine/bodyOrgans/", axiosParams
    );
    return serviceData;
}

export async function createDIService(payload: DIPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/cie10ExtendedDiagnostic/", payload, axiosParams
    );
    return serviceData;
}

export async function getDIStatusService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<DIStatusResponse>(
        "/medical/status/cliHisCie10/", axiosParams
    );
    return serviceData;
}