interface IIconClose {
    height?: number;
    width?: number;
    className?: string;
}

export const IconClose = ({ height = 13, width = 13, className }: IIconClose) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.70453 6.86527L11.9167 2.11422C12.0796 1.93023 12.1711 1.68083 12.1711 1.42081C12.1711 1.16079 12.0796 0.911398 11.9167 0.7274L11.526 0.286672C11.4454 0.195788 11.3498 0.123691 11.2445 0.0745037C11.1393 0.0253164 11.0265 0 10.9125 0C10.7986 0 10.6858 0.0253164 10.5805 0.0745037C10.4753 0.123691 10.3796 0.195788 10.2991 0.286672L6.08687 5.03772L1.87205 0.286672C1.79149 0.195788 1.69585 0.123691 1.5906 0.0745037C1.48534 0.0253164 1.37252 0 1.25858 0C1.14465 0 1.03183 0.0253164 0.926574 0.0745037C0.821315 0.123691 0.725677 0.195788 0.645124 0.286672L0.254382 0.7274C0.0914916 0.911398 0 1.16079 0 1.42081C0 1.68083 0.0914916 1.93023 0.254382 2.11422L4.46397 6.86527L0.254382 11.6134C0.0914916 11.7974 0 12.0468 0 12.3068C0 12.5668 0.0914916 12.8162 0.254382 13.0002L0.645124 13.4409C0.80892 13.6232 1.02977 13.7254 1.25989 13.7254C1.49 13.7254 1.71086 13.6232 1.87465 13.4409L6.08426 8.69282L10.2965 13.4409C10.4598 13.623 10.6802 13.7252 10.9099 13.7252C11.1396 13.7252 11.36 13.623 11.5234 13.4409L11.9141 13.0002C12.077 12.8162 12.1685 12.5668 12.1685 12.3068C12.1685 12.0468 12.077 11.7974 11.9141 11.6134L7.70453 6.86527Z" fill="#CECECE" />
        </svg>
    );
};