import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import {
    DrugRouteAdmResponse,
    ExternalMedicinePayload,
    ExternalMedicineResponse,
    NewMedicinePayload,
    OrdermeasureResponse,
    ReconciliationMedicineParams,
    ReconciliationMedicineResponse,
    SingleMedicineResponse
} from "@/models/pre-consultation/medicine";
import { setHeaders } from "@/utils";

export async function getExternalMedicineService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<ExternalMedicineResponse>(
        "/medical/clinicHistory/externalMedicine/", axiosParams
    );
    return serviceData;
}

export async function getOrderMeasureService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<OrdermeasureResponse>(
        "/medical/generals/orderMeasure/", axiosParams
    );
    return serviceData;
}

export async function getDrugRouteAdministrationService() {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<DrugRouteAdmResponse>(
        "/medical/generals/drugRouteAdministsration/", axiosParams
    );
    return serviceData;
}

export async function createMedicineService(payload: NewMedicinePayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/externalMedicine/", payload, axiosParams
    );
    return serviceData;
}

export async function saveExternalMedicineService(payload: ExternalMedicinePayload) {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/medReconciliationExtMedicine/", payload, axiosParams
    );
    return serviceData;
}

export async function getReconciliationMedicineService(params: ReconciliationMedicineParams) {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<ReconciliationMedicineResponse>(
        "/medical/clinicHistory/medicationReconciliation/", axiosParams
    );
    return serviceData;
}

export async function getOneMedicineService(medicineId: number) {
    const headers = setHeaders();
    const axiosParams = { headers, params: { id: medicineId } };

    const { data: serviceData } = await axiosInstance.get<SingleMedicineResponse>(
        "/medical/clinicHistory/medReconciliationExtMedicine/", axiosParams
    );
    return serviceData;
}