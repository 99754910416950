import { IButton } from "./button";
import "./Button.scss";

export function Button({
    children,
    className,
    variant,
    startIcon,
    endIcon,
    size = "small",
    isLoading,
    ...rest
}: IButton) {

    return (
        <button
            className={`button ${size && `button-${size}`} ${variant && `button-${variant}`} ${className} ${isLoading ? "isLoading" : ""} `}
            {...rest}
        >
            {startIcon && (
                <span className="me-2">
                    {startIcon}
                </span>
            )}
            <span>{children}</span>
            {endIcon && (
                <span className="ms-2">
                    {endIcon}
                </span>
            )}
        </button>
    );
}