import { Button, Modal, TextField } from "@/components";
import { IModal } from "@/components/Modal/modal";
import routes from "@/constants/routes";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { ITabs } from "@/models/surgery/patientAttention/patientAttention";
import { fireSuccessAlert, fireWarningAlert } from "@/utils";
import { IconLock } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendAuthCode, translateSurgeryAttention } from "../patientAttentionSurgery.action";



export default function Auth({ onSuccess, isStageTrue, setAuthValidation, handleGenericFunction, appId, stage, setReloadDataEvidence, reloadDataEvidence, details, authValidation, ...rest }: IModal & {
    authValidation: { isOpen: boolean, prefix: string; };
    details?: boolean, reloadDataEvidence?: number, setReloadDataEvidence?: (data: number) => void;
    stage?: ITabs;
    isStageTrue: boolean;
    setAuthValidation?: (data: string) => void;
    handleGenericFunction: (data?: string) => Promise<boolean>;
}) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const eaccount = useAppSelector(state => state.workspace.id);
    const [data, setData] = useState<string>("");


    const translateAttentionFunction = () => {
        async function fetchData() {
            if (appId && stage) {
                const data = await dispatch(translateSurgeryAttention({ appId, eaccount, stagePrefix: stage?.value }));
                if (data.success) {
                    if (stage.value === "recovery") {
                        fireSuccessAlert("La atención del paciente ha sido exitosa", "Egreso exitoso", () => navigate(routes.workspaceSurgery)
                        );
                    } else {
                        navigate(routes.workspaceSurgery);

                    }

                }
            }
        }
        fetchData();
    };

    const handleSubmit = async () => {
        if (data === "") {
            fireWarningAlert("Ingrese el código de autenticación", "Intenta de nuevo");
        } else {
            if (authValidation.prefix === "transfer") {
                const res = await handleGenericFunction(data);
                if (res) {
                    rest.onClose();
                    setData("");
                }
            } else if (appId && data && stage) {
                const response = await dispatch(sendAuthCode({ appId, "code": Number(data), stgPrefix: stage?.value }));
                if (response.success) {
                    if (authValidation.prefix === "controls") {
                        if (isStageTrue) {
                            translateAttentionFunction();
                        } else {
                            const res = await handleGenericFunction();
                            if (res) {
                                rest.onClose();
                                setData("");
                            }
                        }
                    }
                    else {
                        navigate(-1);
                    }
                }
            }
        }
    };



    return (
        <Modal {...rest} width={500} positionModal="center" onClose={() => {
            rest.onClose();
            setData("");

        }}>
            <div className="p-4">
                <h1 className="text-primary fw-bold text-center ">Verificación</h1>
                <h4 className="text-muted text-center py-3">Por favor, ingrese su código de autenticación</h4>
                <div className="px-3 d-flex flex-column ">
                    <div>
                        <TextField
                            value={data}
                            type="password"
                            autoFocus
                            onChange={({ target }) => {
                                setData((target.value));
                            }}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    handleSubmit();
                                }
                            }}
                            endIcon={<IconLock />}
                        />

                    </div>

                </div>

                <div className="pt-4">

                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="outline" size="large" className="py-2 me-3" onClick={() => {
                            rest.onClose();
                            setData("");

                        }}
                        >
                            Cancelar
                        </Button>
                        <Button variant="primary" size="large" className="py-2" onClick={handleSubmit}
                        >
                            Validar
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}




