import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import { ITable, TablePaginationProps } from "./table";
import "./Table.scss";

export function Table({ children, isCompact = false, className = "" }: ITable) {
    return <table className={`table-wrapper table-wrapper-primary ${className} ${isCompact ? "compact" : ""}`}>{children}</table>;
}

export function TablePagination(props: TablePaginationProps) {

    const onNextPage = (): void => {
        if (props.currentPage < Math.ceil(props.totalPages / props.perPage || 0)) {
            const newPage = props.currentPage + 1;
            props.onNextPage(newPage);
        }
    };

    const onPrevPage = (): void => {
        if (props.currentPage > 1) {
            const newPage = props.currentPage - 1;
            props.onNextPage(newPage);
        }
    };

    const onMaxPage = (): void => { 
        if (props.totalPages !== 0 && props.totalPages !== undefined){
            props.onMaxPage(Math.ceil(props.totalPages/ props.perPage || 0)); 
        } else{
            props.onMaxPage(1); 
        }

    };

    const onMinPage = (): void => props.onMinPage(1);


    return (
        <div className="table-pagination">
            <div className="table-pagination__total">
                Pag. {props.currentPage} de{" "}
                {Math.ceil(props.totalPages / props.perPage || 0) === 0
                    ? 1
                    : Math.ceil(props.totalPages / props.perPage || 0)} {`(${props.totalPages} encontrados)`}
            </div>
            <div className="table-pagination__controls">
                <IconChevronsLeft className="pointer no-user-select" onClick={() => onMinPage()} />
                <IconChevronLeft className="pointer no-user-select" onClick={() => onPrevPage()} />
                <span className="number">
                    {props.currentPage} / {Math.ceil(props.totalPages / props.perPage || 0) === 0
                        ? 1
                        : Math.ceil(props.totalPages / props.perPage || 0)}
                </span>
                <IconChevronRight className="pointer no-user-select" onClick={() => onNextPage()} />
                <IconChevronsRight className="pointer no-user-select" onClick={() => onMaxPage()} />
            </div>
        </div>
    );
}