import axios from "axios";
//
import * as uiActions from "@/config/slices";
//
import { AppDispatch } from "@/config/store";
import { ConfigurationParams, IResponse } from "@/models";
import { statusCodes } from "@/constants/http";
//
import { fireErrorAlert } from "@/utils";
import { getConfigurationService } from "@/services";

export function getConfiguration(params: ConfigurationParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());

            const filters = { ...params };
            const response = await getConfigurationService(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
            console.error(error);

        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}