import { axiosInstance } from "@/config/axios";
import { CategoryResponse } from "@/models/sidebar";
import { setHeaders } from "@/utils";

export async function getCategoryService(id_profile: number): Promise<CategoryResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params: {id_profile} };


    const { data: serviceData } = await axiosInstance.get<CategoryResponse>(
        "/security/permission/category/", axiosParams
    );
    return serviceData;
}