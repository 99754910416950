import { RadioButton, SectionCard } from "@/components";
import SightTest from "../SightTest/SightTest";
import { OrthopticPayload, SightConfig } from "@/models/sheets/orthoptic";

interface IWhiteTest {
    orthopticPayload: OrthopticPayload;
    setOrthopticPayload: (bielschowskyPayload: OrthopticPayload) => void;
    onSubmit: () => void;
    isDisabledForm: boolean;
}

export default function WhiteTest({ orthopticPayload, setOrthopticPayload, onSubmit, isDisabledForm }: IWhiteTest) {

    const onChangeCorrection = (corrected: boolean | null) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            whiteTest: {
                ...orthopticPayload.whiteTest,
                correction: corrected
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const onChangeSightTest = (result: { vl?: SightConfig; vp?: SightConfig; }) => {
        const options: OrthopticPayload = {
            ...orthopticPayload,
            whiteTest: {
                ...orthopticPayload.whiteTest,
                ...result
            }
        };
        setOrthopticPayload(options);
        onSubmit();
    };

    const render = () => {
        return (
            <SectionCard>
                <div className="d-flex align-items-center">
                    <h5 className="flex-grow-1 text-secondary fw-bold">
                        White test
                    </h5>
                    <div className="d-flex align-items-center">
                        <RadioButton
                            disabled={isDisabledForm}
                            className="me-5 fs-5"
                            label="Sin corrección"
                            name="correctionWT"
                            id="withNoCorrectionWT"
                            onClick={() => {
                                if (orthopticPayload.whiteTest?.correction === false) {
                                    onChangeCorrection(null);
                                } else {
                                    onChangeCorrection(false);
                                }
                            }}
                            checked={orthopticPayload?.whiteTest?.correction === false}
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            className="fs-5"
                            label="Con corrección"
                            name="correctionWT"
                            id="withCorrectionWT"
                            onClick={() => {
                                if (orthopticPayload.whiteTest?.correction === true) {
                                    onChangeCorrection(null);
                                } else {
                                    onChangeCorrection(true);
                                }
                            }}
                            checked={orthopticPayload?.whiteTest?.correction === true}
                        />
                    </div>
                </div>
                <hr className="text-primary" />
                <div className="row">
                    <div className="col-lg-6">
                        <SightTest
                            isDisabledForm={isDisabledForm}
                            type="vl"
                            onGetResult={(result) => onChangeSightTest(result)}
                            currentData={orthopticPayload.whiteTest?.vl}
                            isPrism
                        />
                    </div>
                    <div className="col-lg-6">
                        <SightTest
                            isDisabledForm={isDisabledForm}
                            type="vp"
                            onGetResult={(result) => onChangeSightTest(result)}
                            currentData={orthopticPayload.whiteTest?.vp}
                            isPrism
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}