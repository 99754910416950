import { forwardRef } from "react";
//
import { ITextField } from "./textField";
import "./TextField.scss";

export const TextField = forwardRef<HTMLInputElement, ITextField>(
    function TextField({ className, startIcon, endIcon, variant = "default", ...rest }, ref) {

        return (
            <div
                className={`${rest.disabled ? "textfield-disabled" : ""} textfield ${className} textfield-${variant}`}
            >
                {startIcon ? <span className="ms-2" style={{ pointerEvents: "none" }}>{startIcon}</span> : null}
                <input {...rest} ref={ref} />
                {endIcon ? <span className="me-2" >{endIcon}</span> : null}
            </div>
        );
    });