import { useAppSelector } from "@/hooks";
import { useEffect, useState } from "react";
//
import { AccommodationFlexibility, Bielschowsky, BreadthAccommodation, ChromaticVision, CommonSelect, Fixation, FunctionalOptometryFinalFormula, FusionalBreadths, SightTest as ISightTest, KappaAngle, Laterality, NextConvergencePoint, OrthopticPayload, SensoryCorrespondence, Squints, Stereopsis, Versions, VisualAcuity } from "@/models/sheets/orthoptic";
import { formatHistoryDetails } from "@/utils";
import SightTest from "../../../Orthoptic/SightTest/SightTest";
import Version from "../../../Orthoptic/Versions/Version/Version";
//
import { Segments } from "@/models/generalFields";
import { DIListItem } from "@/models/select/diagnosticImpression";
import "../Details.scss";

export default function OrthopticDetails({ orthopticDetails, forDownload , dataVersionList, degreesData, squintsData  }: { 
    orthopticDetails: OrthopticPayload; forDownload?: boolean; 
    dataVersionList?: CommonSelect[]
    squintsData?: Squints[];
    degreesData?: CommonSelect[]
}) {

    const appointmentId = useAppSelector(state => state.patientAttention.appointmentSelected.app_id);
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);

    const [orthopticPayload, setOrthopticPayload] = useState<OrthopticPayload>({
        appId: appointmentId,
        eaccount: accountId,
        userId: userId as number
    });
    const [versionsState, setVersionsState] = useState<CommonSelect[]>([]); 
    useEffect(() => {
        if (dataVersionList) {
            setVersionsState(dataVersionList);
        }
    }, [dataVersionList]);
    

    const renderAttentionOrigin = (attentionOrigin: Segments | null) => {

        const motiveIss = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "current_disease") : {predifinedText: []};
        const motiveReason = attentionOrigin?.predefinedTxts ? attentionOrigin?.predefinedTxts?.find(t=> t.prefix === "reason_attention") : { predifinedText: []};


        if (attentionOrigin) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Origen de atención
                    </h4>
                    {attentionOrigin?.generalFields?.attentionOrigin !== null ? (
                        <h5>
                            <span className="fw-bold">Origen de la atención: </span>{" "}
                            {attentionOrigin?.generalFields?.attentionOrigin?.name}
                        </h5>
                    ) : ""}
                    {attentionOrigin.generalFields.abserveEvent !== null ? (
                        <h5>
                            <span className="fw-bold">Evento adverso: </span>{" "}
                            {attentionOrigin.generalFields.abserveEvent === 1 ? "SÍ" : "NO"}
                        </h5>
                    ) : ""}

                    <>
                        <h5 className="fw-bold mt-3">
                                Motivo consulta:
                        </h5> 
                        <div style={{ whiteSpace: "pre-line" }}>{motiveReason?.predifinedText.map(x=> x.name).join(", ")}</div>
                            
                        <div style={{ whiteSpace: "pre-line" }}>{attentionOrigin.reasonForConsultingJst}</div>
                    </>
                    <>
                        <h5 className="fw-bold mt-3">
                                Enfermedad actual:
                        </h5>

                        <div style={{ whiteSpace: "pre-line" }}>{motiveIss?.predifinedText.map(x=> x.name).join(", ")}</div>
                        <div style={{ whiteSpace: "pre-line" }}>{attentionOrigin.currentDiseaseJst || ""}</div>
                    </>
                    {attentionOrigin.generalFields.evolutionTime ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>{" "}
                            {attentionOrigin.generalFields.evolutionTime} - {attentionOrigin.generalFields.evolutionTimeUnit?.name}
                        </h5>
                    ) : ""}
                </div>
            );
        } else {
            return "";
        }
    };

    const renderVisualAcuity = (visualAcuity: VisualAcuity | null) => {
        if (visualAcuity) {
            <div className="item">
                <h4 className="text-primary fw-bold mb-3">
                    Agudeza visual
                </h4>
                <h5>
                    {visualAcuity.optotype ? (
                        <>
                            <span className="fw-bold">Optotipo: </span>{" "}
                            {visualAcuity.optotype}
                        </>
                    ) : ""}
                    {visualAcuity.optotype && visualAcuity.clasification ? " - " : ""}
                    {visualAcuity.clasification ? (
                        <>
                            <span className="fw-bold">Clasificación: </span>{" "}
                            {visualAcuity.clasification}
                        </>
                    ) : ""}
                </h5>
                {visualAcuity.noCorrection ? (
                    <>
                        <h5 className="fw-bold mt-3">
                            AV sin corrección
                        </h5>
                        <h5>
                            {visualAcuity.noCorrection.od ? (
                                <>
                                    <span className="fw-bold">AV OD </span>{" "}
                                    <span className="">Visión Lejana: {visualAcuity?.noCorrection?.od?.far?.value} </span>{" "}
                                    <span className="">Visión Cercana: {visualAcuity?.noCorrection?.od?.near?.value} </span>{" "}
                                </>
                            ) : ""}
                            <br />
                            {visualAcuity.noCorrection.oi ? (
                                <>
                                    <span className="fw-bold">AV OI </span>{" "}
                                    <span className="">Visión Lejana: {visualAcuity?.noCorrection?.oi?.far?.value} </span>{" "}
                                    <span className="">Visión Cercana: {visualAcuity?.noCorrection?.oi?.near?.value} </span>{" "}
                                </>
                            ) : ""}
                            <br />

                            {visualAcuity.noCorrection.ao ? (
                                <>
                                    <span className="fw-bold">AV AO: </span>{" "}
                                    <span className="">Visión Lejana: {visualAcuity?.noCorrection?.ao?.far?.value} </span>{" "}
                                    <span className="">Visión Cercana: {visualAcuity?.noCorrection?.ao?.near?.value} </span>{" "}
                                </>
                            ) : ""}
                        </h5>
                    </>
                ) : ""}
                {visualAcuity.correction ? (
                    <>
                        <h5 className="fw-bold mt-3">
                            AV con corrección
                        </h5>
                        <h5>
                            {visualAcuity.correction.od ? (
                                <>
                                    <span className="fw-bold">AV OD: </span>{" "}
                                    <span className="">Visión Lejana: {visualAcuity?.correction?.od?.far?.value} </span>{" "}
                                    <span className="">Visión Cercana: {visualAcuity?.correction?.od?.near?.value} </span>{" "}
                                </>
                            ) : ""}                                 <br />

                            {visualAcuity.correction.oi ? (
                                <>
                                    <span className="fw-bold">AV OI: </span>{" "}
                                    <span className="">Visión Lejana: {visualAcuity?.correction?.oi?.far?.value} </span>{" "}
                                    <span className="">Visión Cercana: {visualAcuity?.correction?.oi?.near?.value} </span>{" "}                                       </>
                            ) : ""}                                 <br />

                            {visualAcuity.correction.ao ? (
                                <>
                                    <span className="fw-bold">AV AO: </span>{" "}
                                    <span className="">Visión Lejana: {visualAcuity?.correction?.ao?.far?.value} </span>{" "}
                                    <span className="">Visión Cercana: {visualAcuity?.correction?.ao?.near?.value} </span>{" "}                                       </>
                            ) : ""}
                        </h5>
                    </>
                ) : ""}

                {visualAcuity.ph ? (
                    <>
                        <h5 className="fw-bold mt-3">
                            Pinhole (PH)
                        </h5>
                        <h5>
                            {visualAcuity.ph.od ? (
                                <>
                                    <span className="fw-bold">AV OD: </span>{" "}
                                    {visualAcuity.ph?.od}
                                </>
                            ) : ""}
                            {visualAcuity.ph.od && visualAcuity.ph.od ? " / " : ""}
                            {visualAcuity.ph.oi ? (
                                <>
                                    <span className="fw-bold">AV OI: </span>{" "}
                                    {visualAcuity.ph?.oi}
                                </>
                            ) : ""}
                        </h5>
                    </>
                ) : ""}

                {visualAcuity.lentsInUse ? (
                    <>
                        <h5 className="fw-bold mt-3">
                            Lentes en uso
                        </h5>
                        <h5>
                            {visualAcuity.lentsInUse.lentsOd ? (
                                <>
                                    <br />

                                    <span className="fw-bold">Lente OD </span>{" "}
                                    <br />

                                    <div className="d-flex flex-column">
                                        <span className="fw-bold">Esfera OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOd.sphere}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Cilindro OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOd.cylinder}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Eje OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOd.axis}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">ADD OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOd.add}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Tipo de lente OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOd.len}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Marca OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOd.brand}</span>
                                    </div>

                                </>
                            ) : ""}
                            {visualAcuity.lentsInUse.lentsOi ? (
                                <>
                                    <br />

                                    <span className="fw-bold">Lente OI </span>{" "}
                                    <br />
                                    <div>
                                        <span className="fw-bold">Esfera OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOi.sphere}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Cilindro OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOi.cylinder}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Eje OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOi.axis}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">ADD OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOi.add}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Tipo de lente OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOi.len}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Marca OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.lentsOi.brand}</span>
                                    </div>                                    </>
                            ) : ""}
                        </h5>
                        <h5>
                            {visualAcuity.lentsInUse.od ? (
                                <>
                                    <br />

                                    <span className="fw-bold">AV OD </span>{" "}
                                    <br />

                                    <div>
                                        <span className="fw-bold">Visión Lejana OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.od.far}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Visión Cercana OD: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.od.near}</span>
                                    </div>
                                </>
                            ) : ""}
                            {visualAcuity.lentsInUse.od && visualAcuity.lentsInUse.oi ? " / " : ""}
                            {visualAcuity.lentsInUse.oi ? (
                                <>
                                    <br />
                                    <span className="fw-bold">AV OI </span>{" "}
                                    <br />

                                    <div>
                                        <span className="fw-bold">Visión Lejana OI: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.oi.far}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold">Visión Cercana OI: </span>{" "}
                                        <span>{visualAcuity.lentsInUse?.oi.near}</span>
                                    </div>                                        </>
                            ) : ""}
                        </h5>
                        {visualAcuity.lentsInUse.observations ? (
                            <h5>
                                <span className="fw-bold">Observaciones: </span>{visualAcuity.lentsInUse.observations}
                            </h5>
                        ) : ""}
                    </>
                ) : ""}
            </div>;
        } else {
            return "";
        }
    };

    const renderChromaticVision = (chromaticVision: ChromaticVision | null) => {
        if (chromaticVision) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Visión cromática
                    </h4>
                    {chromaticVision.ishihara ? (
                        <>
                            {chromaticVision.ishihara ? <div>
                                <h5>
                                    <span className="fw-bold">Ishihara </span>{" "}
                                </h5>
                                <div><b>Plato</b>  {chromaticVision.ishihara.disk}</div>
                                <div><b>Correcto</b>  {chromaticVision.ishihara.correct}</div>
                                <div><b>Puntaje</b>  {chromaticVision.ishihara.score}</div>
                            </div>
                                : ""}
                            {chromaticVision.ishihara.observations ? (
                                <h5>
                                    <span className="fw-bold">Observaciones: </span>
                                    {chromaticVision.ishihara.observations}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                    {chromaticVision.farnsworthTest ? (
                        <>
                            <h5 className="mt-3 fw-bold">
                                Farnsworth test
                            </h5>
                            {chromaticVision.farnsworthTest.od ? (
                                <h5>
                                    <span className="fw-bold">OD: </span>{" "}
                                    {chromaticVision.farnsworthTest.od}
                                </h5>
                            ) : ""}
                            {chromaticVision.farnsworthTest.oi ? (
                                <h5>
                                    <span className="fw-bold">OI: </span>{" "}
                                    {chromaticVision.farnsworthTest.oi}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderFFF = (fff: FunctionalOptometryFinalFormula | null) => {
        if (fff) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Fórmula final de optometría funcional
                    </h4>
                    <h5 className="text-muted">
                        {fff?.oi ? (
                            <>
                                <span className="fw-bold">Esfera OI: </span>{" "}
                                <span>{fff.oi.sphere}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.od ? (
                            <>
                                <span className="fw-bold">Esfera OD: </span>{" "}
                                <span>{fff.od.sphere}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.oi ? (
                            <>
                                <span className="fw-bold">Cilindro OI: </span>{" "}
                                <span>{fff.oi.cylinder}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.od ? (
                            <>
                                <span className="fw-bold">Cilindro OD: </span>{" "}
                                <span>{fff.od.cylinder}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.oi ? (
                            <>
                                <span className="fw-bold">Eje OI: </span>{" "}
                                <span>{fff.oi.axis}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.od ? (
                            <>
                                <span className="fw-bold">Eje OD: </span>{" "}
                                <span>{fff.od.axis}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.oi ? (
                            <>
                                <span className="fw-bold">ADD OI: </span>{" "}
                                <span>{fff.oi.add}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.od ? (
                            <>
                                <span className="fw-bold">ADD OD: </span>{" "}
                                <span>{fff.od.add}</span>
                            </>
                        ) : ""}
                        <br />
                        {fff?.oi ? (
                            <>
                                <span className="fw-bold">AV OI: </span>{" "}
                                <span>{fff.oi.far}</span>
                            </>
                        ) : ""}
                        <br />

                        {fff?.od ? (
                            <>
                                <span className="fw-bold">AV OD: </span>{" "}
                                <span>{fff.od.far}</span>
                            </>
                        ) : ""}
                        <br />

                    </h5>
                    {fff.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>{" "}
                            {fff.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderLaterallity = (laterallity: Laterality) => {
        if (laterallity) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Lateralidad
                    </h4>
                    {laterallity.eye ? (
                        <h5>
                            <span className="fw-bold">Ojo dominante: </span>
                            {laterallity.eye}
                        </h5>
                    ) : ""}
                    {laterallity.hand ? (
                        <h5>
                            <span className="fw-bold">Mano dominante: </span>
                            {laterallity.hand}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderKappaAngle = (kappaAngle: KappaAngle) => {
        if (kappaAngle) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Ángulo Kappa
                    </h4>
                    {kappaAngle.od ? (
                        <h5>
                            <span className="fw-bold">Ángulo Kappa OD: </span>
                            {kappaAngle.od}
                        </h5>
                    ) : ""}
                    {kappaAngle.oi ? (
                        <h5>
                            <span className="fw-bold">Ángulo Kappa OI: </span>
                            {kappaAngle.oi}
                        </h5>
                    ) : ""}
                </div>
            );
        }
    };

    const renderFixation = (fixation: Fixation) => {
        if (fixation) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Fijación
                    </h4>
                    <h5>
                        {fixation.od ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {fixation.od}
                            </>
                        ) : ""}
                        {(fixation.od && fixation.oi) ? " - " : ""}
                        {fixation.oi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {fixation.oi}
                            </>
                        ) : ""}
                    </h5>
                    {fixation.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>{" "}
                            {fixation.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderKrimskyTest = (krimskyTest: ISightTest) => {
        if (krimskyTest) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            Test de Krimsky
                        </h4>
                        <h5>{krimskyTest.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={krimskyTest?.vl}
                                isPrism
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={krimskyTest?.vp}
                                isPrism
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderPrismaCoverTest = (prismaCoverTest: ISightTest) => {
        if (prismaCoverTest) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            Prisma Cover Test
                        </h4>
                        <h5>{prismaCoverTest.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={prismaCoverTest?.vl}
                                isPrism
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={prismaCoverTest?.vp}
                                isPrism
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderWhiteTest = (whiteTest: ISightTest) => {
        if (whiteTest) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            White test
                        </h4>
                        <h5>{whiteTest.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={whiteTest?.vl}
                                isPrism
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={whiteTest?.vp}
                                isPrism
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderHirschberg = (hirschbergReflect: ISightTest) => {
        if (hirschbergReflect) {
            return (
                <div className={`${forDownload ? "col-12 page-break" : "col-6"}`}>
                    <div className="d-inline mb-5">
                        <h4 className="text-primary fw-bold mb-3">
                            Reflejo de Hirschberg
                        </h4>
                        <h5>{hirschbergReflect.correction ? "Con corrección" : "Sin corrección"}</h5>
                        <div className="d-flex">
                            <SightTest
                                type="vl"
                                onGetResult={() => null}
                                currentData={hirschbergReflect?.vl}
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                            <div className="mx-3"></div>
                            <SightTest
                                type="vp"
                                onGetResult={() => null}
                                currentData={hirschbergReflect?.vp}
                                readOnly={true}
                                degreesData={degreesData}
                                squintsData={squintsData}
                            />
                        </div>
                    </div>
                </div>
            );
        } else return "";
    };

    const renderVersions = (versions: Versions) => {
        if (versions) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Versiones
                    </h4>
                    <Version
                        orthopticPayload={{
                            ...orthopticPayload,
                            versions: versions
                        }}
                        setOrthopticPayload={setOrthopticPayload}
                        onSubmit={() => null}
                        versions={versionsState}
                        isDetail
                        readOnly
                        dataVersionList={dataVersionList}
                    />
                </div>
            );
        } else return "";
    };

    const renderFusionalBreadth = (fusionalBreadths: FusionalBreadths | null) => {
        if (fusionalBreadths) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Amplitudes fusionales
                    </h4>
                    {fusionalBreadths.vl ? (
                        <>
                            <h5 className=" fw-bold">
                                Objeto real
                            </h5>
                            {fusionalBreadths.vl.or?.divergent ? (
                                <h5>
                                    <span className="fw-bold">Divergencia Visión Lejana:</span>{" "}
                                    {fusionalBreadths.vl.or?.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.vl.or?.convergent ? (
                                <h5>
                                    <span className="fw-bold">Convergencia Visión Lejana:</span>{" "}
                                    {fusionalBreadths.vl.or.convergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.vp?.or?.divergent ? (
                                <h5>
                                    <span className="fw-bold">Divergencia Visión Cercana:</span>{" "}
                                    {fusionalBreadths.vp?.or.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.vp?.or?.convergent ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Convergencia Visión Cercana:</span>{" "}
                                    {fusionalBreadths.vp?.or.convergent}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}

                    {fusionalBreadths.vl ? (
                        <>
                            <h5 className=" fw-bold">
                                Luz
                            </h5>
                            {fusionalBreadths.vl?.luz?.divergent ? (
                                <h5>
                                    <span className="fw-bold">Divergencia Visión Lejana:</span>{" "}
                                    {fusionalBreadths.vl?.luz.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.vl?.luz?.convergent ? (
                                <h5>
                                    <span className="fw-bold">Convergencia Visión Lejana:</span>{" "}
                                    {fusionalBreadths.vl?.luz.convergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths?.vp ? (
                                <h5>
                                    <span className="fw-bold">Divergencia Visión Cercana:</span>{" "}
                                    {fusionalBreadths.vp?.luz?.divergent}
                                </h5>
                            ) : ""}
                            {fusionalBreadths.vp?.luz?.convergent ? (
                                <h5 className=" mb-3">
                                    <span className="fw-bold">Convergencia Visión Cercana:</span>{" "}
                                    {fusionalBreadths.vp?.luz.convergent}
                                </h5>
                            ) : ""}
                        </>
                    ) : ""}
                    {fusionalBreadths.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>
                            {fusionalBreadths.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderBielschowsky = (bielschowskyTest: Bielschowsky) => {
        if (bielschowskyTest) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Test de Bielschowsky
                    </h4>
                    {bielschowskyTest.bielshowschkyVlRight ? (
                        <h5>
                            <span className="fw-bold">Visión Lejana Derecha:</span>{" "}
                            {bielschowskyTest.bielshowschkyVlRight}
                        </h5>
                    ) : ""}
                    {bielschowskyTest.bielshowschkyVlLeft ? (
                        <h5>
                            <span className="fw-bold">Visión Lejana Izquierda:</span>{" "}
                            {bielschowskyTest.bielshowschkyVlLeft}
                        </h5>
                    ) : ""}
                    {bielschowskyTest.bielshowschkyVpRight ? (
                        <h5>
                            <span className="fw-bold">Visión Cercana Derecha:</span>{" "}
                            {bielschowskyTest.bielshowschkyVpRight}
                        </h5>
                    ) : ""}
                    {bielschowskyTest.bielshowschkyVpLeft ? (
                        <h5>
                            <span className="fw-bold">Visión Cercana Izquierda:</span>{" "}
                            {bielschowskyTest.bielshowschkyVpLeft}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderBreadthAccomodation = (breadthAccomodation: BreadthAccommodation) => {
        if (breadthAccomodation) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Amplitud de acomodación
                    </h4>
                    <h5>
                        {breadthAccomodation.level ? (
                            <>
                                <span className="fw-bold">Nivel: </span>
                                {breadthAccomodation.level}
                            </>
                        ) : ""}
                        {(breadthAccomodation.level && breadthAccomodation.od) ? " - " : ""}
                        {breadthAccomodation.od ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {breadthAccomodation.od}
                            </>
                        ) : ""}
                        {(breadthAccomodation.od && breadthAccomodation.oi) ? " - " : ""}
                        {breadthAccomodation.oi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {breadthAccomodation.oi}
                            </>
                        ) : ""}
                        {(breadthAccomodation.oi && breadthAccomodation.distance) ? " - " : ""}
                        {breadthAccomodation.distance ? (
                            <>
                                <span className="fw-bold">Distancia: </span>
                                {breadthAccomodation.distance}
                            </>
                        ) : ""}
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderSensoryCorrespondence = (sensoryCorrespondence: SensoryCorrespondence) => {
        if (sensoryCorrespondence) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Correspondencia sensorial
                    </h4>
                    <h5>
                        {sensoryCorrespondence.vl ? (
                            <>
                                {sensoryCorrespondence.vl.fr ? (
                                    <h5>
                                        <span className="fw-bold">VL FR: </span>
                                        {sensoryCorrespondence.vl.fr}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vl.bag ? (
                                    <h5>
                                        <span className="fw-bold">VL BAG: </span>
                                        {sensoryCorrespondence.vl.bag}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vl.lw ? (
                                    <h5>
                                        <span className="fw-bold">VL LW: </span>
                                        {sensoryCorrespondence.vl.lw}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vl.amd ? (
                                    <h5>
                                        <span className="fw-bold">VL AMB: </span>
                                        {sensoryCorrespondence.vl.amd}
                                    </h5>
                                ) : ""}
                            </>
                        ) : ""}
                    </h5>
                    {(sensoryCorrespondence.vl && sensoryCorrespondence.vp) ? <div className="my-3"></div> : ""}
                    <h5>
                        {sensoryCorrespondence.vp ? (
                            <>
                                {sensoryCorrespondence.vp.fr ? (
                                    <h5>
                                        <span className="fw-bold">VP FR: </span>
                                        {sensoryCorrespondence.vp.fr}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vp.bag ? (
                                    <h5>
                                        <span className="fw-bold">VP BAG: </span>
                                        {sensoryCorrespondence.vp.bag}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vp.lw ? (
                                    <h5>
                                        <span className="fw-bold">VP LW: </span>
                                        {sensoryCorrespondence.vp.lw}
                                    </h5>
                                ) : ""}
                                {sensoryCorrespondence.vp.amd ? (
                                    <h5>
                                        <span className="fw-bold">VP AMB: </span>
                                        {sensoryCorrespondence.vp.amd}
                                    </h5>
                                ) : ""}
                            </>
                        ) : ""}
                    </h5>
                    {sensoryCorrespondence.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>
                            {sensoryCorrespondence.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderAccommodationFlexibility = (accommodationFlexibility: AccommodationFlexibility) => {
        if (accommodationFlexibility) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Flexibilidad de acomodación
                    </h4>
                    <h5>
                        {accommodationFlexibility.od ? (
                            <>
                                <span className="fw-bold">OD: </span>
                                {accommodationFlexibility.od}
                            </>
                        ) : ""}
                        {(accommodationFlexibility.od && accommodationFlexibility.oi) ? " - " : ""}
                        {accommodationFlexibility.oi ? (
                            <>
                                <span className="fw-bold">OI: </span>
                                {accommodationFlexibility.oi}
                            </>
                        ) : ""}
                        {(accommodationFlexibility.oi && accommodationFlexibility.cym) ? " - " : ""}
                        {accommodationFlexibility.cym ? (
                            <>
                                <span className="fw-bold"> Ciclos/min: </span>
                                {accommodationFlexibility.cym}
                            </>
                        ) : ""}
                    
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderNextConvergencePoint = (nextConvergencePoint: NextConvergencePoint) => {
        if (nextConvergencePoint) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Punto próximo de convergencia
                    </h4>
                    <h5>
                        {nextConvergencePoint.realObjectMethod ? (
                            <>
                                <span className="fw-bold">Objeto real: </span>
                                {nextConvergencePoint.realObjectMethod}
                            </>
                        ) : ""}
                        {(nextConvergencePoint.realObjectMethod && nextConvergencePoint.lightMethod) ? " - " : ""}
                        {nextConvergencePoint.lightMethod ? (
                            <>
                                <span className="fw-bold">Luz: </span>
                                {nextConvergencePoint.lightMethod}
                            </>
                        ) : ""}
                        {(nextConvergencePoint.lightMethod && nextConvergencePoint.redFilterMethod) ? " - " : ""}
                        {nextConvergencePoint.redFilterMethod ? (
                            <>
                                <span className="fw-bold">Filtro rojo: </span>
                                {nextConvergencePoint.redFilterMethod}
                            </>
                        ) : ""}
                    </h5>
                </div>
            );
        } else return "";
    };

    const renderStereopsis = (stereopsis: Stereopsis) => {
        if (stereopsis) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Estereopsis
                    </h4>
                    {stereopsis.test ? (
                        <h5>
                            <span className="fw-bold">Test: </span>
                            {stereopsis.test}
                        </h5>
                    ) : ""}
                    {stereopsis.observations ? (
                        <h5>
                            <span className="fw-bold">Observaciones: </span>
                            {stereopsis.observations}
                        </h5>
                    ) : ""}
                </div>
            );
        } else return "";
    };

    const renderExtDiagnostic = (extDiagnostic: DIListItem[] | null) => {
        if (extDiagnostic && extDiagnostic.length) {
            const mainExt = (extDiagnostic).find(item => item.isMain === 1); 
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Impresión diagnóstica
                    </h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="text-secondary">
                                <th>Fecha</th>
                                <th className="text-center">CIE-10</th>
                                <th>Impresión diagnóstica</th>
                                <th className="text-center">Ojo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {extDiagnostic.map((item) => (
                                <tr key={`${item.id}-${item.clhId}`} style={{ backgroundColor: item.isMain ? "#fcfae6" : undefined }}>
                                    <td className="align-middle">{item.date}</td>
                                    <td align="center" className="align-middle">{item.cie10Code}</td>
                                    <td style={{ whiteSpace: "pre-line" }}>{item.cie10Description}</td>
                                    <td className="align-middle" align="center">{item.dbo?.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {mainExt ? (
                        <h5>
                            <span className="fw-bold">Tiempo de evolución: </span>
                            {mainExt.evolution} {mainExt.evolutionTime?.name}
                        </h5>
                    ) : ""}

                </div>
            );
        } else {
            return "";
        }
    };

    const renderAnalysisAndPlan = (analysisPlan: string | null) => {
        if (analysisPlan) {
            return (
                <div className="item">
                    <h4 className="text-primary fw-bold mb-3">
                        Análisis y plan
                    </h4>
                    <h5>{analysisPlan}</h5>
                </div>
            );
        } else {
            return "";
        }
    };

    const render = () => {
        const attentionOrigin = orthopticDetails.generalsFields ? orthopticDetails.generalsFields[0] : null;
        const visualAcuity = formatHistoryDetails(orthopticDetails.visualAcuity);

        const chromaticVision = formatHistoryDetails(orthopticDetails.chromaticVision);
        const functionalOptometryFinalFormula = formatHistoryDetails(orthopticDetails.functionalOptometryFinalFormula);
        const laterality = formatHistoryDetails(orthopticDetails.laterality);
        const kappaAngle = formatHistoryDetails(orthopticDetails.kappaAngle);
        const fixation = formatHistoryDetails(orthopticDetails.fixation);

        const krimskyTest = formatHistoryDetails(orthopticDetails.krimskyTest);
        const prismaCoverTest = formatHistoryDetails(orthopticDetails.prismCoverTest);
        const whiteTest = formatHistoryDetails(orthopticDetails.whiteTest);
        const hirschberg = formatHistoryDetails(orthopticDetails.hirschbergReflexes);
        const versions = formatHistoryDetails(orthopticDetails.versions);

        const fusionalBreadths = formatHistoryDetails(orthopticDetails.fusionalBreadths);
        const bielschowsky = formatHistoryDetails(orthopticDetails.bielshowschky);
        const breadthAccommodation = formatHistoryDetails(orthopticDetails.breadthAccommodation);
        const accommodationFlexibility = formatHistoryDetails(orthopticDetails.accommodationFlexibility);
        const sensoryCorrespondence = formatHistoryDetails(orthopticDetails.sensoryCorrespondence);
        const nextConvergencePoint = formatHistoryDetails(orthopticDetails.nextConvergencePoint);
        const stereopsis = formatHistoryDetails(orthopticDetails.stereopsis);
        const extDiagnostic = orthopticDetails.cie10ExtDiagnostic ?? null;
        const analysisPlan = formatHistoryDetails(orthopticDetails.generalsFields ? orthopticDetails.generalsFields[0].analysisPlanJst : "");

        return (
            <div className={`row ${!forDownload ? "overflow-auto" : "overflow-auto"}`}>
                <div className="detail-container">
                    <div className={`${!forDownload ? "columns" : "columns"}`}>
                        {renderAttentionOrigin(attentionOrigin)}
                        {renderFFF(functionalOptometryFinalFormula)}
                        {renderFusionalBreadth(fusionalBreadths)}
                        {renderFixation(fixation)}
                        {renderVisualAcuity(visualAcuity)}
                    </div>
                </div>

                <div
                    style={{ marginTop: "1rem", display: "block", pageBreakAfter: "always", pageBreakBefore: "always" }}

                >
                    <div className="row mt-3 g-4 "
                    >
                        {renderHirschberg(hirschberg)}
                        {renderKrimskyTest(krimskyTest)}
                        {renderPrismaCoverTest(prismaCoverTest)}
                        {renderWhiteTest(whiteTest)}
                    </div>

                </div>

                <div className="detail-container mt-3"
                    style={{ marginTop: "1rem", display: "block"}}

                >
                    <div className={`${!forDownload ? "columns" : "columns"}`}>
                        {renderVersions(versions)}
                        {renderBielschowsky(bielschowsky)} 
                        {renderNextConvergencePoint(nextConvergencePoint)}
                        {renderAccommodationFlexibility(accommodationFlexibility)}
                        {renderLaterallity(laterality)}
                        {renderKappaAngle(kappaAngle)}
                        {renderBreadthAccomodation(breadthAccommodation)}
                        {renderChromaticVision(chromaticVision)}
                        {renderSensoryCorrespondence(sensoryCorrespondence)}
                        {renderStereopsis(stereopsis)}
                        <div className="block-item">
                            {renderExtDiagnostic(extDiagnostic)}
                            {renderAnalysisAndPlan(analysisPlan)}
                        </div>
                    </div>

                </div>
            </div>
        );
    };

    return render();
}
