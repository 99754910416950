import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models/general";
import { PatientRecord, PatientRecordParams, PatientRecordResponse } from "@/models";
import { setHeaders } from "@/utils/utils";

export async function getPatientRecordsService(params: PatientRecordParams): Promise<PatientRecordResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };
    const { data: serviceData } = await axiosInstance.get<PatientRecordResponse>(
        "/medical/clinicHistory/patientMedicalBackground/", axiosParams
    );
    return serviceData;
}

export async function updatePatientRecordService(payload: PatientRecord): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const { data: serviceData } = await axiosInstance.put(
        "/medical/clinicHistory/patientMedicalBackground/", payload, axiosParams
    );
    return serviceData;
}
