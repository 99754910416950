import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { OptometryParams, OptometryPayload } from "@/models/sheets/optometry";
import { getOptometryService, updateVisualAcuityService } from "@/services/sheets/optometry";

export function getOptometry(params: OptometryParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getOptometryService(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateOptometry(payload: OptometryPayload) {
    return async function () {
        const response = await updateVisualAcuityService(payload);
        return response.success;
    };
}
