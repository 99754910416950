import React from "react";
import { IAvatar } from "./avatar";
import "./Avatar.scss";

export function Avatar({ src, size = "small", className = "", ...rest }: IAvatar) {

    return (
        <div className={`avatar avatar-${size} ${className}`} {...rest}>
            <img
                src={src}
                alt="Avatar"
            />
        </div>
    );
}