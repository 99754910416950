import { useEffect, useState } from "react";
//
import { IModal } from "@/components/Modal/modal";
import { PatientRecord } from "@/models";
import { useAppDispatch, useAppSelector } from "@/hooks";
import * as recordActions from "../../PreConsultation/preConsultation.actions";
//
import {
    Button,
    Checkbox,
    Modal,
    SectionCard,
    TextField
} from "@/components";

interface PreconsultationOptions {
    checked: boolean;
    label: string;
    value: string;
    idName: keyof PatientRecord;
    idNotes: keyof PatientRecord;
}

export default function Antecedents({ onSuccess, ...rest }: IModal) {
    const dispatch = useAppDispatch();
    const account = useAppSelector(state => state.workspace.id);
    const appointment = useAppSelector(state => state.patientAttention.appointmentSelected);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const [records, setRecords] = useState<PatientRecord>({
        appId: rest.appId ?? appointment.app_id,
        eaccount: account,
        userId: userId as number,
        cluId: rest.cluId ?? appointment.patientId,
        arterialHypertension: 0,
        diabetesGeneral: 0,
        asthmaGeneral: 0,
        cancerGeneral: 0,
        coronaryHeartDiseaseGeneral: 0,
        tuberculosisGeneral: 0,
        rheumatoidArthritisGeneral: 0,
        copdGeneral: 0,
        previousSurgeriesGeneral: 0,
        allergiesGeneral: 0,
        useMedicationsGeneral: 0,
        othersGeneral: "",
        notesGeneral: "",
        arterialHypertensionNotes: "",
        diabetesGeneralNotes: "",
        asthmaGeneralNotes: "",
        cancerGeneralNotes: "",
        coronaryHeartDiseaseGeneralNotes: "",
        tuberculosisGeneralNotes: "",
        rheumatoidArthritisGeneralNotes: "",
        copdGeneralNotes: "",
        previousSurgeriesGeneralNotes: "",
        allergiesGeneralNotes: "",
        useMedicationsGeneralNotes: "",
        othersGeneralNotes: "",
        glaucomaOcular: 0,
        ropOcular: 0,
        dmreOcular: 0,
        glassesOcular: 0,
        dryEyeOcular: 0,
        diabeticRetinoPathyOcular: 0,
        uveitisOcular: 0,
        contactLensesOcular: 0,
        traumasOcular: 0,
        surgeriesOcular: 0,
        alertsOcular: 0,
        othersOcular: 0,
        eyeNotesOcular: "",
        glaucomaOcularNotes: "",
        ropOcularNotes: "",
        dmreOcularNotes: "",
        glassesOcularNotes: "",
        dryEyeOcularNotes: "",
        diabeticRetinoPathyOcularNotes: "",
        uveitisOcularNotes: "",
        contactLensesOcularNotes: "",
        traumasOcularNotes: "",
        surgeriesOcularNotes: "",
        alertsOcularNotes: "",
        othersOcularNotes: "",
        ahtFamiliar: 0,
        diabetesFamiliar: 0,
        asthmaFamiliar: 0,
        coronaryHeartDiseaseFamiliar: 0,
        collagenDiseaseFamiliar: 0,
        glaucomaFamiliar: 0,
        keratoconusFamiliar: 0,
        othersFamiliar: 0,
        familyNotesFamiliar: "",
        ahtFamiliarNotes: "",
        diabetesFamiliarNotes: "",
        asthmaFamiliarNotes: "",
        coronaryHeartDiseaseFamiliarNotes: "",
        collagenDiseaseFamiliarNotes: "",
        glaucomaFamiliarNotes: "",
        keratoconusFamiliarNotes: "",
        othersFamiliarNotes: ""
    });

    useEffect(() => {
        async function fetchData() {
            const data = await dispatch(recordActions.getPatientRecords({
                eaccount: account,
                appId: rest.appId ?? appointment.app_id,
                userId: userId as number,
                cluId: rest.cluId ?? appointment.patientId
            }));
            if (data) {
                setRecords((prevStatus) => ({
                    ...prevStatus,
                    ...data as PatientRecord
                }));
            }
        }
        fetchData();
    }, [dispatch, account, appointment.app_id, userId, appointment.patientId, rest.appId, rest.cluId]);

    const handleUpdateOptionValue = (itemId: keyof PatientRecord, value: string) => {
        const options = {
            ...records,
            [itemId]: value
        };
        setRecords(options as PatientRecord);
    };

    const handleUpdateOption = (itemName: keyof PatientRecord, itemNotes: keyof PatientRecord, isChecked: boolean) => {
        const options = {
            ...records,
            [itemName]: isChecked ? 1 : 0,
            [itemNotes]: isChecked ? records[itemNotes] : ""
        };
        setRecords(options as PatientRecord);
    };

    const handleSubmitRecords = async () => {
        const isSuccess = await dispatch(recordActions.updatePatientRecord(records));
        if (isSuccess) {
            const data = await dispatch(recordActions.getPatientRecords({
                eaccount: account,
                appId: rest.appId ?? appointment.app_id,
                userId: userId as number,
                cluId: rest.cluId ?? appointment.patientId
            }));

            if (data) {
                setRecords((prevStatus) => ({
                    ...prevStatus,
                    ...data as PatientRecord
                }));
            }
            onSuccess?.(null);
        }
        rest.onClose();
    };

    const renderCheckbox = (item: PreconsultationOptions) => {
        return (
            <div className="d-flex align-items-center mb-3" style={{ height: "25px" }}>
                <div className="d-flex" style={{ minWidth: "10vw" }}>
                    <Checkbox
                        className="nowrap flex-shrink-0"
                        label={item.label}
                        type="checkbox"
                        name={item.idName}
                        id={item.idName}
                        checked={item.checked}
                        disabled={rest.isDisabledForm}
                        onChange={(event) =>
                            handleUpdateOption(
                                item.idName as keyof PatientRecord,
                                item.idNotes as keyof PatientRecord,
                                event.target.checked
                            )}
                    />
                </div>
                {item.checked && (
                    <TextField
                        className="w-50 ms-3"
                        variant="default"
                        type="text"
                        placeholder="Comentarios..."
                        disabled={rest.isDisabledForm}
                        value={item.value}
                        onChange={(event) =>
                            handleUpdateOptionValue(item.idNotes as keyof PatientRecord, event.target.value)}
                    />
                )}
            </div>
        );
    };

    const renderGeneralOptions = () => {
        return (
            <SectionCard className="h-100">
                <div className="d-flex justify-content-between text-secondary">
                    <h5 className="fw-bold">Generales</h5>
                </div>
                <hr />
                <div className="flex-grow-1 ps-3 py-3 pe-3" style={{ overflowY: "auto" }}>
                    {renderCheckbox({
                        checked: Number(records.arterialHypertension) ? true : false,
                        label: "Hipertensión arterial",
                        value: records.arterialHypertensionNotes,
                        idName: "arterialHypertension",
                        idNotes: "arterialHypertensionNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.diabetesGeneral) ? true : false,
                        label: "Diabetes",
                        value: records.diabetesGeneralNotes,
                        idName: "diabetesGeneral",
                        idNotes: "diabetesGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.asthmaGeneral) ? true : false,
                        label: "Asma",
                        value: records.asthmaGeneralNotes,
                        idName: "asthmaGeneral",
                        idNotes: "asthmaGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.cancerGeneral) ? true : false,
                        label: "Cáncer",
                        value: records.cancerGeneralNotes,
                        idName: "cancerGeneral",
                        idNotes: "cancerGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.coronaryHeartDiseaseGeneral) ? true : false,
                        label: "Enfermedad coronaria",
                        value: records.coronaryHeartDiseaseGeneralNotes,
                        idName: "coronaryHeartDiseaseGeneral",
                        idNotes: "coronaryHeartDiseaseGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.tuberculosisGeneral) ? true : false,
                        label: "Tuberculosis",
                        value: records.tuberculosisGeneralNotes,
                        idName: "tuberculosisGeneral",
                        idNotes: "tuberculosisGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.rheumatoidArthritisGeneral) ? true : false,
                        label: "Artritis Reumatoidea",
                        value: records.rheumatoidArthritisGeneralNotes,
                        idName: "rheumatoidArthritisGeneral",
                        idNotes: "rheumatoidArthritisGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.copdGeneral) ? true : false,
                        label: "EPOC",
                        value: records.copdGeneralNotes,
                        idName: "copdGeneral",
                        idNotes: "copdGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.previousSurgeriesGeneral) ? true : false,
                        label: "Cirugías previas",
                        value: records.previousSurgeriesGeneralNotes,
                        idName: "previousSurgeriesGeneral",
                        idNotes: "previousSurgeriesGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.allergiesGeneral) ? true : false,
                        label: "Alergias",
                        value: records.allergiesGeneralNotes,
                        idName: "allergiesGeneral",
                        idNotes: "allergiesGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.useMedicationsGeneral) ? true : false,
                        label: "¿Usa medicamentos?",
                        value: records.useMedicationsGeneralNotes,
                        idName: "useMedicationsGeneral",
                        idNotes: "useMedicationsGeneralNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.othersGeneral) ? true : false,
                        label: "Otros",
                        value: records.othersGeneralNotes,
                        idName: "othersGeneral",
                        idNotes: "othersGeneralNotes"
                    })}
                </div>
                <div>
                    <label htmlFor="" className="text-label">Nota</label>
                    <textarea
                        className="form-control no-resize"
                        onChange={(event) => handleUpdateOptionValue("notesGeneral", event.target.value)}
                        value={records?.notesGeneral}
                        placeholder="Escribir..."
                        disabled={rest.isDisabledForm}
                    />
                </div>
            </SectionCard>
        );
    };

    const renderOcularOptions = () => {
        return (
            <SectionCard className="h-100">
                <div className="d-flex justify-content-between text-secondary">
                    <h5 className="fw-bold">Oculares</h5>
                </div>
                <hr />
                <div className="flex-grow-1 ps-3 py-3 pe-3" style={{ overflowY: "auto" }}>
                    {renderCheckbox({
                        checked: Number(records.glaucomaOcular) ? true : false,
                        label: "Glaucoma",
                        value: records.glaucomaOcularNotes,
                        idName: "glaucomaOcular",
                        idNotes: "glaucomaOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.ropOcular) ? true : false,
                        label: "ROP",
                        value: records.ropOcularNotes,
                        idName: "ropOcular",
                        idNotes: "ropOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.dmreOcular) ? true : false,
                        label: "DMRE",
                        value: records.dmreOcularNotes,
                        idName: "dmreOcular",
                        idNotes: "dmreOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.glassesOcular) ? true : false,
                        label: "Gafas",
                        value: records.glassesOcularNotes,
                        idName: "glassesOcular",
                        idNotes: "glassesOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.dryEyeOcular) ? true : false,
                        label: "Ojo seco",
                        value: records.dryEyeOcularNotes,
                        idName: "dryEyeOcular",
                        idNotes: "dryEyeOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.diabeticRetinoPathyOcular) ? true : false,
                        label: "Retinopatía diabética",
                        value: records.diabeticRetinoPathyOcularNotes,
                        idName: "diabeticRetinoPathyOcular",
                        idNotes: "diabeticRetinoPathyOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.uveitisOcular) ? true : false,
                        label: "Uveitis",
                        value: records.uveitisOcularNotes,
                        idName: "uveitisOcular",
                        idNotes: "uveitisOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.contactLensesOcular) ? true : false,
                        label: "Lentes de contacto",
                        value: records.contactLensesOcularNotes,
                        idName: "contactLensesOcular",
                        idNotes: "contactLensesOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.traumasOcular) ? true : false,
                        label: "Traumas",
                        value: records.traumasOcularNotes,
                        idName: "traumasOcular",
                        idNotes: "traumasOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.surgeriesOcular) ? true : false,
                        label: "Cirugía",
                        value: records.surgeriesOcularNotes,
                        idName: "surgeriesOcular",
                        idNotes: "surgeriesOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.alertsOcular) ? true : false,
                        label: "Alertas",
                        value: records.alertsOcularNotes,
                        idName: "alertsOcular",
                        idNotes: "alertsOcularNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.othersOcular) ? true : false,
                        label: "Otros",
                        value: records.othersOcularNotes,
                        idName: "othersOcular",
                        idNotes: "othersOcularNotes"
                    })}
                </div>
                <div>
                    <label htmlFor="" className="text-label">Nota</label>
                    <textarea
                        className="form-control no-resize"
                        onChange={(event) => handleUpdateOptionValue("eyeNotesOcular", event.target.value)}
                        value={records?.eyeNotesOcular}
                        placeholder="Escribir..."
                        disabled={rest.isDisabledForm}
                    />
                </div>
            </SectionCard >
        );
    };

    const renderFamiliarOptions = () => {
        return (
            <SectionCard className="h-100">
                <div className="d-flex justify-content-between text-secondary">
                    <h5 className="fw-bold">Familiares</h5>
                </div>
                <hr />
                <div className="flex-grow-1 ps-3 py-3 pe-3" style={{ overflowY: "auto" }}>
                    {renderCheckbox({
                        checked: Number(records.ahtFamiliar) ? true : false,
                        label: "HTA",
                        value: records.ahtFamiliarNotes,
                        idName: "ahtFamiliar",
                        idNotes: "ahtFamiliarNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.diabetesFamiliar) ? true : false,
                        label: "Diabetes",
                        value: records.diabetesFamiliarNotes,
                        idName: "diabetesFamiliar",
                        idNotes: "diabetesFamiliarNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.asthmaFamiliar) ? true : false,
                        label: "Asma",
                        value: records.asthmaFamiliarNotes,
                        idName: "asthmaFamiliar",
                        idNotes: "asthmaFamiliarNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.coronaryHeartDiseaseFamiliar) ? true : false,
                        label: "Enfermedad coronaria",
                        value: records.coronaryHeartDiseaseFamiliarNotes,
                        idName: "coronaryHeartDiseaseFamiliar",
                        idNotes: "coronaryHeartDiseaseFamiliarNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.collagenDiseaseFamiliar) ? true : false,
                        label: "Enfermedad colágeno",
                        value: records.collagenDiseaseFamiliarNotes,
                        idName: "collagenDiseaseFamiliar",
                        idNotes: "collagenDiseaseFamiliarNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.glaucomaFamiliar) ? true : false,
                        label: "Glaucoma",
                        value: records.glaucomaFamiliarNotes,
                        idName: "glaucomaFamiliar",
                        idNotes: "glaucomaFamiliarNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.keratoconusFamiliar) ? true : false,
                        label: "Queratocono",
                        value: records.keratoconusFamiliarNotes,
                        idName: "keratoconusFamiliar",
                        idNotes: "keratoconusFamiliarNotes"
                    })}
                    {renderCheckbox({
                        checked: Number(records.othersFamiliar) ? true : false,
                        label: "Otros",
                        value: records.othersFamiliarNotes,
                        idName: "othersFamiliar",
                        idNotes: "othersFamiliarNotes"
                    })}
                </div>
                <div>
                    <label htmlFor="" className="text-label">Nota</label>
                    <textarea
                        className="form-control no-resize"
                        onChange={(event) => handleUpdateOptionValue("familyNotesFamiliar", event.target.value)}
                        value={records?.familyNotesFamiliar}
                        placeholder="Escribir..."
                        disabled={rest.isDisabledForm}
                    />
                </div>
            </SectionCard>
        );
    };

    const render = () => {
        return (
            <Modal {...rest} width="80%" fullScreen>
                <h1 className="text-secondary fw-bold mb-4">Antecedentes</h1>
                <div className="row h-100 overflow-hidden p-3">
                    <div className="col-lg-4 h-100">
                        {renderGeneralOptions()}
                    </div>
                    <div className="col-lg-4 h-100">
                        {renderOcularOptions()}
                    </div>
                    <div className="col-lg-4 h-100">
                        {renderFamiliarOptions()}
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <Button variant="outline" size="large" className="py-2 me-3" onClick={rest.onClose}
                        disabled={rest.isDisabledForm}

                    >
                        Cancelar
                    </Button>
                    <Button variant="primary" size="large" className="py-2" onClick={handleSubmitRecords}
                        disabled={rest.isDisabledForm}
                    >
                        Guardar cambios
                    </Button>
                </div>
            </Modal>
        );
    };

    return render();
}