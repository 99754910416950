import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { PreoperativeDiagnosesResponse } from "@/models/surgery/patientAttention/patientAttention";
import { DoctorsInformationResponse, SurgeonNotes, SurgeonNotesResponse, SurgicalNotesVariablesListResponse } from "@/models/surgery/patientAttention/surgeon";
import { setHeaders } from "@/utils";

export async function UpdateSurgeonNotesService(payload: SurgeonNotes): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/surgicalNotes", payload, axiosParams
    );
    return serviceData;
}

export async function GetSurgeonNotesService(params: { appId: string; sunId: string; }): Promise<SurgeonNotesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<SurgeonNotesResponse>(
        "/medical/clinicHistory/surgicalNotes", axiosParams
    );
    return serviceData;
}
export async function surgicalDiagnosisService(params: { appId: number; clhId: number; }): Promise<PreoperativeDiagnosesResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<PreoperativeDiagnosesResponse>(
        "/medical/clinicHistory/surgicalNotesDiagnosis", axiosParams
    );
    return serviceData;
}

export async function GetDoctorInformationService(appId: number): Promise<DoctorsInformationResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.get<DoctorsInformationResponse>(
        `/medical/clinicHistory/surgicalNotes/${appId}`, axiosParams
    );
    return serviceData;
}

export async function SurgicalNotesVariablesService(params: { appId: number; }): Promise<SurgicalNotesVariablesListResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<SurgicalNotesVariablesListResponse>(
        "/medical/clinicHistory/surgicalNotesVariables", axiosParams
    );
    return serviceData;
}


export async function FinishSurgeryEventService(payload: SurgeonNotes): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/surgicalNotesClosing", payload, axiosParams
    );
    return serviceData;
}