import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { VitalSignsParams, VitalSignsPayload, VitalSignsResponse } from "@/models/pre-consultation/vitalSigns";
import { setHeaders } from "@/utils";

export async function getVitalSignsService(params: VitalSignsParams): Promise<VitalSignsResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<VitalSignsResponse>(
        "/medical/clinicHistory/vitalSigns/", axiosParams
    );
    return serviceData;
}

export async function createVitalSignsService(payload: VitalSignsPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.post<IResponse>(
        "/medical/clinicHistory/vitalSigns/", payload, axiosParams
    );
    return serviceData;
}

export async function updateVitalSignsService(payload: VitalSignsPayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/vitalSigns/", payload, axiosParams
    );
    return serviceData;
}