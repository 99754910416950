import { useEffect, useState } from "react";

import { useAppDispatch } from "@/hooks";

import { getSurgicalDescriptionDetail } from "../../../../history.actions";

import { SectionCard } from "@/components";
import ScrollableTable from "@/components/ScrollableTable/ScrollableTable";

import { SurgicalDescription } from "@/models/historyDetails/surgicalDescription";

export default function SurgicalDescriptionDetail({ appId }: { appId: number; }) {
    const dispatch = useAppDispatch();

    const [surgicalDescription, setSurgicalDescription] = useState<SurgicalDescription | null>(null);

    useEffect(() => {
        void (async function () {
            const response = await dispatch(getSurgicalDescriptionDetail({ prefix: "surgicalDescription", appId: appId }));

            if (response) {
                setSurgicalDescription(response.surgicalDescription);
            }
        })();
    }, [appId, dispatch]);

    const renderComponent = () => {
        return (
            <>
                <div className="bodyHeight surgeryBody h-100">
                    <div className="card-columns px-4">
                        <div className="item">
                            <SectionCard>
                                <h5 className="fw-bold text-secondary">Equipo quirúrgico</h5>
                                <hr className="lineCards" />
                                <div className="d-flex mb-2">
                                    <div style={{ width: 120 }}>Cirujano</div>
                                    <div className="fw-bold">
                                        {surgicalDescription?.surgicalTeam.surgeonName ?? "-"}
                                    </div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div style={{ width: 120 }}>Cirujano ayudante</div>
                                    <div className="fw-bold">
                                        {surgicalDescription?.surgicalTeam.supportSurgeon ?? "-"}
                                    </div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div style={{ width: 120 }}>Instrumentador</div>
                                    <div className="fw-bold">
                                        {surgicalDescription?.surgicalTeam.supportNurse ?? "-"}
                                    </div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div style={{ width: 120 }}>Rotador</div>
                                    <div className="fw-bold">
                                        {surgicalDescription?.surgicalTeam.supportRotator ?? "-"}
                                    </div>
                                </div>
                                <div className="d-flex mb-2">
                                    <div style={{ width: 120 }}>Anestesiólogo</div>
                                    <div className="fw-bold">
                                        {surgicalDescription?.surgicalTeam.anesName ?? "-"}
                                    </div>
                                </div>

                            </SectionCard>
                        </div>
                        <div className="item">
                            <SectionCard>
                                <h5 className="fw-bold text-secondary">Descripción</h5>
                                <hr className="lineCards" />
                                <textarea
                                    className="form-control"
                                    style={{ minHeight: 150 }}
                                    defaultValue={surgicalDescription?.sunDescription}
                                    disabled
                                />
                            </SectionCard>
                        </div>
                        {surgicalDescription?.variables && surgicalDescription?.variables.length > 0 && (
                            <div className="item">
                                <SectionCard>
                                    <h5 className="fw-bold text-secondary">Variables específicas</h5>
                                    <hr className="lineCards" />
                                    <div className="row gy-2">
                                        {surgicalDescription?.variables.map(variable => (
                                            <div className="col-lg-6 d-flex">
                                                <div className='me-2'>{variable.name}: </div>
                                                <div className="fw-bold">{variable.value ?? "-"}</div>
                                            </div>
                                        ))}
                                    </div>
                                </SectionCard>
                            </div>
                        )}
                        <div className="item">
                            <SectionCard>
                                <h5 className="fw-bold text-secondary">Impresión diagnóstica</h5>
                                <hr className="lineCards mb-2" />
                                <div className="row g-0">
                                    <div className="col-lg-2 fw-bold">
                                        Pre-operatoria
                                    </div>
                                    <div className="col-lg-8">
                                        {surgicalDescription?.preDiagnosis.cie10Id} / {surgicalDescription?.preDiagnosis.name}
                                    </div>
                                    <div className="col-lg-2 text-center">
                                        {surgicalDescription?.preDiagnosis.organ}
                                    </div>
                                </div>
                                <hr className="lineCards mt-2 mb-2" />
                                <div className='fw-bold mb-2'>Post-operatorio</div>
                                <ScrollableTable>
                                    <ScrollableTable.Head>
                                        <ScrollableTable.Cell col={2}>Fecha</ScrollableTable.Cell>
                                        <ScrollableTable.Cell col={2}>CIE-10</ScrollableTable.Cell>
                                        <ScrollableTable.Cell col={6}>Impresión diagnóstica</ScrollableTable.Cell>
                                        <ScrollableTable.Cell col={1}>Ojo</ScrollableTable.Cell>
                                        <ScrollableTable.Cell col={1}>MD</ScrollableTable.Cell>
                                    </ScrollableTable.Head>
                                    <ScrollableTable.Body>
                                        {surgicalDescription?.surgicalNotesDiagnosis.map((item) => (
                                            <ScrollableTable.Row key={`${item.id}-${item.clhId}`}>
                                                <ScrollableTable.Cell col={2}>{item.date}</ScrollableTable.Cell>
                                                <ScrollableTable.Cell col={2}>{item.cie10Code}</ScrollableTable.Cell>
                                                <ScrollableTable.Cell col={6}>
                                                    <div className="nowrap overflow-hidden text-ellipsis">
                                                        {item.extDiagnosticId ? (
                                                            <>
                                                                <span title={item.extDiagnosticDescription}>
                                                                    <span className="text-warning">A</span> / {item.extDiagnosticDescription}
                                                                </span>
                                                                <br />
                                                                <span className="nowrap" title={item.cie10Description}>
                                                                    <span className="text-secondary">10</span> / {item.cie10Description}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="nowrap" title={item.cie10Description}>
                                                                {item.cie10Description}
                                                            </span>
                                                        )}
                                                    </div>
                                                </ScrollableTable.Cell>
                                                <ScrollableTable.Cell col={1}>{item.dbo?.name}</ScrollableTable.Cell>
                                                <ScrollableTable.Cell col={1}>
                                                    {item.doctorInitials?.toUpperCase()}
                                                </ScrollableTable.Cell>
                                            </ScrollableTable.Row>
                                        ))}
                                    </ScrollableTable.Body>
                                </ScrollableTable>
                            </SectionCard>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return renderComponent();
}