import { useAppDispatch } from "@/hooks";
import { useEffect, useState } from "react";

import { getUnexpectedSituationDetail } from "../../../../history.actions";

import { UnexpectedSituationDetail as IUnexpectedSituationDetail, } from "@/models/historyDetails/unexpectedSituation";

export default function UnexpectedSituationDetail({ appId, data }: { appId?: number; data?: IUnexpectedSituationDetail[]; }) {
    const dispatch = useAppDispatch();

    const [unexpectedSituationDetail, setUnexpectedSituationDetail] = useState<IUnexpectedSituationDetail[]>(data ? data : []);

    useEffect(() => {
        async function fetch() {
            if (data) {
                setUnexpectedSituationDetail(data);
            } else {
                const response = await dispatch(getUnexpectedSituationDetail({
                    prefix: "unexpectedSituation",
                    appId: appId
                }));
                if (response) setUnexpectedSituationDetail(response);
            }
        }
        fetch();
    }, [appId, dispatch, data]);

    const renderComponent = () => {
        return (
            <div className={data ? "px-0" : " h-100 px-5 py-4 overflow-auto"}>
                <h4 className="fw-bold text-secondary mb-4">Situaciones inesperadas</h4>
                {unexpectedSituationDetail && unexpectedSituationDetail.map((unx, idx) => (
                    <div className='mb-4'>
                        <h5 className="fw-bold text-primary m-0">
                            {idx + 1}.{" "}
                            {unx.unexpectedSituation}{" "}
                            {unx.causedSuspension ? "(Causó suspensión de la atención)" : ""}
                            {unx.causedCancellation ? "(Causó cancelación de la atención)" : ""}
                        </h5>
                        <div className="p-3" style={{ whiteSpace: "pre-wrap" }}>
                            {unx.sitDescription}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return renderComponent();
}
