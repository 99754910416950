import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { getCategoryService } from "@/services/sidebar";

export function getCategory(id_profile: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getCategoryService(id_profile);
            if (response.success) return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}