import { Outlet, useLocation } from "react-router-dom";
//
import Header from "./Header/Header";
import "./Workspace.scss";
import Sidebar from "./Sidebar/Sidebar";

export default function Workspace() {
    const location = useLocation();

    const render = () => {
        return (
            <div className="workspace">
                <Header />
                <div className="d-flex h-100 overflow-auto">
                    {!location.pathname.includes("home") && <Sidebar />}
                    <div className="workspace-wrapper">
                        <Outlet />
                    </div>
                </div>
            </div>
        );
    };

    return render();
}