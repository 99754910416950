import { RadioButton, SectionCard } from "@/components";
import { ActualCondition, PreoperativeSegment } from "@/models/sheets/preoperative";

export default function GeneralCondition({ preoperativePayload, setPreoperativePayload, onSubmit, isDisabledForm }: PreoperativeSegment) {

    const onChangeCheckbox = (tag: keyof ActualCondition, value: boolean) => {
        const options: typeof preoperativePayload = {
            ...preoperativePayload,
            actualCondition: {
                ...preoperativePayload.actualCondition,
                [tag]: value
            }
        };
        setPreoperativePayload(options);
        onSubmit(false);
    };

    const render = () => {
        return (
            <SectionCard className="align-self-center" style={{ width: 600 }}>
                <h5 className="fw-bold text-secondary">Seleccione una opción</h5>
                <hr className="text-primary mt-2" />
                <div className="d-flex flex-column">
                    <div className="select-option-item">
                        <span className="">Familiares con problemas con la anestesia</span>
                        <div className="flex-grow-1 "></div>
                        <RadioButton
                            disabled={isDisabledForm}
                            className="mx-5"
                            label="Sí"
                            name="familyMemberProblemsAnesthesia"
                            id="yes"
                            checked={preoperativePayload.actualCondition?.familyMemberProblemsAnesthesia === true}
                            onChange={() => onChangeCheckbox("familyMemberProblemsAnesthesia", true)}
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            label="No"
                            name="familyMemberProblemsAnesthesia"
                            id="no"
                            checked={preoperativePayload.actualCondition?.familyMemberProblemsAnesthesia === false}
                            onChange={() => onChangeCheckbox("familyMemberProblemsAnesthesia", false)}
                        />
                    </div>
                    <div className="select-option-item">
                        <span>Paciente fumador</span>
                        <div className="flex-grow-1"></div>
                        <RadioButton
                            disabled={isDisabledForm}
                            className="mx-5"
                            label="Sí"
                            name="option-2"
                            id="yes"
                            checked={preoperativePayload.actualCondition?.smoker === true}
                            onChange={() => onChangeCheckbox("smoker", true)}
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            label="No"
                            name="option-2"
                            id="no"
                            checked={preoperativePayload.actualCondition?.smoker === false}
                            onChange={() => onChangeCheckbox("smoker", false)}
                        />
                    </div>
                    <div className="select-option-item">
                        <span>Bebe frecuentemente alcohol</span>
                        <div className="flex-grow-1"></div>
                        <RadioButton
                            disabled={isDisabledForm}
                            className="mx-5"
                            label="Sí"
                            name="option-3"
                            id="yes"
                            checked={preoperativePayload.actualCondition?.drinkAlcohol === true}
                            onChange={() => onChangeCheckbox("drinkAlcohol", true)}
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            label="No"
                            name="option-3"
                            id="no"
                            checked={preoperativePayload.actualCondition?.drinkAlcohol === false}
                            onChange={() => onChangeCheckbox("drinkAlcohol", false)}
                        />
                    </div>
                    <div className="select-option-item">
                        <span>Usa lentes de contacto</span>
                        <div className="flex-grow-1"></div>
                        <RadioButton
                            disabled={isDisabledForm}
                            className="mx-5"
                            label="Sí"
                            name="option-4"
                            id="yes"
                            checked={preoperativePayload.actualCondition?.contactLenses === true}
                            onChange={() => onChangeCheckbox("contactLenses", true)}
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            label="No"
                            name="option-4"
                            id="no"
                            checked={preoperativePayload.actualCondition?.contactLenses === false}
                            onChange={() => onChangeCheckbox("contactLenses", false)}
                        />
                    </div>
                    <div className="select-option-item">
                        <span>Probabilidad de un posible embarazo</span>
                        <div className="flex-grow-1"></div>
                        <RadioButton
                            disabled={isDisabledForm}
                            className="mx-5"
                            label="Sí"
                            name="option-5"
                            id="yes"
                            checked={preoperativePayload.actualCondition?.possiblePregnancy === true}
                            onChange={() => onChangeCheckbox("possiblePregnancy", true)}
                        />
                        <RadioButton
                            disabled={isDisabledForm}
                            label="No"
                            name="option-5"
                            id="no"
                            checked={preoperativePayload.actualCondition?.possiblePregnancy === false}
                            onChange={() => onChangeCheckbox("possiblePregnancy", false)}
                        />
                    </div>
                </div>
            </SectionCard>
        );
    };

    return render();
}