import { axiosInstance } from "@/config/axios";
import { ValidateAttentionDiagnosticResponse, ValidateAttentionPayload, ValidateAttentionResponse } from "@/models";
import { PatientHeaderResponse } from "@/models/preconsultation";
import { setHeaders } from "@/utils";

export async function getPatientHeaderService(params?: Record<string, unknown>): Promise<PatientHeaderResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };
    const { data: serviceData } = await axiosInstance.get<PatientHeaderResponse>(
        "medical/clinicHistory/patientHeader/", axiosParams
    );
    return serviceData;
}

export async function validatePatientAttentionStatusService(payload: ValidateAttentionPayload): Promise<ValidateAttentionResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const { data: serviceData } = await axiosInstance.post<ValidateAttentionResponse>(
        "/medical/clinicHistory/validatePatientAttentionStatus/", payload, axiosParams
    );
    return serviceData;
}
export async function validatePatientAttentionStatusDiagnosticService(payload: ValidateAttentionPayload): Promise<ValidateAttentionDiagnosticResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };
    const { data: serviceData } = await axiosInstance.post<ValidateAttentionDiagnosticResponse>(
        "/medical/clinicHistory/startAdxAttention/", payload, axiosParams
    );
    return serviceData;
}

