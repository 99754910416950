import * as yup from "yup";
export interface AuthUserData {
    id: number | null;
    first_name: string;
    second_name: string;
    first_surname: string;
    second_surname: string;
    doc_type: string;
    doc_num: string;
    doc_city: string;
    email: string;
    cellphone: string;
    active: boolean;
    image?: string;
    have_password: boolean;
    signature: string;
    medicalRecord: string | null;
}

export interface AuthInitialState {
    user_data: AuthUserData;
    access_token: string | null;
    is_auth: boolean;
    status?: number;
    message?: string;
}

export type AuthViews = "login" | "recover";

export const recoverSchema = yup.object({
    user_email: yup.string().email().required()
});
export type RecoverInput = yup.InferType<typeof recoverSchema>;

export const loginSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required()
});
export type LoginInputs = yup.InferType<typeof loginSchema>;

export interface RecoverPayload {
    email: string;
    forgotPassword: boolean;
    username: string;
    password: string;
}