import React from "react";

interface IIconSchema {
    height?: number;
    width?: number;
    className?: string;
    onClick?: React.MouseEventHandler<SVGSVGElement>
}

export const IconSchema = ({ height = 13, width = 13, className, onClick }: IIconSchema) => {
    return (
        <svg onClick={onClick} className={className} width={width} height={height} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1354 2.31818H13.3778V1.40909C13.3778 0.909091 13.0369 0.5 12.6202 0.5C12.2036 0.5 11.8627 0.909091 11.8627 1.40909V2.31818H4.2869V1.40909C4.2869 0.909091 3.94599 0.5 3.52933 0.5C3.11266 0.5 2.77175 0.909091 2.77175 1.40909V2.31818H2.01417C1.18084 2.31818 0.499023 3.13636 0.499023 4.13636V18.6818C0.499023 19.6818 1.18084 20.5 2.01417 20.5H14.1354C14.9687 20.5 15.6505 19.6818 15.6505 18.6818V4.13636C15.6505 3.13636 14.9687 2.31818 14.1354 2.31818ZM13.3778 18.6818H2.77175C2.35508 18.6818 2.01417 18.2727 2.01417 17.7727V6.86364H14.1354V17.7727C14.1354 18.2727 13.7945 18.6818 13.3778 18.6818Z" fill="#6E6F7C" />
        </svg>
    );
};