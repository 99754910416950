import axios from "axios";
//
import { fireErrorAlert } from "@/utils";
//
import { statusCodes } from "@/constants/http";
//
import { finishLoading, startLoading } from "@/config/slices/ui";
import { AppDispatch } from "@/config/store";
//
import * as uiActions from "@/config/slices/ui";
import {
    ICorrectedIntraocularPressureParams,
    IGetScreeningParams,
    INewScreeningParams,
    IPachymetryCorrectionParams,
    IUpdateScreeningParams,
    IVisionShootParams,
    PatientRecord,
    PatientRecordParams
} from "@/models";
import { IResponse } from "@/models/general";
//
import { DilatationParams, DilatationPayload } from "@/models/pre-consultation/dilatation";
import { ExternalMedicinePayload, NewMedicinePayload, ReconciliationMedicineParams } from "@/models/pre-consultation/medicine";
import { VitalSignsParams, VitalSignsPayload } from "@/models/pre-consultation/vitalSigns";
import {
    createScreeningService,
    getArticlesService,
    getCorrectedIntraocularPressureService,
    getDilatationsService,
    getMeasurementsServices,
    getPachymetryCorrectionService,
    getPatientHeaderService,
    getPatientRecordsService,
    getScreeningService,
    getVisionShootService,
    updateDilatationService,
    updatePatientRecordService,
    updateScreeningService
} from "@/services";
import { createMedicineService, getDrugRouteAdministrationService, getExternalMedicineService, getOneMedicineService, getOrderMeasureService, getReconciliationMedicineService, saveExternalMedicineService } from "@/services/pre-consultation/medicine";
import { createVitalSignsService, getVitalSignsService, updateVitalSignsService } from "@/services/pre-consultation/vitalSigns";

export function getVisionShoot(params?: IVisionShootParams) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());

            const filters = { ...params };
            const response = await getVisionShootService(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function getScreening(params: IGetScreeningParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());

            const filters = { ...params };
            const response = await getScreeningService(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function createNewScreening(params: INewScreeningParams) {
    return async function () {
        const filters = { ...params };
        const response = await createScreeningService(filters);

        if (response.success) {
            return response;
        }
    };
}

export function updateScreening(params: IUpdateScreeningParams) {
    return async function () {
        const filters = { ...params };
        const response = await updateScreeningService(filters);

        if (response.success) {
            return response;
        }
    };
}

export function getPachymetryCorrection(params: IPachymetryCorrectionParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const filters = { ...params };
            const response = await getPachymetryCorrectionService(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getCorrectedIntraocularPressure(params?: ICorrectedIntraocularPressureParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());

            const filters = { ...params };
            const response = await getCorrectedIntraocularPressureService(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getPatientHeader(params: { appId: number; eaccount: number; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(uiActions.startLoading());
            const filters = { ...params };
            const response = await getPatientHeaderService(filters);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }
        } finally {
            dispatch(uiActions.finishLoading());
        }
    };
}

export function getPatientRecords(params: PatientRecordParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const filters = { ...params };
            const response = await getPatientRecordsService(filters);

            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updatePatientRecord(payload: PatientRecord) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await updatePatientRecordService(payload);
            return response.success;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getMeasurements(accountId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getMeasurementsServices(accountId);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.data;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getVitalSigns(params: VitalSignsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getVitalSignsService(params);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function createVitalSigns(payload: VitalSignsPayload) {
    return async function (dispatch: AppDispatch) {
        const response = await createVitalSignsService(payload);

        if (response.success) {
            return response;
        }
    };
}

export function updateVitalSigns(payload: VitalSignsPayload) {
    return async function () {
        const response = await updateVitalSignsService(payload);

        if (response.success) {
            // return response;
            // fireSuccessAlert("Signos vitales guardados satisfactoriamente.");
        }
    };
}

export function getArticles(appId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getArticlesService(appId);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getDilatations(params: DilatationParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDilatationsService(params);

            if (response.status && response.status !== statusCodes.OK) {
                fireErrorAlert(response.message as string);
                return;
            }
            return response.results;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                fireErrorAlert(error.response?.data.message as string);
            }

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function saveDilatations(payload: DilatationPayload) {
    return async function () {
        const response = await updateDilatationService(payload);

        return response;
    };
}

export function getExternalMedicine() {
    return async function () {
        const response = await getExternalMedicineService();
        return response.results;
    };
}

export async function getOrderMeasure() {
    const response = await getOrderMeasureService();
    if (response) return response.results;
}

export async function getDrugRouteAdm() {
    const response = await getDrugRouteAdministrationService();
    if (response) return response.results;
}

export function createMedicine(payload: NewMedicinePayload) {
    return async function () {
        const response = await createMedicineService(payload);
        return response.results as number;
    };
}

export function saveExternalMedicine(payload: ExternalMedicinePayload) {
    return async function () {
        const response = await saveExternalMedicineService(payload);
        return response.success;
    };
}

export function getReconciliationMedicine(params: ReconciliationMedicineParams) {
    return async function () {
        const response = await getReconciliationMedicineService(params);
        if (response) return {
            results: response.results,
            rowTotal: response.rowTotal
        };
        return {};
    };
}

export function getOneMedicine(medicineId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getOneMedicineService(medicineId);
            if (response) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}