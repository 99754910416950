import { finishLoading, startLoading } from "@/config/slices";
import { AppDispatch } from "@/config/store";
import { IResponse } from "@/models";
import { MedicalConceptsPayload, ResultsTranscriptionForm, TranscriptionFormsParams, TranscriptionParams, TranscriptionReportParams, TranscriptionValidatePayload } from "@/models/transcription/transcription";
import { deleteConceptsService, deleteMedicalConceptsService, getDoctorsByContractsListService, getMedicalConceptsService, getOrgansService, getRiskColorService, getStatusServices, getTranscriptionFormService, getTranscriptionReportService, getTranscriptionService, updateMedicalConceptsService, updateTranscriptionService, validateTranscriptionService } from "@/services/transcription/transcription";
import { fireSuccessAlert, fireWarningAlert } from "@/utils";
import axios from "axios";

export function getStatus(params: { eaccount: number, view: string; }) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getStatusServices(params);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getDoctorsByContractsList(eaccount: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getDoctorsByContractsListService(eaccount);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getMedicalConcepts(eaccount: number, searchValue: string, enabled: number, srvId: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getMedicalConceptsService(eaccount, searchValue, enabled, srvId);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getOrgans(eaccount: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getOrgansService(eaccount);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}
export function getRiskColor(eaccount: number) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getRiskColorService(eaccount);
            return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}


export function getTranscription(params: TranscriptionParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getTranscriptionService(params);
            return response;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function validateTranscription(payload: TranscriptionValidatePayload, doAfterSuccess?: () => void, alert?: boolean) {
    return async function (dispatch: AppDispatch) {
        // dispatch(startLoading());
        const response = await validateTranscriptionService(payload);
        if (response.success) {
            doAfterSuccess &&
                doAfterSuccess();
            alert && fireSuccessAlert(response.message, response.title);

        } else {
            // dispatch(finishLoading());
            alert && fireWarningAlert(response.message, response.title);
        }
        // dispatch(finishLoading());
        return response;
    };
}

export function updateTranscription(payload: ResultsTranscriptionForm, doAfterSuccess?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await updateTranscriptionService(payload);
            if (response.success && doAfterSuccess) {
                doAfterSuccess();
            }
            return response.success;

        } finally {
            // dispatch(finishLoading());
        }
    };
}
export function deleteConcepts(payload: ResultsTranscriptionForm, doAfterSuccess?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await deleteConceptsService(payload);
            if (response.success && doAfterSuccess) {
                doAfterSuccess();
            }
            return response.success;

        } finally {
            // dispatch(finishLoading());
        }
    };
}

export function updateMedicalConcept(payload: MedicalConceptsPayload, doAfterSuccess?: () => void, doAfterError?: () => void) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await updateMedicalConceptsService(payload);
            if (response.success && doAfterSuccess) {
                doAfterSuccess();
            } else {
                doAfterError && doAfterError();
            }
            return response.success;

        } finally {
            // dispatch(finishLoading());
        }
    };
}
export function deleteMedicalConcept(payload: {
    "id": number | null,
    "enabled": number;
}, doAfterSuccess?: () => void, doAfterError?: (response: IResponse) => void) {
    return async function (dispatch: AppDispatch) {
        try {
            // dispatch(startLoading());
            const response = await deleteMedicalConceptsService(payload);
            if (response.success && doAfterSuccess) {
                doAfterSuccess();
            }
            return response.success;

        } catch (error) {
            if (axios.isAxiosError<IResponse>(error)) {
                if (error.response?.data?.message) {
                    fireWarningAlert(error.response?.data?.message.replace("editado", "eliminado"), "Intenta de nuevo");
                }

            }

        }

        finally {
            // dispatch(finishLoading());
        }
    };
}

export function getTranscriptionForm(params: TranscriptionFormsParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getTranscriptionFormService(params);
            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}

export function getTranscriptionReport(params: TranscriptionReportParams) {
    return async function (dispatch: AppDispatch) {
        try {
            dispatch(startLoading());
            const response = await getTranscriptionReportService(params);
            if (response.success) return response.results;

        } finally {
            dispatch(finishLoading());
        }
    };
}



