import { axiosInstance } from "@/config/axios";
import { IResponse } from "@/models";
import { StatusResponse, DiagnosticReadingParams, DiagnosticReadingResponse, DiagnosticReadingUpdatePayload } from "@/models/diagnosticAidReadings/diagnosticAidReadings";
import { setHeaders } from "@/utils";

export async function getDiagnosticReadingService(params: DiagnosticReadingParams): Promise<DiagnosticReadingResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<DiagnosticReadingResponse>(
        "/medical/clinicHistory/readingsAssignedToDoctor/", axiosParams
    );
    return serviceData;
}

export async function updateDiagnosticReadingService(payload: DiagnosticReadingUpdatePayload): Promise<IResponse> {
    const headers = setHeaders();
    const axiosParams = { headers };

    const { data: serviceData } = await axiosInstance.put<IResponse>(
        "/medical/clinicHistory/readingsAssignedToDoctor/", payload, axiosParams
    );
    return serviceData;
}

export async function getStatusServices(params:{eaccount: number, view: string}): Promise<StatusResponse> {
    const headers = setHeaders();
    const axiosParams = { headers, params };

    const { data: serviceData } = await axiosInstance.get<StatusResponse>(
        "/medical/admissions/statusFilters/", axiosParams
    );
    return serviceData;
}

