import React from "react";
import "./ScrollableTable.scss";

interface IScrollableTable extends React.HTMLAttributes<HTMLDivElement> {
    maxHeight?: number;
}

interface ICell extends React.HTMLAttributes<HTMLDivElement> {
    col?: number;
    align?: "left" | "right" | "center";
}

export default function ScrollableTable(props: IScrollableTable) {
    return (
        <div {...props} className={`container-fluid scrollable-table ${props.className}`} style={{ maxHeight: props.maxHeight }}>
            {props.children}
        </div>
    );
}

function Head(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div {...props} className="row table-head">
            {props.children}
        </div>
    );
}

function Body(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div {...props} className="row table-body">
            <div className="col-12" style={{ overflowX: "hidden" }}>
                {props.children}
            </div>
        </div>
    );
}

function Row(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div {...props} className="row">
            {props.children}
        </div>
    );
}

function Cell(props: ICell) {
    return (
        <div {...props} className={`${props.col ? "col-" + props.col : "col"} table-cell ${props.className}`} style={{ ...props.style, textAlign: props.align }}>
            {props.children}
        </div>
    );
}

ScrollableTable.Head = Head;
ScrollableTable.Cell = Cell;
ScrollableTable.Body = Body;
ScrollableTable.Row = Row;