import { IconMail, IconPhone, IconPinFilled } from "@tabler/icons-react";
import { Modal } from "../../../../components";
//
import "../Header.scss";

interface IHelp {
    isOpen: boolean;
    onClose: () => void;
}

export default function HelpModal({ isOpen, onClose }: IHelp) {

    const render = () => {
        return (
            <Modal isOpen={isOpen} onClose={onClose} width={500}>
                <div className="header-profile">
                    <h3 className="text-primary fw-bold text-center">Ayuda</h3>
                    <hr className="mb-4" />
                    <h3 className="fw-bold text-center text-muted mb-5">Información de Contacto</h3>
                    <div className="row mb-5">
                        <div className="col-3 d-flex flex-column align-items-end">
                            <div className="mb-4">
                                <IconPhone className="text-primary" />
                            </div>
                            <div className="mb-4">
                                <IconMail className="text-primary" />
                            </div>
                            <div>
                                <IconPinFilled className="text-primary" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="text-muted fs-5 mb-4">+57 300 658 3265</div>
                            <div className="text-muted fs-5 mb-4">analistadesoporte@ttncompany.com</div>
                            <div className="text-muted fs-5">Carrera 51b # 87 - 50 Piso 3 Local 337</div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    return render();
}